import React, {  } from 'react';
import MainMenuComponent from '../../Components/Menus/MainMenuComponent';

const PayrollHome = () => {

  return (
    <MainMenuComponent menuName="payroll" />
  );
};

export default PayrollHome;
