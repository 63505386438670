import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RecruitmentFrontReviewRole from '../Pages/Recruitment/RecruitmentFrontReviewRole'
import RecruitmentFrontHome from '../Pages/Recruitment/RecruitmentFrontHome'
import RecruitmentFrontHistory from '../Pages/Recruitment/RecruitmentFrontHistory'
import RecruitmentApplyForm from '../Pages/Recruitment/RecruitmentApplyForm'
import AuthenticationMainRouter from './AuthenticationMainRouter'
import RecruitmentFrontPreview from '../Pages/Recruitment/RecruitmentFrontPreview'

const FrontRecruitmentRouter = () => {

  const allRouter = [
    ...AuthenticationMainRouter,
  ];

  return (
    <Routes>
      <Route path="/" element={<RecruitmentFrontHome />} />
      <Route path="/application" element={<RecruitmentFrontHistory />} />
      <Route exact path="/preview/:id" element={<RecruitmentFrontPreview />} />
      <Route path="/preview/:id/apply" element={<RecruitmentFrontReviewRole />} />
      <Route path="/preview/:id/apply/form" element={<RecruitmentApplyForm />} />

      {allRouter.map((item, index) => {
        return <Route key={index} path={item.path} element={item.element} />;
      })}
    </Routes>
  )
}

export default FrontRecruitmentRouter