/* eslint-disable no-useless-catch */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Center,
  Divider,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import AppTableComponentData from '../../Components/AppComponent/AppTableComponentData';
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import {
  addDocumentFirebaseV2,
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from '../../Api/firebaseApi';
import MediaUpload from '../../Components/MediaUpload/MediaUpload';
import { decryptToken } from '../../Utils/encrypToken';
import UploadFileToDropbox from '../../Utils/UploadFileToDropbox';

const HrisAttandanceOvertime = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(null);
  const [requested, setRequested] = useState(null);
  const [rejected, setRejected] = useState(null);
  const [loadFile, setLoadFile] = useState(null);
  const [selectDate, setSelectDate] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [overtimeReason, setOvertimeReason] = useState('');
  const [progress, setProgress] = useState(0)
  const toast = useToast();

  const globalState = useUserStore();

  const getDataOvertime = async () => {
    // const session = {
    //     headers: globalState?.authFirebaseToken,
    //     companyId: globalState?.currentCompany,
    //     projectId: globalState?.currentProject
    // }

    globalState?.setIsLoading(true);

    const conditions = [
      { field: 'user_uid', operator: '==', value: globalState?.uid },
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
    ];
    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = 10;
    const startAfterData = null;

    try {
      const res = await getCollectionFirebase(
        'overtimes',
        conditions,
        sortBy,
        limitValue,
        startAfterData
      );

      const data = res;

      const dataFind = data?.filter((x) => x.user_uid === globalState?.uid);
      const request = dataFind?.filter(
        (x) => x.current_status === null || 'APPROVE MANAGER'
      );
      const approve = dataFind?.filter(
        (x) => x.current_status === 'APPROVE HR'
      );
      const reject = dataFind?.filter(
        (x) =>
          x.current_status === 'REJECT MANAGER' ||
          x.current_status === 'REJECT HR'
      );
      setRequested(request);
      setApproved(approve);
      setRejected(reject);
    } catch (error) {
      throw error;
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const handleFile = (data) => {
    setLoadFile(data);
  };

  const handleCancel = () => {
    setLoadFile(null);
  };

  useEffect(() => {
    getDataOvertime();

    return () => {
      setApproved(null);
      setRequested(null);
      setRejected(null);
    };
  }, [globalState?.currentCompany]);

  const handleAdd = () => {
    setModal(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFile,
    multiple: false,
  });

  const submit = async () => {
    // const session = {
    //     headers: globalState?.authFirebaseToken,
    //     companyId: globalState?.currentCompany,
    //     projectId: globalState?.currentProject
    // }

    // const postData = {
    //     periode: selectDate,
    //     start_hour: start,
    //     end_hour: end,
    //     division_id: 2
    // }

    const data = {
      periode: selectDate,
      start_hour: start,
      end_hour: end,
      division_id: 2,
      current_status: null,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      user_uid: globalState?.uid,
      name: globalState?.name || globalState.email,
      reason: overtimeReason,
    };

    const title = loadFile[0]?.name;
    const type = loadFile[0].type?.split('/')[0];

    if (start === '' || end === '' || selectDate === '' || loadFile === null) {
      toast({
        title: 'All input should be filled',
        duration: 3000,
        status: 'error',
      });
    } else {
      try {
        const token = await getSingleDocumentFirebase('token', 'dropbox');
        const decryptResult = decryptToken(`${token?.access_token}`);


        const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/hris/overtime`;
        // const response = await axios.post(
        //   'https://deoapp-backend-awfnqqp6oq-et.a.run.app/upload_file',
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //     },
        //   }
        // );
        const response = await UploadFileToDropbox(loadFile[0], parentPath, decryptResult, setProgress, progress)

        if (response.link) {
          await addDocumentFirebaseV2(
            'overtimes',
            { ...data, attachment: response.link },
            globalState?.currentCompany
          );

          toast({
            status: 'success',
            description: 'success add new request overtime',
            duration: 2000,
          });
        }
      } catch (error) {
        toast({
          status: 'error',
          description: error.message,
          duration: 2000,
        });
      } finally {
        setLoading(false);
        setSelectDate(null);
        setLoadFile(null);
        setStart(null);
        setEnd(null);
        setModal(false);
        getDataOvertime();
      }
    }
  };

  return (
    <Stack my={5}>
      <Tabs
        isFitted
        variant="enclosed"
        bg={'white'}
        borderRadius={'md'}
        boxShadow={'base'}
      >
        <TabList mb="1em">
          <Tab fontWeight={500}>Request</Tab>
          <Tab fontWeight={500}>Approved</Tab>
          <Tab fontWeight={500}>Rejected</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack justify={'space-between'}>
              <Spacer />
              <DynamicButton
                title="Request"
                action="create"
                onClick={handleAdd}
              />
            </HStack>

            {requested?.length === 0 || !requested ? (
              <Center py={5}>
                <Text color={'darkgrey'}>
                  You have not requested any overtime
                </Text>
              </Center>
            ) : (
              <AppTableComponentData data={requested} />
            )}
          </TabPanel>
          <TabPanel>
            {approved?.length === 0 || !approved ? (
              <Center py={5}>
                <Text color={'darkgrey'}>No overtime approved</Text>
              </Center>
            ) : (
              <AppTableComponentData data={approved} />
            )}
          </TabPanel>
          <TabPanel>
            {rejected?.length === 0 || !rejected ? (
              <Center py={5}>
                <Text color={'darkgrey'}>No overtime rejected</Text>
              </Center>
            ) : (
              <AppTableComponentData data={rejected} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        size={'xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Overtime</ModalHeader>
          <ModalBody>
            <Stack mb={8}>
              <Text fontSize={'xs'}>Select Date</Text>
              <Input
                type="date"
                onChange={(e) => setSelectDate(e.target.value)}
              />
            </Stack>
            <Divider my={3} />
            <Stack py={3}>
              <Text align={'center'}>Duration</Text>
              <HStack w={'full'}>
                <Stack w={'50%'}>
                  <Text fontSize={'xs'} align={'center'}>
                    Start
                  </Text>
                  <Input
                    type="time"
                    onChange={(e) => setStart(e.target.value)}
                  />
                </Stack>
                <Stack w={'50%'}>
                  <Text fontSize={'xs'} align={'center'}>
                    End
                  </Text>
                  <Input type="time" onChange={(e) => setEnd(e.target.value)} />
                </Stack>
              </HStack>
              <Text fontSize={'xs'} mt={3}>
                Add Attachment
              </Text>
              <MediaUpload getRootProps={getRootProps} getInputProps={getInputProps} loadFile={loadFile} handleCancel={handleCancel} />
              <Stack my={3}>
                <Text fontSize={'xs'}>Describe Reason</Text>
                <Textarea
                  mb={5}
                  onChange={(e) => setOvertimeReason(e.target.value)}
                />
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button colorScheme="red" onClick={() => setModal(false)}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={submit} isLoading={loading}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default HrisAttandanceOvertime;
