/* eslint-disable react/prop-types */
import { Flex, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

const AppShortcut = ({ icon, label, link }) => {
  return (
    <Stack spacing={2} align="center" px={2}>
      <Link to={link}>
        <Flex
          bg="gray.100"
          shadow={'md'}
          align="center"
          justify="center"
          rounded="100%"
          h={12}
          w={12}
          _hover={{ bg: 'gray.400' }}
        >
          {icon}
        </Flex>
      </Link>
      {/* <Text fontSize="0.8rem" color={isMorning ? "white" : isAfternoon ? "black" : isEvening ? "black" : "white"}>{label}</Text> */}
      <Text fontSize="sm" fontWeight={'bold'} color="gray.900">{label}</Text>
    </Stack>
  );
};

export default AppShortcut;
