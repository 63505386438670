import {
  HStack,
  Heading,
  Input,
  Spacer,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import useUserStore from '../../Hooks/Zustand/Store';
import { createHris, deleteHris, patchHris, readHris } from '../../Api/Hris';
import DynamicTable from '../../Components/Table/DynamicTable';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';

const HrisLeaveCategory = () => {
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [leave, setLeave] = useState([]);
  const modalDelete = useDisclosure()
  const [selectedCategory, setSelectedCategory] = useState()
  const [addLeave, setAddLeave] = useState({
    name: '',
    initial_balance: 0,
  });

  const toast = useToast()
  const tableHeader = ['name', 'initial balance']

  const globalState = useUserStore();

  const [mode, setMode] = useState({
    mode: '',
    title: '',
  });

  const getLeaveCategory = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris('leave-request-category', session);
      setLeave(data);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description:error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });    }
  };

  useEffect(() => {
    getLeaveCategory();

    return () => {
      setLeave([]);
    };
  }, []);

  const tableData = leave?.map((x) => {
    return {
      x,
      'name' : x.name,
      'initial balance' : x.initial_balance
    }
  })

  const handleDetail = (x) => {
    setDetail(x);
    setMode({ mode: 'detail', title: 'Leave Detail' });
    setModal(true);
  };

  const handleAdd = () => {
    setMode({ mode: 'add', title: 'Add Leave Form' });
    setModal(true);
  };

  const handleModalDelete = (value) => {
    setSelectedCategory(value)
    modalDelete?.onOpen()
  }

  const handleDelete = async() => {
    setIsLoading(true)
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    }

    try {
      await deleteHris(`leave-request-category/${selectedCategory?.x?.id}/delete`, session)

      toast({
        status: 'success',
        title: 'Category deleted',
        duration: 3000
      })
    } catch (error) {
      throw new Error(error, 'An Error Occured')
    } finally {
      setIsLoading(false)
      getLeaveCategory()
      modalDelete?.onClose()
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      await createHris(
        'leave-request-category',
        session,
        addLeave
      );
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description:error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });    } finally {
      setModal(false);
      getLeaveCategory();
      setLoading(false);
    }
  };

  const handleEdit = async () => {
    setLoading(true);

    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      await patchHris(
        `leave-request-category/${detail?.id}/update`,
        session,
        detail
      );
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description:error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });    
    } finally {
      setModal(false);
      getLeaveCategory();
      setLoading(false);
    }
  };
  return (
    <Stack>
      <HStack my={5} flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size={'md'} fontWeight="bold">
          Leave Category
        </Heading>
        <Spacer />
        <DynamicButton
          title={'Add Leave Category'}
          action={'create'}
          onClick={handleAdd}
        />
      </HStack>
      <DynamicTable header={tableHeader} data={tableData} onEdit={handleDetail} onDelete={handleModalDelete}/>
    
      {mode.mode === 'detail' ? (
        <Modal
          isOpen={modal}
          onClose={() => setModal(false)}
          motionPreset="slideInBottom"
          isCentered
          size={'xl'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{mode.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack align={'flex-start'} my={2}>
                <Text>Leave Type</Text>
                <Input
                  value={detail?.name}
                  onChange={(e) =>
                    setDetail({ ...detail, name: e.target.value })
                  }
                />
              </VStack>
              <VStack align={'flex-start'} my={2}>
                <Text>Initial Balance</Text>
                <Input
                  value={detail?.initial_balance}
                  // defaultValue={detail?.initial_balance}
                  onChange={(e) =>
                    setDetail({
                      ...addLeave,
                      initial_balance: parseInt(e.target.value),
                    })
                  }
                />
              </VStack>
            </ModalBody>
            <ModalFooter gap={5}>
              <DynamicButton
                title={'Cancel'}
                action="custom"
                onClick={() => setModal(false)}
                variant="solid"
                color={'red'}
              />
              <DynamicButton
                title={'Submit'}
                action="create"
                isLoading={loading}
                onClick={() => handleEdit()}
                variant="solid"
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          isOpen={modal}
          onClose={() => setModal(false)}
          motionPreset="slideInBottom"
          isCentered
          size={'xl'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{mode.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack my={3}>
                <Text fontSize={'sm'}>Leave Type</Text>
                <Input
                  placeholder="Input Type"
                  onChange={(e) =>
                    setAddLeave({ ...addLeave, name: e.target.value })
                  }
                />
              </Stack>
              <Stack my={3}>
                <Text fontSize={'sm'}>Leave Balance</Text>
                <Input
                  placeholder="Input Initial Balance"
                  type="number"
                  onChange={(e) =>
                    setAddLeave({
                      ...addLeave,
                      initial_balance: parseInt(e.target.value),
                    })
                  }
                />
              </Stack>
            </ModalBody>
            <ModalFooter gap={5}>
              <DynamicButton
                title={'Cancel'}
                action="custom"
                color={'red'}
                onClick={() => setModal(false)}
                variant="solid"
              />
              <DynamicButton
                title={'Submit'}
                action="create"
                isLoading={loading}
                onClick={() => handleSubmit()}
                variant="solid"
              />
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <AlertDialogV2 onClose={modalDelete?.onClose} isOpen={modalDelete?.isOpen} onAction={handleDelete} title={'Delete leave category'} isLoading={isLoading}/>
    </Stack>
  );
};

export default HrisLeaveCategory;
