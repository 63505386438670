import {
  HStack, Heading, Stack, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import DynamicTable from '../../Components/Table/DynamicTable';
import BackButtons from '../../Components/Buttons/BackButtons';
import PropTypes from 'prop-types';
import CreateKPI from '../../Components/Form/CreateKPI';
import { deleteDocumentFirebase, getCollectionFirebase } from '../../Api/firebaseApi';

const HrisKpiListPage = (props) => {
  const tableHeader = ['matrix', 'target', 'bobot', 'keterangan', 'indikator', 'deadline', 'interval'];
  const [kpiList, setKpiList] = useState([]);
  const [selected, setSelected] = useState({});
  const [loadingDelete, setLoadingDelete] = useState(false);


  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();


  const getKpis = async (force = false) => {
    if (props.list && kpiList.length === 0 && !force) {
      try {
        const res = await getCollectionFirebase('kpi_target_performance');
        setKpiList(res)
      } catch (error) {
        // console.log(error.message, 'error getting kpis')
      }
    }
  };

  const handleDelete = (data) => {
    setSelected(data);
    onOpen();
  };

  const onDelete = async () => {
    setLoadingDelete(true);
    try {
      await deleteDocumentFirebase('kpi_target_performance', selected.id);
      toast({
        title: 'Success',
        isClosable: true,
        duration: 9000,
        status: 'success'
      });
      onClose();
      getKpis(true);
    } catch (error) {
      toast({
        title: 'error',
        description: error.message,
        isClosable: true,
        duration: 9000,
        status: 'error'
      })

    } finally {
      setLoadingDelete(false);
    }
  };


  useEffect(() => {
    getKpis();
  }, [])

  return (
    <>
      <Stack p={[0, 1, 5]}>
        <HStack pb={3}>
          <BackButtons />
          {/* <Spacer /> */}
          <Heading size={'md'}>
            {props.list ? 'Key Performance Indicators' : props.create && 'Create New KPI'}

          </Heading>
        </HStack>
        {props.list ?
          <DynamicTable header={tableHeader} data={kpiList} onDelete={handleDelete} />
          :
          props.create && <CreateKPI />}

      </Stack>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>You want to delete this KPI?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Matrix : <strong>{selected?.matrix || 'null'}</strong></Text>
            <Text>Bobot : {selected?.bobot || 'null'}</Text>
            <Text>Keterangan : {selected?.keterangan || 'null'}</Text>
            <Text>Indikator : {selected?.indikator || 'null'}</Text>
            <Text>Deadline : {selected?.deadline || 'null'}</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onDelete}
              isLoading={loadingDelete}
              variant='ghost'
              loadingText={'Deleting...'}
            >Yes, delete</Button>
            <Button colorScheme='red' mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

HrisKpiListPage.propTypes = {
  list: PropTypes.bool,
  create: PropTypes.bool,
};

export default HrisKpiListPage