/* eslint-disable react/jsx-key */
import {
  HStack,
  Heading,
  Input,
  Spacer,
  Stack,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
  Image,
  useToast
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { deleteDocumentFirebase, getCollectionFirebase, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicTable from '../../Components/Table/DynamicTable';
import * as XLSX from 'xlsx'; // Import pustaka xlsx
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import useUserStore from '../../Hooks/Zustand/Store';
import { encryptToken } from '../../Utils/encrypToken';
import moment from 'moment';
import { db } from '../../Config/firebase';
import { FaCalendar } from 'react-icons/fa6';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { clientTypessense } from '../../Api/Typesense';
import { Chart } from 'chart.js/auto';

const RecruitmentAssessmentResultPage = () => {
  const toast = useToast()
  const globalState = useUserStore()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [selected, setSelected] = useState({})
  const [modal, setModal] = useState(false)
  const [filterDate, setFilterDate] = useState(false)
  const tableHeader = ['name', 'phone', 'profile', 'house', 'createdAt'];
  const searchTableHeader = ['name'];
  const [profile, setProfile] = useState({})
  const [selectedDate, setSelectedDate] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [chart, setChart] = useState();
  const itemsPerPage = 10;

  const chartContainer = useRef();

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  }


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const wdProfile = [
    {
      profile: 'Mechanic',
      character: ['Innovative', 'perfectionist', 'detail oriented'],
      talent: 'Mechanic are your best completers. they will wrap up the process and find smart ways to do it better next time. Dont ask a Mechanic to start from scratch, but do give them an existing process or product to improve on',
      image: 'https://www.tdprofiletest.com/wp-content/themes/tdprofiletest/images/icons/mechanic.jpg',
      // house: 'steel'
      link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20MECHANIC.pdf?alt=media&token=f9fe7294-508e-4d5d-8b53-ca9c9ebc3f17&_gl=1*1264b3j*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MTY3LjExLjAuMA..'
    },
    {
      profile: 'Deal Maker',
      character: ['Outgoing', 'entertaining', 'approachable', 'mischievous'],
      talent: 'Deal Makers are the best peacemakers, and will leave everyone feeling good. Dont Expect them to go out cold cslling, as they thrive in building the relationship they have. Work with Deal Makers to nurture the costumer and relationship you have',
      image: 'https://www.tdprofiletest.com/wp-content/themes/tdprofiletest/images/icons/deal_maker.jpg',
      // house: 'steel'
      link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20DEAL%20MAKER.pdf?alt=media&token=1c2d516a-c982-4d03-9eb4-f090363f825e&_gl=1*viyhq4*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MTQ0LjM0LjAuMA..'
    },
    {
      profile: 'Accumulator',
      character: ['Reliable', 'careful', 'considered', 'meticulous'],
      talent: 'Accumulators are your best ambassadors. they are reliable, get things done on time, and are not prone to making rash decisions. Accumulators make the best projects managets, when a spesific task needs to be delivered reliably.',
      image: 'https://www.tdprofiletest.com/wp-content/themes/tdprofiletest/images/icons/accumulator.jpg',
      // house: 'steel'
      link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20ACCUMULATOR.pdf?alt=media&token=b1f4079a-8d5c-4e49-bdf8-1fef0c16c2c1&_gl=1*zs29vr*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MDk4LjIwLjAuMA..'
    },
    {
      profile: 'Star',
      character: ['Vibrant', 'energising', 'image driven'],
      talent: 'Star are the best promoters, but need to lead from the front. Give them the change to shine, and give them the space to deliver result without trying them down. Give them the systems and support to enable them to focus on building new bussiness.',
      image: 'https://www.tdprofiletest.com/wp-content/themes/tdprofiletest/images/icons/star.jpg',
      // house: 'steel'
      link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20STAR.pdf?alt=media&token=883bbfd6-5d9e-4b96-812f-05fb30720e3d&_gl=1*1c8dudo*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MDU4LjYwLjAuMA..'
    },
    {
      profile: 'Lord',
      character: ['Controlling', 'Cautious', 'Organised', 'Detailed'],
      talent: 'Lord are your best analysts. Give them space to study the detail, and to deliver the data. Dont ask them to go out and network, as they are strongest when focused behind the scenes',
      image: 'https://www.tdprofiletest.com/wp-content/themes/tdprofiletest/images/icons/lord.jpg',
      house: 'steel',
      link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20LORD.pdf?alt=media&token=6cd5a024-8990-4a6e-b953-10bceea9619d&_gl=1*1fy2pp6*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MDIxLjQuMC4w'
    },
    {
      profile: 'Trader',
      character: ['Sensory', 'Balanced', 'Observant', 'Insightful'],
      talent: 'Trander are the best negotiators. They will always get you the best price, in both what you buy and what you sell. They want to be given the parameters in which to work. Traders will have their ear to the ground in your market and your team',
      image: 'https://www.tdprofiletest.com/wp-content/themes/tdprofiletest/images/icons/trader.jpg',
      house: 'tempo',
      link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20TRADER.pdf?alt=media&token=bd78f74d-a864-4aab-bc7f-952d80060a3f&_gl=1*1ymkutt*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MDAyLjIzLjAuMA..'
    },
    {
      profile: 'Supporter',
      character: ['Relationship focused', 'Confidence building'],
      talent: 'Supporters are the best leaders, but dont expect them to come up with the plan. Build a plan and set the goals with a Supporter, and then let them lead the team towards the plan, Let them set their own management style, and their own agenda.',
      image: 'https://www.tdprofiletest.com/wp-content/themes/tdprofiletest/images/icons/supporter.jpg',
      house: 'blaze',
      link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20SUPPORTER.pdf?alt=media&token=781b7461-ddb6-4cce-bf00-82e6ad0d9b5b&_gl=1*18oepyq*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI4OTY1LjYwLjAuMA..'
    },
    {
      profile: 'Creator',
      character: ['Visioner', 'Creative', 'Optimistic', 'Stimulating'],
      talent: 'Creator are the best initiators, althought they can be quick to create chaos, they can laso be surprisingly innovative at finding ways out of chaos. Always put them in charge of new project, but move them on to the next creation once their job is done.',
      image: 'https://www.tdprofiletest.com/wp-content/themes/tdprofiletest/images/icons/creator.jpg',
      house: 'dynamo',
      link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20CREATOR.pdf?alt=media&token=4e212928-e169-4899-be26-298d13358709&_gl=1*1k0wjmn*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMC4xNjk5MzI4ODU4LjYwLjAuMA..'
    }

  ]

  const getWD = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'type', operator: '==', value: 'assessment' },
    ]
    if (selectedDate) {
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      conditions.push(
        { field: 'createdAt', operator: '>=', value: startDate },
        { field: 'createdAt', operator: '<=', value: endDate }
      );
    }

    const sortBy = { field: 'createdAt', direction: 'desc' };
    const limitValue = startIndex + itemsPerPage;
    try {
      const res = await getCollectionFirebase(
        'files',
        conditions,
        sortBy,
        limitValue,
      );
      setData(res);

      const collectionRef = collection(db, 'files');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
          where('type', '==', 'assessment')
        ));

      const assesmentLength = snapshot.data().count;



      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));

    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const inputStyles = {
    '&::placeholder': {
      color: 'gray.500',
    },
  };

  const handleDownload = () => {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'RecruitmentData');
    XLSX.writeFile(wb, 'recruitment_data.xlsx');
  };

  useEffect(() => {
    getWD();
    // filterDataByName();
  }, [currentPage, selectedDate]);

  const onDelete = async (x) => {
    setSelected(x)
    setAlert(true)
  }

  const handleDelete = async () => {
    setLoading(true)
    try {
      await deleteDocumentFirebase('files', selected?.x?.id)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      getWD()
      setLoading(false)
      setSelected({})
      setAlert(false)
    }
  }

  const onDetail = async (x) => {
    if (isSearching) {
      try {
        const result = await getSingleDocumentFirebase('files', x.id);
        const finds = wdProfile?.find((y) => (y?.profile)?.toLowerCase() === (result?.profile)?.toLowerCase());
        setProfile(finds);
        setSelected(result);
        setModal(true);
        const ctx = chartContainer.current.getContext('2d');

        if (chart) {
          chart.destroy();
        }

        const chartData = {
          labels: [
            'Red',
            'Blue',
            'Yellow'
          ],
          datasets: [{
            label: 'My First Dataset',
            data: [300, 50, 100],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)'
            ],
            hoverOffset: 4
          }]
        };

        const newChart = new Chart(ctx, {
          type: 'doughnut',
          data: chartData
        });

        setChart(newChart);
      } catch (error) {
        // console.log(error.message, 'error getting detail')
      }
    } else {
      try {
        setSelected(x)
        const finds = wdProfile?.find((y) => (y?.profile)?.toLowerCase() === (x?.profile)?.toLowerCase())
        setProfile(finds)
        setModal(true)
      } catch (error) {
        // console.log(error)
      }
    }

  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`https://hr.deoapp.com/wealth-dynamic?company=${encodeURIComponent(encryptToken(globalState.currentCompany))}`);

    toast({
      description: `link copied! ${globalState.currentCompany}`,
      status: 'success',
      duration: 1000,
    });
  }

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };


  const tableData = data?.map((x) => {
    return {
      x,
      'name': x?.name,
      'phone': x?.phone,
      'profile': x?.profile,
      'house': x?.house,
      'createdAt': moment(x?.createdAt.seconds * 1000).format('DD-MM-YYYY')
    }
  })
  

  const handleCancel = () => {
    setSelectedDate('')
    setFilterDate(false)
  }

  const querySearchTypesense = async (q) => {
    // const searchResults = [];
    if (q?.length >= 3) {
      setIsSearching(true)
      const searchParameters = {
        q: q,
        query_by: 'title',
        filter_by: 'type: assessment',
      };


      clientTypessense
        .collections('files')
        .documents()
        .search(searchParameters)
        .then((x) => {
          const newData = x.hits.map((y) => { return { ...y.document, name: y.document.title } })
          // newData.forEach((x) => {
          //   getSingleDocumentFirebase('files', x.id).then((y) => {
          //     searchResults.push(y)
          //     console.log(y, 'y')
          //   });
          // })
          // console.log(searchResults, 'setsearch');
          setSearchSuggestions(newData);
        });



    } else {
      setIsSearching(false);
    }
  };


  return (
    <Stack p={5}>
      <HStack gap={5} flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size={'md'} fontWeight="bold">
          Wealth Dynamic Result
        </Heading>
        <Spacer />
        <Input
          w={['100%', '30%', '30%']}
          type="text"
          placeholder="Search Name (type minimum 3 characters)"
          bgColor="white"
          color="black"
          sx={inputStyles}
          fontSize="sm"
          // onChange={(e) => searchFilterFunction(e.target.value)}
          onChange={(e) => querySearchTypesense(e.target.value)}
        />
        <Button
          variant="outline"
          colorScheme="teal"
          onClick={() => setFilterDate(true)}
        >
          <FaCalendar />
        </Button>
        <DynamicButton title={'Generate Link'} action={'create'} onClick={() => handleCopyLink()} />
        <Button
          variant="outline"
          colorScheme="teal"
          onClick={handleDownload}
        >
          Download Data (XLSX)
        </Button>
      </HStack>

      {isSearching ? (
        <DynamicTable header={searchTableHeader} data={searchSuggestions} onDelete={onDelete} onRead={onDetail} />

      ) :
        <DynamicTable header={tableHeader} data={tableData} onDelete={onDelete} onRead={onDetail} />
      }

      <AlertDialogV2 onAction={handleDelete} title={`Delete ${selected?.name}`} isOpen={alert} onClose={() => setAlert(false)} isLoading={loading} />

      <Stack alignItems={'center'} justifyContent="center">
        {currentPage < totalPages && (
          <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
            Load More
          </Button>
        )}
      </Stack>

      <AlertDialogV2 onAction={handleDelete} title={`Delete ${selected?.name}`} isOpen={alert} onClose={() => setAlert(false)} isLoading={loading} />
      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selected?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack my={2} justify={'space-between'}>
              <Heading fontSize={'sm'}>Name</Heading>
              <Text>{selected?.name}</Text>
            </HStack>
            <HStack my={2} justify={'space-between'}>
              <Heading fontSize={'sm'}>Profile</Heading>
              <Text>{profile.profile}</Text>
            </HStack>
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pb={10}
              bgColor='white'
              p={2}
              borderRadius="xl"
            >
              <Stack w={['full', '', 'full']}>

                <Stack w={'100%'} borderRadius={'xl'} boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'>
                  <HStack gap={5} p={5}>
                    <Stack w={['20%', '12%', '15%']}>
                      <Image src={profile.image} />
                    </Stack>
                    <Stack w={['80%', '80%', '85%']}>
                      <Stack>
                        <Heading fontSize={'2xl'}>The {profile.profile}</Heading>
                      </Stack>
                      <HStack>
                        {profile?.character?.map((z) => (
                          <HStack w={'full'}>
                            <Text color={'darkgray'} fontSize={['xs', 'xs', 'xs']} mr={[1, 1, 1]}>{z}</Text>
                          </HStack>
                        ))}
                      </HStack>
                    </Stack>
                  </HStack>
                  <Stack maxW={['md', '2xl', '3xl']} px={5} mb={2}>
                    <Text fontSize={['sm', 'md', 'md']}>
                      {profile.talent}
                    </Text>
                  </Stack>
                  <HStack bgColor={'#112344'} p={2} px={5} borderBottomRadius={'xl'} justify={'end'}>
                    <Text color={'white'} fontSize={'sm'} cursor={'pointer'} _hover={{ color: 'blue.200' }} onClick={() => window.open(profile.link)}>See Your Detail Result</Text>
                    {/* <AiOutlineArrowRight size={15} color={'white'}/> */}
                  </HStack>
                </Stack>
              </Stack>
              <Text><strong>Dynamo</strong> : {selected?.totalScore?.dynamo} </Text>
              <Text><strong>Blaze</strong> : {selected?.totalScore?.blaze}</Text>
              <Text><strong>Tempo</strong> : {selected?.totalScore?.tempo}</Text>
              <Text><strong>Steel</strong> : {selected?.totalScore?.steel}</Text>
              {/* <canvas ref={chartContainer} width="auto" height="auto"></canvas> */}

            </Stack>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={filterDate} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalBody>
            <Input
              type="date"
              placeholder="Select Date"
              bgColor="white"
              color="black"
              sx={inputStyles}
              fontSize="sm"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button colorScheme='red' onClick={handleCancel}>Cancel</Button>
              <Button colorScheme='teal' onClick={() => setFilterDate(false)}>Filter</Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default RecruitmentAssessmentResultPage;