
import React from 'react';
import MainMenuComponent from '../../Components/Menus/MainMenuComponent';

function HomePage() {

  return (
    <MainMenuComponent menuName="ga" />

  );
}

export default HomePage;
