import React, { useEffect, useState } from 'react'
import { Stack, HStack, Heading, Center, Spinner, Box } from '@chakra-ui/react'
import BackButtons from '../../Components/Buttons/BackButtons'
import { deleteDocumentFirebase, getCollectionFirebaseVI } from '../../Api/firebaseApi'
import useUserStore from '../../Hooks/Zustand/Store'
import DynamicTable from '../../Components/Table/DynamicTable'
import { useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2'
import ExportExcel from '../../Utils/ExportExcel'

const HrisPayslipPage = () => {
  const { period } = useParams()
  const globalState = useUserStore()
  const [loading, setLoading] = useState()
  const [payslip, setPayslip] = useState()
  const [alertShow, setAlertShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const navigate = useNavigate()
  const tableHeader = ['name', 'company', 'role'];
  const { state } = useLocation();
  const { periodeName } = state; // Rea

  const getPayslip = async () => {
    setLoading(true)
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'periodeId', operator: '==', value: period }
    ]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    try {
      const res = await getCollectionFirebaseVI('payroll_payslip', { conditions }, { sortBy }, { limitValue }, { startAfterData })
      
      setPayslip(res)
    } catch (error) {
      // console.log(error, 'ini error')
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    getPayslip()
  }, [])

  const tableData = payslip?.map((x) => {
    return {
      x,
      'name': x.name,
      'company': x.organization,
      'role': x.role
    }
  })

  const handleEdit = () => {

  }

  const handleDelete = (x) => {
    setSelectedItem(x);
    setAlertShow(true);
  }

  const onDelete = async () => {
    try {
      await deleteDocumentFirebase('payroll_payslip', selectedItem.x.payslipId);
      getPayslip();
      setAlertShow(false);
    } catch (error) {
      // console.log('error deleting payslip,', error.message);
    }
  };


  const handleDetail = (x) => {
    navigate(`/hris/payroll/${period}/payslip/${x?.x?.payslipId}`, { state: { periodeName } })
  }


  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack justifyContent={'space-between'}>
        <HStack>
          <BackButtons />
          <Heading size="md">Payslip</Heading>
        </HStack>
        <ExportExcel
          sheetNames={'Data'}
          title={'Download Excel'}
          excelData={tableData}
          fileName={'Monthly Company Attendance Report'}
          colorScheme={'orange'}
          size={'md'}
          variant={'outline'}
        />
      </HStack>
      {loading ?
        <Stack p={8}>
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='rgba(54, 162, 235, 0.6)'
              size='xl'
            />
          </Center>
        </Stack>
        :
        <Box>
          <DynamicTable header={tableHeader} data={tableData} onEdit={handleEdit} onDelete={handleDelete} onRead={handleDetail} />
        </Box>
      }

      {alertShow && <AlertDialogV2
        isLoading={loading}
        isOpen={alertShow}
        onClose={() => setAlertShow(false)}
        onAction={onDelete}
        title={`Are you sure to delete ${selectedItem.name}-${selectedItem.company}`}
        onDelete
        onUpdate
      />}
    </Stack>
  )
}

export default HrisPayslipPage