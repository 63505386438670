import {
  FcCalendar,
  FcConferenceCall,
  FcSettings,
  FcSurvey,
  FcBriefcase,
  FcInTransit,
  FcRules,
  FcMoneyTransfer,
  FcCurrencyExchange,
  FcDepartment,
  FcFrame,
  FcBusinessman,
  FcHighPriority,
  FcPlanner,
  FcLeave,
  FcAnswers,
  FcOvertime,
  FcDebt,
  FcLowPriority,
  FcDonate,
  FcInspection,
  FcPaid,
  FcViewDetails,
  FcBusiness,
  FcApprove,
  FcCheckmark,
  FcComboChart,
} from 'react-icons/fc';

export const data = [


  // {
  //   name: 'hris',
  //   icon: FcCollaboration,
  //   link: '/hris',
  //   description:
  //     'All-in-one HRIS solution that simplifies HR tasks, enhances compliance, and provides valuable data-driven insights for strategic decision-making.',
  //   submenu: [
  //     {
  //       name: 'Attendance',
  //       icon: FcCalendar,
  //       link: '/hris/activity',
  //       submenu: [
  //         {
  //           name: 'Activity',
  //           icon: FcSurvey,
  //           link: '/hris/home',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Attendances & Approvals',
  //       icon: FcCheckmark,
  //       link: '/hris/activity',
  //       submenu: [
  //         {
  //           name: 'Penalty',
  //           icon: FcHighPriority,
  //           link: '/hris/penalty',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'Attendance',
  //           icon: FcCalendar,
  //           link: '/hris/attendance',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'Correction Request',
  //           icon: FcAnswers,
  //           link: '/hris/correction',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'Leave Request',
  //           icon: FcLeave,
  //           link: '/hris/leave',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'Overtime Request',
  //           icon: FcOvertime,
  //           link: '/hris/overtime',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'Employee Loans',
  //           icon: FcDebt,
  //           link: '/hris/payroll/employee-loans',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //       ],
  //     },

  //     {
  //       name: 'Payroll',
  //       icon: FcMoneyTransfer,
  //       link: '/hris/payroll',
  //       submenu: [
  //         {
  //           name: 'Payroll',
  //           icon: FcMoneyTransfer,
  //           link: '/hris/payroll',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'Salary',
  //           icon: FcDonate,
  //           link: '/hris/salary',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'Allowance',
  //           icon: FcLowPriority,
  //           link: '/hris/payroll/allowance',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'Deductions',
  //           icon: FcCurrencyExchange,
  //           link: '/hris/payroll/deduction',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'My Payslip',
  //           icon: FcRules,
  //           link: '/hris/payslip',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'Loans',
  //           icon: FcDebt,
  //           link: '/hris/loans',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //       ],
  //     },

  //     {
  //       name: 'Recruitment',
  //       icon: FcConferenceCall,
  //       link: 'https://recruitment-deoapp.firebaseapp.com/',
  //       submenu: [
  //         {
  //           name: 'Profile',
  //           icon: FcFrame,
  //           link: '/recruitment/profile',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'Job List',
  //           icon: FcBriefcase,
  //           link: '/recruitment/job-listing',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },

  //         {
  //           name: 'Assessment Result',
  //           icon: FcBusinessman,
  //           link: '/recruitment/assessment',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //       ],
  //     },

  //     {
  //       name: 'Settings',
  //       icon: FcSettings,
  //       link: 'https://recruitment-deoapp.firebaseapp.com/',
  //       submenu: [
  //         {
  //           name: 'Project',
  //           icon: FcBriefcase,
  //           link: '/configuration/project',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'Office',
  //           icon: FcDepartment,
  //           link: '/hris/office',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         // {
  //         //   name: "Division",
  //         //   icon: FcSerialTasks,
  //         //   link: "/hris/division",
  //         //   description:
  //         //     "Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.",
  //         // },
  //         {
  //           name: 'Leave Category',
  //           icon: FcLeave,
  //           link: '/hris/leave-category',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'Penalty Category',
  //           icon: FcHighPriority,
  //           link: '/hris/penalty-category',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'Days Off',
  //           icon: FcPlanner,
  //           link: '/hris/days-off',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'Users',
  //           icon: FcConferenceCall,
  //           link: '/hris/users',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'Role',
  //           icon: FcLockLandscape,
  //           link: '/hris/role',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //         {
  //           name: 'KPI',
  //           icon: FcInspection,
  //           link: '/hris/kpi',
  //           description:
  //             'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
  //         },
  //       ],
  //     },
  //   ],
  // },

  {
    name: 'attendance',
    icon: FcCalendar,
    link: '/attendance',
    description:
    'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Activity',
        icon: FcSurvey,
        link: '/attendance/home',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'My Payslip',
        icon: FcRules,
        link: '/hris/payslip',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'approval',
    icon: FcCheckmark,
    link: '/approval',
    description:
    'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Penalty',
        icon: FcHighPriority,
        link: '/hris/penalty',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Attendance',
        icon: FcCalendar,
        link: '/hris/attendance',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Correction Request',
        icon: FcAnswers,
        link: '/hris/correction',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Leave Request',
        icon: FcLeave,
        link: '/hris/leave',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Overtime Request',
        icon: FcOvertime,
        link: '/hris/overtime',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Employee Loans',
        icon: FcDebt,
        link: '/hris/payroll/employee-loans',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },
  
  {
    name: 'payroll',
    icon: FcMoneyTransfer,
    link: '/payroll',
    description:
    'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Payroll',
        icon: FcMoneyTransfer,
        link: '/hris/payroll',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Salary',
        icon: FcDonate,
        link: '/hris/salary',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Allowance',
        icon: FcLowPriority,
        link: '/hris/payroll/allowance',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Deductions',
        icon: FcCurrencyExchange,
        link: '/hris/payroll/deduction',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

  
      {
        name: 'Loans',
        icon: FcDebt,
        link: '/hris/loans',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  {
    name: 'recruitment',
    icon: FcConferenceCall,
    link: '/recruitment',
    description:
    'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
    submenu: [
      {
        name: 'Profile',
        icon: FcFrame,
        link: '/recruitment/profile',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Job List',
        icon: FcBriefcase,
        link: '/recruitment/job-listing',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },

      {
        name: 'Assessment Result',
        icon: FcBusinessman,
        link: '/recruitment/assessment',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },

  

  
  {
    name: 'ga',
    icon: FcPaid,
    link: '/ga',
    // status: "Coming Soon",
    description:
      'Transform financial operations, streamlining tasks, ensuring compliance while providing valuable data-driven insights for informed financial decisions.',
    submenu: [
      {
        name: 'Asset Management',
        icon: FcFrame,
        link: '/',
        submenu: [
          {
            name: 'Assets',
            icon: FcDepartment,
            link: '/ga/asset',
          },

          {
            name: 'Category',
            icon: FcViewDetails,
            link: '/ga/asset/category',
          },

          {
            name: 'Brand',
            icon: FcBusiness,
            link: '/ga/asset/brand',
          },

          {
            name: 'Location',
            icon: FcInTransit,
            link: '/ga/asset/location',
          },
          {
            name: 'Asset Status',
            icon: FcApprove,
            link: '/ga/asset/status',
          },
          {
            name: 'Report',
            icon: FcComboChart,
            link: '/ga/asset/report-depreciation',
          },
        ],
      },
    ],
  },

  {
    name: 'settings',
    icon: FcSettings,
    link: '/setting',
    description:
    'Transform financial operations, streamlining tasks, ensuring compliance while providing valuable data-driven insights for informed financial decisions.',
    submenu: [
      {
        name: 'Project',
        icon: FcBriefcase,
        link: '/configuration/project',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Office',
        icon: FcDepartment,
        link: '/hris/office',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      // {
      //   name: "Division",
      //   icon: FcSerialTasks,
      //   link: "/hris/division",
      //   description:
      //     "Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.",
      // },
      {
        name: 'Leave Category',
        icon: FcLeave,
        link: '/hris/leave-category',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Penalty Category',
        icon: FcHighPriority,
        link: '/hris/penalty-category',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Days Off',
        icon: FcPlanner,
        link: '/hris/days-off',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      {
        name: 'Users',
        icon: FcConferenceCall,
        link: '/hris/users',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
      // {
      //   name: 'Role',
      //   icon: FcLockLandscape,
      //   link: '/hris/role',
      //   description:
      //     'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      // },
      {
        name: 'KPI',
        icon: FcInspection,
        link: '/hris/kpi',
        description:
          'Elevate business efficiency, simplifying task management, collaboration, and scheduling to drive informed decisions and boost productivity.',
      },
    ],
  },
];
