import {
  Heading,
  HStack,
  Stack,
  Spacer,
  Text,
  Center,
  Button,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalContent,
  Input,
  Spinner,
  useToast,
  Box,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import DynamicTable from '../../Components/Table/DynamicTable'
import { useNavigate } from 'react-router-dom'
import useUserStore from '../../Hooks/Zustand/Store'
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebaseV } from '../../Api/firebaseApi'
import moment from 'moment'

function HrisPayrollPage() {
  const [modal, setModal] = useState(false)
  const globalState = useUserStore()
  const [loading, setLoading] = useState(false)
  const [periodMonth, setPeriodMonth] = useState([])
  const [periode, setPeriode] = useState({
    periode: '',
    start: '',
    end: ''
  })
  const toast = useToast()

  const navigate = useNavigate()
  const tableHeader = ['periode', 'start', 'expire']

  const monthNames = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ]
  const options = { day: 'numeric', month: 'long', year: 'numeric' }

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const monthName = monthNames[dateObj?.getMonth()];
    const year = dateObj?.getFullYear();
    return `${monthName} ${year}`;
  };

  const getPeriode = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState?.currentCompany }
    ]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    try {
      const res = await getCollectionFirebaseV('payroll', { conditions }, { sortBy }, { limitValue }, { startAfterData })
      setPeriodMonth(res || [])
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });       
    }
  }

  useEffect(() => {
    getPeriode()
  }, [])

  const tableData = periodMonth?.map((x) => {
    const periode = x?.periode
    const start = new Date(x?.timeFrom || 0).toLocaleDateString('id-ID', options) || '-'
    const end = new Date(x?.timeTo || 0).toLocaleDateString('id-ID', options) || '-'
    return {
      x,
      'periode': formatDate(periode || null),
      'start': start,
      'expire': end
    }
  })

  const handleAdd = () => {
    setModal(true);
  };

  const handleDetail = (x) => {
    navigate(`/hris/payroll/${x?.x?.id}`);
  };

  const addPeriode = async () => {
    setLoading(true)
    const data = {
      timeFrom: moment(periode?.start || 0).valueOf(),
      timeTo: moment(periode?.end || 0).valueOf(),
      periode: periode?.periode,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    }
    try {
      await addDocumentFirebase('payroll', data, globalState?.currentCompany)
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    }
    getPeriode()
    setLoading(false)
    setModal(false)
  };

  const handleDelete = async (x) => {
    setLoading(true);
    try {
      await deleteDocumentFirebase('payroll', x.x.id);
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
      getPeriode();
    }
  }

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size="md">Payroll</Heading> 
        <Spacer />
        <DynamicButton action={'create'} title={'Add Payroll'} variant={'solid'} onClick={handleAdd} />
      </HStack>

     
      {loading ? (
        <Stack p={8}>
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="rgba(54, 162, 235, 0.6)"
              size="xl"
            />
          </Center>
        </Stack>
      ) : (
        <Box>
          <DynamicTable
            header={tableHeader}
            data={tableData}
            onRead={handleDetail} 
            onDelete={handleDelete}
          />
        </Box>
      )}


      <Modal isOpen={modal} onClose={() => setModal(false)} size={'2xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Add Payroll</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack my={2}>
              <Stack w={'50%'} fontSize={'sm'}>
                <Text>Start Date</Text>
                <Input type='date' onChange={(e) => setPeriode({ ...periode, start: e.target.value })} />
              </Stack>
              <Stack w={'50%'} fontSize={'sm'}>
                <Text>End Date</Text>
                <Input type='date' onChange={(e) => setPeriode({ ...periode, end: e.target.value })} />
              </Stack>
            </HStack>
            <Stack my={2}>
              <Text>Periode</Text>
              <Input type='month' onChange={(e) => setPeriode({ ...periode, periode: e.target.value })} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={addPeriode} colorScheme={'green'}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </Stack>
  );
}

export default HrisPayrollPage;