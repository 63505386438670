import {
  CalendarIcon,
  CheckIcon,
  InfoIcon,
  TimeIcon,
  TriangleUpIcon,
  AttachmentIcon 
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Heading,
  HStack,
  Image,
  Input,
  List,
  ListIcon,
  ListItem,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import { addDocumentFirebaseV3 } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import img from '../../assets/icons/importir.jpg'

const RecruitmentCreateJobPage = () => {
  const globalState = useUserStore()
  const {currentCompany, companies} = useUserStore(); //ngambil dari zustand 
  const [category, setCategory] = useState({ open: false })
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const [data, setData] = useState({});
  const [gaji, setGaji] = useState(false);
  const [val, setVal] = useState([]);
  const [val2, setVal2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [add, setAdd] = useState(false);
  const [basicInput] = useState([
    { id: 'position_name', name: 'Job Title', type: 'text' },
    { id: 'address', name: 'Location', type: 'text' },
    {
      id: 'company',
      name: 'Company',
      type: 'text',
      disabled: true,
      default: currentCompany ? companies?.find((x) => x.id === currentCompany)?.name : '',
    },
    { id: 'expiry', name: 'Iklan Berakhir pada', type: 'date' },
  ])

  const [jobType, setJobType] = useState([
    { name: 'Digital Marketer' },
    { name: 'Web Programmer' },
  ]);

  const toast = useToast();
  const navigate = useNavigate();
  const company = companies.find((x) => (x.id) === (globalState.currentCompany))
    
    
  const image = companies[0].image || img
    

  const handleAddJobDesc = () => {
    const abc = [...val, []];
    setVal(abc);
  };

  const handleAddCriteria = () => {
    const abc = [...val2, []];
    setVal2(abc);
  };

  const handleChangeJobDesc = (e, i) => {
    const inputData = [...val];
    inputData[i] = e.target.value;
    setVal(inputData);
    setData({ ...data, jobdesc: val });
  };

  const handleChangeCriteria = (e, i) => {
    const inputData = [...val2];
    inputData[i] = e.target.value;
    setVal2(inputData);
    setData({ ...data, requirements: val2 });
  };

  const handleData = (value, x) => {
    setData({ ...data, [x.id]: value });
  };

  const handleSalary = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    // if (!currentCompany) return
    const data_1 = {
      ...data,
      companyId: currentCompany,
      company : company.name,
      image : image,
      status_active: 0,
      status_assessment: 0,
      status_inactive: 0,
      status_interview: 0,
      status_onboarding: 0,
      status_process: 0,
      status_review: 0,
      status_training: 0,
      status_unprocessed:0,
      createdAt: Date.now().toString(),
    }

    try {
      await addDocumentFirebaseV3('positions', data_1, '')

      toast({
        title: 'Post success',
        description: `${data.title} successfully added `,
        status: 'success',
        isClosable: true,
      });
      navigate('/recruitment/job-listing')
    } catch (error) {
      toast({
        title: 'Something wrong happened...',
        description: error.message,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleType = (value) => {
    if (value !== 'add new type') {
      setData({ ...data, job_type: value });
    } else {
      setAdd(true);
    }
  };
    
  return (
    <>
      {isDesktop? 
        (
          <>
            <Box my={5} >
              <HStack>
                <BackButtons/>
                <Text fontSize={'xl'} fontWeight={500}>
                  Create New Job Vacancy
                </Text>
              </HStack>
            </Box>
            <Box>
              <Divider/>
            </Box>
            <SimpleGrid columns="2" w={'full'}>
              <Box bgColor={'whiteAlpha.900'} borderRightRadius={'md'} m={2} p={5}>
                    
                <Box   borderRadius="md">
                  <Heading fontSize="xl" mb={3}>Program / Job Description</Heading>
                  <Textarea
                    bg="white"
                    size="sm"
                    onChange={(e) =>
                      setData({ ...data, description: e.target.value })
                    }
                  />
                  <SimpleGrid columns="2" gap="5" my={3}>
                    {basicInput.map((x, i) => (
                      <Box key={i}>
                        <Text my={2}>{x.name}</Text>
                        <Input
                          type={x.type}
                          bg="white"
                          defaultValue={x.default ? x.default : null}
                          disabled={x.disabled ? x.disabled : false}
                          onChange={(e) => handleData(e.target.value, x)}
                        />
                      </Box>
                    ))}
                    <Box>
                      <Stack>
                        <Text my={2}>Workplace Type</Text>
                        <Select 
                          placeholder="Select Workplace Type"
                          bg='white'
                          onChange={(e) => setData({...data, workplace: e.target.value})}
                        >
                          <option value="Work From Home(WFH)">Work From Home(WFH)</option>
                          <option value="Work From Office(WFO)">Work From Office(WFO)</option>
                          <option value="Hybrid">Hybrid</option>
                          <option value="Remote">Remote</option>
                        </Select>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack>
                        <Text my={2}>Posisi/Level</Text>
                        <Select
                          placeholder="Select Position"
                          bg='white'
                          onChange={(e) => setData({...data, level: e.target.value})}
                        >
                          <option value="Intern">Intern</option>
                          <option value="Staff">Staff</option>
                          <option value="Supervisor">Supervisor</option>
                          <option value="Manager">Manager</option>
                        </Select>
                      </Stack>
                    </Box>
                    <Box>
                      <HStack>
                        <Checkbox onChange={(e) => setGaji(e.target.checked)} />
                        <Text my={2}>Range gaji</Text>
                      </HStack>
                      {gaji ? (
                        <HStack>
                          <Input
                            type="text"
                            placeholder="IDR 4,200,000"
                            bg="white"
                            id="range_salary_low"
                            onChange={(e) =>
                              handleSalary(e.target.id, e.target.value)
                            }
                          />
                          <Text>-</Text>
                          <Input
                            placeholder="IDR 5,500,000"
                            type="text"
                            bg="white"
                            id="range_salary_high"
                            onChange={(e) =>
                              handleSalary(e.target.id, e.target.value)
                            }
                          />
                        </HStack>
                      ) : (
                        <>
                          <Input type="text" bg="white" value="Negotiable" disabled />
                        </>
                      )}
                    </Box>
                    <Box>
                      <Text my={2}>Pengalaman</Text>
                      <Select
                        placeholder="Jawaban"
                        bgColor="white"
                        onChange={(e) =>
                          setData({ ...data, experience: e.target.value })
                        }
                      >
                        <option value="Tanpa Pengalaman">Tanpa Pengalaman</option>
                        <option value="6 bulan - 1 tahun">6 bulan - 1 tahun</option>
                        <option value="1 tahun - 2 tahun">1 tahun - 2 tahun</option>
                        <option value="2 tahun - 3 tahun">2 tahun - 3 tahun</option>
                        <option value="3 tahun++">3 tahun++</option>
                      </Select>
                    </Box>
                    <Box>
                      <Text my={2}>Job Type</Text>
                      <RadioGroup
                        onChange={(e) => setData({ ...data, time: e })}
                      >
                        <VStack align={'flex-start'}>
                          <Radio value="Full Time">Full Time</Radio>
                          <Radio value="Part Time">Part Time</Radio>
                          <Radio value="Full & Part Time">Full & Part Time</Radio>
                        </VStack>
                      </RadioGroup>
                    </Box>
                    <Box>
                      <Text my={2}>Status</Text>
                      <RadioGroup
                        onChange={(e) => setData({ ...data, published: e })}
                      >
                        <VStack align={'flex-start'}>
                          <Radio value="Publish">Publish</Radio>
                          <Radio value="Draft">Draft</Radio>
                        </VStack>
                      </RadioGroup>
                    </Box>
                  </SimpleGrid>
                </Box>
                <Box m="2" p="2"  borderRadius="md">
                  <Text my={2}>Specialization</Text>
                  <Flex gap={1}>
                    <Input
                      placeholder="Type"
                      bgColor="white"
                      onChange={(e) => handleType(e.target.value)}
                    />
                    {/* <Select
                                placeholder="Type"
                                bgColor="white"
                                onChange={(e) => handleType(e.target.value)}
                            >
                                {jobType.map((x, i) => (
                                    <option key={i} value={x.name}>
                                        {x.name}
                                    </option>
                                ))}
                            </Select> */}

                    <Button colorScheme="green" onClick={() => setCategory({ open: true })}>+</Button>
                  </Flex>
                  {category.open ?
                    <Flex my={1} gap={1}>
                      <Input onChange={(e) => setCategory({ ...category, name: e.target.value })} bg='white' placeholder="Add job type" />
                      <Button colorScheme="green" onClick={() => {
                        const arr = [...jobType]
                        arr.push({ name: category.name })
                        setJobType(arr)
                        setCategory({ open: false })
                      }}
                      >Save</Button>
                    </Flex>
                    : null}
                  {add === true ? (
                    <>
                      <Text>Job Type</Text>
                      <Input
                        placeholder="Type"
                        bgColor="white"
                        onChange={(e) =>
                          setData({ ...data, job_type: e.target.value })
                        }
                      />
                    </>
                  ) : null}
                </Box>

                <Box m="2" p="2"  borderRadius="md">
                  <Heading fontSize="xl">Job Description</Heading>
                  {val.map((x, i) => (
                    <Input
                      key={i}
                      type="text"
                      bg="white"
                      placeholder={`JOBDESK ${i + 1}`}
                      onChange={(e) => handleChangeJobDesc(e, i)}
                    />
                  ))}

                  <Center>
                    <Button bg="brand.100" onClick={() => handleAddJobDesc()}>
                      Tambah Job Description
                    </Button>
                  </Center>
                </Box>

                <Box m="2" p="2"  borderRadius="md">
                  <Heading fontSize="xl">Qualifications</Heading>

                  {val2.map((x, i) => (
                    <Input
                      key={i}
                      type="text"
                      bg="white"
                      placeholder={`CRITERIA ${i + 1}`}
                      onChange={(e) => handleChangeCriteria(e, i)}
                    />
                  ))}

                  <Center>
                    <Button bg="brand.100" onClick={() => handleAddCriteria()}>
                      Tambah kualifikasi
                    </Button>
                  </Center>
                </Box>
              </Box>

              {/* ------------------------------------------------------------------ THIS IS PREVIEW */}

              <Box m="2" p="5"  borderRadius="md" bgColor={'whiteAlpha.900'} borderRightRadius={'md'}>
                <HStack>
                  <Image w="100px" h='100px' src={image}/>
                  <Stack>
                    <Heading>{data?.position_name ? data.position_name : null}</Heading>
                    <Text fontSize={12} color="blackAlpha.600">
                      Berakhir pada : {data.expiry}
                    </Text>
                  </Stack>
                </HStack>
                <Box m="3">
                  <Heading fontSize="lg">Deskripsi Pekerjaan</Heading>
                  <Text>{data?.description}</Text>
                </Box>
                <Box>
                  <SimpleGrid columns="2">
                    <List spacing={1}>
                      <ListItem>
                        <ListIcon as={InfoIcon} color="blue.500" />
                        {data.range_salary_high
                          ? `Rp${new Intl.NumberFormat('en-ID', {
                            maximumSignificantDigits: 3,
                          }).format(
                            data.range_salary_low
                          )} - Rp${new Intl.NumberFormat('en-ID', {
                            maximumSignificantDigits: 3,
                          }).format(data.range_salary_high)} `
                          : 'Negotiable'}
                      </ListItem>
                      <ListItem>
                        <ListIcon as={TimeIcon} color="blue.500" />
                        {data?.time ? data.time : <></>}
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CalendarIcon} color="blue.500" />
                        {data?.experience ? data.experience : <></>}
                      </ListItem>
                    </List>

                    <List spacing={1}>
                      <ListItem>
                        <ListIcon as={InfoIcon} color="blue.500" />
                        {data?.address ? data.address : <></>}
                      </ListItem>
                      <ListItem>
                        <ListIcon as={TriangleUpIcon} color="blue.500" />
                        {data?.workplace?data.workplace:<></>}
                      </ListItem>
                      <ListItem>
                        <ListIcon as={AttachmentIcon} color="blue.500" />
                        {data?.level?data.level:<></>}
                      </ListItem>
                    </List>
                  </SimpleGrid>
                </Box>
                <Divider m="2" width="80%" />

                <Box>
                  <Heading fontSize="lg">Job Description</Heading>
                  <Text p="2">{data?.job_responsibility}</Text>

                  <List spacing={1}>
                    {data?.jobdesc?.length > 0
                      ? data.jobdesc.map((x, i) => (
                        <ListItem key={i}>
                          <ListIcon as={CheckIcon} color="green.500" />
                          {x}
                        </ListItem>
                      ))
                      : null}
                  </List>
                </Box>
                <Box>
                  <Divider m="2" width="80%" />
                  <Heading fontSize="lg">Qualification</Heading>
                  <List spacing={1}>
                    {data?.requirements?.length > 0
                      ? data.requirements.map((x, i) => (
                        <ListItem key={i}>
                          <ListIcon as={CheckIcon} color="green.500" />
                          {x}
                        </ListItem>
                      ))
                      : null}
                  </List>
                </Box>
                <Box m="2">
                  <Button
                    width="full"
                    colorScheme="green"
                    onClick={() => handleSave()}
                    loadingText='Publishing job...'
                    isDisabled={loading}
                    isLoading={loading}
                  >
                    Publish
                  </Button>
                </Box>
                    
              </Box>
            </SimpleGrid>
          </>
        ) : (
          <Stack
            h={'50vh'}
            alignItems="center"
            justifyContent={'center'}
            spacing={2}
          >
            <Heading textAlign={'center'} size="xl">
              Sorry!
            </Heading>
            <Heading textAlign={'center'} size="md">
              This page is only available in Desktop View
            </Heading>

          </Stack>
        )}
    </>
    
  )
}

export default RecruitmentCreateJobPage