import React from 'react'
import Category from '../Pages/GA/AssetManagement/CategoryPage'
import BrandPage from '../Pages/GA/AssetManagement/BrandPage'
import LocationPage from '../Pages/GA/AssetManagement/LocationPage'
import AssetPage from '../Pages/GA/AssetManagement/AssetPage'
import HomePage from '../Pages/GA/HomePage';
import IndexPage from '../Pages/GA/AssetManagement/IndexPage';
import DetailAssetLendingPage from '../Pages/GA/AssetManagement/DetailAssetLendingPage'
import DetailAsset from '../Pages/GA/AssetManagement/DetailAsset'
import ReportDepreciationAsset from '../Pages/GA/AssetManagement/ReportDepreciationAsset'

const GARouter = [

  {
    path: '/ga',
    element: <HomePage />,
  },
  {
    path: '/ga/asset',
    element: <AssetPage />,
  },
  {
    path: '/ga/asset/category',
    element: <Category />,
  },
  {
    path: '/ga/asset/brand',
    element: <BrandPage />,
  },
  {
    path: '/ga/asset/location',
    element: <LocationPage />,
  },
 
  {
    path: '/ga/asset/status',
    element: <IndexPage />,
  },
  {
    path: '/ga/asset/status/:id/detail',
    element: <DetailAssetLendingPage />,
  },
  {
    path: '/ga/asset/:id/detail',
    element: <DetailAsset />,
  },
  {
    path: '/ga/asset/report-depreciation',
    element: <ReportDepreciationAsset />,
  },
]

export default GARouter
