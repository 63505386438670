/* eslint-disable no-useless-catch */
import {
  Button,
  Center,
  Divider,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Box,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import AppTableComponentData from '../../Components/AppComponent/AppTableComponentData';
import { createHris, readHris } from '../../Api/Hris';
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { useDropzone } from 'react-dropzone';
import MediaUpload from '../../Components/MediaUpload/MediaUpload';
import UploadFileToDropbox from '../../Utils/UploadFileToDropbox';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { decryptToken } from '../../Utils/encrypToken';

const HrisAttendanceCorrection = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [correction, setCorrection] = useState(null);
  const [approved, setApproved] = useState(null);
  const [rejected, setRejected] = useState(null);
  const [myAttendance, setMyAttendance] = useState([]);
  const [selectDate, setSelectDate] = useState(null);
  const [clockIn, setClockIn] = useState(null);
  const [ClockOut, setClockOut] = useState(null);
  const [loadFile, setLoadFile] = useState(null);
  const [progress, setProgress] = useState(0)
  const toast = useToast();

  const globalState = useUserStore();

  const handleAdd = () => {
    setModal(true);
  };

  const month = moment().format('MM');
  const year = moment().format('YYYY');

  const getData = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris(
        `attendance/employee?month=${month}&year=${year}`,
        session
      );
      const select = data?.find((x) => x.periode === selectDate);
      setMyAttendance(select);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getData();

    return () => {
      setMyAttendance(null);
    };
  }, [selectDate, globalState?.currentCompany]);

  const getCorrection = async () => {
    globalState?.setIsLoading(true);
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris('correction', session);

      const dataFind = data?.filter((x) => x.user_uid === globalState?.uid);
      const request = dataFind?.filter(
        (x) => x.current_status === null || 'APPROVE MANAGER'
      );
      const approve = dataFind?.filter(
        (x) => x.current_status === 'APPROVE HR'
      );
      const reject = dataFind?.filter(
        (x) =>
          x.current_status === 'REJECT MANAGER' ||
          x.current_status === 'REJECT HR'
      );

      setCorrection(request);
      setApproved(approve);
      setRejected(reject);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      globalState?.setIsLoading(false);
    }
  };
  useEffect(() => {
    getCorrection();

    return () => {
      setCorrection(null);
      setApproved(null);
      setRejected(null);
    };
  }, [globalState?.currentCompany]);

  const cancel = () => {
    setModal(false);
    setSelectDate(null);
    setClockIn(null);
    setClockOut(null);
    setLoadFile(null);
  };
  const handleFile = (data) => {
    setLoadFile(data);
  };

  const handleCancel = () => {
    setLoadFile(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFile,
    multiple: false,
  });

  const onSubmit = async () => {
    if (
      clockIn === null ||
      ClockOut === null ||
      selectDate === null ||
      loadFile === null
    ) {
      toast({
        title: 'All input should be filled',
        duration: 3000,
        status: 'error',
      });
    } else {
      setLoading(true);
      //upload file attachments dulu
      const dataCompany = 'edrus';
      const dataProject = 'edrus';
      const dataModule = 'attandance';
      const formData = new FormData();
      formData.append('companyId', dataCompany);
      formData.append('projectId', dataProject);
      formData.append('module', dataModule);
      formData.append('file', loadFile[0], `${loadFile[0].name}`);

      try {
        const token = await getSingleDocumentFirebase('token', 'dropbox');
        const decryptResult = decryptToken(`${token?.access_token}`);


        const parentPath = `/${globalState?.currentCompany}/${globalState?.currentProject}/hris/correction`;
        // const response = await axios.post(
        //   'https://deoapp-backend-awfnqqp6oq-et.a.run.app/upload_file',
        //   formData,
        //   {
        //     headers: {
        //       'Content-Type': 'multipart/form-data',
        //     },
        //   }
        // );
        const response = await UploadFileToDropbox(loadFile[0], parentPath, decryptResult, setProgress, progress)
        
        if (response.link) {
          const linkImage = response.link;

          const session = {
            headers: globalState?.authFirebaseToken,
            companyId: globalState?.currentCompany,
            projectId: globalState?.currentProject,
          };

          const postData = {
            periode: selectDate,
            clock_in: clockIn,
            clock_out: ClockOut,
            attachments: [linkImage],
          };
          try {
            const res =  await createHris('correction', session, postData);
            if (res.status === true) {
              toast({
                status: 'success',
                description: res.message,
                duration: 2000,
              });
            } else {
              throw new Error('Failed to send Slack login message');
            }
          } catch (error) {
            throw new Error(
              error.message,
              'Failed to send Slack login message'
            );
          }
        }
      } catch (error) {
        throw new Error(error.message, 'Failed to send Slack login message');
      } finally {
        setSelectDate(null);
        setClockIn(null);
        setClockOut(null);
        setLoading(false);
        setModal(false);
        setLoadFile(null);
        getCorrection();
      }
    }
  };
  return (
    <Stack my={5}>
      <Tabs isFitted variant="enclosed" borderRadius={'md'} bg={'white'}>
        <TabList mb="1em">
          <Tab fontWeight={500}>Request</Tab>
          <Tab fontWeight={500}>Approved</Tab>
          <Tab fontWeight={500}>Rejected</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <HStack justify={'space-between'}>
              <Spacer />
              <DynamicButton
                title="Request"
                action="create"
                onClick={handleAdd}
              />
            </HStack>
            {correction?.length === 0 || !correction ? (
              <Center py={5}>
                <Text color={'darkgrey'}>
                  You have not requested any correction
                </Text>
              </Center>
            ) : (
              <AppTableComponentData data={correction} />
            )}
          </TabPanel>
          <TabPanel>
            {approved?.length === 0 || !approved ? (
              <Center py={5}>
                <Text color={'darkgrey'}>No correction approved</Text>
              </Center>
            ) : (
              <AppTableComponentData data={approved} />
            )}
          </TabPanel>
          <TabPanel>
            {rejected?.length === 0 || !rejected ? (
              <Center py={5}>
                <Text color={'darkgrey'}>No correction rejected</Text>
              </Center>
            ) : (
              <AppTableComponentData data={rejected} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        size={'xl'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Request Correction</ModalHeader>
          <ModalBody>
            <Text fontSize={'xs'}>Select Date</Text>
            <Input
              type="date"
              onChange={(e) => setSelectDate(e.target.value)}
            />
            <Center>
              <HStack flex={'row'} m={'auto'}>
                {myAttendance !== null ? (
                  <>
                    {myAttendance?.clock_in ? (
                      <Text
                        my={3}
                        p={2}
                        borderRadius={'md'}
                        bgColor={'lightgrey'}
                      >
                        {moment
                          .parseZone(myAttendance?.clock_in || 0)
                          .local()
                          .format('HH:mm:ss')}
                      </Text>
                    ) : (
                      <Text
                        my={3}
                        p={2}
                        borderRadius={'md'}
                        bgColor={'lightgrey'}
                      >
                        --:--:--
                      </Text>
                    )}
                    <Text>-</Text>
                    {myAttendance?.clock_out ? (
                      <Text
                        my={3}
                        p={2}
                        borderRadius={'md'}
                        bgColor={'lightgrey'}
                      >
                        {moment
                          .parseZone(myAttendance?.clock_out || 0)
                          .local()
                          .format('HH:mm:ss')}
                      </Text>
                    ) : (
                      <Text
                        my={3}
                        p={2}
                        borderRadius={'md'}
                        bgColor={'lightgrey'}
                      >
                        --:--:--
                      </Text>
                    )}
                  </>
                ) : (
                  ''
                )}
              </HStack>
            </Center>
            {/* <Text fontSize={'xs'}>Describe Reason</Text>
                        <Textarea mb={5}/> */}
            <Divider py={3} />
            <Stack py={5}>
              <Text align={'center'}>Correction</Text>
              <HStack w={'full'}>
                <Stack w={'50%'}>
                  <Text fontSize={'xs'} align={'center'}>
                    Clock In
                  </Text>
                  <Input
                    type="time"
                    onChange={(e) => setClockIn(e.target.value)}
                  />
                </Stack>
                <Stack w={'50%'}>
                  <Text fontSize={'xs'} align={'center'}>
                    Clock Out
                  </Text>
                  <Input
                    type="time"
                    onChange={(e) => setClockOut(e.target.value)}
                  />
                </Stack>
              </HStack>
            </Stack>
            <Box>
              <Text align={'center'}>Correction</Text>
              <MediaUpload getRootProps={getRootProps} getInputProps={getInputProps} loadFile={loadFile} handleCancel={handleCancel} />
              {/* <FormControl id="file">
                <Stack {...getRootProps()}>
                  <Input name="file_CV" accept=".pdf" {...getInputProps()} />
                  {loadFile === null ? (
                    <>
                      <Center
                        borderWidth="3px"
                        borderRadius="lg"
                        borderStyle={'dashed'}
                        px="6"
                        py="4"
                      >
                        <VStack spacing="3">
                          <Square size="8" bg="bg-subtle" borderRadius="lg">
                            <Icon as={MdUploadFile} boxSize="8" color="muted" />
                          </Square>
                          <VStack spacing="1">
                            <HStack spacing="1" whiteSpace="nowrap">
                              <Button
                                variant="link"
                                colorScheme="blue"
                                size="sm"
                                cursor={'pointer'}
                              >
                                Click to upload
                              </Button>
                              <Text fontSize="sm" color="muted">
                                or drag here
                              </Text>
                            </HStack>
                            <Text fontSize="xs" color="muted">
                              PDF or Image up to 2MB
                            </Text>
                          </VStack>
                        </VStack>
                      </Center>
                    </>
                  ) : (
                    <>
                      <Center
                        borderWidth="3px"
                        borderRadius="lg"
                        borderStyle={'dashed'}
                        px="6"
                        py="4"
                        gap={3}
                      >
                        <MdFileDownloadDone size={30} />
                        <Text fontSize={'sm'} noOfLines={1}>
                          {loadFile[0].name}
                        </Text>
                        <MdCancel
                          size={30}
                          cursor={'pointer'}
                          onClick={handleCancel}
                        />
                      </Center>
                    </>
                  )}
                </Stack>
              </FormControl> */}
            </Box>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button colorScheme="red" onClick={cancel}>
              Cancel
            </Button>
            <Button colorScheme="blue" isLoading={loading} onClick={onSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default HrisAttendanceCorrection;
