/* eslint-disable react/jsx-key */
/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import { Box, Center, Heading, Text, Stack, Button, Input, useDisclosure, Image, HStack, Spacer, InputGroup, InputLeftAddon, Spinner, Progress, Grid, useToast, VStack } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { serverTimestamp } from 'firebase/firestore';
import moment from 'moment';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { wealth_dynamics } from './RecruitmentQuestions';
import { MdPerson, MdPhone } from 'react-icons/md';
import { IoCalendarOutline } from 'react-icons/io5';
import logo from '../../assets/1.png';
import { AiFillAlert } from 'react-icons/ai';
import { GoDot } from 'react-icons/go';
import { decryptToken } from '../../Utils/encrypToken';
import '../../App.css';
import _axios from '../../Api/AxiosBarrier';

const height = window.innerHeight;

const RecruitmentWealthDynamic = () => {
  const [answers, setAnswers] = useState({});
  const [manual, setManual] = useState([])
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(0);
  const [birthDate, setBirthDate] = useState('');
  const [birthDateUnix, setBirthDateUnix] = useState(911322000);
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('step_1')
  const { onOpen } = useDisclosure();
  const navigate = useNavigate();
  const [wdResult, setWdResult] = useState({})
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const totalQuestions = wealth_dynamics.length;
  const [detail, setDetail] = useState([])
  const [company, setCompany] = useState(null)


  const companyId = searchParams.get('company')
  const companyIdHash = decryptToken(decodeURIComponent(companyId))


  const getCompany = async () => {
    try {
      if (companyIdHash?.length > 0) {
        const res = await getSingleDocumentFirebase('companies', companyIdHash)
        setCompany(res)
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  const toast = useToast()

  useEffect(() => {
    getCompany()
  }, [])


  const dynamo = [{
    profile: 'Creator',
    character: ['Visioner', 'Creative', 'Optimistic', 'Stimulating'],
    talent: 'Creator are the best initiators, althought they can be quick to create chaos, they can laso be surprisingly innovative at finding ways out of chaos. Always put them in charge of new project, but move them on to the next creation once their job is done.',
    image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png',
    house: 'dynamo',
    link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20CREATOR.pdf?alt=media&token=4e212928-e169-4899-be26-298d13358709&_gl=1*1k0wjmn*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMC4xNjk5MzI4ODU4LjYwLjAuMA..'
  }]
  const blaze = [{
    profile: 'Supporter',
    character: ['Relationship focused', 'Confidence building'],
    talent: 'Supporters are the best leaders, but dont expect them to come up with the plan. Build a plan and set the goals with a Supporter, and then let them lead the team towards the plan, Let them set their own management style, and their own agenda.',
    image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png',
    house: 'blaze',
    link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20SUPPORTER.pdf?alt=media&token=781b7461-ddb6-4cce-bf00-82e6ad0d9b5b&_gl=1*18oepyq*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI4OTY1LjYwLjAuMA..'
  }]
  const tempo = [{
    profile: 'Trader',
    character: ['Sensory', 'Balanced', 'Observant', 'Insightful'],
    talent: 'Trander are the best negotiators. They will always get you the best price, in both what you buy and what you sell. They want to be given the parameters in which to work. Traders will have their ear to the ground in your market and your team',
    image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png',
    house: 'tempo',
    link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20TRADER.pdf?alt=media&token=bd78f74d-a864-4aab-bc7f-952d80060a3f&_gl=1*1ymkutt*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MDAyLjIzLjAuMA..'
  }]
  const steel = [{
    profile: 'Lord',
    character: ['Controlling', 'Cautious', 'Organised', 'Detailed'],
    talent: 'Lord are your best analysts. Give them space to study the detail, and to deliver the data. Dont ask them to go out and network, as they are strongest when focused behind the scenes',
    image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png',
    house: 'steel',
    link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20LORD.pdf?alt=media&token=6cd5a024-8990-4a6e-b953-10bceea9619d&_gl=1*1fy2pp6*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MDIxLjQuMC4w'
  }]
  const star = [{
    profile: 'Star',
    character: ['Vibrant', 'energising', 'image driven'],
    talent: 'Star are the best promoters, but need to lead from the front. Give them the change to shine, and give them the space to deliver result without trying them down. Give them the systems and support to enable them to focus on building new bussiness.',
    image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png',
    // house: 'steel'
    link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20STAR.pdf?alt=media&token=883bbfd6-5d9e-4b96-812f-05fb30720e3d&_gl=1*1c8dudo*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MDU4LjYwLjAuMA..'
  }]
  ///
  const accumulator = [{
    profile: 'Accumulator',
    character: ['Reliable', 'careful', 'considered', 'meticulous'],
    talent: 'Accumulators are your best ambassadors. they are reliable, get things done on time, and are not prone to making rash decisions. Accumulators make the best projects managets, when a spesific task needs to be delivered reliably.',
    image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png',
    // house: 'steel'
    link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20ACCUMULATOR.pdf?alt=media&token=b1f4079a-8d5c-4e49-bdf8-1fef0c16c2c1&_gl=1*zs29vr*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MDk4LjIwLjAuMA..'
  }]
  const dealMaker = [{
    profile: 'Deal Maker',
    character: ['Outgoing', 'entertaining', 'approachable', 'mischievous'],
    talent: 'Deal Makers are the best peacemakers, and will leave everyone feeling good. Dont Expect them to go out cold cslling, as they thrive in building the relationship they have. Work with Deal Makers to nurture the costumer and relationship you have',
    image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png',
    // house: 'steel'
    link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20DEAL%20MAKER.pdf?alt=media&token=1c2d516a-c982-4d03-9eb4-f090363f825e&_gl=1*viyhq4*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MTQ0LjM0LjAuMA..'
  }]
  const mechanic = [{
    profile: 'Mechanic',
    character: ['Innovative', 'perfectionist', 'detail oriented'],
    talent: 'Mechanic are your best completers. they will wrap up the process and find smart ways to do it better next time. Dont ask a Mechanic to start from scratch, but do give them an existing process or product to improve on',
    image: 'https://deoapp-bucket.s3.ap-southeast-1.amazonaws.com/img-fzl/logo/autopilot.png',
    // house: 'steel'
    link: 'https://firebasestorage.googleapis.com/v0/b/deoapp-indonesia.appspot.com/o/wealth-dynamic-result%2FWD%20-%20MECHANIC.pdf?alt=media&token=f9fe7294-508e-4d5d-8b53-ca9c9ebc3f17&_gl=1*1264b3j*_ga*MTc3MTk2OTgyNS4xNjg5NTg1OTMz*_ga_CW55HF8NVT*MTY5OTMyODg1OC4xODIuMS4xNjk5MzI5MTY3LjExLjAuMA..'
  }]



  const handleSubmit = async (event) => {
    setLoading(true)
    if (name || name.length > 0 || phone || phone.length > 0) {
      event.preventDefault();
      //INITIALIZE SCORES TO ZERO
      const houseCount = { dynamo: 0, blaze: 0, tempo: 0, steel: 0 };
      //COUNT HOW MANY ANSWERS EACH HOUSE
      for (const key in answers) {
        const house = answers[key].house;
        houseCount[house]++;
      }
      ////ANSWERS HAS TO BE MULTIPLIED BY 4 TO GET 100 IN TOTAL
      const totalScore = houseCount;

      for (const key in totalScore) {
        totalScore[key] *= 4;
      }
      //SORT THE THE SCORES
      const arrayFromTotalScore = Object.entries(totalScore); //expected output : Array of arrays [[tempo, 36], [blaze, 32], [steel, 32], [dynamo, 32]] 
      arrayFromTotalScore.sort((a, b) => b[1] - a[1]);
      const slicedArray = arrayFromTotalScore.slice(0, 3); //expected output : Array of arrays [[tempo, 36], [blaze, 32], [steel, 32]] 
      // const slicedArray = [['dynamo', 36], ['tempo',36], ['steel', 20]]
      //INITIALIZE RESULT
      const result = {
        name: name,
        phone: phone,
        answers: answers,
        totalScore: totalScore,
        profile: '',
        house: '',
        timestamp: serverTimestamp(),
        date: moment().format(),
        type: 'wealth_dynamics',
      };

      // Tambahkan field companyId hanya jika companyIdHash tidak undefined
      if (companyIdHash !== undefined) {
        result.companyId = companyIdHash;
      }

      if (slicedArray[0][1] === slicedArray[1][1]) {
        //jika 2 terbesar pertama sama, Maka lihat yang ke3,
        // console.log('1 dan 2 sama, maka lihat nilai ketiga');

        if (slicedArray[1][1] === slicedArray[2][1]) {
          // kedua dan ketiga sama
          // console.log("2 dan 3 sama, artinya 123 sama.");
        } else {



          switch (`${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[2][0]}`) {
          case 'dynamo_blaze_steel':
          case 'blaze_dynamo_steel':
            // alert('Star - Dynamo')
            result.profile = 'star';
            result.house = 'dynamo';
            setDetail([...star])
            break;
          case 'dynamo_blaze_tempo':
          case 'blaze_dynamo_tempo':
            // alert('Star - Blaze')
            result.profile = 'star';
            result.house = 'blaze';
            setDetail([...star])
            break;
          case 'blaze_tempo_dynamo':
          case 'tempo_blaze_dynamo':
            // alert('Deal Maker - Blaze')
            result.profile = 'deal maker';
            result.house = 'blaze';
            setDetail([...dealMaker])
            break;
          case 'blaze_tempo_steel':
          case 'tempo_blaze_steel':
            // alert('Deal Maker - Tempo')
            result.profile = 'deal maker';
            result.house = 'tempo';
            setDetail([...dealMaker])
            break;
          case 'tempo_steel_blaze':
          case 'steel_tempo_blaze':
            // alert('Accumulator - Tempo')
            result.profile = 'accumulator';
            result.house = 'tempo';
            setDetail([...accumulator])
            break;
          case 'tempo_steel_dynamo':
          case 'steel_tempo_dynamo':
            // alert('Accumulator - Steel')
            result.profile = 'accumulator';
            result.house = 'steel';
            setDetail([...accumulator])
            break;
          case 'dynamo_steel_tempo':
          case 'steel_dynamo_tempo':
            // alert('Mechanic - Steel')
            result.profile = 'mechanic';
            result.house = 'steel';
            setDetail([...mechanic])
            break;
          case 'dynamo_steel_blaze':
          case 'steel_dynamo_blaze':
            // alert('Mechanic - Dynamo')
            result.profile = 'mechanic';
            result.house = 'dynamo';
            setDetail([...mechanic])
            break;
          case 'dynamo_tempo_blaze':
          case 'tempo_dynamo_blaze':
            // alert('Mechanic - Dynamo')
            setManual([...dynamo, ...tempo])
            break;
          case 'dynamo_tempo_steel':
          case 'tempo_dynamo_steel':
            // alert('Mechanic - Dynamo')
            setManual([...dynamo, ...tempo])
            break;
          case 'blaze_steel_dynamo':
          case 'steel_blaze_dynamo':
            // alert('Mechanic - Dynamo')
            setManual([...steel, ...blaze])
            break;
          case 'blaze_steel_tempo':
          case 'steel_blaze_tempo':
            // alert('Mechanic - Dynamo')
            setManual([...steel, ...blaze])
            break;
          default:
            // alert(`nyebrang boss (${slicedArray[0][0]}${slicedArray[1][0]}${slicedArray[1]})`)
            break;
          }

        }
      } else if (slicedArray[0][1] !== slicedArray[1][1]) {

        if ((slicedArray[0][1] - slicedArray[1][1]) >= 10) {
          //TULEN KARENA SELISIH TERBESAR 1 dan 2 lebih dari 10
          switch (slicedArray[0][0]) {
          case 'dynamo':
            // alert('creator')
            result.profile = 'creator';
            result.house = 'dynamo';
            setDetail([...dynamo])
            break;
          case 'blaze':
            // alert('supporter')
            result.profile = 'supporter';
            result.house = 'blaze';
            setDetail([...blaze])
            break;
          case 'tempo':
            // alert('trader')
            result.profile = 'trader';
            result.house = 'tempo';
            setDetail([...tempo])
            break;
          case 'steel':
            // alert('lord')
            result.profile = 'lord';
            result.house = 'steel';
            setDetail([...steel])
            break;
          default:
            break;
          }
        } else if ((slicedArray[0][1] - slicedArray[1][1]) < 10) {

          //CAMPURAN KARENA SELISIHNYA KURANG DARI 10

          if (slicedArray[1][1] === slicedArray[2][1]) {
            //////// selisih kurang dari 10 dan 2 3 nilainya sama.
            if (`${slicedArray[1][0]}_${slicedArray[2][0]}` === 'steel_blaze' || `${slicedArray[1][0]}_${slicedArray[2][0]}` === 'blaze_steel' || `${slicedArray[1][0]}_${slicedArray[2][0]}` === 'dynamo_tempo' || `${slicedArray[1][0]}_${slicedArray[2][0]}` === 'tempo_dynamo') {

              switch (`${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[2][0]}`) {
              case 'dynamo_steel_blaze':
              case 'dynamo_blaze_steel':
                result.profile = 'creator';
                result.house = 'dynamo';
                setDetail([...dynamo])
                break;
              case 'blaze_dynamo_tempo':
              case 'blaze_tempo_dynamo':
                result.profile = 'supporter';
                result.house = 'blaze';
                setDetail([...blaze])
                break;
              case 'tempo_blaze_steel':
              case 'tempo_steel_blaze':
                result.profile = 'trader';
                result.house = 'tempo';
                setDetail([...tempo])
                break;
              case 'steel_dynamo_tempo':
              case 'steel_tempo_dynamo':
                result.profile = 'lord';
                result.house = 'steel';
                setDetail([...steel])
                break;
              default:
                break;
              }
            } else {

              switch (`${slicedArray[0][0]}_${slicedArray[1][0]}_${slicedArray[2][0]}`) {
              case 'steel_tempo_blaze':
              case 'steel_blaze_tempo':
                result.profile = 'accumulator';
                result.house = 'steel';
                setDetail([...accumulator])
                break;
              case 'dynamo_steel_tempo':
              case 'dynamo_tempo_steel':
                result.profile = 'mechanic';
                result.house = 'dynamo';
                setDetail([...mechanic])
                break;
              case 'blaze_dynamo_steel':
              case 'blaze_steel_dynamo':
                result.profile = 'star';
                result.house = 'blaze';
                setDetail([...star])
                break;
              case 'tempo_blaze_dynamo':
              case 'tempo_dynamo_blaze':
                result.profile = 'deal maker';
                result.house = 'tempo';
                setDetail([...dealMaker])
                break;

              default:
                break;
              }
            }
          } else {
            ////// selisih kurang dari 10 dan 2 3 nilainya TIDAK sama
            switch (`${slicedArray[0][0]}_${slicedArray[1][0]}`) {
            case 'dynamo_blaze':
              // alert('Star - Dynamo');
              result.profile = 'star'
              result.house = 'dynamo';
              setDetail([...star])
              break;
            case 'blaze_dynamo':
              // alert('Star - Blaze');
              result.profile = 'star'
              result.house = 'blaze';
              setDetail([...star])
              break;
            case 'blaze_tempo':
              // alert('Deal Maker - Blaze');
              result.profile = 'deal maker'
              result.house = 'blaze';
              setDetail([...dealMaker])
              break;
            case 'tempo_blaze':
              // alert('Deal Maker - Tempo');
              result.profile = 'deal maker'
              result.house = 'tempo';
              setDetail([...dealMaker])
              break;
            case 'tempo_steel':
              // alert('Accumulator - Tempo');
              result.profile = 'accumulator'
              result.house = 'tempo';
              setDetail([...accumulator])
              break;
            case 'steel_tempo':
              // alert('Accumulator - Steel');
              result.profile = 'accumulator'
              result.house = 'steel';
              setDetail([...accumulator])
              break;
            case 'steel_dynamo':
              // alert('Mechanic - Steel');
              result.profile = 'mechanic'
              result.house = 'steel';
              setDetail([...mechanic])
              break;
            case 'dynamo_steel':
              // alert('Mechanic - Dynamo');
              result.profile = 'mechanic'
              result.house = 'dynamo';
              setDetail([...mechanic])
              break;
            case 'dynamo_tempo':
              // alert('Mechanic - Dynamo');
              setManual([...dynamo, ...tempo])
              break;
            case 'tempo_dynamo':
              // alert('Mechanic - Dynamo');
              setManual([...dynamo, ...tempo])
              break;
            case 'steel_blaze':
              // alert('Mechanic - Dynamo');
              setManual([...steel, ...blaze])
              break;
            case 'blaze_steel':
              // alert('Mechanic - Dynamo');
              setManual([...steel, ...blaze])
              break;
            default:
              // alert(`nyebrang boss (${slicedArray[0][0]}_${slicedArray[1][0]})`);
              break;
            }
          }
        }
      }
      //=====INITIAL COUNT : HOW MANY EACH HOUSE ANSWERS
      const count = {};
      for (const key in totalScore) {
        count[key] = totalScore[key] / 4
      }

      result['count'] = count;
      //=====INITIAL COUNT : HOW MANY EACH HOUSE ANSWERS ENDS


      setWdResult({
        name: name,
        profile: result.profile,
        house: result.house,
        phone: result.phone,
        dynamo: result.totalScore.dynamo,
        blaze: result.totalScore.blaze,
        tempo: result.totalScore.tempo,
        steel: result.totalScore.steel,
      })

      if (result.profile == '') {
        navigate({
          search: createSearchParams({
            name: name,
            profile: result.profile,
            house: result.house,
            phone: result.phone,
            dynamo: result.totalScore.dynamo,
            blaze: result.totalScore.blaze,
            tempo: result.totalScore.tempo,
            steel: result.totalScore.steel,
          })?.toString()
        })

        setStep('step_3')
        // try {
        //     const res = await addDocumentFirebaseV3('assessments', result, '')
        // } catch (error) {
        //     console.log(error.message)
        // }
      } else {
        // try {
        //   // await addDocumentFirebaseV3('assessments', result, '');
        //   await addDocumentFirebase('files', {...result, title : result.name, type : 'assessment'}, companyIdHash);
        // } catch (error) {
        //   console.log('error handlesubmit::', error)
        //   toast({
        //     title: 'Deoapp.com',
        //     description: error.message,
        //     status: 'error',
        //     position: 'top-right',
        //     isClosable: true,
        //   });
        // }

        const resultToSave = { ...result, title: result.name, type: 'assessment', companyId: companyIdHash, birthdate: birthDate, birthdate_unix: new Date(birthDateUnix) };
        const dataBody = {
          collection: 'files',
          data: resultToSave,
        }
        await _axios.post('company-addDocument', dataBody);
        navigate({
          search: createSearchParams({
            name: name || '',
            profile: result?.profile || '',
            house: result?.house || '',
            phone: result?.phone || '',
            dynamo: result?.totalScore?.dynamo || 0,
            blaze: result?.totalScore?.blaze || 0,
            tempo: result?.totalScore?.tempo || 0,
            steel: result?.totalScore?.steel || 0,
          })?.toString()
        })

        setStep('step_3')
      }

    } else {
      toast({
        title: 'Deoapp.com',
        description: 'Please input your name, and phone number',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });

    }
    setLoading(false)
    onOpen();
  };

  const handleUpdate = async (x) => {
    wdResult.profile = x.profile
    wdResult.house = x.house
    if (companyIdHash !== undefined) {
      wdResult.companyId = company?.id || '';
    }
    setLoading(true)
    // try {
    //   // await addDocumentFirebaseV3('assessments', wdResult, '')
    //   await addDocumentFirebase('files', { ...wdResult, title: wdResult.name, type: 'assessment' }, companyIdHash)
    // } catch (error) {
    //   setLoading(false)
    //   console.log('error handleupdate:::', error.message)
    //   return toast({
    //     title: 'Deoapp.com',
    //     description: error.message,
    //     status: 'error',
    //     position: 'top-right',
    //     isClosable: true,
    //   });
    // }

    const resultToSave = { ...wdResult, title: wdResult.name, type: 'assessment', companyId: companyIdHash };
    const dataBody = {
      collection: 'files',
      data: resultToSave,
    }
    await _axios.post('company-addDocument', dataBody);
    setLoading(false)
    switch (x.profile) {
    case 'Creator':
      setDetail([...dynamo])
      break
    case 'Star':
      setDetail([...star])
      break
    case 'Supporter':
      setDetail([...blaze])
      break
    case 'Deal Maker':
      setDetail([...dealMaker])
      break
    case 'Trader':
      setDetail([...tempo])
      break
    case 'Accumulator':
      setDetail([...accumulator])
      break
    case 'Lord':
      setDetail([...steel])
      break
    case 'Mechanic':
      setDetail([...mechanic])
      break
    default:
      break
    }

    setStep('step_3')
  }

  const Regist = ({ setName, setPhone, setBirthDate, setBirthDateUnix }) => {
    const nameRef = useRef()
    const phoneRef = useRef()
    const birthRef = useRef()

    return (
      <Stack w={'full'} minH={height} justifyContent={'center'}>
        <Stack >
          <Stack alignItems={'center'} justifyContent="center">
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pb={10}
              bgColor="blackAlpha.500"
              p={10}
              borderRadius="xl"
            >
              <VStack>
                <Heading fontSize={'2xl'} color={'white'}>Wealth Dynamic Assessment</Heading>
                <Text textAlign={'center'} fontFamily={'Arial, Helvetica, sans-serif'} color='white'>Please fill in your name and phone number to proceed to this assessment.</Text>
              </VStack>
              {company !== null ?
                <Box>
                  <Heading fontSize={'xl'} color={'white'}>For {company?.name}</Heading>
                </Box>
                : ''
              }

              <HStack>
                <Text color={'gray.400'} fontSize={['xs', null, 'sm']}>
                  Powered by
                </Text>
                <Box>
                  <Image
                    w="100px"
                    borderRadius={20}
                    src={logo}
                    alt="Alternate Text"
                  />
                </Box>
              </HStack>
              <Spacer />
              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '100%',
                    md: '285',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    children={<MdPerson name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="name"
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) => {
                      nameRef.current = e.target.value
                    }}
                  />
                </InputGroup>
              </Stack>

              <Stack alignItems="center">
                <InputGroup
                  w={{
                    base: '100%',
                    md: '285',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    children={<MdPhone name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="phone number"
                    type='number'
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) => {
                      phoneRef.current = e.target.value
                    }}
                  />
                </InputGroup>
              </Stack>
              <Stack alignItems="center">
                <VStack>
                  <Text color={'white'} fontSize="xs" fontWeight="bold">Birth Date:</Text>
                </VStack>
                <InputGroup
                  w={{
                    base: '100%',
                    md: '285',
                  }}
                >
                  <InputLeftAddon
                    shadow={'md'}
                    children={<IoCalendarOutline name="email" size={24} color="black" />}
                  />
                  <Input
                    w={{
                      base: '100%',
                      md: '100%',
                    }}
                    placeholder="Birth"
                    type='date'
                    fontSize={'md'}
                    shadow={3}
                    bgColor={'white'}
                    color={'black'}
                    onChange={(e) => {
                      birthRef.current = e.target.value
                    }}
                  />
                </InputGroup>
              </Stack>

              <Stack pt={5}>
                {loading ? (
                  <Spinner size={'sm'} />
                ) : (
                  <Button
                    alignItems={'center'}
                    justifyContent="center"
                    size={'sm'}
                    bgColor={'white'}
                    onClick={() => {
                      setStep('step_2')
                      setName(nameRef.current)
                      setPhone(phoneRef.current)
                      setBirthDate(moment(birthRef.current, 'YYYY-MM-DD')?.format('DD-MM-YYYY'))
                      setBirthDateUnix(moment(birthRef.current, 'YYYY-MM-DD').unix() * 1000)
                    }}
                  >
                    <Text color={'black'} fontSize="xs" fontWeight="bold">
                      CONTINUE
                    </Text>
                  </Button>
                )}
              </Stack>
              <Spacer />
            </Stack>
          </Stack>
          <Spacer />
          <Stack>{/* <AppSponsor /> */}</Stack>
        </Stack>

        {/* <Stack w={'40%'} m={'auto'} my={20} bgColor={'white'}>
                <Center my={5}>
                    <VStack>
                        <Heading>Wealth Dynamics Assessment</Heading>
                        <Divider my={3}/>
                    </VStack>
                </Center>
                <Stack boxShadow='rgba(0, 0, 0, 0.16) 0px 1px 4px' p={10}>
                    <Center my={5} >
                        <Stack minW={{base :'',md:'',lg:'lg'}}>
                            <FormControl>
                                <FormLabel>Masukkan Nama Kamu:</FormLabel>
                                <Input bg='white' type='text' placeholder='Your name here' isRequired onChange={(e) => {
                                    nameRef.current = e.target.value
                                }} />
                                <FormHelperText>Pastikan untuk mengisi nama</FormHelperText>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Masukkan Nomor HP:</FormLabel>
                                <Input bg='white' type='number' placeholder='0878***' isRequired onChange={e => {
                                    phoneRef.current = e.target.value
                                }}/>
                                <FormHelperText>Pastikan untuk mengisi nomor hp</FormHelperText>
                            </FormControl>
                        </Stack>
                    </Center>
                    <Button mt={5} cursor={'pointer'} onClick={()=>{
                        setStep('step_2')
                        setName(nameRef.current)
                        setPhone(phoneRef.current)
                        }} w={20}>
                        <HStack>
                            <Text mt={-1}>Next </Text>
                            <MdNavigateNext/>
                        </HStack>
                    </Button>
                </Stack>

            </Stack> */}
      </Stack>
    )
  }
  const Question = () => {
    const handleChange = (questionId, answer) => {
      setAnswers({ ...answers, [questionId]: answer });
    };

    const handleNext = () => {
      if (currentQuestion < totalQuestions - 1) {
        setCurrentQuestion(currentQuestion + 1);
      }
    };

    const handlePrevious = () => {
      if (currentQuestion > 0) {
        setCurrentQuestion(currentQuestion - 1);
      }
    };

    const currentQuestionData = wealth_dynamics[currentQuestion];
    const progress = ((currentQuestion + 1) / totalQuestions) * 100;

    return (
      <Stack w={['100%', '50%', '50%']} p={[1, 1, 5]} bgColor={'blackAlpha.500'} minH="130vh">
        <HStack justify={'space-between'} p={5} bgColor={'white'} borderBottomRadius={'xl'}>
          <Button w={20} onClick={() => setStep('step_1')}>
            Back
          </Button>
          <Center>
            <Heading size={['lg', 'xl', 'xl']}>Wealth Dynamic Quiz</Heading>
          </Center>
        </HStack>
        <HStack p={[1, 1, 5]} gap={3} >
          <AiFillAlert color='white' size={80} />
          <Text fontSize={[10, 15, 15]} fontWeight={'medium'} color={'white'}>
            Silakan isi 25 pertanyaan di bawah ini yang paling sesuai sama kepribadianmu, tidak ada jawaban benar atau salah, dan tidak ada kepribadian yang baik atau buruk. Test ini tidak mempengaruhi hasil penilaian tes yang lain.
          </Text>
        </HStack>
        <Stack p={[1, 1, 5]}>
          <Progress hasStripe value={progress} isAnimated colorScheme='orange' />
        </Stack>
        <Stack p={[1, 1, 5]}>
          <form onSubmit={handleSubmit}>
            <Box bg='white' margin={2} key={currentQuestionData.id} boxShadow="lg" borderRadius={'xl'} px={10}>
              <Box p={5} bgColor={'orange'} w={'fit-content'} borderBottomRadius={'xl'} boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'>
                <Heading fontSize={['md', 'lg', 'lg']}> Q {currentQuestionData.id}</Heading>
              </Box>
              <Box my={[2, 2, 5]} minH={height * 1 / 8}>
                <Heading fontSize={['md', '2xl', '2xl']}>{currentQuestionData.question}</Heading>
              </Box>
              <Stack minH={height * 1 / 4} pb={5}>
                {currentQuestionData.answers.map((answer, index) => (
                  <Stack my={1} key={index}>
                    <label style={{ marginRight: '2em' }}>
                      <input
                        type="radio"
                        name={`question-${currentQuestionData.id}`}
                        value={answer}
                        style={{ marginRight: 10 }}
                        checked={answers[currentQuestionData.id] === answer}
                        onChange={() => handleChange(currentQuestionData.id, answer)}
                      />
                      {answer.answer}
                    </label>
                  </Stack>
                ))}
              </Stack>
            </Box>
            <Spacer />
            <Stack p={[1, 1, 5]}>
              {currentQuestion === totalQuestions - 1 ? (
                <HStack justify={'space-between'}>
                  <Button onClick={handlePrevious} disabled={currentQuestion === 0}>Previous</Button>
                  <Button isLoading={loading} type="submit" bg='blue' color='white'>Submit Now</Button>
                </HStack>
              ) : (
                <HStack justify={'space-between'}>
                  <Button onClick={handlePrevious} disabled={currentQuestion === 0}>Previous</Button>
                  <Button onClick={handleNext} disabled={currentQuestion === totalQuestions - 1}>Next</Button>
                </HStack>
              )}
            </Stack>
          </form>

        </Stack>
      </Stack>
    )
  }
  const Result = () => {
    return (
      <Stack w={'full'} minH={height} justifyContent={'center'}>
        <Stack>
          <Stack alignItems={'center'} justifyContent="center" m={'auto'}>
            <Stack
              justifyContent="center"
              alignItems="center"
              spacing={5}
              pb={10}
              bgColor='white'
              p={10}
              borderRadius="xl"
            // maxW={['90%','xl','2xl']}
            >
              <Box>
                <Image
                  src='http://wdprofiletest.com/mobile/img/landing_logo.jpg'
                  w="200px"
                />
              </Box>
              <Box>
                <Heading fontSize={'lg'} color={'black'}> Hello {wdResult.name} !</Heading>
                <Heading fontSize={'lg'} color={'black'}>According to your submission, your personality type according to Wealth Dynamics is</Heading>
              </Box>
              <Center>
                {wdResult?.profile !== '' ?
                  <></>
                  :
                  <Text className='blink' color="red.600" fontWeight={'bold'}>You have the same score for this profile, please choose the one that best suits you👇👇👇</Text>
                }
              </Center>
              {wdResult?.profile !== '' ?
                <Stack w={['full', '', 'full']}>
                  {detail?.map((x, index) => (
                    <Stack key={index} w={'100%'} borderRadius={'xl'} boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'>
                      <HStack gap={5} p={5}>
                        {/* <Stack w={['20%', '12%', '15%']}>
                          <Image src={x.image} />
                        </Stack> */}
                        <Stack w={['80%', '80%', '85%']}>
                          <Stack>
                            <Heading fontSize={'2xl'}>The {x.profile}</Heading>
                          </Stack>
                          <HStack>
                            {x?.character?.map((z) => (
                              <HStack>
                                {/* <GoDot color={'darkgray'} size={[10,15,15]} /> */}
                                <Text color={'darkgray'} fontSize={['xs', 'md', 'md']} mr={[2, 3, 5]}>{z}</Text>
                              </HStack>
                            ))}
                          </HStack>
                        </Stack>
                      </HStack>
                      <Stack maxW={['md', '2xl', '3xl']} px={5} mb={2}>
                        <Text fontSize={['sm', 'md', 'md']}>
                          {x.talent}
                        </Text>
                      </Stack>
                      <HStack bgColor={'#112344'} p={2} px={5} borderBottomRadius={'xl'} justify={'end'}>
                        <Text color={'white'} fontSize={'sm'} cursor={'pointer'} _hover={{ color: 'blue.200' }} onClick={() => window.open(x.link)}>See Your Detail Result</Text>
                        {/* <AiOutlineArrowRight size={15} color={'white'}/> */}
                      </HStack>
                    </Stack>
                  ))}
                </Stack>
                :
                <Stack w={'full'}>
                  <Grid templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={3} w={'full'}>
                    {manual?.map((x, index) => (
                      <Stack key={index} w={'100%'} p={5} borderRadius={'xl'} boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'>
                        <HStack gap={3}>
                          {/* <Stack>
                            <Image src={x?.image} />
                          </Stack> */}
                          <Stack>
                            <Heading fontSize={'xl'}>{x?.profile}</Heading>
                          </Stack>
                        </HStack>
                        <Stack h={height * 1 / 6}>
                          {x?.character?.map((z) => (
                            <HStack gap={3} >
                              <GoDot size={15} />
                              <Text fontSize={'sm'}>{z}</Text>
                            </HStack>
                          ))}
                        </Stack>
                        <Stack h={'fit-content'}>
                          <Text fontSize={'sm'}>
                            {x.talent}
                          </Text>
                        </Stack>
                        <Button colorScheme='green' w={'fit-content'} onClick={() => handleUpdate(x)}>Choose This</Button>
                      </Stack>
                    ))}

                  </Grid>
                </Stack>


              }
              <Center flexDirection='column' gap={3}>
                <Heading>Details : </Heading>
                <Text m={1}>Name : <strong>{searchParams?.get('name') || ''}</strong></Text>
                <Text>Phone : <strong>{searchParams?.get('phone') || ''}</strong></Text>
                <Text>Dynamo : <strong>{searchParams?.get('dynamo') || ''}</strong></Text>
                <Text>Blaze : <strong>{searchParams?.get('blaze') || ''}</strong></Text>
                <Text>Tempo : <strong>{searchParams?.get('tempo') || ''}</strong></Text>
                <Text>Steel : <strong>{searchParams?.get('steel') || ''}</strong></Text>
              </Center>
              <HStack>
                <Text color={'gray.400'} fontSize={['xs', null, 'sm']}>
                  Powered by
                </Text>
                <Box>
                  <Image
                    w="100px"
                    borderRadius={20}
                    src={logo}
                    alt="Alternate Text"
                  />
                </Box>
              </HStack>
              <Spacer />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    )
  }
  return (
    <Stack
      // spacing={10}
      // pt={20}
      minH={height}
      bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
      bgSize="cover"
      alignItems={'center'}
    // justifyContent="center"
    >
      {step === 'step_1' ? <Regist
        name={name}
        setName={setName}
        phone={phone}
        setPhone={setPhone}
        birthDate={birthDate}
        setBirthDate={setBirthDate}
        birthDateUnix={birthDateUnix}
        setBirthDateUnix={setBirthDateUnix}
      />
        :
        step === 'step_2' ? <Question /> : <Result />}
    </Stack>
  )
}

export default RecruitmentWealthDynamic