/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import DynamicButton from '../../../Components/Buttons/DynamicButton'
import { Stack, useToast } from '@chakra-ui/react'
import useUserStore from '../../../Hooks/Zustand/Store';
import { deleteDocumentFirebase } from '../../../Api/firebaseApi';
import AlertDialogV2 from '../../../Components/AlertDialog/AlertDialogV2';
import { useNavigate } from 'react-router-dom';

function DeleteOffice({ officeId, officeData }) {

  const globalState = useUserStore();
  const navigate=  useNavigate()


  const [alertDelete, setAlertDelete] = useState(false)
  const toast = useToast()
  
  const handleDeleteOffice = async () => {
  
    if (officeData.createdBy !== globalState.uid) {
      if (
        globalState.roleCompany !== 'owner' &&
                  globalState.roleProject === 'user'
      ) 
      {
        return toast({
          title: 'Alert',
          description: 'You dont have access to delete this office',
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
      }
    }
  
    try {
      await deleteDocumentFirebase('offices', officeId)
      toast({
        status: 'success',
        duration: 9000,
        title: 'Delete Card !!',
        isClosable: true,
      });
      handleAlertClose()
      navigate(-1)
    } catch (error) {
      toast({
        status: 'error',
        duration: 9000,
        title: error.message,
        isClosable: true,
      });
    }
  }
  
  const handleAlertOpen = () => {
    setAlertDelete(true)
  }
  
  const handleAlertClose = () => {
    setAlertDelete(false)
  }
  

  return (
    <Stack>
      <DynamicButton
        title={'Deleted Card'}
        action="delete"
        variant={'solid'}
        onClick={() => handleAlertOpen()}
      />

      <AlertDialogV2
        isOpen={alertDelete}
        onClose={handleAlertClose}
        onAction={handleDeleteOffice}
        title={'Would you like to delete this Office data view'}
      />
    </Stack>
  )
}

export default DeleteOffice