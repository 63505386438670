import React, { useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Heading,
  Input,
  Select,
  Stack,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { addDocumentFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import { useNavigate } from 'react-router-dom';

const CreateKPI = () => {
  const [newDataList, setNewDataList] = useState([
    {
      matrix: '',
      target: '',
      bobot: '',
      keterangan: '',
      indikator: '',
      deadline: '',
    }
  ]);

  const toast = useToast();
  const globalState = useUserStore();
  // const { id } = useParams();
  const navigate = useNavigate();


  const handleInputChange = (index, name, value) => {
    setNewDataList((prevList) => {
      const newList = [...prevList];
      newList[index] = {
        ...newList[index],
        [name]: value,
      };
      return newList;
    });
  };

  const handleAddField = () => {
    setNewDataList((prevList) => [
      ...prevList,
      {
        matrix: '',
        target: '',
        bobot: '',
        keterangan: '',
        indikator: '',
        deadline: '',
      }
    ]);
  };

  const handleUpdate = async () => {
    newDataList?.map(async (x) => {
      const data = {
        ...x,
        companyId: globalState.currentCompany,
      }
      try {
        await addDocumentFirebase('kpi_target_performance', data, globalState.currentCompany)

        toast({
          status: 'success',
          description: 'success add new kpi target',
          duration: 2000,
        });
        navigate('/hris/kpi/list');
      } catch (error) {
        throw new Error(error.message, 'Failed to send request api');
      }

    })
    setNewDataList([
      {
        matrix: '',
        target: '',
        bobot: '',
        keterangan: '',
        indikator: '',
        deadline: '',
      },
    ]);
  };





  return (
    <>
      <Stack p={5} m={5} bgColor={'white'} borderRadius={'lg'} shadow={'md'}>
        <Box my={10} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={10}>
          <Heading size='md'>Fill in the forms below:</Heading>
          <VStack>
            <Text color={'gray'} fontWeight={'bold'}> Example:</Text>
            <Text color={'gray'}> Matrix : New Feature | Views | CPM </Text>
            <Text color={'gray'}>Bobot : 70 | 10,000</Text>
            <Text color={'gray'}>Keterangan : New Feature | Meeting klien baru</Text>
            <Text color={'gray'}>Indikator : Semakin banyak semakin baik | Semakin kecil semakin baik</Text>
            <Text color={'gray'}>Interval: daily | weekly | monthly | annual</Text>
          </VStack>
        </Box>
        {newDataList.map((newData, index) => (
          <HStack key={index} spacing={4} align="center">
            <Input
              placeholder="Matrix"
              name={`matrix_${index}`}
              value={newData.matrix}
              onChange={(e) => handleInputChange(index, 'matrix', e.target.value)}
            />
            <Input
              placeholder="Target"
              name={`target_${index}`}
              value={newData.target}
              onChange={(e) => handleInputChange(index, 'target', e.target.value)}
            />
            <Input
              placeholder="Bobot"
              name={`bobot_${index}`}
              value={newData.bobot}
              onChange={(e) => handleInputChange(index, 'bobot', e.target.value)}
            />
            <Input
              placeholder="Keterangan"
              name={`keterangan_${index}`}
              value={newData.keterangan}
              onChange={(e) => handleInputChange(index, 'keterangan', e.target.value)}
            />
            <Select
              placeholder='Indikator'
              value={newData.indikator}
              onChange={(e) => handleInputChange(index, 'indikator', e.target.value)}
            >
              <option>Semakin banyak semakin baik</option>
              <option>Semakin sedikit semakin baik</option>
            </Select>
            <Input
              type='date'
              name={`deadline_${index}`}
              value={newData.deadline}
              onChange={(e) => handleInputChange(index, 'deadline', e.target.value)}
            />
            <Select
              placeholder='Interval'
              value={newData.indikator}
              onChange={(e) => handleInputChange(index, 'interval', e.target.value)}
            >
              <option>daily</option>
              <option>weekly</option>
              <option>monthly</option>
              <option>annual</option>
            </Select>
          </HStack>
        ))}
        <Stack alignItems={'center'} w={'full'} my={10}>
          <Button w="md" size={'sm'} colorScheme="green" onClick={handleAddField} >
            + Add More KPI
          </Button>

        </Stack>
        <Stack direction="row" justify="flex-end" spacing={4} mt={4}>
          <Button colorScheme="blue" onClick={handleUpdate}>
            Add
          </Button>
          <Button>Cancel</Button>
        </Stack>
      </Stack>
    </>
  )
}

export default CreateKPI