import {
  Button,
  Heading,
  HStack,
  Spacer,
  Stack,
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepTitle,
  Stepper,
  Box,
  Card,
  CardHeader,
  CardBody,
  StackDivider,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useToast
} from '@chakra-ui/react';
import { useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../../Components/Buttons/BackButtons';
import {
  collection,
  doc,
  updateDoc,
  getDoc,
  setDoc,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import useUserStore from '../../../Hooks/Zustand/Store';
import moment from 'moment';

function DetailAssetLendingPage() {
  const [activeStep, setActiveStep] = useState()
  const [data, setData] = useState({})
  const globalState = useUserStore();
  const [approveModal, setApproveModal] = useState(false);
  const cancelRef = React.useRef();
  const [buttonVisible, setButtonVisible] = useState(true);
  const param = useParams();
  const toast = useToast()

  const steps = [
    { title: 'User Approved' },
    { title: 'Approved' },
  ]

  const showApprovemodal = () => {
    setApproveModal(true)
  }

  const handleNext = async () => {
    globalState.setIsLoading(true)
    const currentStepTitle = steps[activeStep].title;
    const assetLendingRef = doc(db, 'asset_lendings', param.id);
    await updateDoc(assetLendingRef, {
      status: currentStepTitle,
    });

    const historyStatus = {
      email: globalState.email,
      status: currentStepTitle,
      approved_at: moment().format('MMMM Do YYYY, h:mm:ss a'),
    };

    setButtonVisible(false)
    setApproveModal(false)

    const assetLendingSnapshot = await getDoc(assetLendingRef);
    const assetLendingData = assetLendingSnapshot.data();

    if (assetLendingData) {
      const updatedHistoryStatuses = [...assetLendingData.history_statuses, historyStatus];

      await setDoc(assetLendingRef, { history_statuses: updatedHistoryStatuses }, { merge: true });
    }

    const assetCollection = collection(db, 'asset');

    const queryFilters = [];
    queryFilters.push(where('code', '==', data.code));

    const querySnapshot = await getDocs(query(assetCollection, ...queryFilters));

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const assetRef = doc.ref;
      let status = '';
      if (data.asset_status === 'asset return') {
        status = 'asset returned';
      } else if (data.asset_status === 'asset lending') {
        status = 'asset on loan';
      } else if (data.asset_status === 'asset sold') {
        status = 'assets already sold';
      }

      await updateDoc(assetRef, {
        status: status,
      });
    } else {
      toast({
        title: 'Error!',
        description: 'No asset found with the provided code.',
        status: 'error',
        duration: 2000,
        isClosable: true,
      });      
    }

    toast({
      title: 'Deoapp.com',
      description: 'Hooray, approve has successfully',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    });

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    globalState.setIsLoading(false)
  };

  const getDetailData = async () => {
    try {
      globalState.setIsLoading(true)
      const docRef = doc(db, 'asset_lendings', param.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const documentData = docSnap.data();
        setData(documentData);

        documentData.history_statuses.map((x, i) => {
          setActiveStep(i + 1)
        })
      }
      globalState.setIsLoading(false)
    } catch (error) {
      toast({
        title: 'Error!',
        description: error.message,
        status: 'error',
        duration: 2000,
        isClosable: true,
      });    
    }
  }

  useEffect(() => {
    getDetailData()
  }, [])
  return (
    <Stack p={[1, 1, 5]}>
      <BackButtons />
      <Stack spacing={4}>
        <HStack>
          <Heading size={'md'} fontWeight="bold">
            Asset Lending Detail
          </Heading>
          <Spacer />
          <HStack>
          </HStack>
        </HStack>

        <Stack alignItems={'center'}>
          <Stepper index={activeStep} w={'50%'}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepNumber />
                </StepIndicator>
                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </Stack>

        <Stack
          bgColor="white"
          spacing={1}
          borderRadius="xl"
          p={3}
          m={[1, 1, 5]}
          shadow="md"
        >
          <Card>
            <CardHeader>
              <HStack>
                <Heading size={'md'} fontWeight="bold">
                  Detail
                </Heading>
                <Spacer />
                <HStack>
                  {
                    data.status === 'Approved' ? (
                      <Text>Approved</Text>
                    ) : (
                      buttonVisible && (
                        <Button colorScheme="blue" onClick={showApprovemodal}>
                          {data.status === 'Approved User' ? 'Approved' : ''}
                        </Button>
                      )
                    )
                  }
                </HStack>
              </HStack>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Name
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {data.name}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Email
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {data.email}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Brand
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {data.brand}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Category
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {data.category}
                  </Text>
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Description
                  </Heading>
                  <Text pt='2' fontSize='sm'>
                    {data.description}
                  </Text>
                </Box>
              </Stack>
            </CardBody>
          </Card>
        </Stack>
      </Stack>
      <AlertDialog
        isOpen={approveModal}
        leastDestructiveRef={cancelRef}
        onClose={() => setApproveModal(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Approve
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to approve ?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setApproveModal(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => handleNext()}
                ml={3}
              >
                Approve
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  )
}

export default DetailAssetLendingPage