/* eslint-disable react/prop-types */
import { Alert, AlertDescription, AlertIcon, AlertTitle, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

function AlertFront({ title, link, linkTitle }) {
  return (
    <Alert
      status="warning"
      h={['30px', '30px', '40px']}
      overflowX="auto"
    >
      <AlertIcon />
      <AlertTitle fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
        {('Attention')}! 📢
      </AlertTitle>
      <AlertDescription fontSize={['2xs', 'sm', 'sm']} noOfLines={1}>
        <Text>
          {title} <n />
          {link && (
            <Link
              fontStyle={'italic'}
              color="blue.600"
              href={link}
              fontWeight={'bold'}
              cursor="pointer"
            >
              {linkTitle}
            </Link>
          )}

        </Text>
      </AlertDescription>
    </Alert>
  )
}

export default AlertFront