/* eslint-disable react/prop-types */
import {
  Box,
  Center,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import dayjs from 'dayjs';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import { VscOpenPreview } from 'react-icons/vsc';
import { FiEdit, FiTrash2, FiUsers } from 'react-icons/fi'
import { BiDotsVerticalRounded, BiHide, BiShowAlt, } from 'react-icons/bi'

function RecruitmentBox({ data }) {
  const [alert, setAlert] = useState(false)
  const [mode, setMode] = useState({
    mode: '',
    title: ''
  })
  const dateFormat = (date) => {
    return date ? dayjs(date).format('DD MMM YYYY') : '-'
  }
  const {
    position_name,
    address,
    level,
    expiry,
    published,
  } = data;
  const navigate = useNavigate()

  const handleDraft = async () => {

    setMode({ mode: 'draft', title: 'Draft Role' })
    setAlert(true)
  }

  const handlePublish = async () => {
    setMode({ mode: 'publish', title: 'Publish Role' })
    setAlert(true)
  }

  const handleDelete = () => {
    setMode({ mode: 'delete', title: 'Delete Role' })
    setAlert(true)
  }

  const onAction = async () => {
    if (mode.mode === 'publish') {
      await setDocumentFirebase('positions', data.id, { published: 'Publish' }, '').then(() => {
        setAlert(false)
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }).catch((e) => (e.message))
    } else if (mode.mode === 'draft') {
      await setDocumentFirebase('positions', data.id, { published: 'Draft' }, '').then(() => {
        setAlert(false)
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }).catch((e) => (e.message))
    } else if (mode.mode === 'delete') {
      await deleteDocumentFirebase('positions', data?.id)
      setAlert(false)
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }


  return (
    <>
      {/* <Text>{JSON.stringify(data)}</Text> */}
      <Stack bgColor={'white'} borderRadius={'md'} shadow={'xl'} px={2}>
        <HStack>
          <Box bgColor={published === 'Publish' ? 'green' : 'red'} p={1} borderBottomRadius={'md'} boxShadow='rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'>
            <Text fontWeight={'semibold'} color={'white'}>
              {published}
            </Text>
          </Box>
          <Spacer />
          <Stack >
            <Menu>
              <MenuButton><BiDotsVerticalRounded size={25} /></MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem gap={2} onClick={() => handlePublish()}>
                    <BiShowAlt size={20} cursor={'pointer'} />
                    <Text>Publish</Text>
                  </MenuItem>
                  <MenuItem gap={2} onClick={() => handleDraft()}>
                    <BiHide size={20} cursor={'pointer'} />
                    <Text>Draft</Text>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </Stack>

        </HStack>
        <HStack mb={5}>
          <Stack p={2} w={'32%'} >
            <Heading noOfLines={1} fontSize="md">{position_name}</Heading>
            <Text fontSize={'sm'} color={'gray'}>{level}</Text>
          </Stack>


          <HStack w={'20%'}>
            <VStack mx={2}>
              <Heading fontWeight={'medium'} color={'#626262'} fontSize="sm">{data?.status_unprocessed || 0}</Heading>
              <Text fontWeight={'medium'} color={'#626262'} fontSize={10}>UNPROCESSED</Text>
            </VStack>
            <VStack mx={2}>
              <Heading fontWeight={'medium'} color={'#626262'} fontSize="sm">{data?.status_accepted || 0}</Heading>
              <Text fontWeight={'medium'} color={'#626262'} fontSize={10}>ACCEPTED</Text>
            </VStack>
            <VStack mx={2}>
              <Heading fontWeight={'medium'} color={'#626262'} fontSize="sm">{data?.status_rejected || 0}</Heading>
              <Text fontWeight={'medium'} color={'#626262'} fontSize={10}>REJECTED</Text>
            </VStack>
          </HStack>

          <Stack w={'15%'}>
            <Center>
              <VStack>
                <Heading fontSize={'sm'} color={'darkgray'}>Valid until</Heading>
                <Heading fontSize={'sm'}>{dateFormat(expiry)}</Heading>

              </VStack>
            </Center>
          </Stack>

          <Stack w={'15%'}>
            <Center>
              <Text color={'gray'}>{address}</Text>
            </Center>
          </Stack>

          <HStack gap={4}>
            <VscOpenPreview size={20} cursor={'pointer'} onClick={() => navigate(`/recruitment/job-listing-preview-roles/${data.id}`)} />
            <FiUsers size={20} cursor={'pointer'} onClick={() => navigate(`/recruitment/job-listing-applicant-list/${data.id}`)} />
            <FiTrash2 size={20} cursor={'pointer'} onClick={() => handleDelete(data)} />
            <FiEdit size={20} cursor={'pointer'} onClick={() => navigate(`/recruitment/jobs-edit/${data.id}`)} />
          </HStack>

        </HStack>
      </Stack>
      <AlertDialogV2 isOpen={alert} onClose={() => setAlert(false)} title={mode.title} onAction={onAction} />

    </>
  );
}

export default RecruitmentBox;
