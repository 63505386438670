/* eslint-disable react/no-children-prop */
import { Center,  Grid, HStack, Heading,  Stack, Text, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { auth } from '../../Config/firebase';
import { getCollectionFirebaseV, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import RecruitmentJobCardComponent from './RecruitmentJobCardComponent';
import BackButtons from '../../Components/Buttons/BackButtons';

const RecruitmentFrontHistory = () => {
  const [joblist, setJoblist] = useState(null)
  const user = auth.currentUser

  const toast = useToast()



  const getApplication = async () => {

    try {
      const arrayHistory = [];
      const con = [{ field: 'uid', operator: '==', value: user.uid }];
      const res = await getCollectionFirebaseV('applications', { conditions: con }, { sortBy: null }, { limitValue: null }, { startAfterData: null });

      await Promise.all(res.map(async (x) => {
        const dataPosition = await getSingleDocumentFirebase('positions', x.position_id);
        const jobs = res.find((item) => item.position_id === dataPosition?.id);
        jobs.detail = { ...dataPosition };
        arrayHistory.push(jobs);
      }));

      setJoblist(arrayHistory);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }
  useEffect(() => {
    getApplication()
  }, [])


  return (
    <>
      <HStack py={3}>
        <BackButtons />
        <Heading size={'md'}>Application History</Heading>
      </HStack>
      {/* <Flex w='full'>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={
              <FiSearch color="gray.300" />
            }
          />
          <Input
            type="search-course"
            placeholder="Search Jobs or Company name..."
            w='full'
          />
        </InputGroup>
      </Flex> */}

      <Stack my={25}>
        <Text fontSize={18} fontWeight={'semibold'}>Recently Applied</Text>
      </Stack>
      <Stack my={10}>
        {joblist?.length > 0 ?
          <>
            <Grid templateColumns={['', 'repeat(3, 1fr)', 'repeat(3, 1fr)']} gap={2}>
              {joblist?.map((x, i) => (
                <RecruitmentJobCardComponent
                  key={i}
                  data={x}
                  mx={1}
                />
              ))}
            </Grid>
          </>
          : <>
            <Center>
              <Text>Belum ada lamaran</Text>
            </Center>
          </>}
      </Stack>

    </>
  )
}

export default RecruitmentFrontHistory
