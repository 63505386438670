import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  VStack,
  Image,
  Input,
  Checkbox,
  Grid,
  Select,
  Spacer,
  Stack,
  Text,
  Card,
  CardBody,
  Badge,
  Avatar,
  Divider,
  List,
  ListIcon,
  ListItem,
} from '@chakra-ui/react';
import {
  CheckIcon,
} from '@chakra-ui/icons';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import logo from '../../assets/1.png'
import { getCollectionFirebaseV } from '../../Api/firebaseApi';
import defaultImage from '../../assets/icons/importir.jpg'
import { HiOutlineBuildingLibrary } from 'react-icons/hi2';
import { BiBarChart } from 'react-icons/bi';
import { GoPerson } from 'react-icons/go';
import { CiLocationOn } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { FcDepartment, FcMoneyTransfer } from 'react-icons/fc';
import { dateFormat } from '../../Utils/AccountingUtil';
import DynamicButton from '../../Components/Buttons/DynamicButton';

function RecruitmentUserPage() {
  const [jobs, setJobs] = useState();
  const [drawer, setDrawer] = useState(false)
  const [position, setPosition] = useState('')
  const [dataJob, setDataJob] = useState({})
  const [address, setAddress] = useState('Semua')
  const [timeFilter, setTimeFilter] = useState([])
  const [detailDrawer, setDetailDrawer] = useState(false)
  const [workplaceFilter, setWorkplaceFilter] = useState([])
  const [experienceFilter, setExperienceFilter] = useState([])
  const [specialization, setSpecialization] = useState('')
  const height = window.innerHeight;
  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 600);
  const navigate = useNavigate();

  const updateMedia = () => {
    setDesktop(window.innerWidth > 600);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const getJobs = async () => {
    const conditions = [{
      field: 'published', operator: '==', value: 'Publish'
    }]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    //...get data here
    getCollectionFirebaseV('positions', { conditions }, { sortBy }, { limitValue }, { startAfterData }).then((res) => {
      setJobs(res)
    })
  }

  useEffect(() => {
    getJobs();
  }, [])

  const timeAgo = (dateString) => {
    const createdAt = new Date(parseInt(dateString, 10));
    const now = new Date();
    const timeDifference = now - createdAt;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysAgo === 0) {
      return 'Hari ini';
    } else if (daysAgo === 1) {
      return 'Kemarin';
    } else {
      return `${daysAgo} hari yang lalu`;
    }
  }



  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleSpecialization = (event) => {
    setSpecialization(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleTimeFilterChange = (event) => {
    const value = event.target.value;
    setTimeFilter((prevFilters) =>
      prevFilters.includes(value)
        ? prevFilters.filter((filter) => filter !== value)
        : [...prevFilters, value]
    );
  };

  const handleWorkplaceFilterChange = (event) => {
    const value = event.target.value;
    setWorkplaceFilter((prevFilters) =>
      prevFilters.includes(value)
        ? prevFilters.filter((filter) => filter !== value)
        : [...prevFilters, value]
    );
  };

  const handleExperienceFilterChange = (event) => {
    const value = event.target.value;
    setExperienceFilter((prevFilters) =>
      prevFilters.includes(value)
        ? prevFilters.filter((filter) => filter !== value)
        : [...prevFilters, value]
    );
  };

  const filteredJobs = jobs
    ?.filter((job) => {
      return position === '' || job.position_name.toLowerCase().includes(position.toLowerCase());
    })
    .filter((job) => {
      return address === 'Semua' || job.address === address;
    })
    .filter((job) => {
      return timeFilter.length === 0 || timeFilter.includes(job.time);
    })
    .filter((job) => {
      return workplaceFilter.length === 0 || workplaceFilter.includes(job.workplace);
    })
    .filter((job) => {
      return experienceFilter.length === 0 || experienceFilter.includes(job.experience);
    })
    .filter((job) => {
      return specialization === '' || job.job_type.toLowerCase().includes(specialization.toLowerCase())
    });

  const handleDetail = (x) => {
    setDataJob(x)
    setDetailDrawer(true)
  }

  return (
    <Stack
      minH={height}
      bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
      bgSize="cover"
      alignItems={['', '', 'center']}
    >
      <HStack w={'full'} h={'fit-content'} p={[1, 1, 5]}>
        <Box>
          <Image
            w={['180px', '', '250px']}
            borderRadius={20}
            src={logo}
            alt="Alternate Text"
          />
        </Box>
        <Spacer />

        <Stack position={'absolute'} top={5} right={5}>
          <DynamicButton
            action="custom"
            title="Signup Recruitment"
            color="blue"
            variant={'solid'}
            onClick={() => navigate('/signup/recruitment')}
          />
        </Stack>



      </HStack>

      <Stack direction={['column', 'row', 'row']} w={'full'} h={'fit-content'} bgColor={'blackAlpha.500'} p={[1, 1, 5]} align={['', '', 'center']} gap={5}>
        {isDesktop ?
          <>
            <Input placeholder='Cari Lowongan' w={['full', '30%', '30%']} bgColor={'white'} />
            <Input placeholder='Cari Bidang' w={['full', '30%', '30%']} bgColor={'white'} />
            <Input placeholder='Cari Lokasi' w={['full', '30%', '30%']} bgColor={'white'} />
            <Button w={'10%'}>Cari</Button>
          </> :
          <>
            <Stack w={'full'}>
              <Input size={'md'} bgColor={'white'} placeholder='Find Job' value={position} onChange={handlePositionChange} />
              <Button onClick={() => setDrawer(true)}>Filter</Button>
            </Stack>

            <Drawer placement={'bottom'} onClose={() => setDrawer(false)} isOpen={drawer}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth='1px'>Filter Job</DrawerHeader>
                <DrawerBody>
                  <Stack>
                    <Text fontSize={'sm'}>Job Specialization</Text>
                    <Input size={'sm'} placeholder='Job Specialization' value={specialization} onChange={handleSpecialization} />
                  </Stack>
                  <Divider />
                  <Stack>
                    <Text fontSize={'sm'}>Address</Text>
                    <Select size={'sm'} value={address} onChange={handleAddressChange}>
                      <option value="Semua">Semua</option>
                      <option value="Jakarta">Jakarta</option>
                      <option value="Depok">Depok</option>
                      <option value="Bogor">Bogor</option>
                      <option value="Bekasi">Bekasi</option>
                    </Select>
                  </Stack>
                  <Divider />
                  <Stack>
                    <Text fontSize={'sm'}>Time</Text>
                    <Checkbox
                      size={'sm'}
                      value="Full Time"
                      isChecked={timeFilter.includes('Full Time')}
                      onChange={handleTimeFilterChange}
                    >
                      Full Time
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="Part Time"
                      isChecked={timeFilter.includes('Part Time')}
                      onChange={handleTimeFilterChange}
                    >
                      Part Time
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="Full & Part Time"
                      isChecked={timeFilter.includes('Full & Part Time')}
                      onChange={handleTimeFilterChange}
                    >
                      Full & Part Time
                    </Checkbox>
                  </Stack>
                  <Divider />
                  <Stack>
                    <Text fontSize={'sm'}>Workplace</Text>
                    <Checkbox
                      size={'sm'}
                      value="Work From Home(WFH)"
                      isChecked={workplaceFilter.includes('Work From Home(WFH)')}
                      onChange={handleWorkplaceFilterChange}
                    >
                      Work From Home (WFH)
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="Work From Office(WFO)"
                      isChecked={workplaceFilter.includes('Work From Office(WFO)')}
                      onChange={handleWorkplaceFilterChange}
                    >
                      Work From Office (WFO)
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="Hybrid"
                      isChecked={workplaceFilter.includes('Hybrid')}
                      onChange={handleWorkplaceFilterChange}
                    >
                      Hybrid
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="Remote"
                      isChecked={workplaceFilter.includes('Remote')}
                      onChange={handleWorkplaceFilterChange}
                    >
                      Remote
                    </Checkbox>
                  </Stack>
                  <Divider />
                  <Stack>
                    <Text fontSize={'sm'}>Experience</Text>
                    <Checkbox
                      size={'sm'}
                      value="Tanpa Pengalaman"
                      isChecked={experienceFilter.includes('Tanpa Pengalaman')}
                      onChange={handleExperienceFilterChange}
                    >
                      Tanpa Pengalaman
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="6 bulan - 1 tahun"
                      isChecked={experienceFilter.includes('6 bulan - 1 tahun')}
                      onChange={handleExperienceFilterChange}
                    >
                      6 bulan - 1 tahun
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="1 tahun - 2 tahun"
                      isChecked={experienceFilter.includes('1 tahun - 2 tahun')}
                      onChange={handleExperienceFilterChange}
                    >
                      1 tahun - 2 tahun
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="2 tahun - 3 tahun"
                      isChecked={experienceFilter.includes('2 tahun - 3 tahun')}
                      onChange={handleExperienceFilterChange}
                    >
                      2 tahun - 3 tahun
                    </Checkbox>
                    <Checkbox
                      size={'sm'}
                      value="3 tahun++"
                      isChecked={experienceFilter.includes('3 tahun++')}
                      onChange={handleExperienceFilterChange}
                    >
                      3 tahun++
                    </Checkbox>
                  </Stack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>}

      </Stack>
      <HStack p={[1, 1, 5]} w={'full'} gap={3} align={'flex-start'}>
        {isDesktop ?
          <Stack w={'30%'}>
            <Stack w={'full'} bgColor={'white'} borderRadius={'md'} p={5} shadow={'md'} >
              <Stack >
                <Text fontSize={'sm'}>Position name</Text>
                <Input size={'sm'} placeholder='Find Job' value={position} onChange={handlePositionChange} />
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Job Specialization</Text>
                <Input size={'sm'} placeholder='Job Specialization' value={specialization} onChange={handleSpecialization} />
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Address</Text>
                <Select size={'sm'} value={address} onChange={handleAddressChange}>
                  <option value="Semua">Semua</option>
                  <option value="Jakarta">Jakarta</option>
                  <option value="Depok">Depok</option>
                  <option value="Bogor">Bogor</option>
                  <option value="Bekasi">Bekasi</option>
                </Select>
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Time</Text>
                <Checkbox
                  size={'sm'}
                  value="Full Time"
                  isChecked={timeFilter.includes('Full Time')}
                  onChange={handleTimeFilterChange}
                >
                  Full Time
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Part Time"
                  isChecked={timeFilter.includes('Part Time')}
                  onChange={handleTimeFilterChange}
                >
                  Part Time
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Full & Part Time"
                  isChecked={timeFilter.includes('Full & Part Time')}
                  onChange={handleTimeFilterChange}
                >
                  Full & Part Time
                </Checkbox>
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Workplace</Text>
                <Checkbox
                  size={'sm'}
                  value="Work From Home(WFH)"
                  isChecked={workplaceFilter.includes('Work From Home(WFH)')}
                  onChange={handleWorkplaceFilterChange}
                >
                  Work From Home (WFH)
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Work From Office(WFO)"
                  isChecked={workplaceFilter.includes('Work From Office(WFO)')}
                  onChange={handleWorkplaceFilterChange}
                >
                  Work From Office (WFO)
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Hybrid"
                  isChecked={workplaceFilter.includes('Hybrid')}
                  onChange={handleWorkplaceFilterChange}
                >
                  Hybrid
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Remote"
                  isChecked={workplaceFilter.includes('Remote')}
                  onChange={handleWorkplaceFilterChange}
                >
                  Remote
                </Checkbox>
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Experience</Text>
                <Checkbox
                  size={'sm'}
                  value="Tanpa Pengalaman"
                  isChecked={experienceFilter.includes('Tanpa Pengalaman')}
                  onChange={handleExperienceFilterChange}
                >
                  Tanpa Pengalaman
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="6 bulan - 1 tahun"
                  isChecked={experienceFilter.includes('6 bulan - 1 tahun')}
                  onChange={handleExperienceFilterChange}
                >
                  6 bulan - 1 tahun
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="1 tahun - 2 tahun"
                  isChecked={experienceFilter.includes('1 tahun - 2 tahun')}
                  onChange={handleExperienceFilterChange}
                >
                  1 tahun - 2 tahun
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="2 tahun - 3 tahun"
                  isChecked={experienceFilter.includes('2 tahun - 3 tahun')}
                  onChange={handleExperienceFilterChange}
                >
                  2 tahun - 3 tahun
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="3 tahun++"
                  isChecked={experienceFilter.includes('3 tahun++')}
                  onChange={handleExperienceFilterChange}
                >
                  3 tahun++
                </Checkbox>
              </Stack>
            </Stack>

          </Stack>
          : ''}

        <Stack w={['full', '75%', '75%']} overflow={'hidden'} minH={height} >
          {filteredJobs?.length > 0 ?
            <Grid templateColumns={['', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={3}>
              {filteredJobs?.length > 0 ? filteredJobs?.map((job, index) => (
                <Card key={index} onClick={() => handleDetail(job)}>
                  <CardBody>
                    <HStack justify={'space-between'} mb={2}>
                      <Heading noOfLines={1} size='sm' cursor='pointer' _hover={{ color: 'gray.400' }}>{job.position_name}</Heading>
                      <Badge colorScheme='green'>{job.time}</Badge>
                    </HStack>
                    <HStack>
                      <BiBarChart size={18} />
                      <Text color='gray.600'>{job.level}</Text>
                    </HStack>
                    <HStack>
                      <GoPerson size={18} />
                      <Text color='gray.600'>{job.job_type}</Text>
                    </HStack>
                    <HStack>
                      <GoPerson size={18} />
                      <Text color='gray.600'>{job.experience}</Text>
                    </HStack>
                    <HStack>
                      <HiOutlineBuildingLibrary size={18} />
                      <Text color='gray.600'>{job.workplace}</Text>
                    </HStack>
                    <HStack>
                      <Avatar size={'md'} src={job?.image ?? defaultImage} />
                      <VStack align={'flex-start'}>
                        <HStack>
                          <HiOutlineBuildingLibrary />
                          <Text color='gray.600'>{job.company}</Text>
                        </HStack>
                        <HStack>
                          <CiLocationOn size={18} />
                          <Text color='gray.600'>{job.address}</Text>
                        </HStack>
                      </VStack>
                    </HStack>

                    <Divider my={3} />

                    <HStack>
                      <Text fontSize={'sm'} color={'gray'}>{timeAgo(job.createdAt)}</Text>
                    </HStack>
                  </CardBody>
                </Card>
                // <Flex borderRadius={10}  gap={5} bg='white' shadow='md' w='full' key={index} p={5}>
                //     {job.published === 'Publish' ? 
                //     <>
                //     <Image w={'180px'} h={'180px'} aspectRatio={1} src={job.image} />
                //     <Box flex={1}>
                //         <Stack>
                //             <Flex gap={10}>
                //                 <Heading onClick={()=> navigate(`/preview/${job.id}/apply`)} size='sm' cursor='pointer' _hover={{color : 'gray.400'}}>{job.position_name}</Heading>
                //                 <Badge colorScheme='green'>{job.time}</Badge>
                //             </Flex>
                //             <Flex gap={2}><HiOutlineBuildingLibrary /><Text  color='gray.600'>{job.company}</Text></Flex>
                //             <Flex gap={2}><BiBarChart /><Text color='gray.600'>{job.level}</Text></Flex>
                //             <Flex gap={2}><GoPerson /><Text color='gray.600'>{job.job_type}</Text></Flex>
                //             <Flex gap={2}><CiLocationOn /><Text color='gray.600'>{job.address}</Text></Flex>
                //             <Flex gap={2}><HiOutlineBuildingLibrary /><Text color='gray.600'>{job.workplace}</Text></Flex>
                //         </Stack>
                //     </Box>
                //     </> : <></>}
                // </Flex>
              )) : <></>}

            </Grid>
            : <Center><Text color={'gray'}>Belum ada lowongan</Text></Center>
          }
        </Stack>
      </HStack>

      <Drawer placement={'bottom'} onClose={() => setDetailDrawer(false)} isOpen={detailDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth='1px'>{dataJob?.position_name}</DrawerHeader>
          <DrawerBody>
            <VStack p={[2, 5, 5]} bgColor={'white'} my={2} align={'left'} justify={'space-between'} w={'100%'} borderBottomRadius={'3xl'} >
              <VStack align={'flex-start'}>
                <HStack gap={2} align={'flex-start'}>
                  <Image boxSize="100px" src={defaultImage} />
                  <Stack >
                    <Stack align={'left'} justify={'left'} w={'full'} my={3}>
                      <HStack align={'left'}>
                        <FcDepartment size={25} />
                        <Text>{dataJob?.company}</Text>
                      </HStack>
                      <HStack align={'left'}>
                        <FcDepartment size={25} />
                        <Text>{dataJob?.address}</Text>
                      </HStack>
                      <HStack align={'left'}>
                        <FcMoneyTransfer size={25} />
                        <Text>
                          {dataJob?.range_salary_high
                            ? `Rp${new Intl.NumberFormat('en-ID', {
                              maximumSignificantDigits: 3,
                            }).format(
                              dataJob.range_salary_low
                            )} - Rp${new Intl.NumberFormat('en-ID', {
                              maximumSignificantDigits: 3,
                            }).format(dataJob.range_salary_high)} `
                            : 'Negotiable'}
                        </Text>
                      </HStack>
                      <Text fontSize={12} color="blackAlpha.600">
                        Berakhir pada : {dateFormat(dataJob?.expiry)}
                      </Text>
                    </Stack>
                  </Stack>
                </HStack>
              </VStack>
            </VStack>

            <Divider />
            <Stack bgColor={'white'} my={1} borderRadius={'2xl'} p={[2, 5, 5]}>
              <Box my={2}>
                <Heading fontSize="lg" mb={2}>About Our Program</Heading>

                <Text fontSize={'17px'} fontWeight={'medium'}>{dataJob?.description}</Text>
              </Box>
              {/* <Divider m="2" width="80%" /> */}
              <Box my={2} >
                <Heading fontSize="lg" my={2}>Job Description</Heading>
                <List mt={2} spacing={1} ml={5}>
                  {dataJob?.jobdesc?.length > 0
                    ? dataJob.jobdesc.map((x, i) => (
                      <ListItem key={i}>
                        <ListIcon as={CheckIcon} color="green.500" />
                        {x}
                      </ListItem>
                    ))
                    : null}
                </List>
              </Box>

              {/* <Divider m="2" width="80%" /> */}
              <Box my={2}>
                <Heading fontSize="lg" my={2}>Qualification</Heading>
                <List mt={2} spacing={1} ml={5}>
                  {dataJob?.requirements?.length > 0
                    ? dataJob.requirements.map((x, i) => (
                      <ListItem key={i}>
                        <ListIcon as={CheckIcon} color="green.500" />
                        {x}
                      </ListItem>
                    ))
                    : null}
                </List>
              </Box>
              <Box my={2}>
                <Heading fontSize="lg" my={2}>
                  Additional Information
                </Heading>
                <HStack w={['full', '50%', '50%']} justify={'space-between'}>
                  <VStack gap={5} >
                    <VStack align={'left'} w={'100%'}>
                      <Text fontWeight={'medium'}>Position Level :</Text>
                      <Text>{dataJob?.level ? dataJob.level : <></>}</Text>
                    </VStack>
                    <VStack align={'left'} w={'100%'}>
                      <Text fontWeight={'medium'}>Category :</Text>
                      <Text>{dataJob?.time ? dataJob.time : <></>}</Text>
                    </VStack>
                  </VStack>

                  <VStack gap={5}>
                    <VStack align={'left'} w={'100%'}>
                      <Text fontWeight={'medium'}>Minimum Experience :</Text>
                      <Text>{dataJob?.experience ? dataJob.experience : <></>}</Text>
                    </VStack>
                    <VStack align={'left'} w={'100%'}>
                      <Text fontWeight={'medium'}>Placement :</Text>
                      <Text>{dataJob?.workplace ? dataJob.workplace : <></>}</Text>
                    </VStack>
                  </VStack>
                </HStack>
              </Box>
              <Button bgColor="brand.100" onClick={() => navigate('/signup/recruitment')}>
                Apply now
              </Button>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

    </Stack>
  )
}

export default RecruitmentUserPage