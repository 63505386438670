export default function determineUserRoles(entity, uid,) {
  if (entity?.owner && entity.owner.includes(uid,)) {
    return 'owner';
  } else if (entity.hr && entity.hr.includes(uid,)) {
    return 'hr';
  } else if (entity.managers && entity.managers.includes(uid,)) {
    return 'managers';
  } else {
    return 'user';
  }
}
