import moment from 'moment';

// TIME
export const currentTime = moment();
export const extra = moment().format('YYYY-MM-DD',) + ' ';
export const morning = moment(extra + '05:00:00',);
export const afterMorning = moment(extra + '09:00:00',);
export const afternoon = moment(extra + '12:00:00',);
export const evening = moment(extra + '17:00:00',);
export const night = moment(extra + '20:00:00',);

export const isMorning = moment(currentTime,).isBetween(morning, afternoon,);
export const toAfternoon = moment(currentTime,).isBetween(
  afterMorning,
  afternoon,
);
export const isAfternoon = moment(currentTime,).isBetween(afternoon, evening,);
export const isEvening = moment(currentTime,).isBetween(evening, night,);
export const isNight = moment(currentTime,).isBetween(night, morning,);

//DAY

export const create7Days = () => {
  const last7Days = 7;
  const days = [];
  for (let i = 0; i < last7Days; i++) {
    days.push(moment().subtract(i, 'days',).format('DD-MM-YYYY',),);
  }
  return days;
};
