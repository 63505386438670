import React, {  } from 'react';
import MainMenuComponent from '../../Components/Menus/MainMenuComponent';

const AttendanceHome = () => {

  return (
    <MainMenuComponent menuName="attendance" />
  );
};

export default AttendanceHome;
