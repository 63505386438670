import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { getCollectionFirebaseV, getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { auth } from '../../Config/firebase';
import dayjs from 'dayjs';
import { FcDepartment, FcMoneyTransfer } from 'react-icons/fc';
import BackButtons from '../../Components/Buttons/BackButtons';
import defaultImage from '../../assets/icons/importir.jpg'

const RecruitmentFrontReviewRole = () => {
  const params = useParams();
  const [data, setData] = useState({});
  const [applied, setApplied] = useState()
  const [action] = useState('');
  const navigate = useNavigate();

  const user = auth.currentUser

  const dateFormat = (date) => {
    return date ? dayjs(date).format('DD MMM YYYY') : '-'
  }

  const getJobDetail = async () => {
    try {
      const com = await getSingleDocumentFirebase('positions', `${params.id}`)
      setData(com)
    } catch (error) {
      // console.log(error.message)
    }
  }
  const getUserApplication = async () => {
    try {
      const con = [{ field: 'uid', operator: '==', value: user.uid }]
      const res = await getCollectionFirebaseV('applications', { conditions: con }, { sortBy: null }, { limitValue: null }, { startAfterData: null })
      setApplied(res.map((x) => x.position_id))
    } catch (error) {
      // console.log(error.message)
    }

  }
  const apply = applied?.find((x) => x === params.id)

  useEffect(() => {
    getUserApplication()
  }, [])

  const handleApp = () => {
    if (!user) {
      navigate('/login')
    } else (
      navigate('form')
    )
  }


  useEffect(() => {
    getJobDetail()
  }, [])
  return (
    <>


      {action === 'apply' ? null : (
        <>
          <Stack my={2}>
            <HStack pos={'fixed'} zIndex={1}>
              <BackButtons />
              <Heading size={'md'}>Role Detail</Heading>
            </HStack>
          </Stack>

          <Stack direction={['column', 'row', 'row']} py={2} align={'flex-start'}>
            <Stack w={['full', '25%', '25%']} zIndex={1}>
              <Stack pos={['', 'fixed', 'fixed']} w={['full', '22%', '22%']}>
                <VStack p={8} bgColor={'white'} my={5} align={'left'} justify={'space-between'} w={'100%'} borderBottomRadius={'3xl'} >
                  <VStack align={'flex-start'}>
                    <VStack gap={5} align={'flex-start'}>
                      <Image boxSize="100px" src={defaultImage} />
                      <Stack >
                        <Heading size={'md'}>{data?.position_name ? data.position_name : null}</Heading>
                        <HStack>
                          <Text>{data?.company}</Text>
                        </HStack>
                      </Stack>
                    </VStack>
                    <Stack align={'left'} justify={'left'} w={'full'} my={3}>
                      <HStack align={'left'}>
                        <FcDepartment size={25} />
                        <Text>{data?.address}</Text>
                      </HStack>
                      <HStack align={'left'}>
                        <FcMoneyTransfer size={25} />
                        <Text>
                          {data?.range_salary_high
                            ? `Rp${new Intl.NumberFormat('en-ID', {
                              maximumSignificantDigits: 3,
                            }).format(
                              data.range_salary_low
                            )} - Rp${new Intl.NumberFormat('en-ID', {
                              maximumSignificantDigits: 3,
                            }).format(data.range_salary_high)} `
                            : 'Negotiable'}
                        </Text>
                      </HStack>
                      <Text fontSize={12} color="blackAlpha.600">
                        Berakhir pada : {dateFormat(data?.expiry)}
                      </Text>
                    </Stack>
                  </VStack>
                  <HStack >
                    {apply === undefined ?
                      <>
                        <Button bgColor="brand.100" onClick={() => handleApp(data)}>
                          Apply now
                        </Button>
                      </>
                      :
                      <>
                        <Button bgColor="brand.100" isDisabled={true}>
                          Applied
                        </Button>
                      </>}

                  </HStack>
                </VStack>

              </Stack>
            </Stack>

            <Stack w={['full  ', '75%', '75%']} zIndex={0}>
              <Stack bgColor={'white'} my={[1, 5, 5]} borderRadius={'2xl'} p={[5, 8, 8]}>
                <Box my={[5, 5, 8]}>
                  <Heading fontSize="lg" mb={2}>About Our Program</Heading>

                  <Text fontSize={'17px'} fontWeight={'medium'}>{data?.description}</Text>
                </Box>
                {/* <Divider m="2" width="80%" /> */}
                <Box my={[1, 5, 5]} >
                  <Heading fontSize="lg" my={2}>Job Description</Heading>
                  <List mt={2} spacing={1} ml={5}>
                    {data?.jobdesc?.length > 0
                      ? data.jobdesc.map((x, i) => (
                        <ListItem key={i}>
                          <ListIcon as={CheckIcon} color="green.500" />
                          {x}
                        </ListItem>
                      ))
                      : null}
                  </List>
                </Box>

                {/* <Divider m="2" width="80%" /> */}
                <Box my={[1, 5, 5]}>
                  <Heading fontSize="lg" my={2}>Qualification</Heading>
                  <List mt={2} spacing={1} ml={5}>
                    {data?.requirements?.length > 0
                      ? data.requirements.map((x, i) => (
                        <ListItem key={i}>
                          <ListIcon as={CheckIcon} color="green.500" />
                          {x}
                        </ListItem>
                      ))
                      : null}
                  </List>
                </Box>
                <Box my={[1, 5, 5]}>
                  <Heading fontSize="lg" my={2}>
                    Additional Information
                  </Heading>
                  <HStack w={['full', '50%', '50%']} justify={'space-between'}>
                    <VStack gap={5} >
                      <VStack align={'left'} w={'100%'}>
                        <Text fontWeight={'medium'}>Position Level :</Text>
                        <Text>{data?.level ? data.level : <></>}</Text>
                      </VStack>
                      <VStack align={'left'} w={'100%'}>
                        <Text fontWeight={'medium'}>Category :</Text>
                        <Text>{data?.time ? data.time : <></>}</Text>
                      </VStack>
                    </VStack>

                    <VStack gap={5}>
                      <VStack align={'left'} w={'100%'}>
                        <Text fontWeight={'medium'}>Minimum Experience :</Text>
                        <Text>{data?.experience ? data.experience : <></>}</Text>
                      </VStack>
                      <VStack align={'left'} w={'100%'}>
                        <Text fontWeight={'medium'}>Placement :</Text>
                        <Text>{data?.workplace ? data.workplace : <></>}</Text>
                      </VStack>
                    </VStack>

                  </HStack>
                </Box>
              </Stack>
              <Stack >
                <Divider />
              </Stack>
              <Stack bgColor={'white'} my={[1, 5, 5]} borderTopRadius={'2xl'} p={8}>
                <Box my={8}>
                  <Heading fontSize="lg" mb={2}>Company Overview</Heading>

                  <Text fontSize={'17px'}>{data?.description}</Text>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
}

export default RecruitmentFrontReviewRole