import {
  Box,
  Button,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
  Avatar,
  Center,
  Divider,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FcDepartment } from 'react-icons/fc';
import BackButtons from '../../Components/Buttons/BackButtons';
import MapComponent from '../../Components/Map/MapComponent';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import useUserStore from '../../Hooks/Zustand/Store';
import { useNavigate } from 'react-router-dom';
import {
  addDocumentFirebase,
  getCollectionFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import { db } from '../../Config/firebase';
import {
  collection,
  onSnapshot,
  query,
  where,
  getDocs,
} from '@firebase/firestore';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import CardAvatar from '../../Components/Card/CardAvatar';

const HrisOfficePage = () => {
  const [modal, setModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataOffice, setDataOffice] = useState([]);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [data, setData] = useState([]);
  const [editor, setEditor] = useState([]);

  const [startIndex, setStartIndex] = useState(8);
  const itemsPerPage = 8;

  const [dataStore, setDataStore] = useState({
    title: '',
    radius: 0,
    latitude: '',
    longitude: '',
    address: '',
  });

  const navigate = useNavigate();
  const toast = useToast();

  const globalState = useUserStore();

  const getOffice = async () => {
    const q = query(
      collection(db, 'offices'),
      where('companyId', '==', globalState?.currentCompany)
    );
    onSnapshot(q, (querySnapshot) => {
      const outletArr = [];
      querySnapshot.forEach((doc) => { outletArr.push({ ...doc.data(), id: doc.id }); });
      setDataOffice(outletArr);
    });

    if (globalState?.currentCompany) {
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState?.currentCompany},
      ];

      const sortBy = { field: 'createdAt', direction: 'desc' };
      const limitValue = startIndex + itemsPerPage;

      try {
        const resAssets = await getCollectionFirebase(
          'offices',
          conditions,
          sortBy,
          limitValue
        );
        const projectDataArray = await Promise.all(
          resAssets?.map(async (projectData) => {
            const userSnapshot = await getDocs(
              collection(db, `offices/${projectData?.id}/employees`)
            );
            const userData = userSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            projectData.usersEmployeeData = userData;
            return projectData;
          })
        );

        setDataOffice(projectDataArray.length > 0 ? projectDataArray : []);
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          duration: 3000,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });      }
    }
  };

  useEffect(() => {
    getOffice();

    return () => {
      setDataOffice([]);
    };
  }, [globalState.currentCompany]);

  const handleAdd = () => {
    setModalAdd(true);
  };

  const onLatitude = (latitude) => {
    setLatitude(latitude);
  };

  const onLongitude = (longitude) => {
    setLongitude(longitude);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await addDocumentFirebase(
        'offices',
        {
          ...dataStore,
          longitude: longitude,
          latitude: latitude,
          employee: [globalState?.uid],
          owner: [globalState?.uid],
        },
        globalState.currentCompany
      );

      //create subcollection
      await setDocumentFirebase(
        `offices/${res}/employees`,
        globalState?.uid,
        {
          name: globalState?.name,
          email: globalState?.email,
          id: globalState?.uid,
          createdAt: new Date(),
        }
      );


      toast({
        title: 'Offices Created',
        duration: 3000,
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });    
    } finally {
      setIsLoading(false);
      setModalAdd(false);
    }
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };



  const modalTeam = (team) => {
    // setSelectedFlowchart(x)
    setData(team);

    const detail = team?.usersEmployeeData?.map((x) => {
      return {
        name: x?.name || '',
        email: x?.email || '',
        id: x?.id || '',
      };
    });
    setEditor(detail);
    setModal({ ...modal, user: true });
  };

  // const handleRemoveUser = (x, data) => {
  //   console.log(x, 'xxx');
  //   console.log(data, 'data');
  // };

  return (
    <Stack>
      <HStack my={5} flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size={'md'} fontWeight="bold">
          Office
        </Heading>
        <Spacer />
        <DynamicButton
          title={'Add Office'}
          action={'create'}
          onClick={handleAdd}
        />
      </HStack>
      {dataOffice?.length > 0 ? (
        <Stack>
          <SimpleGrid w={'100%'} columns={[1, 2, 4]} gap={5}>
            {dataOffice?.map((x, index) => {
              return (
                <Stack key={index}>
                  <CardAvatar
                    data={x}
                    title={x.title}
                    globalState={globalState}
                    onNavigate={() => navigate(`${x.id}`)}
                    userArray={x?.usersEmployeeData}
                    timeCreated={x?.lastUpdated?.seconds}
                    modalTeam={() => modalTeam(x)}
                    Icon={FcDepartment}
                  />
                </Stack>
              );
            })}
          </SimpleGrid>
        </Stack>
      ) : (
        <AlertArrayNull titleData={'Office'} action="Add Office" />
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {dataOffice?.length > startIndex && (
            <Button onClick={handleLoadMore} size="sm" colorScheme="blue">
              Load More
            </Button>
          )}
        </Box>
      </Stack>

      <Modal
        isOpen={modalAdd}
        onClose={() => setModalAdd(false)}
        motionPreset="slideInBottom"
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text fontWeight={'semibold'}>Office Data</Text>
              <HStack my={3}>
                <Stack w={'50%'}>
                  <Text fontSize={'sm'}>Office Name</Text>
                  <Input
                    placeholder="Enter office name"
                    onChange={(e) =>
                      setDataStore({ ...dataStore, title: e.target.value })
                    }
                  />
                </Stack>
                <Stack w={'50%'}>
                  <Text fontSize={'sm'}>Telephone</Text>
                  <Input
                    type="number"
                    placeholder="Enter telephone"
                    onChange={(e) =>
                      setDataStore({ ...dataStore, phone: e.target.value })
                    }
                  />
                </Stack>
              </HStack>
              <Stack my={3}>
                <Stack w={'50%'}>
                  <Text fontSize={'sm'}>Description</Text>
                  <Textarea
                    placeholder="Enter your description"
                    onChange={(e) =>
                      setDataStore({
                        ...dataStore,
                        description: e.target.value,
                      })
                    }
                  />
                </Stack>
              </Stack>
              <HStack>
                <Stack w={'50%'}>
                  <Text fontSize={'sm'}>Start Hour</Text>
                  <Input
                    type="time"
                    onChange={(e) =>
                      setDataStore({ ...dataStore, startHour: e.target.value })
                    }
                  />
                </Stack>
                <Stack w={'50%'}>
                  <Text fontSize={'sm'}>End Hour</Text>
                  <Input
                    type="time"
                    onChange={(e) =>
                      setDataStore({ ...dataStore, endHour: e.target.value })
                    }
                  />
                </Stack>
              </HStack>
            </Box>

            <Stack>
              <Text fontWeight={'semibold'} my={2}>
                Office Location
              </Text>
              <Stack>
                <Text fontSize={'sm'}>Address</Text>
                <Textarea
                  onChange={(e) =>
                    setDataStore({ ...dataStore, address: e.target.value })
                  }
                />
              </Stack>
              <Stack>
                <Text fontSize={'sm'}>Select Office Location</Text>
                <MapComponent
                  onLongitude={onLongitude}
                  onLatitude={onLatitude}
                />
              </Stack>
              <HStack>
                <Stack w={'50%'}>
                  <FormLabel>Latitude</FormLabel>
                  <Input placeholder="Map latitude" value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                </Stack>
                <Stack w={'50%'}>
                  <FormLabel>Longitude</FormLabel>
                  <Input placeholder="Map longitude" value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                </Stack>
              </HStack>
              <Stack w={'50%'}>
                <Text fontSize={'sm'}>Radius (m) to fill Absence</Text>
                <Input
                  placeholder=""
                  onChange={(e) =>
                    setDataStore({
                      ...dataStore,
                      radius: parseInt(e.target.value),
                    })
                  }
                />
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter gap={5}>
            <Button colorScheme="red" onClick={() => setModalAdd(false)}>
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              colorScheme="green"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        onClose={() => setModal({ ...modal, user: false })}
        isOpen={modal?.user}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Team</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Center>
                <VStack my={3}>
                  <Heading size={'md'}>{data?.title}</Heading>
                  <Divider />
                </VStack>
              </Center>
              <Stack maxH={'400px'} overflowY="scroll">
                {editor?.map((x, i) => {
                  return (
                    <HStack key={i} justify={'space-between'} w={'full'} py={1}>
                      {x?.name === data?.usersEmployeeData[0]?.name ? (
                        <>
                          <HStack gap={3}>
                            <Avatar size={'md'} name={x?.name} />
                            <VStack align={'flex-start'}>
                              <Text fontWeight={'medium'} fontSize={'sm'}>
                                {x?.name}
                              </Text>
                              <Text fontSize={'xs'}>{x?.email}</Text>
                            </VStack>
                          </HStack>
                          <Stack>
                            <Box p={4}>
                              <Text fontStyle={'italic'} fontSize={'xs'}>
                                Creator
                              </Text>
                            </Box>
                          </Stack>
                        </>
                      ) : (
                        <>
                          <HStack gap={3}>
                            <Avatar size={'md'} name={x?.name} />
                            <Stack align={'flex-start'} spacing={0}>
                              <Text
                                fontWeight={500}
                                textTransform="capitalize"
                                fontSize={'sm'}
                              >
                                {x?.name}
                              </Text>
                              <Text fontSize={'xs'}>{x?.email}</Text>
                            </Stack>
                          </HStack>
                          <Stack>
                            {data?.employee?.includes(globalState?.uid) && (
                              <Box p={4} w="15%">
                                <DynamicButton
                                  action="delete"
                                  size={'sm'}
                                  // onClick={() => handleRemoveUser(x, data)}
                                />
                              </Box>
                            )}
                          </Stack>
                        </>
                      )}
                    </HStack>
                  );
                })}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => setModal({ ...modal, user: false })}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default HrisOfficePage;
