import { Badge, Box, Button, HStack, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicTable from '../../Components/Table/DynamicTable'
import { formatFrice } from '../../Utils/numberUtil'
import { getCollectionFirebaseV4, updateDocumentFirebase } from '../../Api/firebaseApi'
import useUserStore from '../../Hooks/Zustand/Store'

const HrisEmployeeLoansPage = () => {
  const globalState = useUserStore()
  const [modal, setModal] = useState(false)
  const [detail, setDetail] = useState({})
  const [loans, setLoans] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingV2, setLoadingV2] = useState(false)

  const getLoans = async () => {
    const conditions = [
      {field: 'companyId', operator: '==', value: globalState.currentCompany}
    ]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    try {
      const res = await getCollectionFirebaseV4 ('payroll_loans', {conditions}, {sortBy}, {limitValue}, {startAfterData})
      
      setLoans(res)
    } catch (error) {
      // console.log(error,'error ni')
    }
  }

  useEffect(() => {
    getLoans()
  },[globalState.currentCompany])

  const dataTable = loans?.map((x) => {
    const name = x.name
    const memo = x.memo
    const tenor = x.tenor
    const status = x.status
    const amount = formatFrice(x.amount)
    return {
      x,
      'name': name,
      'memo' : memo,
      'tenor' : tenor,
      'status' : status,
      'amount' : amount
    }
  })

  const openModal = (x) => {
    setDetail(x)
    setModal(true)
  }

  const handleApprove = async () => {
    setLoading(true)
    const data = {
      amount: parseInt(detail.x.amount),
      memo: detail.memo,
      name: detail.name,
      status : 'approved',
      tenor : detail.tenor,
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject
    }
    try {
      await updateDocumentFirebase('payroll_loans',detail?.x?.id, data)
    } catch (error) {
      // console.log(error, 'ini error')
    } finally {
      setLoading(false)
      setDetail({})
      setModal(false)
      getLoans()
    }
  }

  const handleReject = async () => {
    setLoadingV2(true)
    const data = {
      amount: parseInt(detail.x.amount),
      memo: detail.memo,
      name: detail.name,
      status : 'rejected',
      tenor : detail.tenor,
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject
    }
    try {
      await updateDocumentFirebase('payroll_loans',detail?.x?.id, data)
    } catch (error) {
      // console.log(error, 'ini error')
    } finally {
      setLoadingV2(false)
      setDetail({})
      setModal(false)
      getLoans()
    }
  }

  const tableHeader = ['name', 'memo','tenor', 'status', 'amount']
  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtons/>
        <Heading size="md"> Employee Loans</Heading>
        <Spacer/>
      </HStack>
      <Box>
        <DynamicTable header={tableHeader} data={dataTable} onEdit={openModal}/>
      </Box>

      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        motionPreset="slideInBottom"
        size={'xl'}
      >
        <ModalOverlay/>
        <ModalContent>
          <ModalHeader>Approval form</ModalHeader>
          <ModalCloseButton/>
          <ModalBody>
            <HStack justify={'space-between'} my={2}>
              <Heading fontSize={'md'}>Name</Heading>
              <Text>{detail.name}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Heading fontSize={'md'}>Memo</Heading>
              <Text>{detail.memo}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Heading fontSize={'md'}>Tenor</Heading>
              <Text>{detail.tenor} Months</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Heading fontSize={'md'}>Status</Heading>
              <Badge colorScheme={detail?.status === 'requested' ? 'yellow' :detail?.status === 'approved' ? 'green': detail?.status === 'processed' ? 'blue' :detail?.status === 'rejected' ? 'red' : ''}>
                {detail?.status === 'requested' ? 'REQUESTED' :detail?.status === 'approved' ? 'APPROVED': detail?.status === 'processed' ? 'PROCESS' :detail?.status === 'rejected' ? 'REJECTED' : ''}
              </Badge>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Heading fontSize={'md'}>Amount</Heading>
              <Text>IDR {(detail.amount)}</Text>
            </HStack>
          </ModalBody>
          {detail.status === 'processed' ? 
            <ModalFooter>
              <HStack>
                <Button isLoading={loadingV2} colorScheme='red' onClick={() => handleReject()}>Reject</Button>
                <Button isLoading={loading} colorScheme='green' onClick={() => handleApprove ()}>Approve</Button>
              </HStack>
            </ModalFooter> 
            :
            <>
              <ModalFooter>
                <HStack>
                  <Button colorScheme='red' isDisabled>Reject</Button>
                  <Button colorScheme='green' isDisabled>Approve</Button>
                </HStack>
              </ModalFooter>
            </>}
                
        </ModalContent>
      </Modal>

    </Stack>
  )
}

export default HrisEmployeeLoansPage