import {
  Button,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  FormErrorMessage,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../../Components/Buttons/BackButtons';
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getCountFromServer,
  query,
  where
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import useUserStore from '../../../Hooks/Zustand/Store';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import DynamicTable from '../../../Components/Table/DynamicTable';
import { getCollectionFirebase } from '../../../Api/firebaseApi';

function IndexPage() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    economy_years: 0,
    depreciation_percentage: 0
  })
  const [categories, setCategories] = useState([]) 
  const globalState = useUserStore();
  const [id, setId] = useState([])
  const toast = useToast()
  const cancelRef = React.useRef();
  const [dataActive, setDataActive] = useState('')
  const [dataSearch, setDataSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDate] = useState('');
  const itemsPerPage = 10

  const schema = yup.object().shape({
    name: yup.string().required('Name is Required'),
    type: yup.string().required('Type is Required'),
    economy_years: yup.string().required('Economy year is Required'),
    depreciation_percentage: yup.string().required('Depreciation percentage is Required'),
  })
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const openModalCreate = () => {
    setFormData({
      name: '',
      type: '',
      economy_years: 0,
      depreciation_percentage: 0
    })
    setIsEditMode(false);
    onOpen()
  };

  const openModalEdit = (x) => {
    setIsEditMode(true);
    const formDataLocal = formData
    formDataLocal.name = x.name
    formDataLocal.type = x.type
    formDataLocal.economy_years = x.economy_years.split(' Years')[0]
    formDataLocal.depreciation_percentage = x.depreciation_percentage.split('%')[0]
    setFormData(formDataLocal)
    onOpen()
    setId(x.data.id)
  };

  const onSubmit = async () => {
    try {
      globalState.setIsLoading(true)
      if (isEditMode === true) {
        const data = doc(db, 'asset_categories', id)
        await updateDoc(data, formData)
      } else {
        const formDataLocal = formData;
        formDataLocal.companyId = globalState.currentCompany
        formDataLocal.projectId = globalState.currentProject
        formDataLocal.created_at =  moment().format('MMMM Do YYYY, h:mm:ss a')
        setFormData(formDataLocal)
        await addDoc(collection(db, 'asset_categories'),formData)
      }
      await getCategories()
      onClose()
      setFormData({
        name: '',
        type: '',
        economy_years: 0,
        depreciation_percentage: 0
      })
      globalState.setIsLoading(false)
      toast({
        title: 'Deoapp.com',
        description: `${isEditMode ? 'Successfully update data' : 'Successfully save data'}`,
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const getCategories = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany }
    ]
    if (selectedDate) {
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      conditions.push(
        { field: 'created_at', operator: '>=', value: startDate },
        { field: 'created_at', operator: '<=', value: endDate }
      );
    }
    const limitValue = startIndex + itemsPerPage;
    const sortBy =  { field: 'created_at', direction: 'desc' };
    try {
      const res = await getCollectionFirebase(
        'asset_categories',
        conditions,
        sortBy,
        limitValue,
      );
      setCategories(res);
      const collectionRef = collection(db, 'asset_categories');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
        ));
      const assesmentLength = snapshot.data().count;
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const handleDelete = async () => {
    globalState.setIsLoading(true)
    deleteDoc(doc(db, 'asset_categories', dataActive.data.id))
    toast({
      title: 'Deoapp.com',
      description: 'Success delete this post',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    });
    await getCategories()
    globalState.setIsLoading(false)
    setDeleteModal(false)
  }

  const handleDeleteModal = (row) => {
    setDeleteModal(true);
    setDataActive(row)
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const tableHeader = ['id', 'type', 'name', 'economy_years', 'depreciation_percentage', 'created']
  const tableData = categories.map((data, index) => {
    const name = data.name || ''
    const type = data.type || ''
    const created = data?.created_at
    return {
      data,
      id: index +1,
      name: name,
      type: type,
      economy_years: data?.economy_years !== undefined ? data?.economy_years + ' Years' : '-',
      depreciation_percentage: data.depreciation_percentage !== undefined ? data.depreciation_percentage + '%' : '-',
      created: moment(created, 'MMMM Do YYYY, h:mm:ss a').format('DD-MM-YYYY HH:ii:ss')
    }
  })

  const tableDataFilter = dataSearch?.map((data, index) => {
    const name = data.name || ''
    const created = data?.created_at
    const type = data.type || ''
    return {
      data,
      id: index +1,
      name: name,
      type: type,
      economy_years: data?.economy_years !== undefined ? data?.economy_years + ' Years' : '-',
      depreciation_percentage: data.depreciation_percentage !== undefined ? data.depreciation_percentage + '%' : '-',
      created: moment(created, 'MMMM Do YYYY, h:mm:ss a').format('DD-MM-YYYY')
    }
  })

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = categories.filter((item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearch(newData);
      setInputSearch(text);
    } else {
      setDataSearch(categories);
      setInputSearch(text);
    }
  };

  const inputStyles = {
    '&::placeholder': {
      color: 'gray.500',
    },
  };

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <Stack p={[1, 1, 5]}>
      <BackButtons />
      <Stack spacing={4}>
        <HStack>
          <Heading size={'md'} fontWeight="bold">
            Category
          </Heading>
          <Spacer />
          <Input
            w={['100%', '30%', '30%']}
            type="text"
            placeholder="Search Name"
            bgColor="white"
            color="black"
            sx={inputStyles}
            fontSize="sm"
            onChange={(e) => searchFilterFunction(e.target.value)}
          />
          <HStack>
            <DynamicButton action={'create'} title={'New Category'}  onClick={() => openModalCreate()}/>
          </HStack>
        </HStack>

        <Stack
          bgColor="white"
          spacing={1}
          borderRadius="xl"
          p={3}
          m={[1, 1, 5]}
          shadow="md"
        >
          {inputSearch !== '' ? (
            <DynamicTable header={tableHeader} data={tableDataFilter}  onDelete={handleDeleteModal} onEdit={openModalEdit}/>
          ):
            <DynamicTable header={tableHeader} data={tableData}  onDelete={handleDeleteModal} onEdit={openModalEdit}/>
          }
        </Stack>
        <Stack alignItems={'center'} justifyContent="center">
          {currentPage < totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </Stack>
      </Stack>

      <AlertDialog
        isOpen={deleteModal}
        leastDestructiveRef={cancelRef}
        onClose={() => setDeleteModal(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure delete this category? You cant undo this action
              afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setDeleteModal(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleDelete()}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
            
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditMode ? 'Edit Category' : 'Create Category'}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody pb={6}>
              <FormControl isInvalid={errors.type}>
                <FormLabel>Type of Building</FormLabel>
                <Controller
                  name="type"
                  control={control}
                  defaultValue={formData?.type}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      onChange={(value) => {
                        setFormData(({...formData, type: value})); 
                        field.onChange(value);
                      }}
                      value={formData?.type}
                    >
                      <Stack direction="row">
                        <Radio value="not a building">Not a Building</Radio>
                        <Radio value="building">Building</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
                <FormErrorMessage>{errors.type?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.name} mt={5}>
                <FormLabel>Name</FormLabel>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={formData?.name}
                  placeholder="Example: Kelompok 1"
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder='Example: Kelompok 1 or Kelompok 2 or other'
                      onChange={(e) => {
                        setFormData(({...formData, name: e.target.value})); 
                        field.onChange(e);
                      }}
                      value={formData?.name}
                    />
                  )}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.economy_years} mt={5}>
                <FormLabel>Economy Years (in Year)</FormLabel>
                <Controller
                  name="economy_years"
                  control={control}
                  defaultValue={formData?.economy_years}
                  render={({ field }) => (
                    <Input
                      type='number'
                      placeholder='Example: 1 or 2 means 1 year or 2 year'
                      {...field}
                      onChange={(e) => {
                        setFormData(({...formData, economy_years: e.target.value})); 
                        field.onChange(e);
                      }}
                      value={formData?.economy_years}
                    />
                  )}
                />
                <FormErrorMessage>{errors.economy_years?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.depreciation_percentage} mt={5}>
                <FormLabel>Depreciation Percentage (from 1  - 100 in %)</FormLabel>
                <Controller
                  name="depreciation_percentage"
                  control={control}
                  defaultValue={formData?.depreciation_percentage}
                  render={({ field }) => (
                    <Input
                      type='number'
                      placeholder='Example: 10 means 10%'
                      {...field}
                      onChange={(e) => {
                        setFormData(({...formData, depreciation_percentage: e.target.value})); 
                        field.onChange(e);
                      }}
                      value={formData?.depreciation_percentage}
                    />
                  )}
                />
                <FormLabel color={'red'} mt={2} fontStyle={'italic'}>Use Comma to make decimal</FormLabel>
                <FormErrorMessage>{errors.depreciation_percentage?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose}>
                Cancel</Button>
              <Button colorScheme='blue' type="submit" ml={2}>
                {isEditMode ? 'Save' : 'Create'}
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default IndexPage