import React, { useEffect, useState } from 'react'
import { Box, Heading, SimpleGrid, Stack, Text, Flex, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, Input, FormLabel, ModalFooter, Select, Textarea, HStack, Image, useToast, FormErrorMessage, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay } from '@chakra-ui/react';
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  updateDoc,
  deleteDoc,
  query, 
  where,
  getDocs,
  getCountFromServer
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import useUserStore from '../../../Hooks/Zustand/Store';
import moment from 'moment';
import ApiBackend from '../../../Api/ApiBackend';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import DynamicTable from '../../../Components/Table/DynamicTable';
import { useNavigate } from 'react-router-dom';
import BackButtons from '../../../Components/Buttons/BackButtons';
import { getCollectionFirebase } from '../../../Api/firebaseApi';

function IndexPage() {
  const itemsPerPage = 10

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isEditMode, setIsEditMode] = useState(false);
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [description, setDescription] = useState('')
  const [division, setDivision] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [brandName, setBrandName] = useState('')
  const [assetStatus, setAssetStatus] = useState('')
  const [fileData, setFileData] = useState([]);
  const globalState = useUserStore();
  const [data, setData] = useState([])
  const [id, setId] = useState([])
  const toast = useToast()
  const cancelRef = React.useRef();
  const [dataActive, setDataActive] = useState('')
  const [deleteModal, setDeleteModal] = useState(false);
  const [codeOption, setCodeOption] = useState([])
  const [code, setCode] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDate] = useState('');
  const navigate = useNavigate()
  const [dataSearch, setDataSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');

  const schema = yup.object().shape({
    category: yup.string().required('Category is Required'),
    brand: yup.string().required('Brand is Required'),
    description: yup.string().required('Description is Required'),
  })
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
    
  const statusOption = [
    {title: 'Asset Lending', value: 'asset lending'},
    {title: 'Asset Return', value: 'asset return'},
    {title: 'Asset Sold', value: 'asset sold'},
  ]


  const openCreateModal = () => {
    setIsEditMode(false)
    onOpen()
    setBrandName('')
    setAssetStatus('')
    setCategoryName('')
    setFileData('')
    setDivision('')
    setCode('')
    setDescription('')
    getProjects()
  }

  const openEditModal = (x) => {
    setIsEditMode(true)
    onOpen()
    setName(x.name)
    setEmail(x.email)
    setId(x.x.id)
    setBrandName(x.brand)
    setCategoryName(x.category)
    setFileData(x.image)
    setDivision(x.division)
    setDescription(x.description)
  }

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany }
    ]
    if (selectedDate) {
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      conditions.push(
        { field: 'created_at', operator: '>=', value: startDate },
        { field: 'created_at', operator: '<=', value: endDate }
      );
    }
    const limitValue = startIndex + itemsPerPage;
    const sortBy =  { field: 'created_at', direction: 'asc' };
    try {
      const res = await getCollectionFirebase(
        'asset_lendings',
        conditions,
        sortBy,
        limitValue,
      );
      setData(res)
      const collectionRef = collection(db, 'asset_lendings');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
        ));
      const assesmentLength = snapshot.data().count;
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const getCategory = async () => {
    try {
      onSnapshot(collection(db, 'asset_categories'), (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });
        setCategories(data);
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const getDataBrand = async () => {
    try {
      onSnapshot(collection(db, 'asset_brands'), (snapshot) => {
        const data = [];
        snapshot.forEach((doc) => {
          const docData = doc.data();
          data.push({ id: doc.id, ...docData });
        });
        setBrands(data);
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const getCode = async (brandName, categoryName) => {
    try {
      globalState.setIsLoading(true);
            
      const assetCollection = collection(db, 'asset');
            
      const queryFilters = [];
            
      queryFilters.push(where('brand', '==', brandName));
      queryFilters.push(where('category', '==', categoryName));
            
      const querySnapshot = await getDocs(query(assetCollection, ...queryFilters));
            
      const data = []; 
             
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        data.push({ id: doc.id, ...docData });
      });
      setCodeOption(data);

      globalState.setIsLoading(false);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  };
      
  const getProjects = () => { 
    try {
      const projects = globalState.projects;
      const currentProject = globalState.currentProject;
    
      const matchingProject = projects.find((project) => project.id === currentProject);
    
      if (matchingProject) {
        setDivision(matchingProject.name);
      } else {
        setDivision('');
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }
     
  const onSubmit = async () => {
    try {
      if (isEditMode === true) {
        globalState.setIsLoading(true)
        const data = doc(db, 'asset_lendings', id)
        await updateDoc(data, {
          name: name,
          email: email,
          category: categoryName,
          brand: brandName,
          code: code,
          status: 'Approved user',
          description: description,
          image: fileData,
        })
        globalState.setIsLoading(false)
        onClose()
        setBrandName('')
        setCategoryName('')
        setAssetStatus('')
        setFileData('')
        setDivision('')
        setDescription('')
        toast({
          title: 'Deoapp.com',
          description: 'Successfully update data',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      } else {
        globalState.setIsLoading(true)
        await addDoc(collection(db, 'asset_lendings'), {
          companyId: globalState.currentCompany,
          projectId: globalState.currentProject,
          uid: globalState.uid,
          name: globalState.name,
          email: globalState.email,
          category: categoryName,
          brand: brandName,
          code: code,
          status: 'Approved User',
          description: description,
          division: division,
          asset_status: assetStatus,
          image: fileData,
          history_statuses: [
            {
              email: globalState.email,
              status: 'Approved User',
              approved_at: moment().format('MMMM Do YYYY, h:mm:ss a')
            }
          ],
          created_at: moment().format('MMMM Do YYYY, h:mm:ss a')
        })
        globalState.setIsLoading(false)
        onClose()
        setBrandName('')
        setAssetStatus('')
        setCategoryName('')
        setFileData('')
        setDivision('')
        setCode('')
        setDescription('')
        toast({
          title: 'Deoapp.com',
          description: 'Successfully save data',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const handleFileChange = (e) => {
    try {
      const files = e.target.files;
      const fileArray = Array.from(files);


      fileArray.map(async (file) => {
        const formData = new FormData();
        formData.append('companyId', globalState.currentCompany);
        formData.append('projectId', globalState.currentProject);
        formData.append('module', 'GA');
        formData.append('file', file);
            
        const res = await ApiBackend.post('/upload_file', formData);
        if (res.status == 200) {
          const links = res.data.link.link;
          setFileData((prevFileData) => [...prevFileData, links ]);
        }
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  };

  const handleDelete = async () => {
    globalState.setIsLoading(true)
    deleteDoc(doc(db, 'asset_lendings', dataActive))
    toast({
      title: 'Deoapp.com',
      description: 'Success delete this post',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    });
    globalState.setIsLoading(false)
    setDeleteModal(false)
  }

  const handleDeleteModal = (row) => {
    setDeleteModal(true);
    setDataActive(row.x.id)
  };

  const handleCustom = (row) => {
    navigate(`/ga/asset/status/${row.x.id}/detail`)
  }

  const tableHeader = ['id', 'name', 'email', 'division', 'category', 'brand', 'image', 'status', 'description', 'created']
  const tableData =  data?.map((x, index) => {
    const name = x.name || ''
    const email = x.email || ''
    const division = x.division || ''
    const category = x.category || ''
    const brand = x.brand || ''
    const image = x.image || ''
    const status = x.status || ''
    const description = x.description || ''
    const created = x.created_at

    return {
      x,
      id: index + 1,
      name: name,
      email: email,
      division: division,
      category: category,
      brand: brand,
      image: image,
      status: status,
      description: description,
      created: moment(created, 'MMMM Do YYYY, h:mm:ss a').format('DD-MM-YYYY')
    }
  })

  const tableDataFilter = dataSearch?.map((x, index) => {
    const name = x.name || ''
    const email = x.email || ''
    const division = x.division || ''
    const category = x.category || ''
    const brand = x.brand || ''
    const image = x.image || ''
    const status = x.status || ''
    const description = x.description || ''
    const created = x.created_at

    return {
      x,
      id: index + 1,
      name: name,
      email: email,
      division: division,
      category: category,
      brand: brand,
      image: image,
      status: status,
      description: description,
      created: moment(created, 'MMMM Do YYYY, h:mm:ss a').format('DD-MM-YYYY')
    }
  })

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = data.filter((item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearch(newData);
      setInputSearch(text);
    } else {
      setDataSearch(data);
      setInputSearch(text);
    }
  };



  const inputStyles = {
    '&::placeholder': {
      color: 'gray.500',
    },
  };

  useEffect(() => {
    getCategory()
    getDataBrand()
    getData()
    getCode(brandName, categoryName);
  }, [brandName, categoryName, currentPage, selectedDate])
  return (
    <Box p={5}>
      <BackButtons />
      <Stack align={'center'} spacing={3}>
        <Heading>Asset Status</Heading>
        <Text w={'80%'} align={'center'} color={'gray.500'}>
        </Text>
      </Stack>

      <Box bg={'white'} my={7} p={4} shadow={'md'}>
        <Input
          w={['100%', '30%', '30%']}
          type="text"
          placeholder="Search Name"
          bgColor="white"
          color="black"
          sx={inputStyles}
          fontSize="sm"
          onChange={(e) => searchFilterFunction(e.target.value)}
        />

        <Flex justifyContent={'end'}>
          <DynamicButton action={'create'} title={'New Asset Status'} onClick={openCreateModal}/>
        </Flex>
        {inputSearch !== '' ? (
          <DynamicTable header={tableHeader} data={tableDataFilter} onDelete={handleDeleteModal} onEdit={openEditModal} onRead={handleCustom}/>
        ) :
          <DynamicTable header={tableHeader} data={tableData} onDelete={handleDeleteModal} onEdit={openEditModal} onRead={handleCustom}/>
        }

        <Stack alignItems={'center'} justifyContent="center">
          {currentPage < totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </Stack>

        <AlertDialog
          isOpen={deleteModal}
          leastDestructiveRef={cancelRef}
          onClose={() => setDeleteModal(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure delete this posting? You cant undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setDeleteModal(false)}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => handleDelete()}
                  ml={3}
                >
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          size={'xl'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{isEditMode ? 'Edit Asset Status' : 'Create Asset Status'}</ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalBody pb={6}>
                <SimpleGrid spacing={3} columns={2}>
                  <FormControl isInvalid={errors.category} mt={'3'}>
                    <FormLabel>Category</FormLabel>
                    <Controller
                      name="category"
                      control={control}
                      defaultValue={categoryName}
                      render={({ field }) => (
                        <Select
                          placeholder="Select category"
                          {...field}
                          onChange={(e) => {
                            setCategoryName(e.target.value); 
                            field.onChange(e);
                          }}
                        >
                          {categories.map((x, i) => (
                            <option key={i} value={x.name}>
                              {x.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
                  </FormControl>

                  <FormControl isInvalid={errors.brand} mt={'3'}>
                    <FormLabel>Brand</FormLabel>
                    <Controller
                      name="brand" 
                      control={control}
                      defaultValue={brandName}
                      render={({ field }) => (
                        <Select
                          placeholder="Select Brand"
                          {...field}
                          onChange={(e) => {
                            setBrandName(e.target.value); 
                            field.onChange(e);
                          }}
                        >
                          {brands.map((x, i) => (
                            <option key={i} value={x.name}>
                              {x.name}
                            </option>
                          ))}
                        </Select>
                      )}
                    />
                    <FormErrorMessage>{errors.brand?.message}</FormErrorMessage>
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid spacing={3} columns={2}>
                  <FormControl mt={'3'}>
                    <FormLabel>Division</FormLabel>
                    <Input
                      value={division}
                      isDisabled
                    />
                  </FormControl>

                  <FormControl  mt={'3'}>
                    <FormLabel>Status</FormLabel>
                    <Controller
                      name="status"
                      control={control}
                      defaultValue={assetStatus}
                      render={({ field }) => (
                        <Select
                          placeholder="Select status"
                          {...field}
                          onChange={(e) => {
                            setAssetStatus(e.target.value); 
                            field.onChange(e);
                          }}
                        >
                          {statusOption?.map((x, index) => {
                            return (
                              <option key={index} value={x?.value}>
                                {x?.title}
                              </option>
                            )
                          })}
                        </Select>
                      )}
                    />
                  </FormControl>
                </SimpleGrid>
                <FormControl  mt={'3'}>
                  <FormLabel>Code</FormLabel>
                  <Controller
                    name="code"
                    control={control}
                    defaultValue={code} 
                    render={({ field }) => (
                      <Select
                        placeholder="Select code"
                        {...field}
                        onChange={(e) => {
                          setCode(e.target.value);
                          field.onChange(e);
                        }}
                      >
                        {codeOption?.map((x, index) => (
                          x.status !== 'asset sold' && (
                            <option key={index} value={x?.code}>
                              {x?.code}
                            </option>
                          )
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <FormControl mt={'3'}>
                  <FormLabel>Image</FormLabel>
                  <HStack>
                    <Stack>
                      <input type="file" onChange={handleFileChange}/>
                    </Stack>
                  </HStack>
                  <SimpleGrid columns={[1, 2, 3]} gap={3} >
                    {fileData.length > 0 &&
                      fileData.map((x, index) => (
                        <Stack key={index}>
                          <Image
                            src={x}
                            borderRadius="xl"
                            alt="Image not found"
                            shadow="md"
                          />
                        </Stack>
                      ))}
                  </SimpleGrid>
                </FormControl>

                <FormControl isInvalid={errors.description} mt={'3'}>
                  <FormLabel>Description</FormLabel>
                  <Controller
                    name="description"
                    control={control}
                    defaultValue={description}
                    render={({ field }) => (
                      <Textarea
                        {...field}
                        onChange={(e) => {
                          setDescription(e.target.value); 
                          field.onChange(e);
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button colorScheme='blue' mr={3} type='submit'>
                  {isEditMode ? 'Save' : 'Create'}
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  )
}

export default IndexPage