import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Select,
  Spacer,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FcFile } from 'react-icons/fc';
import moment from 'moment';
import useUserStore from '../../Hooks/Zustand/Store';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicTable from '../../Components/Table/DynamicTable';
import { createHris, patchHris, readHris } from '../../Api/Hris';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { getUserByEmail, getUserByUid } from '../../Utils/getUser';
import { getProjectUsers } from '../../Utils/getProjectUsers';
import { getCollectionFirebase } from '../../Api/firebaseApi';

const HrisCorrectionPage = () => {
  const globalState = useUserStore();
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [correction, setCorrection] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [loadingV2, setLoadingV2] = useState(false);
  const [sort, setSort] = useState('desc');
  const [userId, setUserId] = useState('');
  const toast = useToast();
  // const role = 'Admnistrator'
  // const role = "Manager";

  const getCompany = localStorage.getItem('currentCompany');
  const getProject = localStorage.getItem('currentProject');
  const tableHeader = ['employee', 'date', 'clockTime', 'status', 'createdAt'];

  const getCorrection = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    globalState?.setIsLoading(true);
    let res;
    try {

      if (userId !== '') {
        res = await readHris(`correction/${userId}}`, session);
      } else {
        res = await readHris(`correction?sort=${sort}`, session);
      }

      const { data } = res;

      if (!data?.length > 0) throw new Error('data is not retrieved')

      const mergedData = [];

      for (const x of data) {
        const uidUser = x?.user_uid;

        const userData = await getUserByUid(globalState?.users, uidUser);

        if (userData) {
          const mergedObject = { ...x, name: userData?.name };
          mergedData.push(mergedObject);
        } else {
          mergedData.push(x);
        }
      }

      mergedData.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.creatdAt);
        return dateB - dateA;
      });

      setCorrection(mergedData);
      setOriginData(mergedData);
    } catch (error) {
      // throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const getFilterStatus = (value) => {
    if (value === '') {
      setCorrection(originData);
    } else {
      const filterArr = originData.filter((x) => x.current_status === value);
      setCorrection(filterArr);
    }

    if (value === 'Requested') {
      const filterArr = originData.filter((x) => x.current_status === null);
      setCorrection(filterArr);
    }
  };

  const handleApprove = async (x, role) => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
      // office_uid : 
    };

    setLoading(true);
    const store = {
      title: 'APPROVE HR',
      reject_note: 'Approved',
    };
    const storeManager = {
      title: 'APPROVE MANAGER',
      reject_note: 'Approve Manager',
    };
    let res;
    try {
      if ( (role === 'manager' || globalState.roleCompany === 'owner') && x?.data?.current_status === null) {
        res = await createHris(
          `correction/${x?.data?.id}/store-status`,
          session,
          storeManager
        );
      } else if ( (role === 'hr' || globalState.roleCompany === 'owner') && x?.data?.current_status === 'APPROVE MANAGER') {
        const offices = await getCollectionFirebase('offices', [{ field: 'employee', operator: 'array-contains', value: x?.data?.user_uid }]);
        store.office_uid = offices[0]?.id;
        res = await createHris(
          `correction/${x?.data?.id}/store-status`,
          session,
          store
        );
      }

      if (res?.status === true) {
        const update = {
          periode: x?.data?.periode,
          clock_in: x?.data?.clock_in,
          clock_out: x?.data?.clock_out,
        };
        try {
          await patchHris(
            `correction/${x?.data?.id}/update`,
            session,
            update
          );
        } catch (error) {
          toast({
            title: 'Failed to send Slack login message',
            description: error.message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          })
        }
      } else if (res?.status === false) {
        // eslint-disable-next-line no-alert
        window.alert(res.message.split(':')[1] + '!')
      }
    } catch (error) {
      toast({
        title: 'Failed to send Slack login message',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setLoading(false);
      getCorrection();
      setModal(false);
    }
  };

  const handleReject = async (x, role) => {
    setLoadingV2(true);

    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    const store = {
      title: 'REJECT HR',
      reject_note: 'Rejected',
    };
    const storeManager = {
      title: 'REJECT MANAGER',
      reject_note: 'Rejected',
    };
    try {
      if (
        (role === 'hr' || globalState.roleCompany === 'owner') &&
        x?.data?.current_status === 'APPROVE MANAGER'
      ) {
        await createHris(
          `correction/${x?.data?.id}/store-status`,
          session,
          store
        );
      } else if (
        (role === 'manager' || globalState.roleCompany === 'owner') &&
        x?.data?.current_status === null
      ) {
        await createHris(
          `correction/${x?.data?.id}/store-status`,
          session,
          storeManager
        );
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      setLoadingV2(false);
      getCorrection();
      setModal(false);
    }
  };

  let tableData = '';

  tableData = correction?.map((data) => {
    const employee = data?.name || '';
    const date = data?.periode;
    const clockTime = `${data?.clock_in} || ${data?.clock_out}` || '--:--:--';
    const created = moment
      .parseZone(data?.createdAt || 0)
      .local()
      .format('DD MMMM YYYY - HH:mm');
    const status = data?.current_status;

    return {
      data,
      employee: employee,
      date: date,
      clockTime: clockTime,
      status:
        status === 'APPROVE MANAGER' ? (
          <Badge borderRadius={'md'} colorScheme="blue">
            PROCESSED
          </Badge>
        ) : status === 'APPROVE HR' ? (
          <Badge borderRadius={'md'} colorScheme="green">
            APPROVED
          </Badge>
        ) : status === 'REJECT MANAGER' ? (
          <Badge borderRadius={'md'} colorScheme="red">
            REJECTED
          </Badge>
        ) : status === 'REJECT HR' ? (
          <Badge borderRadius={'md'} colorScheme="red">
            REJECTED
          </Badge>
        ) : status === null ? (
          <Badge borderRadius={'md'} colorScheme="yellow">
            REQUESTED
          </Badge>
        ) : null,
      createdAt: created,
    };
  });

  useEffect(() => {
    getCorrection();
    return () => {
      setCorrection([]);
      setOriginData([]);
    };
  }, [getCompany, getProject, globalState?.currentProject, sort, userId]);

  const handleDetail = (x) => {
    setDetail(x);
    setModal(true);
  };

  const handleRead = (row) => {
    if (row?.data) handleDetail(row);
  };

  const handleFile = () => {
    if (!detail?.data?.attachments) {
      toast({
        title: 'Error',
        description: 'there is no attachment file',
        status: 'error',
        position: 'center-bottom',
        isClosable: true,
      });
    } else {
      window.open(detail?.data?.attachments);
    }
  };
  

  return (
    <Stack>
      <Box py={2}>
        <BackButtons />
      </Box>
      <Card w={'full'} backgroundColor={'white'} shadow={'md'}>
        <CardHeader>
          <HStack
            flexDirection={['column', 'row', 'row']}
            justify={'space-between'}
          >

            <Stack>
              <Text fontSize={'lg'} fontWeight={'medium'}>
                Correction Request
              </Text>
              <Select
                placeholder='Select user/employee'
                onChange={(e) => setUserId(e.target.value)}
              >
                {getProjectUsers(
                  globalState.projects,
                  globalState.currentProject,
                  globalState.users)
                  .map((user, i) => {
                    return <option value={user.id} key={i}>{user.name || user.email}</option>
                  })}
              </Select>
            </Stack>
            <HStack>
              <Text w={'50%'} color={'gray'}>Sorting:</Text>
              <Select
                onChange={(e) => setSort(e.target.value)}
              >
                <option value={'desc'}>Paling Baru</option>
                <option value={'asc'}>Paling Lama</option>
              </Select>
            </HStack>
            <HStack w={['100%', '30%', '30%']}>
              <Text w={'50%'} color={'gray'}>
                Filter Status
              </Text>
              <Select
                onChange={(e) => getFilterStatus(e.target.value)}
                placeholder="select status"
              // color={"lightgray"}
              >
                <option value={'Requested'}>Requested</option>
                <option value={'APPROVE MANAGER'}>Processed</option>
                <option value={'APPROVE HR'}>Approved</option>
                <option value={'REJECT MANAGER' || 'REJECT HR'}>
                  Rejected
                </option>
              </Select>
            </HStack>
          </HStack>
        </CardHeader>

        <CardBody>
          <DynamicTable
            header={tableHeader}
            data={tableData}
            onRead={handleRead}
            action_color="green"
          // action_icon={FaWhatsapp}
          />
        </CardBody>
      </Card>

      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        motionPreset="slideInBottom"
        isCentered
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Correction Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack justify={'space-between'} my={2}>
              <Text>employee</Text>
              <Text>{detail?.employee}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Date</Text>
              <Text>{detail?.data?.periode}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>ClockTime</Text>
              <Text>{detail?.clockTime ?? '--:--:--'}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Status</Text>
              <Text>
                {detail?.data?.current_status === 'APPROVE MANAGER' ? (
                  <Badge borderRadius={'md'} colorScheme="blue">
                    PROCESSED
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'APPROVE HR' ? (
                  <Badge borderRadius={'md'} colorScheme="green">
                    APPROVED
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'REJECT MANAGER' ? (
                  <Badge borderRadius={'md'} colorScheme="red">
                    REJECT
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'REJECT HR' ? (
                  <Badge borderRadius={'md'} colorScheme="red">
                    REJECT
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === null ? (
                  <Badge borderRadius={'md'} colorScheme="yellow">
                    REQUESTED
                  </Badge>
                ) : (
                  ''
                )}
              </Text>
            </HStack>
            {detail?.data?.current_status !== null && (
              <HStack justify={'space-between'} my={2}>
                <Text>{`${detail?.data?.current_status.includes('APPROVE') ? 'Approved' : 'Rejected'}`} By</Text>
                <Text>
                  {
                    getUserByEmail(
                      globalState?.users,
                      detail?.data?.correction_statuses?.find(
                        (y) => y?.title === detail?.data?.current_status
                      )?.created_by
                    )?.name
                  }
                </Text>
              </HStack>
            )}
            <HStack justify={'space-between'} my={2}>
              <Text>Created At</Text>
              <Text>{detail?.createdAt}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Attachments</Text>
              <HStack>
                <FcFile size={20} />
                <Text
                  _hover={{
                    color: 'blue',
                    cursor: 'pointer',
                    borderBottom: '1px solid blue',
                  }}
                  onClick={() => handleFile()}
                >
                  Open File
                </Text>
              </HStack>
            </HStack>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <HStack>
              {(globalState?.roleCompany === 'owner' || globalState?.roleProject === 'managers') && (
                detail?.data?.current_status === null &&
                <HStack>
                  <Text color={'red'} fontSize={12}>Manager have to approved/reject this request</Text>
                  <Spacer/>
                  <DynamicButton
                    action="custom"
                    title="Reject"
                    color={'red'}
                    variant={'solid'}
                    onClick={() => handleReject(detail, 'manager')}
                    disabled={detail?.data?.current_status === 'APPROVE MANAGER' ? true : false}
                  />
                  <DynamicButton
                    action="custom"
                    title="Approve Manager"
                    color={'green'}
                    variant={'solid'}
                    onClick={() => handleApprove(detail, 'manager')}
                    disabled={detail?.data?.current_status === 'APPROVE MANAGER' ? true : false}
                  />
                </HStack>
              )}
              {(globalState?.roleCompany === 'owner' || globalState?.roleProject === 'hr') && (
                detail?.data?.current_status === 'APPROVE MANAGER' &&
                <HStack>
                  <Text color={'red'} fontSize={12}>HR have to approved/reject this request</Text>
                  <Spacer/>
                  <DynamicButton
                    action="custom"
                    title="Reject"
                    color={'red'}
                    variant={'solid'}
                    onClick={() => handleReject(detail, 'hr')}
                    disabled={detail?.data?.current_status === 'APPROVE HR' ? true : false}
                  />
                  <DynamicButton
                    action="custom"
                    title="Approve HR"
                    color={'green'}
                    variant={'solid'}
                    onClick={() => handleApprove(detail, 'hr')}
                    disabled={detail?.data?.current_status === 'APPROVE HR' ? true : false}
                  />
                </HStack>
              )}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default HrisCorrectionPage;
