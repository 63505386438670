import React from 'react'
import RecruitmentCreateJobPage from '../Pages/Recruitment/RecruitmentCreateJobPage'
import RecruitmentJobListingPage from '../Pages/Recruitment/RecruitmentJobListingPage'
import RecruitmentAssessmentResultPage from '../Pages/Recruitment/RecruitmentAssessmentResultPage'
import RecruitmentApplicantList from '../Pages/Recruitment/RecruitmentApplicantList'
import RecruitmentPreviewRolePage from '../Pages/Recruitment/RecruitmentPreviewRolePage'
import RecruitmentEditJobsPage from '../Pages/Recruitment/RecruitmentEditJobsPage'
import RecruitmentCompanyProfile from '../Pages/Recruitment/RecruitmentCompanyProfile'
import RecruitmentHome from '../Pages/Recruitment/RecruitmentHome'

const RecruitmentRouter = [
  {
    path: '/recruitment',
    element: <RecruitmentHome  />,
  },
  {
    path: '/recruitment/job-listing',
    element: <RecruitmentJobListingPage />,
  },
  {
    path: '/recruitment/create-job',
    element: <RecruitmentCreateJobPage />,
  },
  {
    path: '/recruitment/assessment',
    element: <RecruitmentAssessmentResultPage/>,
  },
  {
    path: '/recruitment/profile',
    element: <RecruitmentCompanyProfile />,
  },
  {
    path: '/recruitment/job-listing-applicant-list/:id',
    element: <RecruitmentApplicantList />,
  },
  {
    path: '/recruitment/job-listing-preview-roles/:id',
    element: <RecruitmentPreviewRolePage />,
  },
  {
    path: '/recruitment/jobs-edit/:id',
    element: <RecruitmentEditJobsPage />,
  },
    
]

export default RecruitmentRouter