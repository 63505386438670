import { Badge, Center, Heading, Input, Select, Stack, Text, Button, HStack, Divider, Checkbox, Card, Grid, CardBody, Avatar, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { HiOutlineBuildingLibrary } from 'react-icons/hi2';
import { BiBarChart } from 'react-icons/bi';
import { GoPerson } from 'react-icons/go';
import { CiLocationOn } from 'react-icons/ci';
import { getCollectionFirebaseV } from '../../Api/firebaseApi';
import { useNavigate } from 'react-router-dom';
import defaultImage from '../../assets/icons/importir.jpg'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'

const RecruitmentFrontHome = () => {
  const [jobs, setJobs] = useState();
  const [drawer, setDrawer] = useState(false)
  const [position, setPosition] = useState('')
  const [address, setAddress] = useState('Semua')
  const [timeFilter, setTimeFilter] = useState([])
  const [workplaceFilter, setWorkplaceFilter] = useState([])
  const [experienceFilter, setExperienceFilter] = useState([])
  const [specialization, setSpecialization] = useState('')
  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 600);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 600);
  };

  const pendingRecruitment = localStorage.getItem('pendingRecruitment');
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const navigate = useNavigate();
  const getJobs = async () => {
    const conditions = [{
      field: 'published', operator: '==', value: 'Publish'
    }]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    //...get data here
    getCollectionFirebaseV('positions', { conditions }, { sortBy }, { limitValue }, { startAfterData }).then((res) => {
      setJobs(res)
    })
  }

  useEffect(() => {
    getJobs();
    if (pendingRecruitment) {
      navigate(`/preview/${pendingRecruitment?.slice(1)?.slice(0, -1)}/apply`);
      localStorage.removeItem('pendingRecruitment')
    }
  }, [])

  const timeAgo = (dateString) => {
    const createdAt = new Date(parseInt(dateString, 10));
    const now = new Date();
    const timeDifference = now - createdAt;
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysAgo === 0) {
      return 'Hari ini';
    } else if (daysAgo === 1) {
      return 'Kemarin';
    } else {
      return `${daysAgo} hari yang lalu`;
    }
  }



  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleSpecialization = (event) => {
    setSpecialization(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleTimeFilterChange = (event) => {
    const value = event.target.value;
    setTimeFilter((prevFilters) =>
      prevFilters.includes(value)
        ? prevFilters.filter((filter) => filter !== value)
        : [...prevFilters, value]
    );
  };

  const handleWorkplaceFilterChange = (event) => {
    const value = event.target.value;
    setWorkplaceFilter((prevFilters) =>
      prevFilters.includes(value)
        ? prevFilters.filter((filter) => filter !== value)
        : [...prevFilters, value]
    );
  };

  const handleExperienceFilterChange = (event) => {
    const value = event.target.value;
    setExperienceFilter((prevFilters) =>
      prevFilters.includes(value)
        ? prevFilters.filter((filter) => filter !== value)
        : [...prevFilters, value]
    );
  };

  const filteredJobs = jobs
    ?.filter((job) => {
      return position === '' || job.position_name.toLowerCase().includes(position.toLowerCase());
    })
    .filter((job) => {
      return address === 'Semua' || job.address === address;
    })
    .filter((job) => {
      return timeFilter.length === 0 || timeFilter.includes(job.time);
    })
    .filter((job) => {
      return workplaceFilter.length === 0 || workplaceFilter.includes(job.workplace);
    })
    .filter((job) => {
      return experienceFilter.length === 0 || experienceFilter.includes(job.experience);
    })
    .filter((job) => {
      return specialization === '' || job.job_type.toLowerCase().includes(specialization.toLowerCase())
    });


  return (
    <Stack p={2}>
      <Heading fontSize={'lg'}>
        Jobs Available
      </Heading>
      {isDesktop ? '' :
        <Stack p={3} bgColor={'white'} borderRadius={'lg'} shadow={'md'}>
          <Stack >
            <Text fontSize={'sm'}></Text>
            <Input placeholder='Find Job' value={position} onChange={handlePositionChange} />
          </Stack>
          <Button size={'sm'} onClick={() => setDrawer(true)}> Filter</Button>

          <Drawer placement={'bottom'} onClose={() => setDrawer(false)} isOpen={drawer}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader borderBottomWidth='1px'>Filter Job</DrawerHeader>
              <DrawerBody>
                <Stack>
                  <Text fontSize={'sm'}>Job Specialization</Text>
                  <Input size={'sm'} placeholder='Job Specialization' value={specialization} onChange={handleSpecialization} />
                </Stack>
                <Divider />
                <Stack>
                  <Text fontSize={'sm'}>Address</Text>
                  <Select size={'sm'} value={address} onChange={handleAddressChange}>
                    <option value="Semua">Semua</option>
                    <option value="Jakarta">Jakarta</option>
                    <option value="Depok">Depok</option>
                    <option value="Bogor">Bogor</option>
                    <option value="Bekasi">Bekasi</option>
                  </Select>
                </Stack>
                <Divider />
                <Stack>
                  <Text fontSize={'sm'}>Time</Text>
                  <Checkbox
                    size={'sm'}
                    value="Full Time"
                    isChecked={timeFilter.includes('Full Time')}
                    onChange={handleTimeFilterChange}
                  >
                    Full Time
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="Part Time"
                    isChecked={timeFilter.includes('Part Time')}
                    onChange={handleTimeFilterChange}
                  >
                    Part Time
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="Full & Part Time"
                    isChecked={timeFilter.includes('Full & Part Time')}
                    onChange={handleTimeFilterChange}
                  >
                    Full & Part Time
                  </Checkbox>
                </Stack>
                <Divider />
                <Stack>
                  <Text fontSize={'sm'}>Workplace</Text>
                  <Checkbox
                    size={'sm'}
                    value="Work From Home(WFH)"
                    isChecked={workplaceFilter.includes('Work From Home(WFH)')}
                    onChange={handleWorkplaceFilterChange}
                  >
                    Work From Home (WFH)
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="Work From Office(WFO)"
                    isChecked={workplaceFilter.includes('Work From Office(WFO)')}
                    onChange={handleWorkplaceFilterChange}
                  >
                    Work From Office (WFO)
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="Hybrid"
                    isChecked={workplaceFilter.includes('Hybrid')}
                    onChange={handleWorkplaceFilterChange}
                  >
                    Hybrid
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="Remote"
                    isChecked={workplaceFilter.includes('Remote')}
                    onChange={handleWorkplaceFilterChange}
                  >
                    Remote
                  </Checkbox>
                </Stack>
                <Divider />
                <Stack>
                  <Text fontSize={'sm'}>Experience</Text>
                  <Checkbox
                    size={'sm'}
                    value="Tanpa Pengalaman"
                    isChecked={experienceFilter.includes('Tanpa Pengalaman')}
                    onChange={handleExperienceFilterChange}
                  >
                    Tanpa Pengalaman
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="6 bulan - 1 tahun"
                    isChecked={experienceFilter.includes('6 bulan - 1 tahun')}
                    onChange={handleExperienceFilterChange}
                  >
                    6 bulan - 1 tahun
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="1 tahun - 2 tahun"
                    isChecked={experienceFilter.includes('1 tahun - 2 tahun')}
                    onChange={handleExperienceFilterChange}
                  >
                    1 tahun - 2 tahun
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="2 tahun - 3 tahun"
                    isChecked={experienceFilter.includes('2 tahun - 3 tahun')}
                    onChange={handleExperienceFilterChange}
                  >
                    2 tahun - 3 tahun
                  </Checkbox>
                  <Checkbox
                    size={'sm'}
                    value="3 tahun++"
                    isChecked={experienceFilter.includes('3 tahun++')}
                    onChange={handleExperienceFilterChange}
                  >
                    3 tahun++
                  </Checkbox>
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Stack>}
      <HStack py={1} gap={3} align={'flex-start'}>
        {isDesktop ?
          <Stack w={'20%'}>
            <Stack w={'17%'} bgColor={'white'} borderRadius={'md'} p={5} shadow={'md'} pos={'fixed'}>
              <Stack >
                <Text fontSize={'sm'}>Position name</Text>
                <Input size={'sm'} placeholder='Find Job' value={position} onChange={handlePositionChange} />
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Job Specialization</Text>
                <Input size={'sm'} placeholder='Job Specialization' value={specialization} onChange={handleSpecialization} />
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Address</Text>
                <Select size={'sm'} value={address} onChange={handleAddressChange}>
                  <option value="Semua">Semua</option>
                  <option value="Jakarta">Jakarta</option>
                  <option value="Depok">Depok</option>
                  <option value="Bogor">Bogor</option>
                  <option value="Bekasi">Bekasi</option>
                </Select>
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Time</Text>
                <Checkbox
                  size={'sm'}
                  value="Full Time"
                  isChecked={timeFilter.includes('Full Time')}
                  onChange={handleTimeFilterChange}
                >
                  Full Time
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Part Time"
                  isChecked={timeFilter.includes('Part Time')}
                  onChange={handleTimeFilterChange}
                >
                  Part Time
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Full & Part Time"
                  isChecked={timeFilter.includes('Full & Part Time')}
                  onChange={handleTimeFilterChange}
                >
                  Full & Part Time
                </Checkbox>
              </Stack>
              <Divider />

              <Stack>
                <Text fontSize={'sm'}>Workplace</Text>
                <Checkbox
                  size={'sm'}
                  value="Work From Home(WFH)"
                  isChecked={workplaceFilter.includes('Work From Home(WFH)')}
                  onChange={handleWorkplaceFilterChange}
                >
                  Work From Home (WFH)
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Work From Office(WFO)"
                  isChecked={workplaceFilter.includes('Work From Office(WFO)')}
                  onChange={handleWorkplaceFilterChange}
                >
                  Work From Office (WFO)
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Hybrid"
                  isChecked={workplaceFilter.includes('Hybrid')}
                  onChange={handleWorkplaceFilterChange}
                >
                  Hybrid
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="Remote"
                  isChecked={workplaceFilter.includes('Remote')}
                  onChange={handleWorkplaceFilterChange}
                >
                  Remote
                </Checkbox>
              </Stack>
              <Divider />
              <Stack>
                <Text fontSize={'sm'}>Experience</Text>
                <Checkbox
                  size={'sm'}
                  value="Tanpa Pengalaman"
                  isChecked={experienceFilter.includes('Tanpa Pengalaman')}
                  onChange={handleExperienceFilterChange}
                >
                  Tanpa Pengalaman
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="6 bulan - 1 tahun"
                  isChecked={experienceFilter.includes('6 bulan - 1 tahun')}
                  onChange={handleExperienceFilterChange}
                >
                  6 bulan - 1 tahun
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="1 tahun - 2 tahun"
                  isChecked={experienceFilter.includes('1 tahun - 2 tahun')}
                  onChange={handleExperienceFilterChange}
                >
                  1 tahun - 2 tahun
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="2 tahun - 3 tahun"
                  isChecked={experienceFilter.includes('2 tahun - 3 tahun')}
                  onChange={handleExperienceFilterChange}
                >
                  2 tahun - 3 tahun
                </Checkbox>
                <Checkbox
                  size={'sm'}
                  value="3 tahun++"
                  isChecked={experienceFilter.includes('3 tahun++')}
                  onChange={handleExperienceFilterChange}
                >
                  3 tahun++
                </Checkbox>
              </Stack>
            </Stack>

          </Stack>
          :
          ''
        }

        <Stack w={['full', '80%', '80%']} >
          {filteredJobs?.length > 0 ?
            <Grid templateColumns={['', 'repeat(2, 1fr)', 'repeat(2, 1fr)']} gap={3}>
              {filteredJobs?.length > 0 ? filteredJobs?.map((job, index) => (
                <Card key={index}>
                  <CardBody>
                    <HStack justify={'space-between'} mb={2}>
                      <Heading noOfLines={1} onClick={() => navigate(`/preview/${job.id}/apply`)} size='sm' cursor='pointer' _hover={{ color: 'gray.400' }}>{job.position_name}</Heading>
                      <Badge colorScheme='green'>{job.time}</Badge>
                    </HStack>
                    <HStack>
                      <BiBarChart size={18} />
                      <Text color='gray.600'>{job.level}</Text>
                    </HStack>
                    <HStack>
                      <GoPerson size={18} />
                      <Text color='gray.600'>{job.job_type}</Text>
                    </HStack>
                    <HStack>
                      <GoPerson size={18} />
                      <Text color='gray.600'>{job.experience}</Text>
                    </HStack>
                    <HStack>
                      <HiOutlineBuildingLibrary size={18} />
                      <Text color='gray.600'>{job.workplace}</Text>
                    </HStack>
                    <HStack>
                      <Avatar size={'md'} src={job?.image ?? defaultImage} />
                      <VStack align={'flex-start'}>
                        <HStack>
                          <HiOutlineBuildingLibrary />
                          <Text color='gray.600'>{job.company}</Text>
                        </HStack>
                        <HStack>
                          <CiLocationOn size={18} />
                          <Text color='gray.600'>{job.address}</Text>
                        </HStack>
                      </VStack>
                    </HStack>

                    <Divider my={3} />

                    <HStack>
                      <Text fontSize={'sm'} color={'gray'}>{timeAgo(job.createdAt)}</Text>
                    </HStack>
                  </CardBody>
                </Card>
                // <Flex borderRadius={10}  gap={5} bg='white' shadow='md' w='full' key={index} p={5}>
                //     {job.published === 'Publish' ? 
                //     <>
                //     <Image w={'180px'} h={'180px'} aspectRatio={1} src={job.image} />
                //     <Box flex={1}>
                //         <Stack>
                //             <Flex gap={10}>
                //                 <Heading onClick={()=> navigate(`/preview/${job.id}/apply`)} size='sm' cursor='pointer' _hover={{color : 'gray.400'}}>{job.position_name}</Heading>
                //                 <Badge colorScheme='green'>{job.time}</Badge>
                //             </Flex>
                //             <Flex gap={2}><HiOutlineBuildingLibrary /><Text  color='gray.600'>{job.company}</Text></Flex>
                //             <Flex gap={2}><BiBarChart /><Text color='gray.600'>{job.level}</Text></Flex>
                //             <Flex gap={2}><GoPerson /><Text color='gray.600'>{job.job_type}</Text></Flex>
                //             <Flex gap={2}><CiLocationOn /><Text color='gray.600'>{job.address}</Text></Flex>
                //             <Flex gap={2}><HiOutlineBuildingLibrary /><Text color='gray.600'>{job.workplace}</Text></Flex>
                //         </Stack>
                //     </Box>
                //     </> : <></>}
                // </Flex>
              )) : <></>}

            </Grid>
            : <Center><Text color={'gray'}>Belum ada lowongan</Text></Center>
          }
        </Stack>
      </HStack>
    </Stack>
  )
}

export default RecruitmentFrontHome