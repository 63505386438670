/* eslint-disable no-console */
import axios from 'axios';
import moment from 'moment';
import imageCompression from 'browser-image-compression';


const UploadFileToDropbox = async (file, parentPath, token, setProgress) => {
  const currentMillis = moment(new Date()).valueOf();


  const fileType = file.type.split('/')[0]; // Mengambil bagian depan sebelum tanda "/"


  const fileTypeFix =
    fileType === 'image'
      ? 'image'
      : fileType === 'video'
        ? 'video'
        : fileType === 'audio'
          ? 'audio'
          : 'file';


  // if type image use aws
  const uploadUrlAws = 'https://new-apiv2.importir.com/api/upload-aws/image';
  const url = 'https://content.dropboxapi.com/2/files/upload';


  try {
    // Compress image if file type is image
    let fileToUpload = file;
    if (fileTypeFix === 'image') {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 800,
        useWebWorker: true
      };
      fileToUpload = await imageCompression(file, options);
    }



    // config Aws
    const dataAws = {
      file: fileToUpload,
      folder: `${parentPath}`,
    };


    const configAws = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(progress);
      },
    };


    // config dropbox
    const accessToken = token;
    const headers = {
      'Content-Type': 'application/octet-stream',
      Authorization: `Bearer ${accessToken}`,
      'Dropbox-API-Arg': JSON.stringify({
        path: `${parentPath}/${fileTypeFix}/${currentMillis}-${file.name}`,
        mode: 'add',
        autorename: true,
        mute: false,
      }),
    };


    if (fileTypeFix === 'image') {
      const responseAws = await axios.post(uploadUrlAws, dataAws, configAws);
      if (responseAws) {
        return {
          type: 'image',
          link: responseAws?.data?.data,
        };
      }
    } else {
      // For other file types, skip Dropbox and return null
      const response = await axios.post(url, fileToUpload, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(progress); // Anda dapat menampilkan progres ini jika diperlukan
        },
      });


      if (response?.data?.path_lower) {
        const dropboxLink = await createShareLink(
          response.data.path_lower,
          fileTypeFix,
          accessToken
        );
        return dropboxLink;
      }
    }
    return null;


  } catch (error) {
    console.log(error.message);
    return null;
  }
};


const createShareLink = async (filePath, typeFile, token) => {
  const accessToken = token;
  const url = 'https://api.dropboxapi.com/2/sharing/create_shared_link_with_settings';


  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  };


  const requestData = {
    path: filePath,
  };


  try {
    const response = await axios.post(url, requestData, {
      headers: headers,
    });


    const urlData = response?.data?.url;
    const dataFix = urlData.includes('.mov') || urlData.includes('.MOV');


    const urlRaw = urlData.replace('www.dropbox.com', 'dl.dropboxusercontent.com');


    const finalUrl = dataFix ? urlRaw : `${urlRaw}&raw=1`;
    return { link: finalUrl, type: typeFile };
  } catch (error) {
    return null;
  }
};


export default UploadFileToDropbox;
