/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
import { Icon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
  useDisclosure,
  Avatar,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import {
  IoIosArrowForward,
  IoIosArrowBack,
} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../Config/firebase';

import { data } from './DataMenuRecruitment';
import useUserStore from '../../Hooks/Zustand/Store';
import { signOut } from 'firebase/auth';
import { useState } from 'react';
import themeConfig from '../../Config/themeConfig';
import { logoutUserWithIp } from '../../Hooks/Middleware/sessionMiddleWare';
import { AiOutlineDesktop, AiOutlineMobile } from 'react-icons/ai';

const SidebarUserRecruitment = ({layout}) => {
  const navigate = useNavigate();
  // const {setIsLoggedIn} = useUserStore()

  const toast = useToast()
  // const globalState = useUserStore();
  const [menu, setMenu] = useState('');
  const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure();
  const [desktopShow, setDesktopShow] = useState(true);
  const [hidden, setHidden] = useState(!isOpen);

  const height = window?.innerHeight;

  const isDesktop = useBreakpointValue({ base: false, lg: desktopShow });


  const globalState = useUserStore();




  const handleLogout = async () => {
    const pathLink = 'business';

    await logoutUserWithIp(
      window.location.hostname,
      auth.currentUser.email,
      pathLink
    );
    
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        toast({
          status: 'success',
          description: 'Logged out success',
          duration: 2000,
        });
        navigate('/');
        localStorage.clear();
        globalState.setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });          });
  }
  const user = auth.currentUser

  const handleSelectMenu = (value) => {
    setMenu(value);
    
    if (value.status) {
      null;
    } else {
      if (value?.link?.includes('https')) {
        window.open(value?.link, '_blank');
      } else {
        navigate(value.link);
      }
    }
  }

  const handleClick = () => {
    setDesktopShow(!desktopShow);
  };

  if (layout?.type === 'vertical' || layout?.type === 'vertical-horizontal')
    return (
      <HStack spacing={'-1'}>
        <Box
          sx={{
            '&::-webkit-scrollbar': {
              w: '2',
              h: '3',
            },
            '&::-webkit-scrollbar-track': {
              w: '6',
              h: '5',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10',
              bg: 'gray.200',
            },
          }}
          width={desktopShow ? '100px' : '15px'}
          transition={'0.2s ease-in-out'}
          shadow={'xl'}
          roundedBottomRight={'lg'}
          roundedTopRight={'lg'}
          minH={height}
          backgroundColor={themeConfig.color.colorFirst}
        >
          <Box position="sticky">
            <Stack
              position={'absolute'}
              right={-4}
              cursor={'pointer'}
              onClick={handleClick}
              alignItems="flex-end"
              justifyContent={'flex-end'}
              p={2}
              borderRadius="full"
              bgColor="blue.200"
              zIndex={500}
            >
              {desktopShow ? (
                <AiOutlineMobile size={17} />
              ) : (
                <AiOutlineDesktop size={17} />
              )}
            </Stack>

            <Stack
              {...getButtonProps()}
              position={'absolute'}
              right={-5}
              top={300}
              bg={'blue.300'}
              h={50}
              borderRadius={'md'}
              zIndex={-1}
              cursor={'pointer'}
              p={1}
              alignItems={'center'}
              justify={'center'}
            >
              {isOpen ? (
                <IoIosArrowBack size={18} color="white" />
              ) : (
                <IoIosArrowForward size={18} color="white" />
              )}
            </Stack>

            <Flex as="section">
              <Stack display={desktopShow ? 'flex' : 'none'}>
                <Box onClick={() => navigate('/')} cursor={'pointer'}>
                  <Image src={themeConfig.logokotak} borderRadius="full" />
                </Box>

                <Box px={2}>
                  <Divider />
                </Box>

                <Stack
                  height={['40vh', '50vh', '50vh']}
                  overflowY="scroll"
                  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '3',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '6',
                      h: '5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.200',
                    },
                  }}
                >
                  {data.map((menu, i) => (
                    <Stack
                      key={i}
                      pt={2}
                      spacing={1}
                      justifyContent={'center'}
                      alignItems={'center'}
                      onClick={() => handleSelectMenu(menu)}
                      cursor={'pointer'}
                      position="relative"
                    >
                      <Icon as={menu.icon} boxSize={6} />
                      <Text fontSize={10}>{menu.name}</Text>
                      {menu.status ? (
                        <Stack
                          size={'xs'}
                          position="absolute"
                          bgColor={'red'}
                          borderRadius="md"
                          visibility={'visible'}
                          top={2}
                        >
                          <Text
                            color={'white'}
                            fontSize={'xx-small'}
                            fontWeight={500}
                            py={'0.5'}
                            px={1}
                          >
                            Coming Soon
                          </Text>
                        </Stack>
                      ) : null}
                    </Stack>
                  ))}
                </Stack>

                
                <Spacer />

                {/* <Button
                  as={Link}
                  to={"/settings"}
                  variant="ghost"
                  justifyContent="start"
                > */}
                
                {/* </Button> */}

                <Box py={3}>
                  <Center py={2}>
                    {/* <UserProfile
                      image={
                        globalState.email === null
                          ? userInfo.photoURL
                          : globalState.email
                        
                      }
                    /> */}
                    <Avatar
                      boxSize={10}
                      name={user?.displayName}
                      src={user?.photoURL}
                    />
                  </Center>

                  <Box align={'center'}>
                    <Button size={'xs'} colorScheme="red" onClick={handleLogout}>
                      Logout
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Flex>
          </Box>
        </Box>

        <motion.div
          {...getDisclosureProps()}
          hidden={hidden}
          initial={false}
          onAnimationStart={menu?.submenu ? () => setHidden(false) : null}
          onAnimationComplete={menu?.submenu ? () => setHidden(!isOpen) : null}
          animate={menu?.submenu ? { width: isOpen ? 200 : 0 } : 0}
          style={{
            borderStartEndRadius: 20,
            borderEndEndRadius: 20,
            background: '#f5f5f5',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            // position: "absolute",
            // left: "0",
            height: '100vh',
            // top: "0",
          }}
        >
          <Stack p={3} h={'100vh'}>
            <Text
              color={'gray.600'}
              my={3}
              fontWeight={'semibold'}
              align={'center'}
              textTransform="uppercase"
            >
              Business Deoapp
            </Text>
            <Box>
              <Divider />
            </Box>
            <Stack spacing={3} pt={3}>
              <Accordion allowToggle>
                {menu.submenu?.map((sub, i) => (
                  <AccordionItem
                    key={i}
                  >
                    <h2>
                      <AccordionButton w={'100%'}>
                        <HStack spacing={2} w={'100%'}>
                          <Icon as={sub.icon} boxSize={isDesktop ? 5 : 7} />
                          {isDesktop && (
                            <Text fontWeight={500} fontSize="sm" noOfLines={1}>
                              {sub.name}
                            </Text>
                          )}
                          <Spacer />
                          <AccordionIcon />
                        </HStack>
                      </AccordionButton>
                    </h2>
                    
                  </AccordionItem>
                  // <Box
                  //   p={1}
                  //   key={i}
                  //   onClick={() => navigate(sub.link)}
                  //   cursor={"pointer"}
                  // >
                  //   <HStack spacing={3}>
                  //     <Icon as={sub.icon} boxSize={4} />
                  //     <Text fontWeight={500} fontSize={"sm"}>
                  //       {sub.name}
                  //     </Text>
                  //   </HStack>
                  //   <Divider py={1} />
                  // </Box>
                ))}
              </Accordion>
            </Stack>
            <Spacer />
            <Stack spacing={0} align={'center'} color={'gray.500'}>
              <Text fontSize={'xs'}>{globalState.name}</Text>
              <Text fontSize={'xs'}>{globalState.email}</Text>
            </Stack>
            <Divider />
            <Text
              color={'gray.500'}
              align={'center'}
              fontSize={12}
              fontWeight={'semibold'}
            >
              Business Deoapp
            </Text>
          </Stack>
        </motion.div>

      </HStack>
    );

  return <></>;
}

export default SidebarUserRecruitment