import React, {  } from 'react';
import MainMenuComponent from '../../Components/Menus/MainMenuComponent';

const HrisPage = () => {

  return (
    <MainMenuComponent menuName="hris" />
  );
};

export default HrisPage;
