/* eslint-disable no-useless-catch */
import * as React from 'react';
import {
  AspectRatio,
  Avatar,
  Box,
  Container,
  Divider,
  Heading,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import imagegelap from '../../assets/kotakhitam.png';
import imageterang from '../../assets/kotakputih.png';
import moment from 'moment';
import {
  isAfternoon,
  isEvening,
  isMorning,
  isNight,
  toAfternoon,
} from '../../Utils/getTimes';
import { useState } from 'react';
import { useEffect } from 'react';
import AppShortcut from '../../Components/AppComponent/AppShortcut';
import useUserStore from '../../Hooks/Zustand/Store';
import { readHris } from '../../Api/Hris';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  FcAdvance,
  FcAlarmClock,
  FcCompactCamera,
  FcSettings,
} from 'react-icons/fc';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import LiveTime from '../../Components/Timer/LiveTime';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { getUserByUid } from '../../Utils/getUser';

function HrisHomePage() {
  const [attendance, setAttendance] = useState([]);
  const [count, setCount] = useState(1);

  const globalState = useUserStore();

  const currentWidth = window.innerWidth;


  const getAttendance = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris(`attendance?page=${count}`, session);

      if (data) {
        data?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

        const dataArr = data;
        if (count > 1) {
          setAttendance([...attendance, ...dataArr]);
        } else {
          setAttendance(dataArr || []);
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const handlePage = () => {
    setCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    getAttendance();

    return () => {
      if (window?.location?.pathname !== '/hris/home') {
        setAttendance([]);
      }
    };
  }, [globalState?.currentProject, count]);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }

  return (
    <Box width="full">
      <Container
        maxW="full"
        py="4"
        flex="1"
        px={useBreakpointValue({
          base: '5px',
          lg: '30px',
        })}
      >
        <BackButtons />
        <Stack
          spacing={{
            base: '4',
            lg: '4',
          }}
          mt="4"
        >
          <Box
            width="100%"
            mr="4rem"
            bgGradient={
              isMorning
                ? 'linear(to-br, #07508B, #0168A6, #66C7EF, #DCD6B3)'
                : isAfternoon
                  ? 'linear(to-br, #4BBCF4, #61C0BF, #BBDED6,#FFB6B9, #FAE3D9)'
                  : isEvening
                    ? 'linear(to-b, #351C4D, #765285, #FF7E5F, #FEB47B, #F5AB99)'
                    : isNight
                      ? 'linear(to-b, #030433 ,#035FBB)'
                      : 'linear(to-br, #030433 ,#035FBB)'
            }
            rounded="md"
            shadow="md"
            _hover={{ shadow: 'lg' }}
          >
            <Box p={2}>
              <Stack
                align="center"
                py={3}
                px={6}
                justify="space-between"
                boxShadow="sm"
                rounded="lg"
                direction={{ base: 'column', md: 'row' }}
              >
                <Stack
                  spacing={{ base: 2, md: 3 }}
                  alignItems={{ base: 'center', lg: 'start' }}
                >
                  <HStack>
                    <Stack overflow={'hidden'} h="full">
                      <Image
                        alt="hero image"
                        fit="cover"
                        alignItems={'flex-start'}
                        h="60px"
                        w={'60px'}
                        m={1}
                        display={['none', 'flex', 'flex']}
                        borderRadius={'full'}
                        src={
                          isMorning
                            ? imageterang
                            : toAfternoon
                              ? imageterang
                              : isAfternoon
                                ? imageterang
                                : isEvening
                                  ? imagegelap
                                  : isNight
                                    ? imagegelap
                                    : imagegelap
                        }
                      />
                    </Stack>
                    <Stack textAlign={{ base: 'center', lg: 'start' }}>
                      {isMorning ? (
                        <Text
                          fontFamily="Poppins, sans-serif"
                          fontSize="2xl"
                          as={'span'}
                          _after={{
                            content: '\'\'',
                            width: 'full',
                            height: '30%',
                          }}
                          color={'blue.800'}
                        >
                          {' '}
                          Very Good Morning,
                        </Text>
                      ) : isAfternoon ? (
                        <Text
                          fontFamily="Poppins, sans-serif"
                          fontSize="2xl"
                          as={'span'}
                          _after={{
                            content: '\'\'',
                            width: 'full',
                            height: '30%',
                          }}
                        >
                          {' '}
                          Good Afternoon,
                        </Text>
                      ) : isEvening ? (
                        <Text
                          fontFamily="Poppins, sans-serif"
                          fontSize="2xl"
                          as={'span'}
                          _after={{
                            content: '\'\'',
                            width: 'full',
                            height: '30%',
                          }}
                          color={'white'}
                        >
                          {' '}
                          Good Evening,
                        </Text>
                      ) : (
                        <Text
                          fontFamily="Poppins, sans-serif"
                          fontSize="2xl"
                          as={'span'}
                          _after={{
                            content: '\'\'',
                            width: 'full',
                            height: '30%',
                          }}
                          color="gray.100"
                        >
                          {' '}
                          Good Night,
                        </Text>
                      )}
                      <Heading
                        fontFamily="Poppins, sans-serif"
                        fontSize="3xl"
                        fontWeight={'extrabold'}
                        color={
                          isMorning
                            ? 'white'
                            : isAfternoon
                              ? 'black'
                              : isEvening
                                ? 'white'
                                : 'white'
                        }
                        textShadow={'base'}
                      >
                        <Text>{globalState?.name}</Text>
                      </Heading>
                    </Stack>
                  </HStack>
                  <Text
                    fontWeight={'bold'}
                    fontSize="sm"
                    color={
                      isMorning
                        ? 'white'
                        : isAfternoon
                          ? 'black'
                          : isEvening
                            ? 'white'
                            : 'white'
                    }
                  >
                    Shortcut
                  </Text>
                  <Divider
                    borderColor={isMorning || isAfternoon ? 'black' : 'white'}
                  />
                  <HStack
                    w={['250px', '400px', '500px']}
                    p={1}
                    overflowX={currentWidth < 500 ? 'scroll' : null}
                    spacing={2}
                  >
                    <AppShortcut
                      icon={<FcCompactCamera size={25} />}
                      label="Attendance"
                      link="/attendance/home/live"
                    />

                    <AppShortcut
                      icon={<FcSettings size={25} />}
                      label="Correction"
                      link="/attendance/home/correction"
                    />

                    <AppShortcut
                      icon={<FcAdvance size={25} />}
                      label="Leave"
                      link="/attendance/home/leave"
                    />

                    <AppShortcut
                      icon={<FcAlarmClock size={25} />}
                      label="Overtime"
                      link="/attendance/home/overtime"
                    />
                  </HStack>
                </Stack>
                <Stack alignItems={'center'} justifyContent="center">
                  <Stack>
                    <LiveTime color={isMorning ? 'black' : 'white'} />
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Text
            p={2}
            fontFamily="Poppins, sans-serif"
            fontWeight={'bold'}
            fontSize="lg"
            mb={5}
          >
            News
          </Text>
          <SimpleGrid spacing={{ base: '6', lg: '8' }} columns={1}>
            {attendance ? (
              attendance?.map((item, index) => {
                const res = getUserByUid(globalState?.users, item?.user_uid );

                return (
                  <Stack key={index}>
                    <HStack>
                      <Avatar size={'sm'} fontwe name={item?.user_uid} />
                          
                      <Text>{capitalizeFirstLetter(res?.name || res?.email || '')}-{' '}</Text>
                      {item?.createdAt && (
                        <Text fontWeight={500} fontSize={['sm', 'sm', 'md']}>
                          {item?.createdAt && (
                            moment
                              .parseZone(item?.createdAt)
                              .utcOffset(item?.gmt_offset_in || 7)
                              .format('dddd, D MMMM YYYY')
                          )}

                        </Text>
                      )}

                    </HStack>
                    <HStack flexDirection={['column', 'row', 'row']} w={'full'}>
                      <HStack w={['100%', '50%', '50%']}>
                        <Stack w={'3%'}></Stack>
                        <Stack w={'95%'} shadow={'md'} borderRadius={'md'}>
                          <Stack
                            bgColor={'orange'}
                            roundedTopLeft="md"
                            roundedTopRight="md"
                            p={2}
                          >
                            <Text fontWeight={'bold'}>Clock In Time</Text>
                          </Stack>
                          <HStack p={3}>
                            <AspectRatio ratio={1} boxSize={'100px'}>
                              <Image
                                src={item?.image_in}
                                fallbackSrc="https://via.placeholder.com/150"
                                borderRadius={'md'}
                              />
                            </AspectRatio>
                            {item?.clock_in && (
                              <VStack pl={3} align={'flex-start'}>
                                <Heading size={['md', 'lg', 'lg']}>
                                  {moment
                                    .parseZone(item?.clock_in)
                                    .utcOffset(item?.gmt_offset_in || 7)
                                    .format('HH:mm:ss')}
                                </Heading>
                                <Text>
                                  {' '}
                                  {moment
                                    .parseZone(item?.clock_in)
                                    .utcOffset(item?.gmt_offset_in || 7)
                                    .format('D MMMM YYYY')}
                                </Text>
                              </VStack>

                            )}

                          </HStack>
                        </Stack>
                      </HStack>
                      {item?.clock_out !== null ? (
                        <HStack w={['100%', '50%', '50%']}>
                          <Stack w={'3%'}></Stack>
                          <Stack w={'95%'} shadow={'md'} borderRadius={'md'}>
                            <Stack
                              bgColor={'blue.700'}
                              roundedTopLeft="md"
                              roundedTopRight="md"
                              p={2}
                            >
                              <Text color={'white'} fontWeight={'bold'}>
                                Clock Out Time
                              </Text>
                            </Stack>
                            <HStack p={3}>
                              <AspectRatio ratio={1} boxSize={'100px'}>
                                <Image
                                  src={item.image_out}
                                  fallbackSrc="https://via.placeholder.com/150"
                                  borderRadius={'md'}
                                />
                              </AspectRatio>
                              {item?.clock_out && (
                                <VStack pl={3} align={'flex-start'}>
                                  <Heading size={['md', 'lg', 'lg']}>
                                    {moment
                                      .parseZone(item?.clock_out)
                                      .utcOffset(item?.gmt_offset_out || 7)
                                      .format('HH:mm:ss')}
                                  </Heading>
                                  <Text>
                                    {' '}
                                    {moment
                                      .parseZone(item?.clock_out)
                                      .utcOffset(item?.gmt_offset_out || 7)
                                      .format('D MMMM YYYY')}
                                  </Text>
                                </VStack>
                              )}

                            </HStack>
                          </Stack>
                        </HStack>
                      ) : (
                        ''
                      )}
                    </HStack>

                    <Divider />
                  </Stack>
                );
              })
            ) : (
              <AlertArrayNull
                titleData={'Attendance'}
                action="Add Attendance"
              />
            )}
          </SimpleGrid>
          {attendance?.length < 25 ? null : (
            <Stack alignItems={'center'} justifyContent="center">
              <DynamicButton
                title="Load more"
                action={'read'}
                variant="solid"
                onClick={handlePage}
              />
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  );
}

export default HrisHomePage;
