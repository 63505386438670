/* eslint-disable no-undef */
import axios from 'axios';

const baseURL =
          process.env.REACT_APP_FUNCTIONS_HOST;
// const baseURL = 'http://127.0.0.1:8080/deoapp-indonesia/asia-southeast2'
const options = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_PAYMENT_KEY,
  },
};

export const payrollRead = async (dataApi,) => {
  try {
    const newUrl = `${baseURL}/payroll-read`
    const res = await axios.post(newUrl, dataApi, options,);
    return res.data 
  } catch (error) {
    throw new Error(error.message);
  }
    
}

export const payrollUpdate = async (dataApi,) => {
  try {
    const newUrl = `${baseURL}/payroll-edit`
    const res = await axios.post(newUrl, dataApi, options,);
    return res.data 
  } catch (error) {
    throw new Error(error.message);
  }
}

export const payrollDelete = async (dataApi,) => {
  try {
    const newUrl = `${baseURL}/payroll-delete`
    const res = await axios.post(newUrl, dataApi, options,);
    if (res.data.status === false) throw new Error(res.data.message)
    return res.data 
  } catch (error) {
    throw new Error(error.message);
  }
}

export const payrollCreate = async (dataApi,) => {
  try {
    const newUrl = `${baseURL}/payroll-create`// post to mongodb
    const res = await axios.post(newUrl, dataApi, options,);
    return res.data 
  } catch (error) {
    throw new Error(error.message);
  }
}


// export const payrollBulkDelete = async (dataApi) => {
//   try {
//     const newUrl = `${baseURL}/payroll-delete`// post to mongodb
//     const res = await axios.post(newUrl, dataApi, options);
//     return res.data;
//   } catch (error) {
//     throw new Error(error.message);
//   }
// };