import {
  Button,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  useDisclosure,
  FormControl,
  FormLabel,
  useToast,
  FormErrorMessage,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../../Components/Buttons/BackButtons';
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getCountFromServer,
  query,
  where
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import useUserStore from '../../../Hooks/Zustand/Store';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import DynamicTable from '../../../Components/Table/DynamicTable';
import { getCollectionFirebase } from '../../../Api/firebaseApi';

function LocationPage() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState([])
  const toast = useToast()
  const [name, setName] = useState('')
  const [location, setLocations] = useState([])
  const globalState = useUserStore();
  const cancelRef = React.useRef();
  const [dataActive, setDataActive] = useState('')
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataSearch, setDataSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDate] = useState('');
  const itemsPerPage = 10
  const schema = yup.object().shape({
    name: yup.string().required('Name is Required'),
  })
  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const openModalCreate = () => {
    setIsEditMode(false);
    onOpen()
  };

  const openModalEdit = (x) => {
    setIsEditMode(true);
    onOpen()
    setName(x.name)
    setId(x.data.id)
  };

  const onSubmit = async () => {
    try {
      if (isEditMode === true) {
        globalState.setIsLoading(true)
        const data = doc(db, 'asset_locations', id)
        await updateDoc(data, {
          name: name,
        })
        globalState.setIsLoading(false)
        onClose()
        setName('')
        toast({
          title: 'Deoapp.com',
          description: 'Successfully update data',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      } else {
        globalState.setIsLoading(true)
        await addDoc(collection(db, 'asset_locations'), {
          companyId: globalState.currentCompany,
          projectId: globalState.currentProject,
          name: name,
          created_at: moment().format('MMMM Do YYYY, h:mm:ss a')
        })
        globalState.setIsLoading(false)
        onClose()
        setName('')
        toast({
          title: 'Deoapp.com',
          description: 'Successfully save data',
          status: 'success',
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany }
    ]
    if (selectedDate) {
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      conditions.push(
        { field: 'created_at', operator: '>=', value: startDate },
        { field: 'created_at', operator: '<=', value: endDate }
      );
    }
    const limitValue = startIndex + itemsPerPage;
    const sortBy =  { field: 'created_at', direction: 'asc' };
    try {
      const res = await getCollectionFirebase(
        'asset_locations',
        conditions,
        sortBy,
        limitValue,
      );
      setLocations(res);
      const collectionRef = collection(db, 'asset_locations');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
        ));
      const assesmentLength = snapshot.data().count;
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const handleDelete = async () => {
    globalState.setIsLoading(true)
    deleteDoc(doc(db, 'asset_locations', dataActive.data.id))
    toast({
      title: 'Deoapp.com',
      description: 'Success delete this post',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    });
    globalState.setIsLoading(false)
    setDeleteModal(false)
  }

  const handleDeleteModal = (row) => {
    setDeleteModal(true);
    setDataActive(row)
  };

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const tableHeader = ['id', 'name', 'created']
  const tableData = location.map((data, index) => {
    const name = data.name || ''
    const created = data?.created_at

    return {
      data,
      id: index +1,
      name: name,
      created: moment(created, 'MMMM Do YYYY, h:mm:ss a').format('DD-MM-YYYY')
    }
  })

  const tableDataFilter = dataSearch?.map((data, index) => {
    const name = data.name || ''
    const created = data?.created_at

    return {
      data,
      id: index +1,
      name: name,
      created: moment(created, 'MMMM Do YYYY, h:mm:ss a').format('DD-MM-YYYY')
    }
  })

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = location.filter((item) => {
        const itemData = item.name
          ? item.name.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearch(newData);
      setInputSearch(text);
    } else {
      setDataSearch(location);
      setInputSearch(text);
    }
  };

  const inputStyles = {
    '&::placeholder': {
      color: 'gray.500',
    },
  };

  useEffect(() => {
    getData()
  }, [currentPage, selectedDate])
  return (
    <Stack p={[1, 1, 5]}>
      <BackButtons />
      <Stack spacing={4}>
        <HStack>
          <Heading size={'md'} fontWeight="bold">
            Location
          </Heading>
          <Spacer />
          <Input
            w={['100%', '30%', '30%']}
            type="text"
            placeholder="Search Name"
            bgColor="white"
            color="black"
            sx={inputStyles}
            fontSize="sm"
            onChange={(e) => searchFilterFunction(e.target.value)}
          />
          <HStack>
            <DynamicButton action={'create'} title={'New Location'} onClick={() => openModalCreate()} />
          </HStack>
        </HStack>

        <Stack
          bgColor="white"
          spacing={1}
          borderRadius="xl"
          p={3}
          m={[1, 1, 5]}
          shadow="md"
        >
          {inputSearch !== '' ? (
            <DynamicTable header={tableHeader} data={tableDataFilter} onDelete={handleDeleteModal} onEdit={openModalEdit} />
          ) :
            <DynamicTable header={tableHeader} data={tableData} onDelete={handleDeleteModal} onEdit={openModalEdit} />
          }
        </Stack>

        <Stack alignItems={'center'} justifyContent="center">
          {currentPage < totalPages && (
            <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
              Load More
            </Button>
          )}
        </Stack>
      </Stack>

      <AlertDialog
        isOpen={deleteModal}
        leastDestructiveRef={cancelRef}
        onClose={() => setDeleteModal(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure delete this posting? You cant undo this action
              afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setDeleteModal(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleDelete()}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditMode ? 'Edit Asset' : 'Create Asset'}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody pb={6}>
              <FormControl isInvalid={errors.name}>
                <FormLabel>Name</FormLabel>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={name}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(e) => {
                        setName(e.target.value);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
                <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' type="submit">
                {isEditMode ? 'Save' : 'Create'}
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default LocationPage