import {
  Heading,
  HStack,
  Spacer,
  Stack,
  Card, 
  CardHeader, 
  CardBody, 
  SimpleGrid,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useToast,
  Center,
  Select,
  Flex,
  Box,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons';
import useUserStore from '../../../Hooks/Zustand/Store';
import { getCollectionFirebase } from '../../../Api/firebaseApi';
import { formatFrice } from '../../../Utils/numberUtil';

function ReportDepreciationAsset() {
  const globalState = useUserStore();
  const toast = useToast()
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const [filterYear, setFilterYear] = useState(new Date().getFullYear())
  const [yearOptions, setYearOptions] = useState([])
  const [nonBuilding, setNonBuilding] = useState([])
  const [building, setBuilding] = useState([])
  const [totalNonBuilding, setTotalNonBuilding] = useState({
    total_price: 0,
    month_totals: []
  })
  const [totalBuilding, setTotalBuilding] = useState({
    total_price: 0,
    month_totals: []
  })
  const [totalAsset, setTotalAsset] = useState({
    total_price: 0,
    month_totals: []
  })

  const getData = async (year = '') => {
    if (year === '') {
      year = filterYear
    }
    const conditionAsset = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany }
    ]
    const conditionCategory = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany }
    ]
    
    try {
      globalState.setIsLoading(true)
      const res = await getCollectionFirebase(
        'asset',
        conditionAsset
      );

      const sortByCategory =  { field: 'name', direction: 'asc' };
      const resCategories = await getCollectionFirebase(
        'asset_categories',
        conditionCategory,
        sortByCategory
      );
      splitPerGroup(res, resCategories, year)
      globalState.setIsLoading(false)
    } catch (error) {
      globalState.setIsLoading(false)
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const splitPerGroup = (datasLocals, catLocals, year) => {
    const filterDatas = [];
    datasLocals.forEach((x) => {
      const years = JSON.parse(x.depreciation_values)
      const selYear = years.find((x) => x.year === parseInt(year))
      if (selYear !== undefined) {
        filterDatas.push(x)
      }
    })

    const buildingDatas = []
    const nonBuildingDatas = []

    catLocals.forEach((x) => {
      const datas = filterDatas.filter((dL) => dL.category === x.name)
      let totalPrice = 0;
      const monthTotals = []
      months.forEach((x) => {
        monthTotals.push({
          month: x,
          nilai_awal: 0,
          nilai_akhir: 0,
          dep_value: 0,
          akumulasi_penyusutan: 0
        })
      })

      datas.forEach((x) => {
        totalPrice = totalPrice + (x.quantity * x.price_per_quantity)
        const monthLocals = JSON.parse(x.depreciation_values).find((x) => x.year === parseInt(year))
        monthLocals.months.forEach((x, i) => {
          monthTotals[i].nilai_awal = monthTotals[i].nilai_awal + x.nilai_awal 
          monthTotals[i].nilai_akhir = monthTotals[i].nilai_akhir + x.nilai_akhir
          monthTotals[i].dep_value = monthTotals[i].dep_value + x.dep_value
          monthTotals[i].akumulasi_penyusutan = monthTotals[i].akumulasi_penyusutan + x.akumulasi_penyusutan
        })
      })

      const dataInput = {
        type: x.type,
        name: x.name,
        category: x,
        total_price: totalPrice,
        month_totals: monthTotals,
        datas: datas
      }
      if (x.type === 'building') {
        buildingDatas.push(dataInput)
      } else {
        nonBuildingDatas.push(dataInput)
      }
    })

    const totalNonB = setTotalOfEachGroup(nonBuildingDatas)
    setTotalNonBuilding({
      total_price: totalNonB.total_price,
      month_totals: totalNonB.month_totals
    })
    
    const totalNB = setTotalOfEachGroup(buildingDatas)
    setTotalBuilding({
      total_price: totalNB.total_price,
      month_totals: totalNB.month_totals
    })

    const totalA = [];
    totalA.push(totalNonB)
    totalA.push(totalNB)
    const totalAssG = setTotalOfEachGroup(totalA)
    setTotalAsset({
      total_price: totalAssG.total_price,
      month_totals: totalAssG.month_totals
    })

    setNonBuilding(nonBuildingDatas)
    setBuilding(buildingDatas)
  }

  const setTotalOfEachGroup = (nonBuildingDatas) => {

    let totalPriceNB = 0
    const monthTotalNbs = []
    months.forEach((x) => {
      monthTotalNbs.push({
        month: x,
        nilai_awal: 0,
        nilai_akhir: 0,
        dep_value: 0,
        akumulasi_penyusutan: 0
      })
    })
    nonBuildingDatas.forEach((x) => {
      totalPriceNB = totalPriceNB + x.total_price
      x.month_totals.forEach((x, i) => {
        monthTotalNbs[i].nilai_awal = monthTotalNbs[i].nilai_awal + x.nilai_awal 
        monthTotalNbs[i].nilai_akhir = monthTotalNbs[i].nilai_akhir + x.nilai_akhir
        monthTotalNbs[i].dep_value = monthTotalNbs[i].dep_value + x.dep_value
        monthTotalNbs[i].akumulasi_penyusutan = monthTotalNbs[i].akumulasi_penyusutan + x.akumulasi_penyusutan
      })
    })
    return {
      total_price: totalPriceNB,
      month_totals: monthTotalNbs
    }
  }

  const showDepreciationValue = (category, xd) => {
    const totalPrice = xd.quantity * xd.price_per_quantity
    const depValu = category.depreciation_percentage / 100 * totalPrice / 12
    return depValu
  }

  const getSelectedYear = (xd) => {
    const selYear = JSON.parse(xd.depreciation_values).find((x) => x.year === filterYear)
    return selYear !== undefined ? selYear.months : []
  }

  const handleSubmitData = (data) => {
    setFilterYear(parseInt(data))
    setNonBuilding([])
    getData(data)
  }

  useEffect(() => {
    const optYears = []
    for (let i = 2000; i < 2040; i++) {
      optYears.push(i)
    }
    setYearOptions(optYears)
    getData()
  }, [])

  return (
    <Stack p={[1, 1, 5]}>
      <BackButtons />
      <Stack spacing={4}>
        <HStack>
          <Heading size={'md'} fontWeight="bold">
            Report Depreciation Asset
          </Heading>
          <Spacer />
          <HStack>
          </HStack>
        </HStack>

        <Stack
          bgColor="white"
          spacing={1}
          borderRadius="xl"
          p={3}
          m={[1, 1, 5]}
          shadow="md"
        >
                  
          <Card>
            <CardHeader>
              <Stack>
                <Flex>
                  <Box>
                    <Heading size={'md'} fontWeight="bold">
                      Daftar
                    </Heading>
                  </Box>
                  <Spacer />
                  <Box>
                    <Select
                      placeholder="Select Location"
                      onChange={(e) => handleSubmitData(e.target.value)}
                      value={filterYear}
                    >
                      {yearOptions.map((x, i) => (
                        <option value={x} key={i}>{x}</option>
                      ))}
                    </Select>
                  </Box>
                </Flex>
              </Stack>
            </CardHeader>

            <CardBody>
              <SimpleGrid columns={2}>
                <TableContainer>
                  <Table size='sm'>
                    <Thead>
                      <Tr bg={'gray.50'}>
                        <Th rowSpan={2}><Center>No</Center></Th>
                        <Th rowSpan={2}><Center>Keterangan</Center></Th>
                        <Th><Center>Tanggal</Center></Th>
                        <Th rowSpan={2}><Center>Lokasi</Center></Th>
                      </Tr>
                      <Tr bg={'gray.50'}>
                        <Th><Center>Perolehan</Center></Th> 
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td colSpan={2}>Bukan Bangunan</Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      {nonBuilding.map((x, i) => (
                        <>
                          {i != 0 ? 
                            <>
                              <Tr bg={'gray.50'}>
                                <Th rowSpan={2}><Center>No</Center></Th>
                                <Th rowSpan={2}><Center>Keterangan</Center></Th>
                                <Th><Center>Tanggal</Center></Th>
                                <Th rowSpan={2}><Center>Lokasi</Center></Th>
                              </Tr>
                              <Tr bg={'gray.50'}>
                                <Th><Center>Perolehan</Center></Th> 
                              </Tr>
                            </> : ''}
                          <Tr>
                            <Td colSpan={2}>{x.name}</Td>
                            <Td></Td>
                            <Td></Td>
                          </Tr>
                          {x.datas.map((xd, xdI) => (
                            <Tr key={i+'-'+xdI}>
                              <Td>{parseInt(xdI)+1}</Td>
                              <Td>{xd.title}</Td>
                              <Td>{xd.date_of_purchase}</Td>
                              <Td>{xd.location}</Td>
                            </Tr>
                          ))}
                          <Tr bg={'red.100'}>
                            <Td colSpan={2}>Sub Total {x.name}</Td>
                            <Td></Td>
                            <Td></Td>
                          </Tr>
                          <Tr key={i}>
                            <Td colSpan={2}></Td>
                            <Td></Td>
                            <Td></Td>
                          </Tr>
                        </>
                      ))}
                      <Tr bg={'red.500'} color={'white'}>
                        <Td colSpan={2}>Sub Total Bukan Bangunan</Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td colSpan={2}></Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td colSpan={2}>Bangunan</Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      {building.map((x, i) => (
                        <>
                          <Tr>
                            <Td colSpan={2}>{x.name}</Td>
                            <Td></Td>
                            <Td></Td>
                          </Tr>
                          <>
                            <Tr bg={'gray.50'}>
                              <Th rowSpan={2}><Center>No</Center></Th>
                              <Th rowSpan={2}><Center>Keterangan</Center></Th>
                              <Th><Center>Tanggal</Center></Th>
                              <Th rowSpan={2}><Center>Lokasi</Center></Th>
                            </Tr>
                            <Tr bg={'gray.50'}>
                              <Th><Center>Perolehan</Center></Th> 
                            </Tr>
                          </>
                          {x.datas.map((xd, xdI) => (
                            <Tr key={i+'-'+xdI}>
                              <Td>{parseInt(xdI)+1}</Td>
                              <Td>{xd.title}</Td>
                              <Td>{xd.date_of_purchase}</Td>
                              <Td>{xd.location}</Td>
                            </Tr>
                          ))}
                          <Tr bg={'red.100'}>
                            <Td colSpan={2}>Sub Total {x.name}</Td>
                            <Td></Td>
                            <Td></Td>
                          </Tr>
                          <Tr bg={'gray.50'} key={i}>
                            <Td colSpan={2}>...</Td>
                            <Td></Td>
                            <Td></Td>
                          </Tr>
                        </>
                      ))}
                      <Tr>
                        <Td colSpan={2}></Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      <Tr bg={'red.500'} color={'white'}>
                        <Td colSpan={2}>Sub Total Bangunan</Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      <Tr>
                        <Td colSpan={2}></Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                      <Tr bg={'gray.500'} color={'white'}>
                        <Td colSpan={2}>Sub Total Asset</Td>
                        <Td></Td>
                        <Td></Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
                <TableContainer>
                  <Table size='sm'>
                    <Thead>
                      <Tr bg={'gray.50'}>
                        <Th colSpan={3}><Center>Nilai Asset</Center></Th>
                        <Th rowSpan={2}><Center>Umur Ekonomis</Center></Th>
                        <Th rowSpan={2}><Center>Persentasi Penyusutan / Tahun</Center></Th>
                        <Th rowSpan={2}><Center>Penyusutan / Bulan</Center></Th>
                        {months.map((x, i) => (
                          <Th colSpan={4} key={i}><Center>{x}</Center></Th>
                        ))}
                      </Tr>
                      <Tr bg={'gray.50'}>
                        <Th>Qty</Th>
                        <Th>Harga per Unit</Th>
                        <Th>Jumlah Harga Perolehan</Th>
                        {months.map(() => (
                          <>
                            <Th>Nilai Buku Awal</Th>
                            <Th>Beban Penyusutan</Th>
                            <Th>Akum. Penyusutan</Th>
                            <Th>Nilai Buku Akhir</Th>
                          </>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td colSpan={54}>...</Td>
                      </Tr>

                      {nonBuilding.map((x, i) => (
                        <>
                          {i != 0 ?
                            <>
                              <Tr bg={'gray.50'}>
                                <Th colSpan={3}><Center>Nilai Asset</Center></Th>
                                <Th rowSpan={2}><Center>Umur Ekonomis</Center></Th>
                                <Th rowSpan={2}><Center>Persentasi Penyusutan / Tahun</Center></Th>
                                <Th rowSpan={2}><Center>Penyusutan / Bulan</Center></Th>
                                {months.map((x, i) => (
                                  <Th colSpan={4} key={i}><Center>{x}</Center></Th>
                                ))}
                              </Tr>
                              <Tr bg={'gray.50'}>
                                <Th>Qty</Th>
                                <Th>Harga per Unit</Th>
                                <Th>Jumlah Harga Perolehan</Th>
                                {months.map(() => (
                                  <>
                                    <Th>Nilai Buku Awal</Th>
                                    <Th>Beban Penyusutan</Th>
                                    <Th>Akum. Penyusutan</Th>
                                    <Th>Nilai Buku Akhir</Th>
                                  </>
                                ))}
                              </Tr>
                            </> : ''}
                          <Tr>
                            <Td colSpan={54}>...</Td>
                          </Tr>
                          {x.datas.map((xd, xdI) => (
                            <Tr key={i+ '-' +xdI}>
                              <Td>{xd.quantity}</Td>
                              <Td isNumeric>{xd.price_per_quantity === '' ? '' : formatFrice(parseInt(xd.price_per_quantity))}</Td>
                              <Td isNumeric>{formatFrice(xd.price_per_quantity * xd.quantity)}</Td>
                              <Td isNumeric>{x.category.economy_years}</Td>
                              <Td isNumeric>{x.category.depreciation_percentage}%</Td>
                              <Td isNumeric>{formatFrice(showDepreciationValue(x.category, xd))}</Td>
                              {
                                getSelectedYear(xd).map((month) => (
                                  <>
                                    <Td isNumeric>{formatFrice(parseInt(month.nilai_awal))}</Td>
                                    <Td isNumeric>{formatFrice(parseInt(month.dep_value))}</Td>
                                    <Td isNumeric>{formatFrice(parseInt(month.akumulasi_penyusutan))}</Td>
                                    <Td isNumeric>{formatFrice(parseInt(month.nilai_akhir))}</Td>
                                  </>
                                ))
                              }
                            </Tr>
                          ))}
                          <Tr bg={'red.100'}>
                            <Td colSpan={2}></Td>
                            <Td isNumeric>{formatFrice(parseInt(x.total_price))}</Td>
                            <Td colSpan={3}></Td>
                            {x.month_totals.map((mt) => (
                              <>
                                <Td isNumeric>{formatFrice(parseInt(mt.nilai_awal))}</Td>
                                <Td isNumeric>{formatFrice(parseInt(mt.dep_value))}</Td>
                                <Td isNumeric>{formatFrice(parseInt(mt.akumulasi_penyusutan))}</Td>
                                <Td isNumeric>{formatFrice(parseInt(mt.nilai_akhir))}</Td>
                              </>
                            ))}
                          </Tr>
                          <Tr key={i}>
                            <Td colSpan={54}></Td>
                          </Tr>
                        </>
                      ))}
                      <Tr bg={'red.500'} color={'white'}>
                        <Td colSpan={2}></Td>
                        <Td isNumeric>{formatFrice(parseInt(totalNonBuilding.total_price))}</Td>
                        <Td colSpan={3}></Td>
                        {totalNonBuilding.month_totals.map((mt) => (
                          <>
                            <Td isNumeric>{formatFrice(parseInt(mt.nilai_awal))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.dep_value))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.akumulasi_penyusutan))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.nilai_akhir))}</Td>
                          </>
                        ))}
                      </Tr>
                      <Tr>
                        <Td colSpan={54}></Td>
                      </Tr>
                      <Tr>
                        <Td colSpan={54}>...</Td>
                      </Tr>

                      {building.map((x, i) => (
                        <>
                          <Tr>
                            <Td colSpan={54}>...</Td>
                          </Tr>
                          <Tr bg={'gray.50'}>
                            <Th colSpan={3}><Center>Nilai Asset</Center></Th>
                            <Th rowSpan={2}><Center>Umur Ekonomis</Center></Th>
                            <Th rowSpan={2}><Center>Persentasi Penyusutan / Tahun</Center></Th>
                            <Th rowSpan={2}><Center>Penyusutan / Bulan</Center></Th>
                            {months.map((x, i) => (
                              <Th colSpan={4} key={i}><Center>{x}</Center></Th>
                            ))}
                          </Tr>
                          <Tr bg={'gray.50'}>
                            <Th>Qty</Th>
                            <Th>Harga per Unit</Th>
                            <Th>Jumlah Harga Perolehan</Th>
                            {months.map(() => (
                              <>
                                <Th>Nilai Buku Awal</Th>
                                <Th>Beban Penyusutan</Th>
                                <Th>Akum. Penyusutan</Th>
                                <Th>Nilai Buku Akhir</Th>
                              </>
                            ))}
                          </Tr>
                          {x.datas.map((xd, xdI) => (
                            <Tr key={i+ '-' +xdI}>
                              <Td>{xd.quantity}</Td>
                              <Td isNumeric>{xd.price_per_quantity === '' ? '' : formatFrice(parseInt(xd.price_per_quantity))}</Td>
                              <Td isNumeric>{formatFrice(xd.price_per_quantity * xd.quantity)}</Td>
                              <Td isNumeric>{x.category.economy_years}</Td>
                              <Td isNumeric>{x.category.depreciation_percentage}%</Td>
                              <Td isNumeric>{formatFrice(showDepreciationValue(x.category, xd))}</Td>
                              {
                                getSelectedYear(xd).map((month) => (
                                  <>
                                    <Td isNumeric>{formatFrice(parseInt(month.nilai_awal))}</Td>
                                    <Td isNumeric>{formatFrice(parseInt(month.dep_value))}</Td>
                                    <Td isNumeric>{formatFrice(parseInt(month.akumulasi_penyusutan))}</Td>
                                    <Td isNumeric>{formatFrice(parseInt(month.nilai_akhir))}</Td>
                                  </>
                                ))
                              }
                            </Tr>
                          ))}
                          <Tr bg={'red.100'}>
                            <Td colSpan={2}></Td>
                            <Td isNumeric>{formatFrice(parseInt(x.total_price))}</Td>
                            <Td colSpan={3}></Td>
                            {x.month_totals.map((mt) => (
                              <>
                                <Td isNumeric>{formatFrice(parseInt(mt.nilai_awal))}</Td>
                                <Td isNumeric>{formatFrice(parseInt(mt.dep_value))}</Td>
                                <Td isNumeric>{formatFrice(parseInt(mt.akumulasi_penyusutan))}</Td>
                                <Td isNumeric>{formatFrice(parseInt(mt.nilai_akhir))}</Td>
                              </>
                            ))}
                          </Tr>
                          <Tr key={i} bg={'gray.50'}>
                            <Td colSpan={54}>...</Td>
                          </Tr>
                        </>
                      ))}
                      <Tr>
                        <Td colSpan={54}></Td>
                      </Tr>
                      <Tr bg={'red.500'} color={'white'}>
                        <Td colSpan={2}></Td>
                        <Td isNumeric>{formatFrice(parseInt(totalBuilding.total_price))}</Td>
                        <Td colSpan={3}></Td>
                        {totalBuilding.month_totals.map((mt) => (
                          <>
                            <Td isNumeric>{formatFrice(parseInt(mt.nilai_awal))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.dep_value))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.akumulasi_penyusutan))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.nilai_akhir))}</Td>
                          </>
                        ))}
                      </Tr>
                      <Tr>
                        <Td colSpan={54}></Td>
                      </Tr>
                      <Tr bg={'gray.500'} color={'white'}>
                        <Td colSpan={2}></Td>
                        <Td isNumeric>{formatFrice(parseInt(totalAsset.total_price))}</Td>
                        <Td colSpan={3}></Td>
                        {totalAsset.month_totals.map((mt) => (
                          <>
                            <Td isNumeric>{formatFrice(parseInt(mt.nilai_awal))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.dep_value))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.akumulasi_penyusutan))}</Td>
                            <Td isNumeric>{formatFrice(parseInt(mt.nilai_akhir))}</Td>
                          </>
                        ))}
                      </Tr>
                      
                    </Tbody>
                  </Table>
                </TableContainer>
              </SimpleGrid>
            </CardBody>
          </Card>
        </Stack>  
      </Stack>
    </Stack>
  )
}

export default ReportDepreciationAsset