/* eslint-disable no-useless-escape */
/* eslint-disable react/react-in-jsx-scope */
import {  Button, Card, CardBody, Divider, FormControl, HStack, Heading, Image, Input, Select, Stack, Text, Textarea, VStack, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup';
import { FiMail, FiPhoneCall } from 'react-icons/fi';
import { MdOutlinePermMedia } from 'react-icons/md';
import { getSingleDocumentFirebase, updateDocumentFirebase, uploadFileFirebase } from '../../Api/firebaseApi';
import useUserStore from '../../Hooks/Zustand/Store';
import BackButtons from '../../Components/Buttons/BackButtons';

const RecruitmentCompanyProfile = () => {
  const toast = useToast()
  const { currentCompany} = useUserStore();
  const [dataCompany, setDataCompany] = useState()
  const [data, setData] = useState()
  const [files, setFiles] = useState([]);
  const [filesImage, setFilesImage] = useState([]);
  const city = ['jakarta','tangerang','mexico']
  const getSingleCompany = async () => {
    try {
      const result = await getSingleDocumentFirebase ('companies', currentCompany)
      const res = await getSingleDocumentFirebase ('companies', `${currentCompany}/description/companyDescription`)
      setData(result)
      setDataCompany(res)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description:error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });    }
  }
  useEffect(() => {
    getSingleCompany()
  }, [currentCompany])
  const companyName = data?.name
  const industry = dataCompany?.industry
  const description = dataCompany?.description
  const address = dataCompany?.address
  const domicile = dataCompany?.domicile
  const email = dataCompany?.email
  const phone = dataCompany?.phone
  const schema = yup.object().shape({
    name: yup.string().required('Name is Required'),
    industry: yup.string().required('industry is Required'),
    description: yup.string().required('description is Required'),
    address: yup.string(),
    domicile: yup.string().required('Choose your domicile'),
    email: yup.string().required('Email is Required'),
    phone: yup.string().required('Phone is Required'),
  });
    
  const handleFileInputChange = (event) => {
    const { files: newFiles } = event.target;
    if (newFiles.length) {
      const newFileArray = [...files];
      for (let i = 0; i < newFiles.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(newFiles[i]);
        reader.onload = () => {
          newFileArray.push({
            file: reader.result,
            fileName: newFiles[i].name,
            description: newFiles[i].type,
          });
          setFiles(newFileArray);
        };
      }
      setFilesImage(newFiles)
      ; // Mengubah state filesImage menjadi array baru dari selectedFiles
    }
  }

  const onSubmitForm = async (payload) => {
    const dataCompany = {
      name: payload.name,
      industry: payload.industry,
      description: payload.description,
      address: payload.address,
      domicile: payload.domicile,
      email: payload.email,
      phone: payload.phone
    };
    if (filesImage[0]) {
      const resImage = await uploadFileFirebase(filesImage[0],{type:null});
      if(resImage){
        dataCompany.image = resImage.image_url.replace(/(\.[^.\/\\]+)$/i, '_800x800$1')
      }}
    const newData = dataCompany
    try {
      await updateDocumentFirebase('companies',`${currentCompany}/description/companyDescription`, newData)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description:error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });    }
    toast({
      title: 'Saved!',
      status: 'success',
      isClosable: true,
    });
    setTimeout(() => {
      window.location.reload()
    }, 500)
  };

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  useEffect(() => {
    if (dataCompany) {
      setValue('name', companyName);
      setValue('industry', industry);
      setValue('description', description);
      setValue('address', address);
      setValue('domicile', domicile);
      setValue('email', email);
      setValue('phone', phone);
    }
  }, [currentCompany, data])
  return (
    <Stack w={{ lg: '100%' }}>
      <HStack my={5} justify={'space-between'}>
        <BackButtons/>
        <Text fontSize={'lg'} color='gray' >Update your company profile</Text>
      </HStack>
      <Card w={'85%'} m={'auto'}>
        <CardBody>
          <HStack justify={'space-between'}>
            <VStack align={'flex-start'}>
              <HStack py={1} gap={8}>
                <Image w={100} src={dataCompany?.image}/>
                <Heading>{companyName}</Heading>
              </HStack>
              {/* <Text fontSize={10} color='gray'>Company ID: {currentCompany}</Text> */}
                    
            </VStack>
            {/* <HStack>
                    <Button bgColor={'green.500'}>Save</Button>
                    <Button bgColor={'red'}>Cancel</Button>
                </HStack> */}
          </HStack>
          <Divider my={3}/>
          {/* <form onSubmit={handleSubmit(onSubmitForm)}>
                <Stack py={5}>
                    <Heading  size={'sm'}>Public Profile</Heading>
                    <Controller
							    name="name"
								defaultValue=""
								control={control}
								render={({ field }) => (
								<Input
                                    placeholder="Input your company name"
									type="text"
									{...field}
									name="name"
									invalid={errors.name}
                                    bgColor={'white'}
                                    // disabled={true}
									/>
								)}
							/>
							{errors.name && (<Text fontSize={"sm"} color={"red"} fontStyle={"italic"}>{errors.name.message}</Text>)}
                </Stack>

                <Stack py={5}>
                    <Heading  size={'sm'}>Industry</Heading>
                    <Controller
							    name="industry"
								defaultValue=""
								control={control}
								render={({ field }) => (
								<Input
                                    placeholder="Input your company industry"
									type="text"
									{...field}
									name="industry"
									invalid={errors.industry}
                                    bgColor={'white'}
									/>
								)}
							/>
							{errors.industry && (<Text fontSize={"sm"} color={"red"} fontStyle={"italic"}>{errors.industry.message}</Text>)}
                </Stack>

                <Stack py={5}>
                    <Heading  size={'sm'}>Description</Heading>
                    <Controller
							    name="description"
								defaultValue=""
								control={control}
								render={({ field }) => (
								<Textarea
									type="text"
									{...field}
									name="description"
									invalid={errors.description}
                                    bgColor={'white'}
                                    placeholder="Describe your company"
									/>
								)}
							/>
							{errors.description && (<Text fontSize={"sm"} color={"red"} fontStyle={"italic"}>{errors.description.message}</Text>)}
                </Stack>

                <Stack py={5}>
                    <Heading  size={'sm'}>Address</Heading>
                    <Controller
                                    name="domicile"
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => (
                                    <Select
                                        type="text"
                                        {...field}
                                        name="domicile"
                                        invalid={errors.domicile}
                                        bgColor={'white'}
                                        placeholder="select domicile"
                                        >
                                            {city?.map((x,i)=> (
                                                <option  key={i}>{x}</option>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors.domicile && (<Text fontSize={"sm"} color={"red"} fontStyle={"italic"}>{errors.domicile.message}</Text>)}
                                <Controller
                                    name="address"
                                    defaultValue=""
                                    control={control}
                                    render={({ field }) => (
                                    <Textarea
                                        type="text"
                                        {...field}
                                        name="address"
                                        invalid={errors.address}
                                        bgColor={'white'}
                                        placeholder="Describe your company"
                                        />
                                    )}
                                />
                                {errors.address && (<Text fontSize={"sm"} color={"red"} fontStyle={"italic"}>{errors.address.message}</Text>)}
                </Stack>

                <Stack py={5}>
                    <Heading  size={'sm'}>Company Logo</Heading>
                    <FormControl id="image" name='companyProfile' isRequired>
                        <HStack>
                            {files.length > 0 ? (
                            <Stack alignItems={"center"}>
                                <Image
                                src={files[0].file}
                                boxSize="100%"
                                w={300}
                                maxH={200}
                                borderRadius="xl"
                                alt={files[0].file}
                                shadow="sm"
                                objectFit={"cover"}
                                objectPosition={"center center"}
                                />
                                <Flex>
                                <Input
                                    type="file"
                                    onChange={handleFileInputChange}
                                    display="none"
                                    id="fileInput"
                                />

                                <label htmlFor="fileInput">
                                    <HStack cursor="pointer">
                                    <Stack>
                                        <MdOutlinePermMedia />
                                    </Stack>
                                    <Text
                                        fontSize="sm"
                                        color="blue.600"
                                        fontStyle="italic"
                                    >
                                        Change Image thumbnail
                                    </Text>
                                    </HStack>
                                </label>
                                </Flex>
                            </Stack>
                            ) : (
                            <Flex
                                border={"2px"}
                                borderRadius={"md"}
                                borderStyle={"dashed"}
                                borderColor={"gray.300"}
                                h={250}
                                w={300}
                                justifyContent={"center"}
                                alignItems={"center"}
                            >
                                <Input
                                type="file"
                                onChange={handleFileInputChange}
                                display="none"
                                id="fileInput"
                                />

                                <label htmlFor="fileInput">
                                <HStack cursor="pointer">
                                    <Stack>
                                    <MdOutlinePermMedia />
                                    </Stack>
                                    <Text fontSize="sm" color="blue.600" fontStyle="italic">
                                        Add Image
                                    </Text>
                                </HStack>
                                </label>
                            </Flex>
                            )}
                        </HStack>
                    </FormControl>
                    
                </Stack>

                <Stack py={5}>
                    <Heading py={3} size={'sm'}>Contacts</Heading>
                    <Stack>
                        <FiMail/>
                        <Controller
                                        name="email"
                                        defaultValue=""
                                        control={control}
                                        render={({ field }) => (
                                        <Input
                                            placeholder="Input your company email"
                                            type="text"
                                            {...field}
                                            name="email"
                                            invalid={errors.email}
                                            bgColor={'white'}
                                            />
                                        )}
                                    />
                                    {errors.email && (<Text fontSize={"sm"} color={"red"} fontStyle={"italic"}>{errors.email.message}</Text>)}
                    </Stack>
                    <Stack>
                        <FiPhoneCall/>
                        <Controller
                                            name="phone"
                                            defaultValue=""
                                            control={control}
                                            render={({ field }) => (
                                            <Input
                                                placeholder="Input your company phone number"
                                                type="text"
                                                {...field}
                                                name="phone"
                                                invalid={errors.phone}
                                                bgColor={'white'}
                                                />
                                            )}
                                        />
                                        {errors.phone && (<Text fontSize={"sm"} color={"red"} fontStyle={"italic"}>{errors.phone.message}</Text>)}
                    </Stack>
                </Stack>
                <Stack align={"end"}>
						<Button type="submit" colorScheme="green">Submit</Button>
					</Stack>
            </form> */}

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Stack mt={5} mb={5}>
              <Heading size={'sm'}>Public Profile</Heading>
              <Controller
                name="name"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Input your company name"
                    type="text"
                    {...field}
                    name="name"
                    invalid={errors.name}
                    bgColor={'white'}
                  />
                )}
              />
              {errors.name && (<Text fontSize={'sm'} color={'red'} fontStyle={'italic'}>{errors.name.message}</Text>)}
            </Stack>
            <Stack mt={5} mb={5}>
              <Heading size={'sm'}>Industry</Heading>
              <Controller
                name="industry"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Input your company industry"
                    type="text"
                    {...field}
                    name="industry"
                    invalid={errors.industry}
                    bgColor={'white'}
                  />
                )}
              />
              {errors.industry && (<Text fontSize={'sm'} color={'red'} fontStyle={'italic'}>{errors.industry.message}</Text>)}
            </Stack>
            <Stack display={'flex'} mt={5} mb={10}>
              <Heading size={'sm'}>Description</Heading>
              <Controller
                name="description"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Textarea
                    type="text"
                    {...field}
                    name="description"
                    invalid={errors.description}
                    bgColor={'white'}
                    placeholder="Describe your company"
                  />
                )}
              />
              {errors.description && (<Text fontSize={'sm'} color={'red'} fontStyle={'italic'}>{errors.description.message}</Text>)}
            </Stack>
            <Stack display={'flex'} mt={5} mb={5}>
              <Heading size={'sm'}>Address</Heading>
              <Controller
                name="domicile"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Select
                    type="text"
                    {...field}
                    name="domicile"
                    invalid={errors.domicile}
                    bgColor={'white'}
                    placeholder="select domicile"
                  >
                    {city?.map((x,i) => (
                      <option  key={i}>{x}</option>
                    ))}
                  </Select>
                )}
              />
              {errors.domicile && (<Text fontSize={'sm'} color={'red'} fontStyle={'italic'}>{errors.domicile.message}</Text>)}
              <Controller
                name="address"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Textarea
                    type="text"
                    {...field}
                    name="address"
                    invalid={errors.address}
                    bgColor={'white'}
                    placeholder="Describe your company"
                  />
                )}
              />
              {errors.address && (<Text fontSize={'sm'} color={'red'} fontStyle={'italic'}>{errors.address.message}</Text>)}
            </Stack>
            <Stack mt={5} mb={5}>
              <Heading size={'sm'}>Company Logo</Heading>
              <FormControl id="image" isRequired>
                <HStack>
                  {files.length > 0 && (
                    <Stack>
                      <Image
                        src={files[0].file}
                        boxSize="100%"
                        maxWidth={300}
                        borderRadius="xl"
                        alt={files[0].name}
                        shadow="sm"
                      />
                    </Stack>
                  )}
                </HStack>
                <Stack>
                  <Input
                    type="file"
                    onChange={handleFileInputChange}
                    display="none"
                    id="fileInput"
                  />

                  <label htmlFor="fileInput">
                    <HStack cursor={'pointer'}>
                      <Stack>
                        <MdOutlinePermMedia />
                      </Stack>
                      <Text fontSize={'sm'} color="blue.600" fontStyle={'italic'}>
                        Add Image
                      </Text>
                    </HStack>
                  </label>
                </Stack>
              </FormControl>
            </Stack>
            <Stack mt={5} mb={5}>
              <Heading size={'sm'}>Contacts</Heading>
              <HStack>
                <FiMail/>
                <Controller
                  name="email"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Input your company email"
                      type="text"
                      {...field}
                      name="email"
                      invalid={errors.email}
                      bgColor={'white'}
                    />
                  )}
                />
                {errors.email && (<Text fontSize={'sm'} color={'red'} fontStyle={'italic'}>{errors.email.message}</Text>)}
              </HStack>
              <HStack>
                <FiPhoneCall/>
                <Controller
                  name="phone"
                  defaultValue=""
                  control={control}
                  render={({ field }) => (
                    <Input
                      placeholder="Input your company phone number"
                      type="text"
                      {...field}
                      name="phone"
                      invalid={errors.phone}
                      bgColor={'white'}
                    />
                  )}
                />
                {errors.phone && (<Text fontSize={'sm'} color={'red'} fontStyle={'italic'}>{errors.phone.message}</Text>)}
              </HStack>
            </Stack>
            <Stack align={'end'}>
              <Button type="submit" colorScheme="green">Submit</Button>
            </Stack>
          </form>
            
            
        </CardBody>
      </Card>
    </Stack>
  )
}

export default RecruitmentCompanyProfile