/* eslint-disable react/jsx-key */
import {
  Avatar,
  Button,
  Center,
  Divider,
  Grid,
  HStack,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Square,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
  Spacer,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdCancel, MdFileDownloadDone, MdUploadFile } from 'react-icons/md';
import axios from 'axios';
import useUserStore from '../../Hooks/Zustand/Store';
import { createHris, deleteHris, readHris } from '../../Api/Hris';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertArrayNull from '../../Components/AlertDialog/AlertArrayNull';
import BackButtons from '../../Components/Buttons/BackButtons';
import { getUserByUid } from '../../Utils/getUser';
import moment from 'moment';
import { FaRegTrashAlt } from 'react-icons/fa';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';

const HrisPenaltyPage = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState()
  const modalDelete = useDisclosure()
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [penalty, setPenalty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [loadFile, setLoadFile] = useState(null);
  const [selectedPenalty, setSelectedPenalty] = useState()
  const [penaltyOrigin, setPenaltyOrigin] = useState([]);
  const [attribut, setAttribut] = useState({
    mode: '',
    title: '',
  });

  const globalState = useUserStore();

  const [dataPost, setDataPost] = useState({
    user_uid: '',
    penalty_category_id: 0,
    message: '',
  });


  const handleFile = (data) => {
    setLoadFile(data);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleFile,
    multiple: false,
  });
  const [dataDetail, setDataDetail] = useState();
  const handleAdd = () => {
    setAttribut({
      mode: 'add',
      title: 'Add Pinalty',
    });
    setModal(true);
  };

  const getUser = async () => {
    const dataCompany = globalState?.companies?.find(
      (x) => x?.id === globalState?.currentCompany
    );

    try {
      if (dataCompany?.users?.length > 0) {
        if (dataCompany?.users) {
          try {
            const promises = dataCompany?.users.map(async (x) => {
              const y = await getUserByUid(globalState?.users, x);
              return y;
            });

            const results = await Promise.all(promises);

            setUsers(results);
          } catch (error) {
            throw new Error(error.message, 'Failed to send Slack login message');
            // Handle the error, e.g., return a default value or rethrow the error
          }
        }
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  useEffect(() => {
    getUser();

    return () => {
      setUsers([]);
    };
  }, [globalState?.currentCompany]);

  const getPenaltyCategory = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris('penalty-category', session);
      setCategory(data);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };
  useEffect(() => {
    getPenaltyCategory();

    return () => {
      setCategory([]);
    };
  }, [globalState?.currentCompany, globalState?.currentProject]);

  const getPenalty = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris('penalty', session);

      const dataProm = data?.map((x) => {
        const uid = x?.user_uid;
        const categoryId = x?.penalty_category_id;
        const addUser = users?.find((q) => q.id === uid);
        const addCategory = category?.find((p) => p.id === categoryId);
        if (addUser) {
          x.user_data = addUser;
        }
        if (addCategory) {
          x.penalty_data = addCategory;
        }
        return x;
      });
      const newData = await Promise.all(dataProm);
      setPenalty(newData);
      setPenaltyOrigin(newData);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  useEffect(() => {
    getPenalty();

    return () => {
      setPenalty([]);
    };
  }, [users, category]);

  const handleSelectUser = (value) => {
    if (value !== '') {
      const filterUser = penaltyOrigin?.filter(
        (data) => data?.user_uid === value
      );
      setPenalty(filterUser);
    } else {
      setPenalty(penaltyOrigin);
    }
  };

  const handleSubmit = async () => {
    if (loadFile === null) {
      toast({
        status: 'error',
        description: 'Upload your file',
        title: 'File is empty',
        duration: '3000',
      });
    } else if (
      dataPost?.user_uid === '' ||
      dataPost?.message === '' ||
      dataPost?.penalty_category_id === 0
    ) {
      toast({
        status: 'error',
        title: 'All input should be filled',
        duration: 3000,
      });
    } else {
      setLoading(true);

      const dataCompany = 'edrus';
      const dataProject = 'edrus';
      const dataModule = 'attandance';
      const formData = new FormData();
      formData?.append('companyId', dataCompany);
      formData?.append('projectId', dataProject);
      formData?.append('module', dataModule);
      formData?.append('file', loadFile[0], 'file.pdf');

      try {
        const response = await axios.post(
          'https://deoapp-backend-awfnqqp6oq-et.a.run.app/upload_file',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.data.link) {
          // console.log("File berhasil diunggah:", response.data.link.link);

          const linkImage = response.data.link.link;

          const session = {
            headers: globalState?.authFirebaseToken,
            companyId: globalState?.currentCompany,
            projectId: globalState?.currentProject,
          };

          const newData = {
            user_uid: dataPost.user_uid,
            penalty_category_id: dataPost.penalty_category_id,
            message: dataPost.message,
            attachments: [linkImage],
          };

          const upload = await createHris('penalty', session, newData);

          if (upload.status === true) {
            toast({
              status: 'success',
              description: upload.message,
              duration: 2000,
            });
          }
        } else {
          throw new Error('Failed to send Slack login message');
        }
      } catch (error) {
        throw new Error(error.message, 'Failed to send Slack login message');
      } finally {
        setLoading(false);
        setModal(false);
        setLoadFile(null);
        setDataPost({ user_uid: '', penalty_category_id: 0, message: '' });
        getPenalty();
      }
    }
  };
  
  const handleOpenAlert = (value) => {
    modalDelete?.onOpen()
    setSelectedPenalty(value)
  }
  const handleClose = () => {
    setModal(false);
    setLoadFile(null);
  };

  const handleDetail = (x) => {
    setAttribut({
      mode: 'detail',
      title: 'Detail Pinalty',
    });
    setDataDetail(x);
    setModal(true);
  };

  const handleDelete = async() => {
    setIsLoading(true)
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    }

    try {
      await deleteHris(`penalty/${selectedPenalty?.id}`, session)

      toast({
        status: 'success',
        title: 'Penalty deleted',
        duration: 3000
      })
    } catch (error) {
      throw new Error(error, 'An Error Occured')
    } finally {
      setIsLoading(false)
      getPenalty()
      modalDelete?.onClose()
    }
  }


  const DetailModalContent = () => {
    return (
      <ModalContent>
        <ModalHeader>Detail Penalty</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack justify={'space-between'} my={3}>
            <Text fontWeight={'medium'}>Name</Text>
            <Text>{dataDetail?.user_data?.name}</Text>
          </HStack>
          <HStack justify={'space-between'} my={3}>
            <Text fontWeight={'medium'}>Penalty Category</Text>
            <Text>{dataDetail?.penalty_category_id}</Text>
          </HStack>
          <HStack justify={'space-between'} my={3}>
            <Text fontWeight={'medium'}>Validity Period</Text>
            <Text>{dataDetail?.penalty_data?.duration} Month</Text>
          </HStack>
          <HStack justify={'space-between'} my={3}>
            <Text fontWeight={'medium'}>Valid until</Text>
            <Text>{dataDetail?.expired_at}</Text>
          </HStack>
        </ModalBody>
      </ModalContent>
    );
  };
  return (
    <Stack>
      <HStack flexDirection={['column', 'row', 'row']} my={3}>
        <BackButtons />
        <Heading size={'lg'}>Employees Penalty Data</Heading>

        <Spacer />

        <DynamicButton
          title="Assign Penalty"
          action={'create'}
          variant={'solid'}
          onClick={handleAdd}
        />
      </HStack>
      <Divider />

      <Box mt={3} bg={'white'} p={3} borderRadius={'md'}>
        <Select
          my={2}
          onChange={(e) => handleSelectUser(e?.target?.value)}
          w={['100%', '25%', '25%']}
          backgroundColor={'white'}
          color={'gray'}
          fontSize={'sm'}
          shadow={'base'}
        >
          <option value={''}>Select Employee</option>
          {users
            ?.sort()
            ?.filter((x) => x?.name !== undefined)
            .map((x, i) => {
              return (
                <option key={i} value={x?.id}>
                  {x?.name !== '' ? x?.name : x?.email}
                </option>
              );
            })}
        </Select>
        {penalty?.length === 0 || !penalty ? (
          <AlertArrayNull titleData={'Penalty'} action="Add Penalty" />
        ) : (
          <Box py={5}>
            <Grid
              w={'100%'}
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(4, 1fr)',
                'repeat(4, 1fr)',
              ]}
              gap={5}
            >
              {penalty?.map((x) => (
                <Stack
                  
                  backgroundColor={'white'}
                  shadow={'md'}
                  p={2}
                  borderRadius={'md'}
                  // onClick={() => handleDetail(x)}
                >
                  <Box cursor="pointer" onClick={() => handleDetail(x)} >
                    <HStack spacing={3}>
                      <Stack>
                        <Avatar size={'sm'} name={'S P'} />
                      </Stack>

                      <VStack align={'start'} spacing={1}>
                        <Text fontSize={'md'} fontWeight={'semibold'}>
                          {x?.user_data?.name}
                        </Text>
                        <Text fontSize={'xs'}>{x.message}</Text>
                      </VStack>
                    </HStack>
                  </Box>
                  <Spacer />
                  <HStack>
                    <Text align={'right'} fontSize={10}>{moment(x.createdAt).format('LL')}</Text>
                    <Spacer />
                    <FaRegTrashAlt size={12} cursor={'pointer'} color='gray.300' onClick={() => handleOpenAlert(x)} />
                  </HStack>
                </Stack>
              ))}
            </Grid>
          </Box>
        )}
      </Box>

      <Modal
        isOpen={modal}
        onClose={handleClose}
        motionPreset="slideInBottom"
        isCentered
        size={'lg'}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        {attribut.mode === 'detail' ? <DetailModalContent /> : ''}
        {attribut.mode === 'add' ? (
          <ModalContent>
            <ModalHeader>Add Penalty</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack align={'start'} my={2}>
                <Text fontSize={'xs'} color={'gray'}>
                  Name
                </Text>
                <Select
                  placeholder="Select Employee"
                  color={'gray'}
                  onChange={(e) =>
                    setDataPost({ ...dataPost, user_uid: e.target.value })
                  }
                >
                  {users
                    ?.sort()
                    ?.filter((x) => x?.name !== undefined)
                    .map((x, i) => {
                      return (
                        <option key={i} value={x?.id}>
                          {x?.name !== '' ? x?.name : x?.email}
                        </option>
                      );
                    })}
                </Select>
              </VStack>
              <VStack align={'start'} my={2}>
                <Text fontSize={'xs'} color={'gray'}>
                  Penalty
                </Text>
                <Select
                  placeholder="Select Penalty"
                  color={'gray'}
                  onChange={(e) =>
                    setDataPost({
                      ...dataPost,
                      penalty_category_id: parseInt(e.target.value),
                    })
                  }
                >
                  {category?.map((x, i) => (
                    <option value={x.id} key={i}>
                      {x.title}
                    </option>
                  ))}
                </Select>
              </VStack>
              <VStack align={'start'} my={2}>
                <Text fontSize={'xs'} color={'gray'}>
                  Message
                </Text>
                <Textarea
                  onChange={(e) =>
                    setDataPost({ ...dataPost, message: e.target.value })
                  }
                />
              </VStack>
              <Stack {...getRootProps()}>
                <Input name="file_CV" accept=".pdf" {...getInputProps()} />
                {loadFile === null ? (
                  <>
                    <Center
                      borderWidth="3px"
                      borderRadius="lg"
                      borderStyle={'dashed'}
                      px="6"
                      py="4"
                    >
                      <VStack spacing="3">
                        <Square size="8" bg="bg-subtle" borderRadius="lg">
                          <Icon as={MdUploadFile} boxSize="8" color="muted" />
                        </Square>
                        <VStack spacing="1">
                          <HStack spacing="1" whiteSpace="nowrap">
                            <Button
                              variant="link"
                              colorScheme="blue"
                              size="sm"
                              cursor={'pointer'}
                            >
                              Click to upload
                            </Button>
                            <Text fontSize="sm" color="muted">
                              or drag here
                            </Text>
                          </HStack>
                          <Text fontSize="2xs" color="muted">
                            PDF up to 2MB
                          </Text>
                        </VStack>
                      </VStack>
                    </Center>
                  </>
                ) : (
                  <>
                    <Center
                      borderWidth="3px"
                      borderRadius="lg"
                      borderStyle={'dashed'}
                      px="6"
                      py="4"
                      gap={3}
                    >
                      <MdFileDownloadDone size={30} />
                      <Text fontSize={'sm'} noOfLines={1}>
                        {loadFile[0]?.name}
                      </Text>
                      <MdCancel
                        size={30}
                        cursor={'pointer'}
                        onClick={() => setLoadFile(null)}
                      />
                    </Center>
                  </>
                )}
              </Stack>
            </ModalBody>
            <Divider />
            <ModalFooter gap={3}>
              <DynamicButton
                title={'Cancel'}
                action="custom"
                color={'red'}
                variant="solid"
                onClick={() => handleClose()}
              />
              <DynamicButton
                title={'Submit'}
                action="create"
                variant="solid"
                isLoading={loading}
                onClick={() => handleSubmit()}
              />
            </ModalFooter>
          </ModalContent>
        ) : (
          ''
        )}
      </Modal>

      <AlertDialogV2 isLoading={isLoading} title={'This penalty will be deleted'} isOpen={modalDelete?.isOpen} onClose={modalDelete?.onClose} onAction={handleDelete}/>
    </Stack>
  );
};

export default HrisPenaltyPage;
