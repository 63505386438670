import {
  Button,
  HStack,
  Input,
  Spacer,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import useUserStore from '../../Hooks/Zustand/Store';
import { createHris, deleteHris, readHris } from '../../Api/Hris';
import DynamicTable from '../../Components/Table/DynamicTable';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';

const HrisPenaltyCategoryPage = () => {
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [penalty, setPenalty] = useState();
  const [detail, setDetail] = useState();
  const modalDelete = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const [addPenalty, setAddPenalty] = useState({
    title: '',
    duration: 0,
  });
  const tableHeader = ['id', 'title', 'duration']

  const toast = useToast();

  const [mode, setMode] = useState({
    mode: '',
    title: '',
  });

  const globalState = useUserStore();

  const getPenaltyCategory = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris('penalty-category', session);
      setPenalty(data);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });          }
  };
  useEffect(() => {
    getPenaltyCategory();
  }, []);

  const tableData = penalty?.map((x) => {
    return {
      'id': x?.id,
      'title' : x?.title,
      'duration' : x?.duration
    }
  })

  const handleDetail = (x) => {
    setDetail(x);
    setMode({ mode: 'detail', title: 'Penalty Detail' });
    setModal(true);
  };

  const handleOpenAlert = (value) => {
    modalDelete?.onOpen()
    setSelectedCategory(value)
  }

  const handleAdd = () => {
    setMode({ mode: 'add', title: 'Add Penalty Form' });
    setModal(true);
  };

  const handleDelete = async() => {
    setIsLoading(true)
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    }

    try {
      await deleteHris(`penalty-category/${selectedCategory?.id}`, session)

      toast({
        status: 'success',
        title: 'Category deleted',
        duration: 3000
      })
    } catch (error) {
      throw new Error(error, 'An Error Occured')
    } finally {
      setIsLoading(false)
      getPenaltyCategory()
      modalDelete?.onClose()
    }
  }

  const handleSubmit = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    setLoading(true);
    try {
      await createHris(
        'penalty-category',
        session,
        addPenalty
      );
      toast({
        title: 'success',
        description: 'Success create penalty category',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });          } finally {
      setModal(false);
      getPenaltyCategory();
      setLoading(false);
    }
  };

  // console.log(globalState);

  // const handleEdit = async () => {
  //   setLoading(true);

  //   const session = {
  //     headers: globalState?.authFirebaseToken,
  //     companyId: globalState?.currentCompany,
  //     projectId: globalState?.currentProject,
  //   };

  //   try {
  //     const { data } = await patchHris(
  //       `penalty-category/${detail?.id}/update`,
  //       session,
  //       { title: detail?.title, duration: detail?.duration }
  //     );

  //     console.log(data);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setModal(false);
  //     getPenaltyCategory();
  //     setLoading(false);
  //   }
  // };

  return (
    <Stack>
      <HStack my={5} flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Spacer />
        <DynamicButton onClick={handleAdd} title={'Pernalty Category'} action={'create'}/>
        
      </HStack>

      <DynamicTable header={tableHeader} data={tableData} onRead={handleDetail} onDelete={handleOpenAlert}/>

      {mode.mode === 'detail' ? (
        <Modal
          isOpen={modal}
          onClose={() => setModal(false)}
          motionPreset="slideInBottom"
          isCentered
          size={'xl'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{mode.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack justify={'space-between'} align={'flex-start'} my={2}>
                <Text>Penalty Type</Text>
                <Input
                  isDisabled
                  defaultValue={detail?.title}
                  onChange={(e) =>
                    setDetail({ ...detail, title: e.target.value })
                  }
                />
              </VStack>
              <VStack justify={'space-between'} align={'flex-start'} my={2}>
                <Text>Duration (month)</Text>
                <Input
                  isDisabled
                  type="number"
                  defaultValue={detail?.duration}
                  onChange={(e) =>
                    setDetail({
                      ...detail,
                      duration: parseFloat(e.target.value),
                    })
                  }
                />
              </VStack>
            </ModalBody>
            {/* <ModalFooter gap={5}>
              <Button colorScheme="red" onClick={() => setModal(false)}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                colorScheme="green"
                onClick={() => handleEdit()}
              >
                Update
              </Button>
            </ModalFooter> */}
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          isOpen={modal}
          onClose={() => setModal(false)}
          motionPreset="slideInBottom"
          isCentered
          size={'xl'}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{mode.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack my={3}>
                <Text fontSize={'sm'}>Penalty Type</Text>
                <Input
                  placeholder="Penalty Type"
                  onChange={(e) =>
                    setAddPenalty({ ...addPenalty, title: e.target.value })
                  }
                />
              </Stack>
              <Stack my={3}>
                <Text fontSize={'sm'}>Duration</Text>
                <Input
                  type="number"
                  placeholder="Input Duration"
                  onChange={(e) =>
                    setAddPenalty({
                      ...addPenalty,
                      duration: parseFloat(e.target.value),
                    })
                  }
                />
              </Stack>
            </ModalBody>
            <ModalFooter gap={5}>
              <Button colorScheme="red" onClick={() => setModal(false)}>
                Cancel
              </Button>
              <Button
                isLoading={loading}
                colorScheme="green"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <AlertDialogV2 isLoading={isLoading} title={'Delete this category'} onClose={modalDelete.onClose} isOpen={modalDelete?.isOpen} onAction={handleDelete} />
    </Stack>
  );
};

export default HrisPenaltyCategoryPage;
