import React from 'react';
import HomePage from '../Pages/Home/HomePage';

const HomeRouter = [
  {
    path: '/',
    element: <HomePage />
  },

];

export default HomeRouter;
