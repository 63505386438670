/* eslint-disable react/prop-types */
import { Button, Center, FormControl, HStack, Icon, Input, Square, Stack, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { MdCancel, MdFileDownloadDone, MdUploadFile } from 'react-icons/md'

const MediaUpload = ({ loadFile, getRootProps, getInputProps, handleCancel }) => {
  
  return (
    <FormControl id="file">
      <Stack {...getRootProps()}>
        <Input name="file_CV" accept=".pdf" {...getInputProps()} />
        {loadFile === null ? (
          <>
            <Center
              borderWidth="3px"
              borderRadius="lg"
              borderStyle={'dashed'}
              px="6"
              py="4"
            >
              <VStack spacing="3">
                <Square size="8" bg="bg-subtle" borderRadius="lg">
                  <Icon as={MdUploadFile} boxSize="8" color="muted" />
                </Square>
                <VStack spacing="1">
                  <HStack spacing="1" whiteSpace="nowrap">
                    <Button
                      variant="link"
                      colorScheme="blue"
                      size="sm"
                      cursor={'pointer'}
                    >
                     Click to upload
                    </Button>
                    <Text fontSize="sm" color="muted">
                      or drag here
                    </Text>
                  </HStack>
                  <Text fontSize="xs" color="muted">
                    PDF or Image up to 2MB
                  </Text>
                </VStack>
              </VStack>
            </Center>
          </>
        ) : (
          <>
            <Center
              borderWidth="3px"
              borderRadius="lg"
              borderStyle={'dashed'}
              px="6"
              py="4"
              gap={3}
            >
              <MdFileDownloadDone size={30} />
              <Text fontSize={'sm'} noOfLines={1}>
                {loadFile[0]?.name}
              </Text>
              <MdCancel
                size={30}
                cursor={'pointer'}
                onClick={handleCancel}
              />
            </Center>
          </>
        )}
      </Stack>
    </FormControl>
  )
}

export default MediaUpload