import React, {  } from 'react';
import MainMenuComponent from '../../Components/Menus/MainMenuComponent';

const RecruitmentHome = () => {

  return (
    <MainMenuComponent menuName="recruitment" />
  );
};

export default RecruitmentHome;
