import React, {  } from 'react';
import MainMenuComponent from '../../Components/Menus/MainMenuComponent';

const SettingsHome = () => {

  return (
    <MainMenuComponent menuName="settings" />
  );
};

export default SettingsHome;
