/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-key */
import {
  Badge,
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import useUserStore from '../../Hooks/Zustand/Store';
import BackButtons from '../../Components/Buttons/BackButtons';
import moment from 'moment';
import { readHris } from '../../Api/Hris';
import { getUserByUid } from '../../Utils/getUser';
import dayjs from 'dayjs';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { FaRegCalendar } from 'react-icons/fa';
import DatePicker from '../../Components/DatePicker/DatePicker';
import ExportExcel from '../../Utils/ExportExcel';

const HrisAttandanceUserPage = () => {
  const param = useParams();
  const globalState = useUserStore();
  const [attendanceUser, setAttendanceUser] = useState([]);
  const { state } = useLocation();
  const [selectedDate, setSelectedDate] = useState();
  const [dataForExport, setDataForExport] = useState();
  const modalFilterDate = useDisclosure();
  const [initialDate] = useState(dayjs().format('YYYY-MM-DD'));

  const userData = globalState?.users?.find((x) => x?.id === param.id);

  const calendarMonth = moment(initialDate).format('MM');
  const calendarYear = moment(initialDate).format('YYYY');

  const firstDayOfMonth = moment().set({
    year: calendarYear,
    month: calendarMonth - 1,
    date: 1,
  });

  const lastDayOfMonth = moment(firstDayOfMonth)
    .add(1, 'months')
    .subtract(1, 'days');

  const handleDateRange = (value) => {
    const datePick = {
      startDate: moment(value?.startDate).format('YYYY-MM-DD'),
      endDate: moment(value?.endDate).format('YYYY-MM-DD'),
    };

    setSelectedDate(datePick);
  };

  const getDataAttendance = async () => {
    globalState.setIsLoading(true);
    const session = {
      headers: globalState?.authFirebaseToken,
    };

    try {
      if (selectedDate === undefined) {
        const { data } = await readHris(
          `attendance/${param?.id}/detail?startDate=${firstDayOfMonth?.format(
            'YYYY-MM-DD'
          )}&endDate=${lastDayOfMonth?.format('YYYY-MM-DD')}&office=${state?.id
          }`,
          session
        );

        const attendanceData = data?.attendances;

        const attendanceArray = Object.entries(attendanceData).map(
          ([date, data]) => ({
            date,
            data,
          })
        );

        handleMergeData(attendanceArray);
      } else {
        const { data } = await readHris(
          `attendance/${param?.id}/detail?startDate=${selectedDate?.startDate}&endDate=${selectedDate?.endDate}&office=${state?.id}`,
          session
        );

        const attendanceData = data?.attendances;

        const attendanceArray = Object.entries(attendanceData).map(
          ([date, data]) => ({
            date,
            data,
          })
        );


        handleMergeData(attendanceArray);
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      globalState.setIsLoading(false);
      modalFilterDate.onClose();
    }
  };

  const handleMergeData = async (data) => {
    const mergedData = [];
    const dataExport = [];

    for (const x of data) {
      const uidUser = x?.data?.user_uid;

      const userData = await getUserByUid(globalState?.users, uidUser);

      if (userData) {
        const mergedObject = { ...x, data: { ...x.data, user_data: userData } };

        mergedData.push(mergedObject);
      } else {
        mergedData.push(x);
      }
    }

    if (mergedData?.length > 0) {
      setAttendanceUser(mergedData);

      dataExport.push(
        ...mergedData.map((x) => {
          return {
            name: userData?.name,
            email: userData?.email,
            date: x?.date,
            office_start_hour: state?.startHour,
            office_end_hour: state?.endHour,
            clock_in:
              x?.data !== null
                ? moment
                  ?.parseZone(x?.data?.clock_in || 0)
                  ?.utcOffset(x?.data?.gmt_offset_in || 0)
                  ?.format('HH:mm:ss') ?? '--:--:--'
                : '--:--:--',
            clock_out:
              x?.data !== null
                ? moment
                  ?.parseZone(x?.data?.clock_out || 0)
                  ?.utcOffset(x?.data?.gmt_offset_in || 0)
                  ?.format('HH:mm:ss') ?? '--:--:--'
                : '--:--:--',
            status:
              x?.data !== null
                ? state?.startHour <
                  moment
                    ?.parseZone(x?.data?.clock_in || 0)
                    ?.utcOffset(x?.data?.gmt_offset_in || 0)
                    ?.format('HH:mm') &&
                  state?.endHour >
                  moment
                    ?.parseZone(x?.data?.clock_in || 0)
                    ?.utcOffset(x?.data?.gmt_offset_in || 0)
                    ?.format('HH:mm')
                  ? 'LATE'
                  : 'ON TIME'
                : '-',
          };
        })
      );
    }

    setDataForExport(dataExport);
  };

  useEffect(() => {
    getDataAttendance();

    return () => { };
  }, []);

  return (
    <Box>
      <HStack>
        <BackButtons />
        <Spacer />
        <Heading textTransform={'capitalize'} size={'md'}>
          {userData?.name}s Attendance Report
        </Heading>
      </HStack>
      <Stack bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'} my={4}>
        <HStack>
          <DynamicButton
            onClick={modalFilterDate.onOpen}
            title={'Filter Date'}
            icon={FaRegCalendar}
            color={'blue'}
            action={'custom'}
          />
          <Spacer />
          <ExportExcel
            excelData={dataForExport}
            fileName={'Attendance Report'}
            colorScheme={'orange'}
            size={'md'}
            variant={'outline'}
          />
        </HStack>

        <HStack pb={1}>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            Date Range:
          </Text>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            {selectedDate === undefined
              ? firstDayOfMonth.format('DD-MM-YYYY')
              : moment.unix(selectedDate?.startDate).format('DD-MM-YYYY')}
          </Text>
          <Text color={'gray.500'}> to </Text>
          <Text fontSize={13} fontWeight={'semibold'} color={'gray.500'}>
            {selectedDate === undefined
              ? lastDayOfMonth.format('DD-MM-YYYY')
              : moment.unix(selectedDate?.endDate).format('DD-MM-YYYY')}
          </Text>
        </HStack>

        {attendanceUser?.length > 0 ? (
          <TableContainer
            p={3}
            borderRadius={'md'}
            border={'1px'}
            borderColor={'gray.50'}
            boxShadow={'base'}
          >
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Date</Th>
                  <Th>Office Start Hour</Th>
                  <Th>Office End Hour</Th>
                  <Th>Attendance Clock In</Th>
                  <Th>Attendance Clock Out</Th>
                  {/* <Th>Correction</Th> */}
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {attendanceUser?.map((user, i) => (
                  <Tr fontSize="xs" key={i}>
                    <Td textTransform={'capitalize'}>
                      {user?.data !== null
                        ? user?.data?.user_data?.name
                        : userData?.name}
                    </Td>
                    <Td>{moment(user?.date)?.format('DD-MM-YYYY')}</Td>
                    <Td>{state?.startHour}</Td>
                    <Td fontWeight="semibold" textAlign="right">
                      {state?.endHour}
                    </Td>
                    <Td fontWeight="semibold" textAlign="right" color={user?.data !== null
                      ? moment
                        ?.parseZone(user?.data?.clock_in || 0)
                        ?.utcOffset(user?.data?.gmt_offset_in || 0)
                        ?.format('HH:mm:ss') !== 'Invalid date' ? 'black' : 'red'
                      : 'black'
                    }>
                      {user?.data !== null
                        ? moment
                          ?.parseZone(user?.data?.clock_in || 0)
                          ?.utcOffset(user?.data?.gmt_offset_in || 0)
                          ?.format('HH:mm:ss') !== 'Invalid date' ? moment
                            ?.parseZone(user?.data?.clock_in || 0)
                            ?.utcOffset(user?.data?.gmt_offset_in || 0)
                            ?.format('HH:mm:ss') : 'absent'
                        : '--:--:--'
                      }
                    </Td>
                    <Td fontWeight="semibold" textAlign="right" color={user?.data !== null
                      ? moment
                        ?.parseZone(user?.data?.clock_out || 0)
                        ?.utcOffset(user?.data?.gmt_offset_in || 0)
                        ?.format('HH:mm:ss') !== 'Invalid date' ? 'black' : 'red'
                      : 'black'
                    }>
                      {user?.data !== null
                        ? moment
                          ?.parseZone(user?.data?.clock_out || 0)
                          ?.utcOffset(user?.data?.gmt_offset_in || 0)
                          ?.format('HH:mm:ss') !== 'Invalid date' ? moment
                            ?.parseZone(user?.data?.clock_out || 0)
                            ?.utcOffset(user?.data?.gmt_offset_in || 0)
                            ?.format('HH:mm:ss') : 'absent'
                        : '--:--:--'
                      }
                    </Td>
                    <Td fontWeight="semibold" textAlign="right">
                      {user?.data !== null ? (
                        state?.startHour <
                          moment
                            ?.parseZone(user?.data?.clock_in || 0)
                            ?.utcOffset(user?.data?.gmt_offset_in || 0)
                            ?.format('HH:mm') &&
                          state?.endHour >
                          moment
                            ?.parseZone(user?.data?.clock_in || 0)
                            ?.utcOffset(user?.data?.gmt_offset_in || 0)
                            ?.format('HH:mm') ? (
                            <Badge colorScheme="red">LATE</Badge>
                          ) : (
                            <Badge colorScheme="green">ON TIME</Badge>
                          )
                      ) : (
                        '-'
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        ) : (
          <Center>
            <Text fontWeight={'semibold'} color={'gray.500'}>
              No Attendance Data
            </Text>
          </Center>
        )}
      </Stack>

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRange} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>Start Date: </Text>
                  <Text>{moment(selectedDate?.startDate).format('LLL')}</Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>End Date:</Text>
                  <Text>{moment(selectedDate?.endDate).format('LLL')}</Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={'xs'}
                colorScheme="red"
                onClick={() => setSelectedDate()}
              >
                Clear Filter
              </Button>
              <Button
                size={'xs'}
                colorScheme="green"
                onClick={getDataAttendance}
              >
                Confirm
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default HrisAttandanceUserPage;
