import {
  Badge,
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  getCollectionFirebase,
  updateDocumentFirebase,
} from '../../Api/firebaseApi';
import DynamicTable from '../../Components/Table/DynamicTable';
import useUserStore from '../../Hooks/Zustand/Store';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { getUserByUid } from '../../Utils/getUser';
import { getProjectUsers} from '../../Utils/getProjectUsers';

const HrisOvertimePage = () => {
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState();
  const [dataOvertime, setDataOvertime] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingV2, setLoadingV2] = useState(false);
  const [originData, setOriginData] = useState([]);
  const [sort, setSort] = useState('desc');
  const [userId, setUserId] = useState('');
  const globalState = useUserStore();

  const tableHeader = ['employee', 'date', 'duration', 'status', 'createdAt'];

  const getOvertime = async () => {
    globalState?.setIsLoading(true);

    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
      {
        field: 'projectId',
        operator: '==',
        value: globalState?.currentProject,
      },
    ];

    if (userId !== '') conditions.push({
      field: 'user_uid',
      operator: '==',
      value: userId
    })
    const sortBy = { field: 'periode', direction: sort };

    try {
      // const { data } = await readHris("overtime", session);
      const data = await getCollectionFirebase('overtimes', conditions, sortBy);

      const userProm = data?.map(async (x) => {
        const uid = x?.user_uid;
        // const addUser = user?.find((p) => p.uid === uid);
        const addUser = await getUserByUid(globalState?.users, uid);
        if (addUser) {
          x.user_data = addUser;
        }
        return x;
      });
      const addDataUser = await Promise.all(userProm);
      setDataOvertime(addDataUser);
      setOriginData(addDataUser);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      globalState?.setIsLoading(false);
    }
  };

  const getFilterStatus = (value) => {
    // setFilteredData(originData);

    if (value === '') {
      setDataOvertime(originData);
    } else {
      const filterArr = originData.filter((x) => x.current_status === value);
      setDataOvertime(filterArr);
    }

    if (value === 'Requested') {
      const filterArr = originData.filter((x) => x.current_status === null);
      setDataOvertime(filterArr);
    }
  };

  let tableData = '';
  tableData = dataOvertime?.map((data) => {
    // console.log(data, "ini data lohhh");

    const employee = data?.user_data?.name || '';
    const date = data?.periode;
    const duration = `${data?.start_hour} || ${data?.end_hour}`;
    const created = moment
      .parseZone((data?.createdAt?.seconds || 0) * 1000)
      .local()
      .format('DD MMMM YYYY - HH:mm');
    const status = data?.current_status;

    return {
      data,
      employee: employee,
      date: date,
      duration: duration,
      status:
        status === 'APPROVE MANAGER' ? (
          <Badge borderRadius={'md'} colorScheme="blue">
            PROCESSED
          </Badge>
        ) : status === 'APPROVE HR' ? (
          <Badge borderRadius={'md'} colorScheme="green">
            APPROVED
          </Badge>
        ) : status === 'REJECT MANAGER' ? (
          <Badge borderRadius={'md'} colorScheme="red">
            REJECTED
          </Badge>
        ) : status === 'REJECT HR' ? (
          <Badge borderRadius={'md'} colorScheme="red">
            REJECTED
          </Badge>
        ) : status === null ? (
          <Badge borderRadius={'md'} colorScheme="yellow">
            REQUESTED
          </Badge>
        ) : null,
      createdAt: created,
    };
  });

  const handleReject = async (detail) => {
    setLoadingV2(true);
    try {
      if (
        globalState.roleCompany === 'owner' ||
        globalState?.roleProject === 'hr') {
        await updateDocumentFirebase(
          'overtimes',
          detail?.data?.id,
          {
            current_status: 'REJECT HR',
          }
        );

      } else if (
        globalState.roleCompany === 'owner' ||
        globalState?.roleProject === 'manager') {
        await updateDocumentFirebase(
          'overtimes',
          detail?.data?.id,
          {
            current_status: 'REJECT MANAGER',
          }
        );

      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      setModal(false);
      getOvertime([]);
      setLoadingV2(false);
    }
  };
  const handleApprove = async (detail) => {
    setLoading(true);

    try {
      if (
        globalState.roleCompany === 'owner' ||
        globalState?.roleProject === 'hr') {

        await updateDocumentFirebase(
          'overtimes',
          detail?.data?.id,
          {
            current_status: 'APPROVE HR',
          }
        );
      } else if (
        globalState.roleCompany === 'owner' ||
        globalState?.roleProject === 'manager') {
        await updateDocumentFirebase('overtimes', detail?.data?.id, {
          current_status: 'APPROVE MANAGER',
        });
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {
      setModal(false);
      getOvertime();
      setLoading(false);
    }
  };

  const handleRead = (row) => {
    handleDetail(row);
  };

  useEffect(() => {
    getOvertime();

    return () => {
      setDataOvertime([]);
      setOriginData([]);
    };
  }, [globalState?.currentProject, globalState?.currentCompany, sort, userId]);

  const handleDetail = (x) => {
    setDetail(x);
    setModal(true);
  };
  return (
    <Stack>
      <Box py={2}>
        <BackButtons />
      </Box>
      <Card w={'full'} backgroundColor={'white'} shadow={'md'}>
        <CardHeader mt={5}>
          <HStack
            flexDirection={['column', 'row', 'row']}
            justify={'space-between'}
          >
            <Stack>
              <Text fontSize={'lg'} fontWeight={'medium'}>
                Overtime Request
              </Text>
              <Select
                onChange={(e) => setUserId(e.target.value)}
                placeholder='Select user/employee'
              >
                {getProjectUsers(
                  globalState.projects,
                  globalState.currentProject,
                  globalState.users)
                  .map((user, i) => {
                    return <option value={user.id} key={i}>{user.name || user.email}</option>
                  })}
              </Select>
            </Stack>
            <HStack>
              <Text w={'50%'} color={'gray'}>Sorting:</Text>
              <Select
                onChange={(e) => setSort(e.target.value)}
              >
                <option value={'desc'}>Paling Baru</option>
                <option value={'asc'}>Paling Lama</option>
              </Select>
            </HStack>
            <HStack w={['100%', '30%', '30%']}>
              <Text w={'50%'} color={'gray'}>
                Filter Status
              </Text>
              <Select
                onChange={(e) => getFilterStatus(e.target.value)}
                placeholder="select status"
              // color={"lightgray"}
              >
                <option value={'Requested'}>Requested</option>
                <option value={'APPROVE MANAGER'}>Processed</option>
                <option value={'APPROVE HR'}>Approved</option>
                <option value={'REJECT MANAGER' || 'REJECT HR'}>
                  Rejected
                </option>
              </Select>
            </HStack>
          </HStack>
        </CardHeader>

        <CardBody>
          <DynamicTable
            header={tableHeader}
            data={tableData}
            onRead={handleRead}
            action_color="green"
          // action_icon={FaWhatsapp}
          />
        </CardBody>
      </Card>

      <Modal
        isOpen={modal}
        onClose={() => setModal(false)}
        motionPreset="slideInBottom"
        isCentered
        size={'lg'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Leave Request Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack justify={'space-between'} my={2}>
              <Text>Employee</Text>
              <Text>{detail?.employee}</Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Duration</Text>
              <Text>{detail?.duration}</Text>
            </HStack>

            <HStack justify={'space-between'} my={2}>
              <Text>Status</Text>
              <Text>
                {detail?.data?.current_status === 'APPROVE MANAGER' ? (
                  <Badge borderRadius={'md'} colorScheme="blue">
                    PROCESSED
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'APPROVE HR' ? (
                  <Badge borderRadius={'md'} colorScheme="green">
                    APPROVED
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'REJECT MANAGER' ? (
                  <Badge borderRadius={'md'} colorScheme="red">
                    REJECTED
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === 'REJECT HR' ? (
                  <Badge borderRadius={'md'} colorScheme="red">
                    REJECTED
                  </Badge>
                ) : (
                  ''
                )}
                {detail?.data?.current_status === null ? (
                  <Badge borderRadius={'md'} colorScheme="yellow">
                    REQUESTED
                  </Badge>
                ) : (
                  ''
                )}
              </Text>
            </HStack>
            <HStack justify={'space-between'} my={2}>
              <Text>Created At</Text>
              <Text>{detail?.createdAt}</Text>
            </HStack>
          </ModalBody>
          <Divider />

          <ModalFooter>
            <HStack>
              {globalState?.roleCompany === 'owner' ||
                globalState?.role?.name === 'hr' ||
                globalState?.role?.title === 'hr' ? (
                  <>
                    {detail?.data?.current_status !== 'APPROVE HR' ? (
                      <>
                        <DynamicButton
                          action="custom"
                          title="Reject"
                          color={'red'}
                          isLoading={loadingV2}
                          variant={'solid'}
                          onClick={() => handleReject(detail)}
                        />
                        <DynamicButton
                          action="custom"
                          title="Approve"
                          color={'green'}
                          isLoading={loading}
                          variant={'solid'}
                          onClick={() => handleApprove(detail)}
                        />
                      </>
                    ) : (
                      <Stack spacing={2}>
                        {detail?.data?.current_status === null && (
                          <Text fontSize={12}>
                          The manager still not approved, this request
                          </Text>
                        )}
                        <HStack spacing={1} justify={'right'}>
                          <DynamicButton
                            action="custom"
                            title="Reject"
                            color={'red'}
                            variant={'solid'}
                            onClick={() => handleReject(detail)}
                            disabled={true}
                          />
                          <DynamicButton
                            action="custom"
                            title="Approve"
                            color={'green'}
                            variant={'solid'}
                            onClick={() => handleApprove(detail)}
                            disabled={true}
                          />
                        </HStack>
                      </Stack>
                    )}
                  </>
                ) : globalState?.roleCompany === 'owner' ||
                globalState?.role?.name === 'manager' ||
                globalState?.role?.title === 'manager' ? (
                    <>
                      {detail?.data?.current_status === null ||
                    globalState?.roleCompany === 'owner' ? (
                          <>
                            <DynamicButton
                              action="custom"
                              title="Reject"
                              color={'red'}
                              variant={'solid'}
                              isLoading={loadingV2}
                              onClick={() => handleReject(detail)}
                            />
                            <DynamicButton
                              action="custom"
                              title="Approve"
                              color={'green'}
                              variant={'solid'}
                              isLoading={loading}
                              onClick={() => handleApprove(detail)}
                            />
                          </>
                        ) : detail?.data?.current_status === 'APPROVE MANAGER' &&
                    (globalState?.role?.name === 'manager' ||
                      globalState?.role?.title === 'manager') ? (
                            <Stack spacing={2}>
                              {detail?.data?.current_status === 'APPROVE MANAGER' && (
                                <Text fontSize={12}>
                          Waiting HR or Company Owner to approve this request
                                </Text>
                              )}
                              <HStack spacing={1} justify={'right'}>
                                <DynamicButton
                                  action="custom"
                                  title="Reject"
                                  color={'red'}
                                  variant={'solid'}
                                  onClick={() => handleReject(detail)}
                                  disabled={true}
                                />
                                <DynamicButton
                                  action="custom"
                                  title="Approve"
                                  color={'green'}
                                  variant={'solid'}
                                  onClick={() => handleApprove(detail)}
                                  disabled={true}
                                />
                              </HStack>
                            </Stack>
                          ) : null}
                    </>
                  ) : null}
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default HrisOvertimePage;