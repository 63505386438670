import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import DynamicTable from '../../Components/Table/DynamicTable';
import { formatFrice } from '../../Utils/numberUtil';
import { clientTypessense } from '../../Api/Typesense';
import useUserStore from '../../Hooks/Zustand/Store';
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2';
import moment from 'moment';

import {
  payrollCreate,
  payrollDelete,
  payrollRead,
  payrollUpdate,
} from '../../Api/PayrollApi';
import { getUserByUid } from '../../Utils/getUser';

const HrisSalaryPage = () => {
  const globalState = useUserStore();
  const [userSalary, setUserSalary] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [mode, setMode] = useState('');
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);

  const [createData, setCreateData] = useState({});
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  const toast = useToast();

  const tableHeader = ['name', 'email', 'amount salary', 'start', 'expire'];

  const getDataAllowance = async () => {
    setLoading(true);
    const dataApi = {
      companyId: globalState.currentCompany,
      category: 'salary', // salary, allowance, deduction, loans
      tokenId: globalState.authFirebaseToken,
    };
    try {
      const res = await payrollRead(dataApi);
      setUserSalary(res.data);
      if (res.status == false) throw new Error(res.message);
    } catch (error) {
      toast({
        status: 'error',
        description: error + ', try to logout and relogin',
        duration: 2000,
      });
      // throw new Error(error.message, 'Failed to send  error message');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getDataAllowance();

    return () => {
      setUserSalary([]);
    };
  }, [globalState.currentCompany]);

  const tableData = userSalary?.map((x) => {
    const userData = getUserByUid(globalState?.users, x.uid);
    const name = userData?.name;
    const email = userData?.email;
    const amount = formatFrice(x.amount);
    const start =
      new Date(x.timeFrom).toLocaleDateString('id-ID', options) || '-';
    const end = new Date(x.timeTo).toLocaleDateString('id-ID', options) || '-';
    return {
      x,
      name: name,
      email: email,
      'amount salary': amount,
      start: start,
      expire: end,
    };
  });

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        throw new Error(error.message, 'Failed to send  error message');

      });
  };
  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };

  const handleCreate = async () => {
    try {
      setLoading(true);
      selectedUserProjectIds?.map(async (x) => {
        try {
          const dataApi = {
            uid: x.id,
            companyId: globalState.currentCompany,
            timeFrom: moment(createData.timeFrom).valueOf(),
            timeTo: moment(createData.timeTo).valueOf(),
            category: 'salary', // salary, allowance, deduction, loans
            categoryId: `${x.id}-${moment(new Date()).valueOf()}`,
            tokenId: globalState.authFirebaseToken,
            amount: parseInt(createData.amount),
          };
          const res = await payrollCreate(dataApi);
          if (res.status === true) {
            getDataAllowance();
            toast({
              status: 'success',
              description: 'Add new Team success',
              duration: 2000,
            });
          }
        } catch (error) {
          throw new Error(error.message, 'Failed to send  error message');
        }
      });
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    } finally {
      setMode('');
      setSelectedUserProjectIds([]);
      setCreateData({});
      setLoading(false);
      setModal(false);
    }
  };

  const handleAdd = (x) => {
    setDetail(x);
    setMode('add');
    setModal(true);
  };

  const handleEdit = (x) => {
    setDetail(x);
    setMode('detail');
    setModal(true);
  };

  const handleUpdate = async () => {
    // setLoading(true);
    const dataApi = {
      uid: detail?.x.uid,
      companyId: globalState.currentCompany,
      timeFrom: detail.x?.timeFrom,
      timeTo: detail.x?.timeTo,
      category: 'salary', // salary, allowance, deduction, loans
      // categoryId: `${id}`,
      tokenId: globalState.authFirebaseToken,
      amount: parseInt(detail.amount || detail.x.amount),
      mongoId: detail?.x?._id,
    };
    // return  console.log(dataApi, 'dataApi')
    try {
      const res = await payrollUpdate(dataApi);
      if (res.status === false) {
        toast({
          status: 'error',
          description: res.message,
          duration: 9000,
          isClosable: true
        })
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    } finally {
      setLoading(false);
      setDetail({});
      setMode('');
      setModal(false);
      getDataAllowance();
    }
  };
  const onClose = () => {
    setMode('');
    setDetail({});
    // setDataDefault(false)
    setModal(false);
  };
  const handleDelete = (x) => {
    setMode('delete');
    setDetail(x);
    setAlert(true);
  };

  const onDelete = async () => {
    setLoading(true);
    const dataApi = {
      mongoId: detail?.x?._id,
      tokenId: globalState.authFirebaseToken,
      companyId: globalState.currentCompany,
    };
    try {
      // const del = await deleteDocumentFirebase('payroll_allowance_users', detail?.x?.id)
      await payrollDelete(dataApi);
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');

    } finally {
      getDataAllowance();
      setMode('');
      setDetail({});
      setLoading(false);
      setAlert(false);
    }
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size="md">Salary</Heading>
        <Spacer />
        <DynamicButton
          action={'create'}
          title={'Add Employee'}
          variant={'solid'}
          onClick={() => handleAdd()}
        />
      </HStack>

      {loading ? (
        <Stack p={8}>
          <Center>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="rgba(54, 162, 235, 0.6)"
              size="xl"
            />
          </Center>
        </Stack>
      ) : (
        <Box>
          <DynamicTable
            header={tableHeader}
            data={tableData}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Box>
      )}

      <Modal
        isOpen={modal}
        onClose={() => onClose()}
        motionPreset="slideInBottom"
        size={'2xl'}
      >
        <ModalOverlay />
        {mode === 'detail' ? (
          <ModalContent>
            <ModalHeader>
              <Heading size='md'>{detail.name}</Heading>
              <Text fontSize={10} color='gray'>ID: {detail.x._id}</Text>
            </ModalHeader>

            <ModalCloseButton />
            <ModalBody>

              <Stack justify={'space-between'} my={2}>
                <Heading fontSize={'md'}>Amount</Heading>
                <Input type='number' defaultValue={detail?.x?.amount || 0} onChange={(e) => { setDetail({ ...detail, amount: e.target.value }) }} />
              </Stack>
              <Center>
                <Heading fontSize={'md'} p={2}>
                  Validity Period
                </Heading>
              </Center>
              <HStack pt={5}>
                <Stack w={'50%'}>
                  <Text fontSize={'md'}>Start From</Text>
                  <Input
                    type="date"
                    defaultValue={
                      detail?.x?.timeFrom
                        ? moment(detail.x.timeFrom).utcOffset(7).format('YYYY-MM-DD') :
                        moment().format('YYYY-MM-DD')
                    }
                    onChange={(e) => {
                      setDetail({
                        ...detail,
                        x: {
                          ...detail.x,
                          timeFrom: e.target.value
                        }
                      })
                      // detail.start = e.target.value;
                    }}
                  />
                </Stack>
                <Stack w={'50%'}>
                  <Text fontSize={'md'}>Until</Text>
                  <Input
                    type="date"
                    defaultValue={
                      detail?.x?.timeTo
                        ? moment(detail.x.timeTo).utcOffset(7).format('YYYY-MM-DD') :
                        moment().format('YYYY-MM-DD')
                    }
                    onChange={(e) => {
                      // detail.end = e.target.value;
                      setDetail({
                        ...detail,
                        x: {
                          ...detail.x,
                          timeTo: e.target.value
                        }
                      })
                      // detail.start = e.target.value;
                    }}
                  />
                </Stack>
              </HStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button colorScheme="orange" onClick={onClose}>
                  Tutup
                </Button>
                <Button colorScheme="green" onClick={() => handleUpdate()}>
                  Submit
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        ) : (
          <ModalContent>
            <ModalHeader>Add Employees Salary</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack>
                <Stack w={'50%'}>
                  <Text>Time from</Text>
                  <Input
                    type={'date'}
                    onChange={(e) =>
                      setCreateData({ ...createData, timeFrom: e.target.value })
                    }
                  />
                </Stack>
                <Stack w={'50%'}>
                  <Text>Time To</Text>
                  <Input
                    type={'date'}
                    onChange={(e) =>
                      setCreateData({ ...createData, timeTo: e.target.value })
                    }
                  />
                </Stack>
              </HStack>
              <Stack my={2}>
                <Text fontSize={'md'}>Amount Salary</Text>
                <Input
                  placeholder="input amount Salary"
                  type="number"
                  onChange={(e) =>
                    setCreateData({ ...createData, amount: e.target.value })
                  }
                />
              </Stack>
              <Stack my={2}>
                <Text fontSize={'md'}>Select Employee</Text>
                <Input
                  type="text"
                  placeholder="Search users"
                  onChange={(e) => handleSearchUsers(e.target.value)}
                />
              </Stack>
              <Stack>
                <Stack
                  h={300}
                  overflowY={'auto'}
                  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '3',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '6',
                      h: '5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.200',
                    },
                  }}
                >
                  {searchResult.length > 0 ? (
                    searchResult.map((x, index) => {
                      return (
                        <HStack key={index} p="2" borderBottom="1px">
                          <Avatar
                            name={x.document.name}
                            src={x.document.image ? x.document.image : ''}
                          />
                          <Box>
                            <Text>{x.document.name}</Text>
                            <Text>{x.document.email}</Text>
                          </Box>
                          <Spacer />
                          <Button
                            colorScheme="green"
                            onClick={() => handleUserProjectClick(x.document)}
                          >
                            +
                          </Button>
                        </HStack>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <AvatarGroup size="sm" gap="1" max={4}>
                {selectedUserProjectIds.length > 0 &&
                  selectedUserProjectIds.map((x, i) => (
                    <Avatar key={i} name={x?.name} />
                  ))}
              </AvatarGroup>
              <HStack>
                <Button colorScheme="red" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  colorScheme="green"
                  onClick={() => handleCreate()}
                  isLoading={loading}
                >
                  Add
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>

      <AlertDialogV2
        isOpen={alert}
        onClose={() => setAlert(false)}
        title={`Delete ${detail?.name}`}
        onAction={onDelete}
        isLoading={loading}
      />
    </Stack>
  );
};

export default HrisSalaryPage;
