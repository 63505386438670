import { Box, Button, Center, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import DynamicTable from '../../Components/Table/DynamicTable'
import { useNavigate } from 'react-router-dom'
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebaseV4, updateDocumentFirebase } from '../../Api/firebaseApi'
import useUserStore from '../../Hooks/Zustand/Store'
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2'
import { payrollDelete } from '../../Api/PayrollApi'

const HrisAllowancePage = () => {
  const [modal, setModal] = useState(false)
  const [mode, setMode] = useState('')
  const navigate = useNavigate()
  const [alert, setAlert] = useState(false)
  const [detail, setDetail] = useState({})
  const [loading, setLoading] = useState(false)
  const [newAllowance, setNewAllowance] = useState('')
  const tableHeader = ['type']
  const globalState = useUserStore()
    
  const [allowance, setAllowance] = useState(null)

  const getAllowance = async () => {
    setLoading(true)
    const conditions = [
      {field: 'companyId', operator: '==', value: globalState.currentCompany}
    ]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    try {
      const res = await getCollectionFirebaseV4('payroll_allowance',{conditions}, {sortBy}, {limitValue}, {startAfterData})
      setAllowance(res)
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    getAllowance()
  },[globalState.currentCompany])

  const dataTable = allowance?.map((x) => {
    const type = x.type
    return {
      x,
      'type' : type
    }
  })
  const handleDelete = (x) => {
    setMode('delete')
    setDetail(x)
    setAlert(true)
  }

  const handleEdit = (x) => {
    setDetail(x)
    setMode('detail')
    setModal(true)
  }
  
  const handleUser = (x) => {
    navigate(`/hris/payroll/allowance/${x.x.id}`)
  }

  const handleAdd = () => {
    setMode('add')
    setModal(true)
  }

  const onClose = () => {
    setMode('')
    setDetail({})
    setModal(false)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const data = {
      type : newAllowance,
      companyId : globalState.currentCompany,
      projectId : globalState.currentProject
    }
    try {
      await addDocumentFirebase('payroll_allowance', data, globalState.currentCompany) 
      getAllowance();
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    }
    setLoading(false)
    setModal(false)
    setDetail({})
    getAllowance()
  }

  const handleUpdate = async () => {
    setLoading(true)
    const data = {
      type : detail.type,
      companyId : globalState.currentCompany,
      projectId : globalState.currentProject
    }
    try {
      await updateDocumentFirebase('payroll_allowance', detail?.x?.id, data )
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');

    }
    setLoading(false)
    setDetail({})
    setMode('')
    setModal(false)
    getAllowance()
  }

  const onDelete = async () => {
    setLoading(true)
    const dataApi = {
      tokenId: globalState.authFirebaseToken,
      companyId: globalState.currentCompany,
      bulkDelete: true,
      categoryId : detail.x.id,
      mongoId : '-'
    };
    try {
      const deleteMongo = await payrollDelete(dataApi);
      if (deleteMongo.status === false) {
        throw new Error(deleteMongo.message)
      } else {
        await deleteDocumentFirebase ('payroll_allowance', detail?.x?.id)
      }

    } catch (error) {
      // throw new Error(error.message, 'Failed to send  error message');
    } finally {
      setLoading(false)
      setMode('')
      setDetail({})
      setAlert(false)
      getAllowance()
    }
  }
      
  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons/>
        <Heading size="md">Allowance</Heading>
        <Spacer/>
        <DynamicButton action={'create'} title={'Add Allowance'} variant={'solid'} onClick={() => handleAdd()}/>
      </HStack>

      {loading?
        <Stack p={8}>
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='rgba(54, 162, 235, 0.6)'
              size='xl'
            /> 
          </Center>
        </Stack>
        :
        <Box>
          <DynamicTable header={tableHeader} data={dataTable} onDelete={handleDelete} onEdit={handleEdit} onRead={handleUser}/>
        </Box>
      }
        

      <Modal
        isOpen={modal}
        onClose={() => onClose()}
        motionPreset="slideInBottom"
        size={'2xl'}
      >
        <ModalOverlay/>
        {mode === 'detail' ? 
          <ModalContent>
            <ModalHeader>Edit Allowance</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
              <Stack justify={'space-between'} my={2}>
                <Heading fontSize={'md'}>Type</Heading>
                <Input defaultValue={detail.type} onChange={(e) => {detail.type = e.target.value}} />
              </Stack>
                
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button colorScheme='orange' onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='green' onClick={() => handleUpdate()}>
                  Submit
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent> 
          : 
          <ModalContent>
            <ModalHeader>Add Allowance</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
              <Stack my={5}>
                <Text fontSize={'md'}>Type</Text>
                <Input type='text' placeholder='Add new Allowance' onChange={(e) => setNewAllowance(e.target.value)}/>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button colorScheme='red' onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='green' onClick={() => handleSubmit()}>
                  Submit
                </Button>

              </HStack>
            </ModalFooter>
          </ModalContent>
        }
      </Modal>
      <AlertDialogV2 isOpen={alert} onClose={() => setAlert(false)} title={`Delete ${detail?.type}`} onAction={onDelete}/> 
    </Stack>
  )
}

export default HrisAllowancePage