import {
  Heading,
  HStack,
  Spacer,
  Stack,
  Box, 
  Card, 
  CardHeader, 
  CardBody, 
  StackDivider,
  Text,
  SimpleGrid
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react'
import BackButtons from '../../../Components/Buttons/BackButtons';
import { useParams } from 'react-router';
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import useUserStore from '../../../Hooks/Zustand/Store';
import DynamicTable from '../../../Components/Table/DynamicTable';  

function DetailAsset() {
  const [historyAsset,setHistoryAsset] = useState([])
  const [assetData, setAssetData] = useState({})
  const param = useParams();
  const globalState = useUserStore();

  const getAssetData = async () => {
    try {
      globalState.setIsLoading(true)
      const docRef = doc(db, 'asset', param.id);
      const docSnap = await getDoc(docRef);
    
      if (docSnap.exists()) {
        const documentData = docSnap.data();
        setAssetData(documentData);
        getAssetStatusData(documentData)
      }
      globalState.setIsLoading(false)
    } catch (error) {
      // console.error('Error fetching document:', error);
    }
  }

  const getAssetStatusData = async (asset) => {
    try {
      globalState.setIsLoading(true);
            
      const assetCollection = collection(db, 'asset_lendings');
            
      const queryFilters = [];
            
      queryFilters.push(where('code', '==', asset.code));
            
      const querySnapshot = await getDocs(query(assetCollection, ...queryFilters));
            
      const data = []; 
             
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        data.push({ id: doc.id, ...docData });
      });
      setHistoryAsset(data);

      globalState.setIsLoading(false);
    } catch (error) {
      // console.error('Error querying Firestore:', error);
    }
  }

  const tableHeader = ['id', 'name', 'email', 'project', 'created']
  const tableData = historyAsset.map((data, index) => {
    const name = data.name || ''
    const email = data.email || ''
    const project = data.division || ''
    const created = data?.created_at

    return {
      data,
      id: index +1,
      name: name,
      email: email,
      project: project,
      created: created
    }
  })

  useEffect(() => {
    getAssetData()
    // getAssetStatusData()
  },[])
  return (
    <Stack p={[1, 1, 5]}>
      <BackButtons />
      <Stack spacing={4}>
        <HStack>
          <Heading size={'md'} fontWeight="bold">
            Asset Detail
          </Heading>
          <Spacer />
          <HStack>
          </HStack>
        </HStack>

        <SimpleGrid columns={2} spacing={10}>
          <Stack
            bgColor="white"
            spacing={1}
            borderRadius="xl"
            p={3}
            m={[1, 1, 5]}
            shadow="md"
          >
                    
            <Card>
              <CardHeader>
                <HStack>
                  <Heading size={'md'} fontWeight="bold">
                    Detail
                  </Heading>
                </HStack>
              </CardHeader>

              <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Code
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {assetData.code}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Brand
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {assetData.brand}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Category
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {assetData.category}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Type
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {assetData.type}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Status
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {assetData.status !== '' ? assetData.status : '-'}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Spesification
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {assetData.spesification}
                    </Text>
                  </Box>
                  <Box>
                    <Heading size='xs' textTransform='uppercase'>
                      Price
                    </Heading>
                    <Text pt='2' fontSize='sm'>
                      {assetData.price}
                    </Text>
                  </Box>
                </Stack>
              </CardBody>
            </Card>
          </Stack>

          <Stack 
            bgColor="white"
            spacing={1}
            borderRadius="xl"
            p={3}
            m={[1, 1, 5]}
            shadow="md"
          >
            <HStack>
              <Heading size={'md'} fontWeight="bold">
                History Asset
              </Heading>
            </HStack>
            <Box overflowY={'auto'}>
              <DynamicTable header={tableHeader} data={tableData}/>
            </Box>
          </Stack>    
        </SimpleGrid>
      </Stack>
    </Stack>
  )
}

export default DetailAsset