import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Input,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicTable from '../../Components/Table/DynamicTable';
import { MdOutlineEditCalendar } from 'react-icons/md';
import useUserStore from '../../Hooks/Zustand/Store';
import { getUserByUid } from '../../Utils/getUser';
import DynamicButton from '../../Components/Buttons/DynamicButton';

const HrisKpiTargetPerformance = () => {
  const { id } = useParams();
  const globalState = useUserStore()
  const navigate = useNavigate()

  const [name, setName] = useState()
  const [loading, setLoading] = useState(false);
  const [dataTarget, setDataTarget] = useState();
  const [showInputStack, setShowInputStack] = useState(false);
  const [newDataList, setNewDataList] = useState([
    {
      matrix: '',
      target: '',
      bobot: '',
      keterangan: '',
      indikator: '',
      deadline: '',
    }
  ]);

  const toast = useToast()

  const [filterDate, setFilterDate] = useState('');
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 10;

  const tableHeader = ['matrix', 'target', 'bobot', 'keterangan', 'indikator', 'deadline'];

  const getData = async () => {
    setLoading(true);
    const conditions = [
      { field: 'userId', operator: '==', value: id }
    ]
    const sortBy = null
    const limitValue = startIndex + itemsPerPage;
    try {
      const res = await getCollectionFirebase('kpi_target_performance', conditions, sortBy, limitValue);
      const userName = await getUserByUid(globalState?.users, id)
      setName(userName)
      setDataTarget(res);
    } catch (error) {
      throw new Error(error.message, 'Failed to send request api');
    }
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (index, name, value) => {
    setNewDataList((prevList) => {
      const newList = [...prevList];
      newList[index] = {
        ...newList[index],
        [name]: value,
      };
      return newList;
    });
  };



  const handleTambahField = () => {
    setNewDataList((prevList) => [
      ...prevList,
      {
        matrix: '',
        target: '',
        bobot: '',
        keterangan: '',
        indikator: '',
        deadline: '',
      }
    ]);
  };

  const handleUpdate = async () => {
    setLoading(true)
    newDataList?.map(async (x) => {
      const data = {
        ...x,
        companyId: globalState.currentCompany,
        userId: id
      }
      try {
        await addDocumentFirebase('kpi_target_performance', data, globalState.currentCompany)

        toast({
          status: 'success',
          description: 'success add new kpi target',
          duration: 2000,
        });

        getData()

      } catch (error) {
        throw new Error(error.message, 'Failed to send request api');
      }

    })
    setNewDataList([
      {
        matrix: '',
        target: '',
        bobot: '',
        keterangan: '',
        indikator: '',
        deadline: '',
      },
    ]);
    setLoading(false)
  };

  const monthNames = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ]

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const day = dateObj.getDate();
    const monthName = monthNames[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    return `${day} ${monthName} ${year}`;
  };

  const handleFilter = () => {
    const formattedFilterDate = formatDate(filterDate);

    const filteredData = dataTarget?.filter(
      (item) => formatDate(item.deadline) === formattedFilterDate
    );

    setDataTarget(filteredData);
    setFilterModalOpen(false);
  };

  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };


  const handleClearFilter = () => {
    getData();
    setFilterDate('');
    setFilterModalOpen(false);
  };

  const handleDelete = async(x) => {
    const collectionName = 'kpi_target_performance';
    const docName = x.id;

    try {
      await deleteDocumentFirebase(collectionName, docName);
      toast({
        status: 'success',
        description: 'success delete kpi target',
        duration: 2000,
      });

      getData()
    } catch (error) {
      throw new Error(error.message, 'Failed to send request api');
    }
  }

  return (
    <Stack p={[1, 1, 5]} spacing={2}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size="md">Performance target : {name?.name}</Heading>
        <Spacer />
        <Button
          onClick={() => setFilterModalOpen(true)}
          colorScheme="teal"
          ml={4}
          display={filterDate ? 'none' : 'inline-block'}
        >
          <HStack>
            <MdOutlineEditCalendar />
            <Text>Filter Date </Text>
          </HStack>
        </Button>
        <Button
          onClick={handleClearFilter}
          colorScheme="gray"
          ml={2}
          display={filterDate ? 'inline-block' : 'none'}
        >
          Clear Filter
        </Button>
        <DynamicButton action={'read'} title={'Review Evaluation'} onClick={() => navigate(`/hris/kpi/${id}/evaluation`)} variant="solid"/>

      </HStack>

      {loading ? (
        <Stack p={8}>
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='rgba(54, 162, 235, 0.6)'
              size='xl'
            />
          </Center>
        </Stack>
      ) : (
        <Stack>
          <DynamicTable header={tableHeader} data={dataTarget} onDelete={handleDelete} />

        </Stack>
      )}

      {showInputStack && (
        <Stack p={5} m={5} bgColor={'white'} borderRadius={'lg'} shadow={'md'}>
          {newDataList.map((newData, index) => (
            <HStack key={index} spacing={4} align="center">
              <Input
                placeholder="Matrix"
                name={`matrix_${index}`}
                value={newData.matrix}
                onChange={(e) => handleInputChange(index, 'matrix', e.target.value)}
              />
              <Input
                placeholder="Target"
                name={`target_${index}`}
                value={newData.target}
                onChange={(e) => handleInputChange(index, 'target', e.target.value)}
              />
              <Input
                placeholder="Bobot"
                name={`bobot_${index}`}
                value={newData.bobot}
                onChange={(e) => handleInputChange(index, 'bobot', e.target.value)}
              />
              <Input
                placeholder="Keterangan"
                name={`keterangan_${index}`}
                value={newData.keterangan}
                onChange={(e) => handleInputChange(index, 'keterangan', e.target.value)}
              />
              <Select
                placeholder='Indikator'
                value={newData.indikator}
                onChange={(e) => handleInputChange(index, 'indikator', e.target.value)}
              >
                <option>Semakin banyak semakin baik</option>
                <option>Semakin sedikit semakin baik</option>
              </Select>
              <Input
                type='date'
                name={`deadline_${index}`}
                value={newData.deadline}
                onChange={(e) => handleInputChange(index, 'deadline', e.target.value)}
              />
            </HStack>
          ))}
          <Stack alignItems={'center'} w={'full'}>
            <Button size={'sm'} colorScheme="green" onClick={handleTambahField} w={'fit-content'}>
              +
            </Button>

          </Stack>
          <Stack direction="row" justify="flex-end" spacing={4} mt={4}>
            <Button colorScheme="blue" onClick={handleUpdate}>
              Add
            </Button>
            <Button onClick={() => setShowInputStack(false)}>Cancel</Button>
          </Stack>
        </Stack>
      )}

      {!showInputStack && (
        <Stack px={5}>
          <Button
            w={'fit-content'}
            colorScheme='green'
            onClick={() => setShowInputStack(true)}
          >
            Tambah +
          </Button>
        </Stack>
      )}

      <Stack alignItems={'center'} justifyContent="center" py={5}>
        <Box>
          {dataTarget?.length > startIndex && (
            <Button onClick={handleLoadMore} size="sm">
              Load More
            </Button>
          )}
        </Box>
      </Stack>

      <Modal isOpen={isFilterModalOpen} onClose={() => setFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Text>Select a date to filter the data:</Text>
              <Input
                type="date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              />
            </VStack>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button colorScheme="teal" onClick={handleFilter}>
              Apply Filter
            </Button>
            <Button colorScheme="red" onClick={handleClearFilter}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default HrisKpiTargetPerformance;
