/* eslint-disable react/prop-types */
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import React from 'react';
import { Button, Tooltip } from '@chakra-ui/react';

const ExportExcel = ({
  excelData,
  fileName,
  size,
  variant,
  colorScheme,
  title,
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const dataCheck =
    Array.isArray(excelData) &&
    excelData.every(
      (innerArr) =>
        Array.isArray(innerArr) &&
        innerArr.every((obj) => typeof obj === 'object')
    );

  const exportToExcel = async () => {
    const sheets = {};
    let combinedData = [];


    if (dataCheck) {
      if (dataCheck === true) {
        excelData?.forEach((arr) => {
          combinedData = combinedData.concat(arr); // Combine arrays
        });

        const ws = XLSX.utils.json_to_sheet(combinedData);
        sheets['CombinedData'] = ws;
      } else {
        const ws = XLSX.utils.json_to_sheet(excelData);
        sheets['data'] = ws;

        ws['A1'].s = {
          fill: {
            bg: '#f53b22'
          },
          font: {
            color: '#fff'
          }
        }

      }

      const wb = { Sheets: sheets, SheetNames: ['CombinedData'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } else {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  };

  return (
    <>
      <Tooltip title="Excel Export">
        <Button
          size={size}
          my={2}
          variant={variant}
          colorScheme={colorScheme}
          onClick={() => exportToExcel(fileName)}
          cursor="pointer"
        >
          {title || 'Export to Excel'}
        </Button>
      </Tooltip>
    </>
  );
};

export default ExportExcel;
