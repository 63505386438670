import { Box, Button, Center, HStack, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Stack, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicTable from '../../Components/Table/DynamicTable'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import { useNavigate } from 'react-router-dom'
import { addDocumentFirebase, deleteDocumentFirebase, getCollectionFirebaseV4, updateDocumentFirebase } from '../../Api/firebaseApi'
import useUserStore from '../../Hooks/Zustand/Store'
import AlertDialogV2 from '../../Components/AlertDialog/AlertDialogV2'
import { payrollDelete } from '../../Api/PayrollApi'

const HrisDeductionPage = () => {
  const globalState = useUserStore()
  const navigate = useNavigate()
  const [alert, setAlert] = useState(false)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState('')
  const [detail, setDetail] = useState({})
  const [deduction, setDeduction] = useState()
  const [newDeduction, setNewDeduction] = useState('')
  const tableHeader = ['type']

  const getDeduction = async () => {
    setLoading(true)
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany }
    ]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    try {
      const res = await getCollectionFirebaseV4('payroll_deduction', { conditions }, { sortBy }, { limitValue }, { startAfterData })
      setDeduction(res)
    } catch (error) {
      // console.log(error, 'error getting deduction')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getDeduction()
  }, [globalState.currentCompany])

  const dataTable = deduction?.map((x) => {
    const type = x.type
    return {
      x,
      'type': type,
    }
  })
  const openModal = (x) => {
    setDetail(x)
    setMode('detail')
    setModal(true)
  }
  const handleAdd = () => {
    setMode('add')
    setModal(true)
  }

  const handleSubmit = async () => {
    setLoading(true)
    const data = {
      type: newDeduction,
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject
    }
    try {
      await addDocumentFirebase('payroll_deduction', data, globalState.currentCompany)
    } catch (error) {
      // console.log(error, 'ini error')
    }
    setLoading(false)
    setModal(false)
    setDetail({})
    getDeduction()
  }

  const handleDelete = (x) => {
    setMode('delete')
    setDetail(x)
    setAlert(true)
  }
  const handleRead = (x) => {
    navigate(`${x.x.id}`)
  }
  const onClose = () => {
    setMode('')
    setDetail({})
    setModal(false)
  }

  const handleUpdate = async () => {
    setLoading(true)
    const data = {
      type: detail.type,
      companyId: globalState.currentCompany,
      projectId: globalState.currentProject
    }
    try {
      await updateDocumentFirebase('payroll_deduction', detail?.x?.id, data)
    } catch (error) {
      // console.log(error, 'ini error')
    }
    setLoading(false)
    setDetail({})
    setMode('')
    setModal(false)
    getDeduction()
  }

  const onDelete = async () => {
    setLoading(true);
    const dataApi = {
      tokenId: globalState.authFirebaseToken,
      companyId: globalState.currentCompany,
      bulkDelete: true,
      categoryId: detail.x.id,
      mongoId: '-'
    };
    try {
      const deleteMongo = await payrollDelete(dataApi);
      if (deleteMongo.status === false) {
        throw new Error(deleteMongo.message)
      } else {
        await deleteDocumentFirebase('payroll_deduction', detail?.x?.id);
      }
    } catch (error) {
      // console.log(error, 'ini error')
    } finally {
      setLoading(false)
      setMode('')
      setDetail({})
      setAlert(false)
      getDeduction()
    }
  }

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size="md">Deduction</Heading>
        <Spacer />
        <DynamicButton action={'create'} title={'Add Deduction'} variant={'solid'} onClick={() => handleAdd()} />
      </HStack>

      {loading ?
        <Stack p={8}>
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='rgba(54, 162, 235, 0.6)'
              size='xl'
            />
          </Center>
        </Stack>
        :
        <Box>
          <DynamicTable header={tableHeader} data={dataTable} onEdit={openModal} onDelete={handleDelete} onRead={handleRead} />
        </Box>
      }


      <Modal
        isOpen={modal}
        onClose={onClose}
        motionPreset="slideInBottom"
        size={'2xl'}
      >
        <ModalOverlay />
        {mode === 'detail' ?
          <ModalContent>
            <ModalHeader>Edit Deduction</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack justify={'space-between'} my={2}>
                <Heading fontSize={'md'}>Type</Heading>
                <Input defaultValue={detail.type} onChange={(e) => { detail.type = e.target.value }} />
              </Stack>

            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button colorScheme='orange' onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='green' onClick={() => handleUpdate()}>
                  Submit
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
          :
          <ModalContent>
            <ModalHeader>Add Deduction</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack my={5}>
                <Text fontSize={'md'}>Type</Text>
                <Input type='text' placeholder='Add new Deduction' onChange={(e) => setNewDeduction(e.target.value)} />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button colorScheme='red' onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme='green' onClick={() => handleSubmit()}>
                  Submit
                </Button>

              </HStack>
            </ModalFooter>
          </ModalContent>
        }
      </Modal>
      <AlertDialogV2 isOpen={alert} onClose={() => setAlert(false)} title={`Delete ${detail?.type}`} onAction={onDelete} isLoading={loading} />
    </Stack>
  )
}

export default HrisDeductionPage