/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/prop-types */
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import moment from 'moment';
import { RiWhatsappFill } from 'react-icons/ri';

const RecruitmentCandidateCardComponent = ({ handleReject, handleSelect, x, seen }) => {
  return (
    <Box bgColor="white" borderRadius="md" m="1">
      <HStack>
        <Box>
          <Checkbox p="2" />
        </Box>
        <Box width="full" p="2">
          <HStack>
            <Text fontSize={15} fontWeight={'medium'}>{x?.user?.displayName} </Text>
            <Text fontSize={10} color="gray.600">
              Applied on:{' '}
              <strong>{`${moment.unix(x?.createdAt?.seconds).format('llll')}`}</strong>
            </Text>
          </HStack>

          <Text fontSize={12}>
            {x?.user?.major} - {x?.user?.school}
          </Text>
          <Flex>
            <Text fontSize={12}>Status:</Text>
            <Badge
              size='sm'
              colorScheme={x?.status === 'unprocessed' ? 'red' : x?.status === 'not_suitable' ? 'blackAlpha' : 'green'}
              mx={2}
            >
              {x?.status}
            </Badge>
          </Flex>
          <Spacer />
        </Box>
        <Box p="2" borderLeft="1px">
          <Text fontSize={12}>Kontak</Text>
          <HStack>
            <RiWhatsappFill
              size="15"
              color="green"
              onClick={() => window.open(`https://wa.me/${(x?.user?.phone_number).replace(/^0/, '+62')}`)}
              cursor="pointer"
            />
          </HStack>
        </Box>
      </HStack>
      <Divider />
      <Stack align={'flex-end'} p={3}>
        <HStack gap={2} >
          <Button size={'xs'} fontSize={10}
            fontWeight='bold'
            color="yellow.600"
            cursor="pointer"
            onClick={() => {
              window.open(x?.user?.cv)
              seen()
            }}
          >
            Tinjau CV
          </Button>
          <Button size={'xs'} fontSize={10}
            fontWeight='bold'
            color="green"
            cursor="pointer"
            onClick={handleSelect}
          >
            Terpilih
          </Button>
          <Button size={'xs'} fontSize={10}
            fontWeight='bold'
            color="red"
            cursor="pointer"
            onClick={handleReject}
          >
            Tidak Sesuai
          </Button>
          <Spacer />

        </HStack>
      </Stack>
    </Box>
  );
}

export default RecruitmentCandidateCardComponent