/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, fetchToken } from './Config/firebase';
import {
  arrayUnionFirebase,
  getCollectionFirebase,
  getSingleDocumentFirebase,
} from './Api/firebaseApi';
import useUserStore from './Hooks/Zustand/Store';
import { decryptToken } from './Utils/encrypToken';
import { Stack } from '@chakra-ui/react';
import AuthRouter from './Router/AuthRouter';
import LoadingOverlay from './Components/Loader/LoadingOverlay';
import moment from 'moment';
import FrontRecruitmentRouter from './Router/FrontRecruitmentRouter';
import EmailVerification from './Pages/Auth/EmailVerification';
import LayoutRecruitment from './Layouts/indexRecuritment';
import MainRouter from './Router/MainRouter';
import AlertFront from './Components/AlertDialog/AlertFront';

function App() {
  const globalState = useUserStore();

  const handleUsersDisplay = async (companies, currentCompany) => {
    try {
      const findCompany = companies.find((x) => x.id === currentCompany);

      if (!findCompany) {
        return;
      }



      globalState.setExpired(
        findCompany !== undefined && findCompany?.expired_at?.seconds >= moment().unix() && findCompany?.module?.includes('hris') ? false : true
      );

      const dataUsers = findCompany.users;
      const dataUsersDisplay = [];

      await Promise.all(
        dataUsers.map(async (userId) => {
          try {
            const result = await getSingleDocumentFirebase('users', userId);

            const user = {
              id: userId || '',
              name: result.name || result.displayName,
              email: result.email || '',
              phoneNumber: result.phoneNumber || result.phone,
              image: result.image || '',
            };

            dataUsersDisplay.push(user);
          } catch (error) {
            throw new Error(error.message, 'Failed to send  error message');
          }
        })
      );

      globalState.setUsers(dataUsersDisplay);
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    }
  };

  const fetchProjectsAndCompanies = async (uid) => {
    let fetchCompanyId = localStorage.getItem('currentCompany');
    let fetchProjectId = localStorage.getItem('currentProject');

    const conditions = [
      {
        field: 'users',
        operator: 'array-contains',
        value: uid,
      }
    ];

    const sortBy = { field: 'name', direction: 'asc' };

    try {
      const [companies, projects] = await Promise.all([
        getCollectionFirebase('companies', conditions, sortBy),
        getCollectionFirebase('projects', conditions, sortBy),
      ]);

      if (!fetchCompanyId) {
        // Set data to localStorage only if it's not already set
        fetchCompanyId = companies[0]?.id; // Set the fetched ID to the variable
        const userRoleInCompany = getUserRole(
          companies,
          uid,
          companies[0]?.id,
          'company'
        );

        localStorage.setItem('currentCompany', fetchCompanyId);

        globalState.setCompanies(companies);
        globalState.setCurrentCompany(fetchCompanyId);
        globalState.setCurrentXenditId(companies[0]?.xenditId);
        globalState.setRoleCompany(userRoleInCompany);
        await handleUsersDisplay(companies, fetchCompanyId);



      } else {

        globalState.setCurrentCompany(fetchCompanyId);

        const userRoleInCompany = getUserRole(
          companies,
          uid,
          fetchCompanyId,
          'company'
        );
        globalState.setCompanies(companies);
        globalState.setRoleCompany(userRoleInCompany);

        await handleUsersDisplay(companies, fetchCompanyId);

      }

      if (!fetchProjectId) {
        if (companies[0]?.id) {
          fetchProjectId = projects[0]?.id;
          const userRoleInProject = getUserRole(
            projects,
            uid,
            fetchProjectId,
            'project'
          );
          localStorage.setItem('currentProject', fetchProjectId);
          globalState.setProjects(projects);
          globalState.setCurrentProject(fetchProjectId);
          globalState.setRoleProject(userRoleInProject);
        }
      } else {
        const userRoleInProject = getUserRole(
          projects,
          uid,
          fetchProjectId,
          'project'
        );

        globalState.setProjects(projects);
        globalState.setCurrentProject(fetchProjectId);
        globalState.setRoleProject(userRoleInProject);
      }
    } catch (error) {
      throw new Error(error.message, 'Failed to send  error message');
    }
  };

  const getUserRole = (data, uid, dataId) => {
    let findData = {};
    findData = data.find((x) => x.id === dataId);

    if (findData && findData.owner?.includes(uid)) {
      return 'owner';
    }
    else if (findData && findData.hr?.includes(uid)) {
      return 'hr';
    }
    else if (findData && findData?.managers?.includes(uid)) {
      return 'managers';
    } else {
      return 'user';
    }
  };

  const uploadTokenToFirebase = async (token, user) => {
    if (token !== '') {
      const collectionName = 'users';
      const docName = user.uid;
      const field = 'tokenId';
      const values = [token];

      try {
        await arrayUnionFirebase(collectionName, docName, field, values);
      } catch (error) {
        return 'Error occured:', error;
      }
    }
  };

  const getAccessToken = async () => {
    try {
      const result = await getSingleDocumentFirebase('token', 'dropbox');
      const resultData = decryptToken(result?.access_token);
      globalState.setAccessToken(resultData);
    } catch (error) {
      return (error);
    }
  };

  const getDataUser = async (uid) => {
    try {
      const result = await getSingleDocumentFirebase('users', uid);
      if (result?.type === 'recruitment') {
        globalState.setUserType('recruitment');
      } else {
        globalState.setUserType('user');
      }
    } catch (error) {
      return (error);
    }
  };

  const refreshTokenHourly = async () => {
    const user = auth.currentUser;
    if (user) {
      const tokenFirebase = await user.getIdToken(true);
      globalState.setAuthFirebaseToken(tokenFirebase);
      localStorage.setItem('tokenFirebase', JSON.stringify(tokenFirebase));
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        await fetchProjectsAndCompanies(user?.uid);

        globalState.setIsLoggedIn(true);
        globalState.setUid(user.uid);
        globalState.setName(user.displayName);
        globalState.setEmail(user.email);

        await getAccessToken();

        await getDataUser(user?.uid);


        const token = await fetchToken();

        if (token) {
          await uploadTokenToFirebase(token, user);
        }


        await getAccessToken();
        const tokenFirebase = await user.getIdToken(true);

        globalState.setAuthFirebaseToken(tokenFirebase);
        localStorage.setItem('tokenFirebase', JSON.stringify(tokenFirebase));




      } else {
        globalState.setIsLoggedIn(false);
      }
    });

    const intervalId = setInterval(refreshTokenHourly, 60 * 60 * 1000);


    globalState.setExpired(true)






    return () => {
      clearInterval(intervalId);
    };
  }, []);



  return (
    <Stack position={'relative'} overflow="hidden">
      {globalState?.isLoggedIn ? (
        auth?.currentUser?.emailVerified ? (
          globalState?.userType === 'recruitment' ? (
            <LayoutRecruitment>
              <LoadingOverlay />
              <FrontRecruitmentRouter />
            </LayoutRecruitment>
          ) : globalState?.expired ? (
            // <Layout>
            //   <MembershipPaymentPage />
            // </Layout>
            <Stack>
              <AlertFront
                title={('This is beta version, any bugs / need any help please contact our customer service')}
              />
  
              <LoadingOverlay />
              <MainRouter />
            </Stack>
          ) : (
            <Stack>
              <AlertFront
                title={('This is beta version, any bugs / need any help please contact our customer service')}
              />

              <LoadingOverlay />
              <MainRouter />
            </Stack>
          )
        ) : globalState?.email?.includes('@testing-company.com') === true ? (
          <Stack position={'relative'}>
            <AlertFront
              title={('This is beta version, any bugs / need any help please contact our customer service')}
            />

            <LoadingOverlay />
            <MainRouter />
          </Stack>
        ) : (
          <EmailVerification />
        )
      ) : (
        <AuthRouter /> //recruitment depan ada di sini
      )}
    </Stack>
  );
}

export default App;
