import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  HStack,
  Heading,
  Input,
  Spacer,
  Spinner,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { getCollectionFirebase, setDocumentFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicTable from '../../Components/Table/DynamicTable';
import { MdOutlineEditCalendar } from 'react-icons/md';
import useUserStore from '../../Hooks/Zustand/Store';
import { getUserByUid } from '../../Utils/getUser';
import moment from 'moment';

const HrisKpiPerformanceEvaluation = () => {
  const { id } = useParams();
  const globalState = useUserStore()
  const toast = useToast()

  const [name, setName] = useState()
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false);
  const [realisasi, setRealisasi] = useState('')
  const [dataTarget, setDataTarget] = useState();
  const [dataDetail, setDataDetail] = useState({})

  const [filterDate, setFilterDate] = useState('');
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const itemsPerPage = 10;

  const tableHeader = ['matrix', 'target', 'bobot', 'keterangan', 'indikator', 'deadline', 'realisasi', 'pencapaian (%)'];


  const handleLoadMore = () => {
    setStartIndex((prev) => prev + itemsPerPage);
  };

  const getData = async () => {
    setLoading(true);
    const conditions = [
      { field: 'userId', operator: '==', value: id }
    ]
    const sortBy = null
    const limitValue = startIndex + itemsPerPage;
    try {
      const res = await getCollectionFirebase('kpi_target_performance', conditions, sortBy, limitValue);
      const userName = await getUserByUid(globalState?.users, id)
      setName(userName)
      setDataTarget(res);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const tableData = dataTarget?.map((x) => {
    return {
      x,
      'matrix': x?.matrix,
      'target': x?.target,
      'bobot': x?.bobot,
      'keterangan': x?.keterangan,
      'indikator': x?.indikator,
      'deadline': moment(x?.deadline.seconds * 1000).format('DD-MM-YYYY'),
      'realisasi': x?.realisasi || 'belum diinput',
      'pencapaian (%)': x?.pencapaian
        ? `${(parseInt(x?.pencapaian)).toFixed(2)} %`
        : 'belum diinput',
    }
  })

  const handleInput = (x) => {
    setModal(true)
    setDataDetail(x)
  }

  const handleUpdate = async () => {
    setLoading(true)
    dataTarget[0].realisasi = realisasi
    dataTarget[0].pencapaian = parseInt(realisasi) / parseInt(dataDetail?.target) * 100
    try {
      await setDocumentFirebase('kpi_target_performance', dataDetail?.x.id, ...dataTarget)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
    setDataDetail({})
    getData()
    setLoading(false)
    setModal(false)
  };

  const handleFilter = () => {
    const formattedFilterDate = (filterDate);
    const filteredData = dataTarget?.filter(
      (item) => (item.deadline) === formattedFilterDate
    );
    setDataTarget(filteredData);
    setFilterModalOpen(false);
  };

  const handleClearFilter = () => {
    getData();
    setFilterDate('');
    setFilterModalOpen(false);
  };


  const onClose = () => {
    setModal(false)
    setDataDetail({})
  }

  return (
    <Stack p={[1, 1, 5]} spacing={2}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size="md">Performance Evaluation : {name?.name}</Heading>
        <Spacer />
        <Button
          onClick={() => setFilterModalOpen(true)}
          colorScheme="teal"
          ml={4}
          display={filterDate ? 'none' : 'inline-block'}
        >
          <HStack>
            <MdOutlineEditCalendar />
            <Text>Filter Date </Text>
          </HStack>
        </Button>
        <Button
          onClick={handleClearFilter}
          colorScheme="gray"
          ml={2}
          display={filterDate ? 'inline-block' : 'none'}
        >
          Clear Filter
        </Button>
      </HStack>

      {loading ? (
        <Stack p={8}>
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='rgba(54, 162, 235, 0.6)'
              size='xl'
            />
          </Center>
        </Stack>
      ) : (
        <Stack>
          <DynamicTable header={tableHeader} data={tableData} onEdit={handleInput} />

          <Stack alignItems={'center'} justifyContent="center" py={5}>
            <Box>
              {dataTarget?.length > startIndex && (
                <Button onClick={handleLoadMore} size="sm">
                  Load More
                </Button>
              )}
            </Box>
          </Stack>
        </Stack>
      )}

      <Modal isOpen={isFilterModalOpen} onClose={() => setFilterModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <Text>Select a date to filter the data:</Text>
              <Input
                type="date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              />
            </VStack>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button colorScheme="teal" onClick={handleFilter}>
              Apply Filter
            </Button>
            <Button colorScheme="red" onClick={handleClearFilter}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={modal} onClose={() => setModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Input Pencapaian</ModalHeader>
          <ModalBody>
            <Input placeholder={'Realisasi'} onChange={(e) => setRealisasi(e.target.value)} />
          </ModalBody>
          <ModalFooter gap={3}>
            <Button colorScheme="teal" onClick={handleUpdate} isLoading={loading}>
              Submit
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

export default HrisKpiPerformanceEvaluation
