import { Box, Flex, HStack, Heading, Input, Spacer, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { getCollectionFirebaseV3 } from '../../Api/firebaseApi';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../../Hooks/Zustand/Store';
import RecruitmentBox from './RecruitmentBox';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { db } from '../../Config/firebase';

const RecruitmentJobListingPage = () => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  // const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const { currentCompany } = useUserStore();
  const isDesktop = useBreakpointValue({ base: false, lg: true });


  const getRoles = async () => {
    try {
      const positions = await getCollectionFirebaseV3('positions', { conditions: [{ field: 'companyId', operator: '==', value: currentCompany }] }, { sortBy: null }, { limitValue: null }, { startAfterData: null });



      ////////
      const countStatuses = positions.map(async (position) => {
        const statuses = ['unprocessed', 'review', 'onboarding', 'interview', 'assessment', 'not_suitable', 'accepted'];

        const newObject = {
          position_name: position.position_name,
          position_id: position.id,
          ...position
        };

        const x = statuses.map(async (status) => {
          const coll = collection(db, 'applications');
          const q = query(coll, where('position_id', '==', position.id), where('status', '==', status));
          const snapshot = await getCountFromServer(q);
          newObject[`status_${status}`] = snapshot.data().count;
        })
        await Promise.all(x);

        return newObject;
      });


      //////
      const countResults = await Promise.all(countStatuses)
      setJobs(countResults, 'countResults');
    } catch (error) {
      // console.log(error.message);
    }
  };

  useEffect(() => {
    getRoles();
  }, [currentCompany]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    jobs.filter((job) => {
      return job?.position_name?.toLowerCase().includes(searchTerm.toLowerCase());
    });
    // setSearchResults(filteredJobs);
  }, [searchTerm, jobs]);


  return (
    <>
      {isDesktop ? (
        <Stack w={{ lg: '100%' }} py={2}>
          <HStack flexDirection={['column', 'row', 'row']}>
            <BackButtons />
            <Text fontSize={'xl'} fontWeight={500}>
              Job Listings
            </Text>
            <Spacer />
            <DynamicButton action={'create'} onClick={() => navigate('/recruitment/create-job')} title={'Create New Job'} />
          </HStack>
          <Stack>
            <Input
              mb={3}
              mt={5}
              type="text"
              placeholder="Search Role ..."
              bgColor="white"
              color="black"
              fontSize="sm"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Stack>
          <Box>
            {jobs.length > 0 ? jobs.map((job, i) => (
              <Stack key={i} mt={2}>
                <RecruitmentBox data={job} />
              </Stack>
            )) : (
              <Flex h="100vh" flexDir="row" justifyContent="center" alignItems="center">
                <Text textAlign="center">
                  <strong>No Job Listings</strong>, make sure you already picked a company in the sidebar <br /> or click Add New Job Posting above
                </Text>
              </Flex>
            )}
          </Box>

        </Stack>
      ) : (
        <Stack
          h={'50vh'}
          alignItems="center"
          justifyContent={'center'}
          spacing={2}
        >
          <Heading textAlign={'center'} size="xl">
            Sorry!
          </Heading>
          <Heading textAlign={'center'} size="md">
            This page is only available in Desktop View
          </Heading>

        </Stack>
      )}
    </>

  )
}

export default RecruitmentJobListingPage