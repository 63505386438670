import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Spacer,
  Text,
  SimpleGrid,
  Stack,
  Flex,
  useToast,
  Textarea,
  Grid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  arrayUnionFirebase,
  deleteDocumentFirebase,
  getSingleDocumentFirebase,
  setDocumentFirebase,
} from '../../Api/firebaseApi';
import BackButtonComponent from '../../Components/Buttons/BackButtons';
import ImageComponent from '../../Components/Image/ImageComponent';
import useUserStore from '../../Hooks/Zustand/Store';
import {
  arrayRemove,
  collection,
  doc,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../Config/firebase';
import { clientTypessense } from '../../Api/Typesense';
import MapComponent from '../../Components/Map/MapComponent';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { createHris } from '../../Api/Hris';
import DeleteOffice from './components/DeleteOffice';

function HrisOfficeSinglePage() {
  const globalState = useUserStore();
  const [data, setData] = useState();
  const [input, setInput] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [selectedUserProjectIds, setSelectedUserProjectIds] = useState([]);
  const [projectActive, setProjectActive] = useState([]);
  const [isAddingTeam, setIsAddingTeam] = useState(false);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [companyActive, setCompanyActive] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [modalProjectUser, setModalProjectUser] = useState(false);
  const [modalProjectUserTeam, setModalProjectUserTeam] = useState(false);

  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const handleRemoveUser = async (data) => {
    try {
      await deleteDocumentFirebase(
        `offices/${companyActive?.id}/employees`,
        data.id
      );

      const dataRef = doc(db, 'offices', companyActive?.id);
      await updateDoc(dataRef, { employee: arrayRemove(data.id) });

      setModalProjectUserTeam(false);
      toast({
        title: 'Deleted',
        description: 'User Deleted',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      getData();
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const getData = async () => {
    try {
      const dataRes = await getSingleDocumentFirebase('offices', params.id);
      setData(dataRes);
      setLongitude(dataRes?.longitude);
      setLatitude(dataRes?.latitude)

      if (dataRes) {
        const userSnapshot = getDocs(
          collection(db, `offices/${dataRes.id}/employees`)
        );

        userSnapshot
          .then((snapshot) => {
            const usersData = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setData({ ...dataRes, usersProjectData: usersData });
          })
          .catch((err) => {
            toast({
              title: 'Deoapp.com',
              description: err.message,
              duration: 3000,
              status: 'error',
              position: 'top-right',
              isClosable: true,
            });
          });

        // setModules(dataRes?.modules ? dataRes.modules : []);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  };

  const saveData = async () => {
    if (input.image === '') {
      toast({
        title: 'Deoapp.com',
        description: 'Please fill the image',
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      }); return;
    }

    setIsLoading(true);
    try {
      await setDocumentFirebase(
        'offices',
        params.id,
        { ...input, latitude: latitude, longitude: longitude },
        data.companyId
      );

      const session = {
        headers: globalState?.authFirebaseToken,
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
      };

      const postData = {};

      try {
        await createHris(`cache/update/offices/${ params.id}`, session, postData);
      } catch (error) {
        // console.log(error.message, '::error POST api cache office')
      }

      toast({
        status: 'success',
        duratio: 5000,
        description: 'Sucess edit office',
      });

      navigate(-1);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      // window.location.reload();
    }
  };

  const handleSearchUsers = (q) => {
    const companyUsers = globalState.companies.find(
      (x) => x.id === globalState.currentCompany
    );
    const userChunks = chunkArray(companyUsers?.users, 100);

    const searchPromises = userChunks.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };

      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        const combinedResults = results.flatMap((result) => result.hits);
        setSearchResult(combinedResults);
      })
      .catch((error) => {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          duration: 3000,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      });
  };

  const chunkArray = (arr, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleOpenModaProjectTeam = () => {
    setModalProjectUserTeam(true);
    setCompanyActive(data);
  };

  const handleOpenModalProject = () => {
    setModalProjectUser(true);
    setProjectActive(data);
  };

  const handleAddTeamProject = async () => {
    setIsAddingTeam(true);
    selectedUserProjectIds.forEach(async (x) => {
      const collectionName = `offices/${projectActive.id}/employees`;
      const docName = x.id;
      const data = x;

      try {
        await setDocumentFirebase(collectionName, docName, data);

        // Pesan toast yang berhasil
      } catch (error) {
        toast({
          title: 'Deoapp.com',
          description: error.message,
          duration: 3000,
          status: 'error',
          position: 'top-right',
          isClosable: true,
        });
      }
    });

    const mapIdUser = selectedUserProjectIds.map((x) => x.id);
    const collectionName = 'offices';
    const docName = `${projectActive.id}`;
    const field = 'employee';
    const values = mapIdUser;

    try {
      await arrayUnionFirebase(
        collectionName,
        docName,
        field,
        values
      );
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setIsAddingTeam(false);
    }

    toast({
      status: 'success',
      title: 'Deoapp Business',
      description: 'Success adding team to the project',
      duration: 1000,
    });

    setModalProjectUser(false);
    setSelectedUserProjectIds([]);
    setProjectActive('');
    setSearchResult([]);
    getData();
  };

  const handleUserProjectClick = (userId) => {
    setSelectedUserProjectIds((prevIds) => {
      if (prevIds.includes(userId)) {
        return prevIds.filter((id) => id !== userId);
      } else {
        return [...prevIds, userId];
      }
    });
  };

  const onLatitude = (latitude) => {
    setLatitude(latitude);
  };

  const onLongitude = (longitude) => {
    setLongitude(longitude);
  };

  useEffect(() => {
    getData();
    return () => {
      setData([]);
    };
  }, []);

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack>
        <BackButtonComponent />
        <Spacer />
        <Stack spacing={2}>
          <Heading size={'lg'}>Office</Heading>
          <Text fontSize="3xs">ID: {params.id}</Text>
          <DeleteOffice officeId={params.id} officeData={data}/>
        </Stack>
      </HStack>

      <Grid templateColumns={{ base: '1fr', md: '1fr 2fr' }} w={'100%'} gap={5}>
        <Box bgColor={'white'} p={5} borderRadius="md" shadow={'base'}>
          <Stack spacing={3} align={'center'}>
            <ImageComponent
              image={data?.image}
              name={data?.title}
              width="200px"
            />
            {data?.image ? (
              <Button
                size={'sm'}
                colorScheme="red"
              // onClick={() => deleteImage()}
              >
                Change Image
              </Button>
            ) : (
              <Box>
                <Input
                  type="file"
                //   onChange={(e) => submitImage(e.target.files)}
                />
              </Box>
            )}
          </Stack>

          <FormControl mt="2">
            <FormLabel>Office Name</FormLabel>
            <Input
              type="text"
              placeholder="Office name"
              defaultValue={data?.title}
              onChange={(e) => setInput({ ...input, title: e.target.value })}
            />
          </FormControl>
          <FormControl mt="2">
            <FormLabel>Office Full Address</FormLabel>
            <Input
              type="text"
              placeholder="Full Address"
              defaultValue={data?.address}
              onChange={(e) => setInput({ ...input, address: e.target.value })}
            />
          </FormControl>

          <FormControl mt="2">
            <FormLabel>Office Description</FormLabel>
            <Textarea
              type="text"
              defaultValue={data?.description}
              placeholder="Description"
              onChange={(e) =>
                setInput({ ...input, description: e.target.value })
              }
            />
          </FormControl>

          <FormControl mt="2">
            <FormLabel>Type</FormLabel>
            <Select
              value={input?.type || data?.type}
              onChange={(e) => setInput({ ...input, type: e.target.value })}
            >
              <option value="shift_1">Shift 1</option>
              <option value="shift_2">Shift 2</option>
              <option value="shift_3">Shift 3</option>
              <option value="optional">optional</option>
            </Select>
          </FormControl>

          <FormControl mt="2">
            <FormLabel>Office Phone Number</FormLabel>
            <Input
              isDisabled={
                params.id !== 'new' && globalState.roleCompany !== 'owner'
                  ? true
                  : false
              }
              type="number"
              defaultValue={data?.phone}
              placeholder="Phone Number"
              onChange={(e) => setInput({ ...input, phone: e.target.value })}
            />
          </FormControl>

          <HStack mt="2">
            <FormControl>
              <FormLabel>Start Hour</FormLabel>
              <Input
                type="time"
                defaultValue={data?.startHour}
                placeholder="--:--"
                onChange={(e) =>
                  setInput({ ...input, startHour: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>End Hour</FormLabel>
              <Input
                type="time"
                defaultValue={data?.endHour}
                placeholder="--:--"
                onChange={(e) =>
                  setInput({ ...input, endHour: e.target.value })
                }
              />
            </FormControl>
          </HStack>
          <Stack mt="2">
            <Text fontWeight={500}>Location (for absent)</Text>
            <Box py={3}>
              <MapComponent onLongitude={onLongitude} onLatitude={onLatitude} />
            </Box>
            <HStack>
              <FormControl>
                <FormLabel>Latitude</FormLabel>
                <Input
                  type="number"
                  defaultValue={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Longitude</FormLabel>
                <Input
                  type="number"
                  defaultValue={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Radius</FormLabel>
                <Input
                  type="number"
                  defaultValue={data?.radius}
                  onChange={(e) =>
                    setInput({ ...input, radius: e.target.value })
                  }
                />
              </FormControl>
            </HStack>
          </Stack>

          <Button
            isLoading={isLoading}
            mt="5"
            colorScheme="green"
            w="full"
            onClick={() => saveData()}
          >
            Save
          </Button>
        </Box>

        <Box p={5} bg={params?.id !== 'new' ? 'white' : null}>
          {params.id !== 'new' ? (
            <Stack>
              <Heading size={'md'} align={'center'}>
                {data?.name}
              </Heading>

              <Text py={5} align={'center'}>
                Employees
              </Text>

              <SimpleGrid columns={[1, 2, 3]} spacing={3}>
                {data?.employee?.length > 0 &&
                  data?.employee?.map((y, i) => {
                    const user = data?.usersProjectData?.find(
                      (userData) => userData.id === y
                    );
                    
                    return (
                      <Stack
                        key={i}
                        p={3}
                        borderRadius={'md'}
                        shadow={'base'}
                        align={'center'}
                      >
                        <Avatar
                          size={'sm'}
                          name={user?.name}
                          src={user?.image ? user?.image : user?.email}
                        />
                        <Text fontSize={12} fontWeight={500}>
                          {user?.email}
                        </Text>
                      </Stack>
                    );
                  })}
              </SimpleGrid>
              <Text
                align={'center'}
                onClick={() => handleOpenModaProjectTeam()}
                color={'blue.400'}
                fontSize={12}
                cursor={'pointer'}
              >
                See All Employee Here
              </Text>

              <HStack justify={'center'} py={3}>
                <Button
                  onClick={handleOpenModalProject}
                  size={'sm'}
                  colorScheme="green"
                >
                  Add Users
                </Button>
              </HStack>
            </Stack>
          ) : // <ProjectCard
            //   projectData={projectData}
            //   navigate={navigate}
            //   handleOpenModalProject={handleOpenModalProject}
            //   handleOpenModaProjectTeam={handleOpenModaProjectTeam}
            // />
            null}
        </Box>
      </Grid>

      <Modal
        size={'md'}
        isOpen={modalProjectUser}
        onClose={() => setModalProjectUser(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Users In The Company</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={1} py={3}>
              <HStack m="1">
                <Input
                  type="text"
                  placeholder="Search users"
                  onChange={(e) => handleSearchUsers(e.target.value)}
                />
              </HStack>

              <Stack>
                <Stack
                  h={300}
                  overflowY={'auto'}
                  sx={{
                    '&::-webkit-scrollbar': {
                      w: '2',
                      h: '3',
                    },
                    '&::-webkit-scrollbar-track': {
                      w: '6',
                      h: '5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      borderRadius: '10',
                      bg: 'gray.200',
                    },
                  }}
                >
                  {searchResult.length > 0 ? (
                    searchResult.map((x, index) => {
                      return (
                        <HStack key={index} p="2" borderBottom="1px">
                          <Avatar
                            name={x.document.name}
                            src={x.document.image ? x.document.image : ''}
                          />
                          <Box>
                            <Text>{x.document.name}</Text>
                            <Text>{x.document.email}</Text>
                          </Box>
                          <Spacer />
                          <Button
                            colorScheme="green"
                            onClick={() => handleUserProjectClick(x.document)}
                          >
                            +
                          </Button>
                        </HStack>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <AvatarGroup size="sm" gap="1" max={4}>
                {selectedUserProjectIds.length > 0 &&
                  selectedUserProjectIds.map((x, i) => (
                    <Avatar key={i} name={x?.name} />
                  ))}
              </AvatarGroup>
              <Spacer />
              <Button
                isLoading={isAddingTeam}
                leftIcon={<AddIcon boxSize={3} />}
                colorScheme="green"
                onClick={() => handleAddTeamProject()}
              >
                Add Team
              </Button>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalProjectUser(false);
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalProjectUserTeam}
        onClose={() => setModalProjectUserTeam(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Employee</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack
              spacing={1}
              py={3}
              h={300}
              overflowY={'auto'}
              sx={{
                '&::-webkit-scrollbar': {
                  w: '2',
                  h: '3',
                },
                '&::-webkit-scrollbar-track': {
                  w: '6',
                  h: '5',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '10',
                  bg: 'gray.200',
                },
              }}
            >
              {companyActive?.usersProjectData?.length > 0 &&
                companyActive?.usersProjectData?.map((x, index) => {
                  return (
                    <HStack
                      cursor={'pointer'}
                      spacing={2}
                      key={index}
                      p={2}
                      borderRadius="lg"
                    >
                      <Stack>
                        <Avatar size={'sm'} name={x?.name} />
                      </Stack>
                      <Stack spacing={0}>
                        <Text
                          fontSize={'sm'}
                          fontWeight={500}
                          textTransform="capitalize"
                        >
                          {x?.name}
                        </Text>
                        <Text fontSize={'xs'}>{x?.email}</Text>
                      </Stack>
                      <Spacer />
                      <Stack>
                        <DynamicButton
                          action={'delete'}
                          size="xs"
                          onClick={() => handleRemoveUser(x)}
                          title={'remove user'}
                        />
                      </Stack>
                    </HStack>
                  );
                })}
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Flex gap={5}>
              <Button
                leftIcon={<CloseIcon boxSize={3} />}
                colorScheme="red"
                onClick={() => {
                  setModalProjectUserTeam(false);
                }}
              >
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default HrisOfficeSinglePage;
