import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/react';
import { Chart } from 'chart.js/auto';
import PropTypes from 'prop-types';

const ChartsPiePayroll = ({
  netSalary,
  totalDeductions,
  totalAllowance,

}) => {
  const [chartInstance, setChartInstance] = useState(null);

  const chartData = {
    labels: ['Nett Salary', 'Total Deductions', 'Total Allowance'],
    datasets: [
      {
        data: [netSalary, totalDeductions, totalAllowance],
        backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(75, 192, 192, 0.6)', 'rgba(255, 206, 86, 0.6)'],
      },
    ],
  };

  useEffect(() => {
    // Destroy the existing chart instance if it exists
    if (chartInstance) {
      chartInstance.destroy();
    }

    const ctx = document.getElementById('myPieChart').getContext('2d');
    const newChartInstance = new Chart(ctx, {
      type: 'pie',
      data: chartData,
    });

    // Save the new chart instance
    setChartInstance(newChartInstance);

    // Clean up: Destroy the chart when the component unmounts
    return () => {
      if (newChartInstance) {
        newChartInstance.destroy();
      }
    };
  }, []);

  return (
    <Stack className="pie-chart" spacing={2} w={'50%'}>
      <canvas id="myPieChart" width="400" height="400"></canvas>
    </Stack>
  );
};

ChartsPiePayroll.propTypes = {
  netSalary: PropTypes.number,
  totalDeductions: PropTypes.number,
  totalAllowance: PropTypes.number,
}

export default ChartsPiePayroll