/* eslint-disable no-undef */
const Typesense = require('typesense',);

export const clientTypessense = new Typesense.Client({
  nodes: [
    {
      // host: process.env.REACT_APP_TYPESENSE_HOST, // For Typesense Cloud use xxx.a1.typesense.net
      host: 'bynu8vp41sx3m0zwp-1.a1.typesense.net', // For Typesense Cloud use xxx.a1.typesense.net
      port: '443', // For Typesense Cloud use 443
      protocol: 'https', // For Typesense Cloud use https
    },
  ],
  // apiKey: API,
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 2,
},);


// REACT_APP_TYPESENSE_HOST="bynu8vp41sx3m0zwp-1.a1.typesense.net"
// REACT_APP_TYPESENSE_API_KEY="Bnc8j41P6HyAySid8vc218BusSAXh30f"
