import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  useNavigate,
  // useLocation,
  // useNavigate
} from 'react-router-dom';
import HomeRouter from './HomeRouter';
import SettingRouter from './SettingRouter';
import HRISRouter from './HRISRouter';
import GARouter from './GARouter';
import AuthenticationMainRouter from './AuthenticationMainRouter';
import RecruitmentRouter from './RecruitmentRouting';
// import useUserStore from '../Hooks/Zustand/Store';
import Layout from '../Layouts';
import useUserStore from '../Hooks/Zustand/Store';

function MainRouter() {
  const globalState = useUserStore();
  const navigate = useNavigate()


  const allRouter = [
    ...AuthenticationMainRouter,
    ...HRISRouter,
    ...HomeRouter,
    ...SettingRouter,
    ...GARouter,
    ...RecruitmentRouter
  ];

  const isPathAllowed = (pathname) => {



    if (pathname.includes('attendance')) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    const path = location.pathname;

    if (globalState.roleCompany !== 'owner' || globalState.roleProject === 'user') {
      if (
        path &&
        path !== '/' &&
        path !== '/login' &&
        path !== '/settings' &&
        path !== '/notification' &&
        path !== '/users' &&
        path !== '/hris' &&
        path !== '/no-access' &&
        !isPathAllowed(path)
      ) {
        // return navigate('/no-access');
      }
    }

    return () => {
      // Cleanup, if needed
    };
  }, [location.pathname]);


  return (
    <Routes>
      {allRouter.map((item, index) => {
        return (
          <Route
            key={index}
            path={item.path}
            element={<Layout>{item.element}</Layout>}
          />
        );
      })}
    </Routes>
  );
}

export default MainRouter;
