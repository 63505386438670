import React, { useEffect, useState } from 'react'
import { VStack, HStack, Image, Stack, Text, Divider, Box, Button, Heading, List, ListItem, ListIcon, Spinner, } from '@chakra-ui/react';
import { FcDepartment, FcMoneyTransfer } from 'react-icons/fc';
import defaultImage from '../../assets/icons/importir.jpg'
import { useNavigate, useParams } from 'react-router-dom';
import {
  CheckIcon,
} from '@chakra-ui/icons';
import { dateFormat } from '../../Utils/AccountingUtil';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import { auth } from '../../Config/firebase';

function RecruitmentFrontPreview() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dataJob, setDataJob] = useState({});



  const getJobDetail = async () => {
    setLoading(true);

    try {
      const res = await getSingleDocumentFirebase('positions', id);
      if (res !== undefined) setDataJob(res);
    } catch (error) {
      // console.log(error.message, 'error getting job detail')
    } finally {
      setLoading(false)
    }
  };

  const handleNavigateApply = () => {
    if (auth.currentUser) navigate(`/preview/${id}/apply`)
    navigate('/signup/recruitment');
    return localStorage.setItem('pendingRecruitment', id);
  };


  useEffect(() => {
    getJobDetail();
  }, []);

  return (
    <>
      {loading && <Stack zIndex={2} bg='white' width={window.innerWidth} height={window.innerHeight} justifyContent='center' alignItems='center'><Spinner size='lg' /></Stack>}
      <VStack p={[2, 5, 5]} bgColor={'white'} my={2} align={'left'} justify={'space-between'} w={'100%'} borderBottomRadius={'3xl'} >
        <VStack align={'flex-start'}>
          <HStack gap={2} align={'flex-start'}>
            <Image boxSize="100px" src={defaultImage} />
            <Stack >
              <Stack align={'left'} justify={'left'} w={'full'} my={3}>
                <HStack align={'leftn'}>
                  <FcDepartment size={25} />
                  <Text fontWeight='bold'>{(dataJob?.company)?.toUpperCase()}</Text>
                </HStack>
                <HStack align={'left'}>
                  <FcDepartment size={25} />
                  <Text>{dataJob?.address}</Text>
                </HStack>
                <HStack align={'left'}>
                  <FcMoneyTransfer size={25} />
                  <Text>
                    {dataJob?.range_salary_high
                      ? `Rp${new Intl.NumberFormat('en-ID', {
                        maximumSignificantDigits: 3,
                      }).format(
                        dataJob.range_salary_low
                      )} - Rp${new Intl.NumberFormat('en-ID', {
                        maximumSignificantDigits: 3,
                      }).format(dataJob.range_salary_high)} `
                      : 'Negotiable'}
                  </Text>
                </HStack>
                <Text fontSize={12} color="blackAlpha.600">
                  Berakhir pada : {dateFormat(dataJob?.expiry)}
                </Text>
              </Stack>
            </Stack>
          </HStack>
        </VStack>
      </VStack>

      <Divider />
      <Stack bgColor={'white'} my={1} borderRadius={'2xl'} p={[2, 5, 5]}>
        <Box my={2}>
          <Heading fontSize="lg" mb={2}>About Our Program</Heading>

          <Text fontSize={'17px'} fontWeight={'medium'}>{dataJob?.description}</Text>
        </Box>
        {/* <Divider m="2" width="80%" /> */}
        <Box my={2} >
          <Heading fontSize="lg" my={2}>Job Description</Heading>
          <List mt={2} spacing={1} ml={5}>
            {dataJob?.jobdesc?.length > 0
              ? dataJob.jobdesc.map((x, i) => (
                <ListItem key={i}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  {x}
                </ListItem>
              ))
              : null}
          </List>
        </Box>

        {/* <Divider m="2" width="80%" /> */}
        <Box my={2}>
          <Heading fontSize="lg" my={2}>Qualification</Heading>
          <List mt={2} spacing={1} ml={5}>
            {dataJob?.requirements?.length > 0
              ? dataJob.requirements.map((x, i) => (
                <ListItem key={i}>
                  <ListIcon as={CheckIcon} color="green.500" />
                  {x}
                </ListItem>
              ))
              : null}
          </List>
        </Box>
        <Box my={2}>
          <Heading fontSize="lg" my={2}>
            Additional Information
          </Heading>
          <HStack w={['full', '50%', '50%']} justify={'space-between'}>
            <VStack gap={5} >
              <VStack align={'left'} w={'100%'}>
                <Text fontWeight={'medium'}>Position Level :</Text>
                <Text>{dataJob?.level ? dataJob.level : <></>}</Text>
              </VStack>
              <VStack align={'left'} w={'100%'}>
                <Text fontWeight={'medium'}>Category :</Text>
                <Text>{dataJob?.time ? dataJob.time : <></>}</Text>
              </VStack>
            </VStack>

            <VStack gap={5}>
              <VStack align={'left'} w={'100%'}>
                <Text fontWeight={'medium'}>Minimum Experience :</Text>
                <Text>{dataJob?.experience ? dataJob.experience : <></>}</Text>
              </VStack>
              <VStack align={'left'} w={'100%'}>
                <Text fontWeight={'medium'}>Placement :</Text>
                <Text>{dataJob?.workplace ? dataJob.workplace : <></>}</Text>
              </VStack>
            </VStack>
          </HStack>
        </Box>
        <Button bgColor="brand.100" onClick={handleNavigateApply}>
          Apply now
        </Button>
      </Stack>
    </>
  )
}

export default RecruitmentFrontPreview