import {
  Button,
  Heading,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  FormControl,
  FormLabel, 
  Select,
  useToast,
  Textarea,
  SimpleGrid,
  Radio, 
  RadioGroup,
  Image,
  Box,
  FormErrorMessage,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Center,
  IconButton,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import BackButtons from '../../../Components/Buttons/BackButtons';
import {
  collection,
  doc,
  addDoc,
  updateDoc,
  deleteDoc,
  getCountFromServer,
  query,
  where
} from 'firebase/firestore';
import { db } from '../../../Config/firebase';
import useUserStore from '../../../Hooks/Zustand/Store';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DynamicButton from '../../../Components/Buttons/DynamicButton';
import DynamicTable from '../../../Components/Table/DynamicTable';
import { useNavigate } from 'react-router-dom';
import { getCollectionFirebase } from '../../../Api/firebaseApi';
import axios from 'axios';
import { formatFrice } from '../../../Utils/numberUtil';
import { LinkIcon } from '@chakra-ui/icons';

function AssetPage() {
  const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  const [formDataEdit, setFormDataEdit] = useState({
    title: '',
    type: '',
    location: '',
    category: '',
    brand: '',
    code: '',
    price_per_quantity: 0,
    quantity: 0,
    spesification: '',
    condition: '',
    date_of_purchase: '',
    image: ''
  })

  const [formDataCreate, setFormDataCreate] = useState({
    spesification: '',
    condition: '',
    date_of_purchase: '',
    image: ''
  })
  const [fields, setFields] = useState([]);
  const itemsPerPage = 10
  
  const [assetData, setAssetData] = useState([])
  const [brands, setBrands] = useState([])
  const [locations, setLocations] = useState([])
  const [categories, setCategories] = useState([])
  const globalState = useUserStore();

  const [id, setId] = useState([])
  const toast = useToast()
  const cancelRef = React.useRef();
  const [dataActive, setDataActive] = useState('')
  const [deleteModal, setDeleteModal] = useState(false);
  const [nextId, setNextId] = useState(0);
  const [createModal, setCreateModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [dataSearch, setDataSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDate] = useState('');
  const navigate = useNavigate()
  const schema = yup.object().shape({
    title: yup.string().required('Title is Required'),
    type: yup.string().required('Type is Required'),
    location: yup.string().required('Location is Required'),
    category: yup.string().required('Category is Required'),
    brand: yup.string().required('Brand is Required'),
    price: yup.number().required('Price is Required'),
    spesification: yup.string().required('Spesification is Required'),
    condition: yup.string().required('Condition is Required'),
    dateOfPurchase: yup.string().required('Date of Purchase is Required')
  })
  const { control, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const openModalCreate = () => {
    setFields([{
      id: nextId+1,
      title: '',
      type: '',
      location: '',
      category: '',
      brand: '',
      price_per_quantity: 0,
      quantity: 0,
      code: ''
    }])

    const formCreateLocal = {
      spesification: '',
      condition: '',
      date_of_purchase: '',
      image: ''
    }
    setFormDataCreate(formCreateLocal)
    setCreateModal(true)
  };
  const closeModalCreate = () => setCreateModal(false);

  const openModalEdit = (x) => {
    setEditModal(true)
    setId(x.id_firebase);
    const formDataEditLocal = formDataEdit
    formDataEditLocal.title = x?.title
    formDataEditLocal.type = x?.type
    formDataEditLocal.location = x?.location
    formDataEditLocal.category = x?.category
    formDataEditLocal.brand = x?.brand
    formDataEditLocal.code = x?.code
    formDataEditLocal.quantity = x?.quantity
    formDataEditLocal.price_per_quantity = x.price_per_quantity.replace(/,/g, '')
    formDataEditLocal.spesification = x?.spesification
    formDataEditLocal.condition = x?.condition
    formDataEditLocal.date_of_purchase = x?.date_of_purchase
    formDataEditLocal.image = x?.image
    setFormDataEdit(formDataEditLocal)
  };

  const closeModalEdit = () => setEditModal(false);
      

  const addField = () => {
    const newField = {
      id: nextId+1,
      type: '',
      location: '',
      category: '',
      brand: '',
      price_per_quantity: 0,
      quantity: 0,
      code: ''
    };
    setFields([...fields, newField]);
    setNextId(nextId + 1);
  };

  const deleteField = (id) => {
    const updatedFields = fields.filter((field) => field.id !== id);
    setFields(updatedFields);
  };
    
  const handleChange = (id, updatedFields) => {
    setFields((prevFields) => {
      return prevFields.map((field) => {
        if (field.id === id) {
          return { ...field, ...updatedFields };
        } else {
          return field;
        }
      });
    });
  };

  const depreciationValueCalc = (category, dateOfPurchase, priceTotal) => {
    const selectedCategory = categories.find((x) => x.name === category)
    const startYear = parseInt(dateOfPurchase.split('-')[0])
    const endYear = startYear + parseInt(selectedCategory.economy_years)
    const depValue = Math.ceil(selectedCategory.depreciation_percentage/100 *priceTotal/12);

    const depValues = [];
    let nilaiAwal = 0;
    let akumulasiPenyusutan = 0;
    let nilaiAkhir = 0
    for (let i = startYear; i <= endYear; i++) {
      const perMonth = [];
      months.forEach((x) => {
        const selectedMonth = months[parseInt(dateOfPurchase.split('-')[1]) - 1];
        if (selectedMonth === x && i === parseInt(dateOfPurchase.split('-')[0])) {
          nilaiAwal = priceTotal
        }

        if (nilaiAwal > 0) {
          akumulasiPenyusutan = akumulasiPenyusutan + depValue
          nilaiAkhir = nilaiAwal - depValue
        }

        perMonth.push({
          'month': x,
          'nilai_awal': nilaiAwal,
          'dep_value': depValue,
          'akumulasi_penyusutan': akumulasiPenyusutan,
          'nilai_akhir': nilaiAkhir
        })
        if (nilaiAwal > 0) {
          nilaiAwal = nilaiAkhir
        }
        if (akumulasiPenyusutan > priceTotal) {
          nilaiAwal = 0;
          akumulasiPenyusutan = 0;
          nilaiAkhir = 0;
        }
      })
      depValues.push({
        'year': i,
        'months': perMonth
      })
    }
    return {
      'selected_category': selectedCategory,
      'values': depValues,
      'start_year': startYear,
      'end_year': endYear
    }
  }
      
  const handleCreate = async (event) => {
    try {
      event.preventDefault();
      fields.map((field) => {
        const totalPrice = field.price_per_quantity * field.quantity
        const resultDepCal = depreciationValueCalc(field.category, formDataCreate.date_of_purchase, totalPrice)
        const assetData = {
          companyId: globalState.currentCompany,
          projectId: globalState.currentProject,
          title: field.title,
          type: field.type,
          location: field.location,
          category: field.category,
          type_category: resultDepCal.selected_category.type,
          brand: field.brand,
          price_per_quantity: field.price_per_quantity,
          quantity: field.quantity,
          code: field.code,
          spesification: formDataCreate.spesification,
          condition: formDataCreate.condition,
          image: formDataCreate.image,
          date_of_purchase: formDataCreate.date_of_purchase,
          start_year: resultDepCal.start_year,
          end_year: resultDepCal.end_year,
          depreciation_values: JSON.stringify(resultDepCal.values),
          created_at: moment().format('MMMM Do YYYY, h:mm:ss a')
        };
        return addDoc(collection(db, 'asset'), assetData);
      });

      setCreateModal(false)
      await getData()
                            
      toast({
        title: 'Deoapp.com',
        description: 'Successfully save data',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });    
    }
  }

  const handleEdit = async (event) => {
    try {
      event.preventDefault();
      globalState.setIsLoading(true)
      const data = doc(db, 'asset', id)
      const totalPrice = formDataEdit.price_per_quantity * formDataEdit.quantity
      const resultDepCal = depreciationValueCalc(formDataEdit.category, formDataEdit.date_of_purchase, totalPrice)
      await updateDoc(data, {
        title: formDataEdit.title,
        type: formDataEdit.type,
        location: formDataEdit.location,
        category: formDataEdit.category,
        type_category: resultDepCal.selected_category.type,
        brand: formDataEdit.brand,
        price_per_quantity: formDataEdit.price_per_quantity,
        quantity: formDataEdit.quantity,
        code: formDataEdit.code,
        spesification: formDataEdit.spesification,
        condition: formDataEdit.condition,
        image: formDataEdit.image,
        start_year: resultDepCal.start_year,
        end_year: resultDepCal.end_year,
        depreciation_values: JSON.stringify(resultDepCal.values),
      })
      globalState.setIsLoading(false)
      setEditModal(false)
      await getData()
      toast({
        title: 'Deoapp.com',
        description: 'Successfully update data',
        status: 'success',
        position: 'top-right',
        isClosable: true,
      });
    } catch (error) {
      globalState.setIsLoading(false)
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });    
    }
  }

  const getData = async () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany }
    ]
    if (selectedDate) {
      const startDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);

      const endDate = new Date(selectedDate);
      endDate.setHours(23, 59, 59, 999);

      conditions.push(
        { field: 'created_at', operator: '>=', value: startDate },
        { field: 'created_at', operator: '<=', value: endDate }
      );
    }
    const sortBy =  { field: 'created_at', direction: 'asc' };
    const limitValue = startIndex + itemsPerPage;
    try {
      const res = await getCollectionFirebase(
        'asset',
        conditions,
        sortBy,
        limitValue,
      );
      setAssetData(res);

      const collectionRef = collection(db, 'asset');
      const snapshot = await getCountFromServer(
        query(
          collectionRef,
          where('companyId', '==', globalState.currentCompany),
        ));

      const assesmentLength = snapshot.data().count;
        
      setTotalPages(Math.ceil(assesmentLength / itemsPerPage));
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const getDataBrand = async () => {
    try {
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany }
      ]
      const resBrands = await getCollectionFirebase(
        'asset_brands',
        conditions
      );
      setBrands(resBrands)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const getLocation = async () => {
    try {
      globalState.setIsLoading(true)

      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany }
      ]
      const resLocations = await getCollectionFirebase(
        'asset_locations',
        conditions
      );
      setLocations(resLocations);
      globalState.setIsLoading(false)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const getCategory = async () => {
    try {
      globalState.setIsLoading(true)
      const conditions = [
        { field: 'companyId', operator: '==', value: globalState.currentCompany }
      ]
      const sortBy =  { field: 'created_at', direction: 'asc' };
      const resCategories = await getCollectionFirebase(
        'asset_categories',
        conditions,
        sortBy
      );
      setCategories(resCategories);
      globalState.setIsLoading(false)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  }

  const handleDelete = async () => {
    globalState.setIsLoading(true)
    await deleteDoc(doc(db, 'asset', dataActive.id_firebase))
    toast({
      title: 'Deoapp.com',
      description: 'Success delete this post',
      status: 'success',
      position: 'top-right',
      isClosable: true,
    });
    await getData()
    globalState.setIsLoading(false)
    setDeleteModal(false)
  }

  const handleDeleteModal = (row) => {
    setDeleteModal(true);
    setDataActive(row)
  };

  const handleFileChange = async (file, type) => {
    try {
      // const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
          
      globalState.setIsLoading(true)
      const res = (await axios.post('https://new-apiv2.importir.com/api/storage?directory=general', formData)).data;
      globalState.setIsLoading(false)
      if (!res.status) {
        toast({
          title: 'Deoapp.com',
          description: res.message,
          status: 'error',
          position: 'top-right',
          isClosable: true,
          duration: 3000
        });
        return
      }
      if (type === 'create') {
        setFormDataCreate({...formDataCreate, 'image': res.data})
      } else {
        setFormDataEdit({...formDataEdit, 'image': res.data})
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  };

  const handleCustom = (row) => {
    navigate(`/ga/asset/${row.id_firebase}/detail`)
  }

  const tableHeader = ['id', 'code', 'title', 'image', 'category', 'brand', 'location', 'type', 'price_per_quantity', 'quantity', 'spesification', 'condition', 'date_of_purchase', 'created']
  const tableData = assetData.map((data, index) => {
    const code = data.code || ''
    const title = data.title || ''
    const image = data.image || ''
    const category = data.category || ''
    const brand = data.brand || ''
    const location = data.location || ''
    const type = data.type || ''
    const spesification = data.spesification || ''
    const condition = data.condition || ''
    const created = data?.created_at

    return {
      id_firebase: data.id,
      id: index +1,
      code: code,
      title: title,
      image: image,
      category: category,
      brand: brand,
      location: location,
      type: type,
      price_per_quantity: formatFrice(parseInt(data.price_per_quantity)),
      quantity: data.quantity,
      spesification: spesification,
      condition: condition,
      date_of_purchase: data?.date_of_purchase,
      created: moment(created, 'MMMM Do YYYY, h:mm:ss a').format('DD-MM-YYYY')
    }
  })

  const tableDataFilter = dataSearch?.map((data, index) => {
    const code = data.code || ''
    const image = data.image || ''
    const category = data.category || ''
    const brand = data.brand || ''
    const location = data.location || ''
    const type = data.type || ''
    const price = data.price || ''
    const spesification = data.spesification || ''
    const condition = data.condition || ''
    const status = data.status || ''
    const created = data?.created_at

    return {
      data,
      id: index +1,
      code: code,
      image: image,
      category: category,
      brand: brand,
      location: location,
      type: type,
      price: price,
      spesification: spesification,
      condition: condition,
      status: status,
      created: moment(created, 'MMMM Do YYYY, h:mm:ss a').format('DD-MM-YYYY')
    }
  })

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = assetData.filter((item) => {
        const itemData = item.code
          ? item.code.toUpperCase()
          : ''.toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
      setDataSearch(newData);
      setInputSearch(text);
    } else {
      setDataSearch(assetData);
      setInputSearch(text);
    }
  };

  const inputStyles = {
    '&::placeholder': {
      color: 'gray.500',
    },
  };

  useEffect(() => {
    getDataBrand()
    getLocation()
    getCategory()
    getData()
  }, []);

  return (
    <Stack p={[1, 1, 5]}>
      <BackButtons />
      <Stack spacing={4}>
        <HStack>
          <Heading size={'md'} fontWeight="bold">
            Assets
          </Heading>
          <Spacer />
          
          <Input
            w={['100%', '30%', '30%']}
            type="text"
            placeholder="Search Code"
            bgColor="white"
            color="black"
            sx={inputStyles}
            fontSize="sm"
            onChange={(e) => searchFilterFunction(e.target.value)}
          />
          <HStack>
            <DynamicButton action={'create'} title={'New Asset'} onClick={() => openModalCreate()}/>
          </HStack>
        </HStack>

        <Stack
          bgColor="white"
          spacing={1}
          borderRadius="xl"
          p={3}
          m={[1, 1, 5]}
          shadow="md"
        >
          <Box overflowY={'auto'}>
            {inputSearch !== '' ? (
              <DynamicTable header={tableHeader} data={tableDataFilter}  onDelete={handleDeleteModal} onEdit={openModalEdit} onRead={handleCustom}/>
            ) :
              <DynamicTable header={tableHeader} data={tableData}  onDelete={handleDeleteModal} onEdit={openModalEdit} onRead={handleCustom}/>
            }
          </Box>
          <Stack alignItems={'center'} justifyContent="center">
            {currentPage < totalPages && (
              <Button colorScheme={'blue'} fontSize="sm" onClick={handleLoadMore}>
                Load More
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>

      <AlertDialog
        isOpen={deleteModal}
        leastDestructiveRef={cancelRef}
        onClose={() => setDeleteModal(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure delete this posting? You cant undo this action
              afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setDeleteModal(false)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => handleDelete()}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
            
      <Modal
        isOpen={createModal}
        onClose={closeModalCreate}
        size={'3xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Asset</ModalHeader>
          <HStack p={1} mr={'5'} justifyContent={'end'} alignItems={'end'}>
            <DynamicButton action={'create'} title={'Add Field'} onClick={() => addField()}/>
          </HStack>
          <ModalCloseButton />
          <form onSubmit={handleCreate}>
            <ModalBody pb={6}>
              {fields.map((data, index) => (
                <Box key={data.id} borderRadius={'lg'} shadow={'lg'} p={2} mt={2}>

                  <FormControl isInvalid={errors[`title${index}`]}>
                    <FormLabel>Title</FormLabel>
                                      
                    <Controller
                      name={`title${index}`}
                      control={control}
                      defaultValue={data.title}
                      render={({ field }) => (
                        <Input
                          type="text"
                          {...field}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            handleChange(data.id, {title:selectedValue});
                            field.onChange(e);
                          }}
                          placeholder='Example: Kursi gaming'
                          value={data.title}
                        />
                      )}
                    />
                    <FormErrorMessage>{errors[`title${index}`]?.message}</FormErrorMessage>
                  </FormControl>
                  <SimpleGrid spacing={3} columns={2} mt={'3'}>
                    <FormControl isInvalid={errors[`type${index}`]}>
                      <FormLabel>Type</FormLabel>
                      <Controller
                        name={`type${index}`}
                        control={control}
                        defaultValue={data.type}
                        render={({ field }) => (
                          <Select
                            placeholder="Select Type"
                            {...field}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              handleChange(data.id, {type:selectedValue});
                              field.onChange(e);
                            }}
                            value={data.type}
                          >
                            <option value="asset">Asset</option>
                            <option value="inventory">Inventory</option>
                            <option value="general_asset">General Asset</option>
                          </Select>
                        )}
                      />
                      <FormErrorMessage>{errors[`type${index}`]?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors[`location${index}`]}>
                      <FormLabel>Location</FormLabel>
                      <Controller
                        name={`location${index}`}
                        control={control}
                        defaultValue={data.location}
                        render={({ field }) => (
                          <Select
                            placeholder="Select Location"
                            {...field}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              handleChange(data.id, {location:selectedValue});
                              field.onChange(e);
                            }}
                            value={data.location}
                          >
                            {locations.map((x, i) => (
                              <option key={i} value={x.name}>
                                {x.name}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                      <FormErrorMessage>{errors[`location${index}`]?.message}</FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>
                  <SimpleGrid spacing={3} columns={2} mt={'3'}>
                    <FormControl isInvalid={errors[`category${index}`]}>
                      <FormLabel>
                        Category <IconButton
                          ml={3}
                          onClick={() => window.open('https://d2jnbxtr5v4vqu.cloudfront.net/filemanager/Imam/fixed-asset-deoapp-kategori-2024_03_21_11_54_46.pdf', '_blank')}
                          colorScheme='blue'
                          aria-label='Search database'
                          icon={<LinkIcon />}
                        />
                      </FormLabel>
                      <Controller
                        name={`category${index}`}
                        control={control}
                        defaultValue={data.category}
                        render={({ field }) => (
                          <Select
                            placeholder="Select Category"
                            {...field}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              handleChange(data.id, {category:selectedValue});
                              field.onChange(e);
                            }}
                            value={data.category}
                          >
                            {categories.map((x, i) => (
                              <option key={i} value={x.name}>
                                ({x.type}) {x.name}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                      <FormErrorMessage>{errors[`category${index}`]?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors[`brand${index}`]}>
                      <FormLabel>Brand</FormLabel>
                      <Controller
                        name={`brand${index}`}
                        control={control}
                        defaultValue={data.brandName}
                        render={({ field }) => (
                          <Select
                            placeholder="Select Brand"
                            {...field}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              handleChange(data.id, {brand:selectedValue});
                              field.onChange(e);
                            }}
                            value={data.brand}
                          >
                            {brands.map((x, i) => (
                              <option key={i} value={x.name}>
                                {x.name}
                              </option>
                            ))}
                          </Select>
                        )}
                      />
                      <FormErrorMessage>{errors[`brand${index}`]?.message}</FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>

                  <SimpleGrid spacing={3} columns={2} mt={'3'}>
                    <FormControl isInvalid={errors[`quantity${index}`]}>
                      <FormLabel>Quantity</FormLabel>
                                        
                      <Controller
                        name={`quantity${index}`}
                        control={control}
                        defaultValue={data.quantity}
                        render={({ field }) => (
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              handleChange(data.id, {quantity:selectedValue});
                              field.onChange(e);
                            }}
                            value={data.quantity}
                          />
                        )}
                      />
                      <FormErrorMessage>{errors[`quantity${index}`]?.message}</FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={errors[`price_per_quantity${index}`]}>
                      <FormLabel>Price per Quantity</FormLabel>
                                        
                      <Controller
                        name={`price_per_quantity${index}`}
                        control={control}
                        defaultValue={data.price_per_quantity}
                        render={({ field }) => (
                          <Input
                            type="number"
                            {...field}
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              handleChange(data.id, {price_per_quantity:selectedValue});
                              field.onChange(e);
                            }}
                            value={data.price_per_quantity}
                          />
                        )}
                      />
                      <FormErrorMessage>{errors[`price_per_quantity${index}`]?.message}</FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>
                  <FormControl>
                    <FormLabel>Code</FormLabel>
                    <Input placeholder='Please input code' name={`code${index}`} isDisabled value={data.code = `${data.type}/${data.location}/${data.category}/${data.id + assetData.length }`} />
                  </FormControl>
                                
                  <HStack justifyContent={'end'} alignItems={'end'}>
                    <Button
                      colorScheme="red"
                      onClick={() => deleteField(data.id)}
                      mt={2}
                    >
                      Delete
                    </Button>
                  </HStack>
                </Box>
              ))}

              <FormControl isInvalid={errors.spesification} mt={'10'}>
                <FormLabel>Spesification</FormLabel>
                <Controller
                  name="spesification"
                  control={control}
                  defaultValue={formDataCreate.spesification}
                  render={({ field }) => (
                    <Textarea
                      onChange={(e) => {
                        setFormDataCreate({...formDataCreate, spesification: e.target.value})
                        field.onChange(e);
                      }}
                      value={formDataCreate.spesification}
                    />
                  )}
                />
                <FormErrorMessage>{errors.spesification?.message}</FormErrorMessage>
              </FormControl>
                            
              <FormControl isInvalid={errors.condition} mt={'3'}>
                <FormLabel>Condition</FormLabel>
                <Controller
                  name="condition"
                  control={control}
                  defaultValue={formDataCreate.condition}
                  render={({ field }) => (
                    <RadioGroup
                      onChange={(value) => {
                        setFormDataCreate({...formDataCreate, condition: value})
                        field.onChange(value);
                      }}
                    >
                      <Stack direction="row">
                        <Radio value="new">New</Radio>
                        <Radio value="good">Good</Radio>
                        <Radio value="broken">Broken</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
                <FormErrorMessage>{errors.condition?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.dateOfPurchase} mt={'3'}>
                <FormLabel>Date of Purchase</FormLabel>
                <Controller
                  name="dateOfPurchase"
                  control={control}
                  defaultValue={formDataCreate.date_of_purchase}
                  render={({ field }) => (
                    <Input
                      type="date"
                      onChange={(e) => {
                        setFormDataCreate({...formDataCreate, date_of_purchase: e.target.value})
                        field.onChange(e);
                      }}
                      value={formDataCreate.date_of_purchase}
                    />
                  )}
                />
                <FormErrorMessage>{errors.dateOfPurchase?.message}</FormErrorMessage>
              </FormControl>
              <FormControl mt={'3'}>
                <FormLabel>Image</FormLabel>
                {formDataCreate?.image ? 
                  <Center>
                    <Box mt={2}>
                      <Image
                        src={formDataCreate?.image}
                        width={300}
                        borderRadius="xl"
                        alt="Image not found"
                        shadow="md"
                      />
                      <Center>
                        <Button colorScheme='red' mt={3} type="button" onClick={() => setFormDataCreate({...formDataCreate, 'image' : ''})}>
                          Change
                        </Button>
                      </Center>
                    </Box>
                  </Center>
                  : 
                  <HStack>
                    <Stack>
                      <input type="file" multiple onChange={(e) => handleFileChange(e.target.files[0], 'create')} />
                    </Stack>
                  </HStack>}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} type="submit">
                Create
              </Button>
              <Button onClick={closeModalCreate}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={editModal}
        onClose={closeModalEdit}
        size={'3xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Asset</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleEdit}>
            <ModalBody pb={6}>
              <FormControl isInvalid={errors['title']}>
                <FormLabel>Title</FormLabel>
                                  
                <Controller
                  name={'title'}
                  control={control}
                  defaultValue={formDataEdit.title}
                  render={({ field }) => (
                    <Input
                      type="text"
                      {...field}
                      onChange={(e) => {
                        setFormDataEdit({...formDataEdit, title: e.target.value})
                        field.onChange(e);
                      }}
                      placeholder='Example: Kursi gaming'
                    />
                  )}
                />
                <FormErrorMessage>{errors['title']?.message}</FormErrorMessage>
              </FormControl>
              <SimpleGrid spacing={3} columns={2} mt={'3'}>
                <FormControl isInvalid={errors['type']}>
                  <FormLabel>Type</FormLabel>
                  <Controller
                    name={'type'}
                    control={control}
                    defaultValue={formDataEdit.type}
                    render={({ field }) => (
                      <Select
                        placeholder="Select Type"
                        {...field}
                        onChange={(e) => {
                          setFormDataEdit({...formDataEdit, type: e.target.value})
                          field.onChange(e);
                        }}
                        value={formDataEdit.type}
                      >
                        <option value="asset">Asset</option>
                        <option value="inventory">Inventory</option>
                        <option value="general_asset">General Asset</option>
                      </Select>
                    )}
                  />
                  <FormErrorMessage>{errors['type']?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors['location']}>
                  <FormLabel>Location</FormLabel>
                  <Controller
                    name={'location'}
                    control={control}
                    defaultValue={formDataEdit.location}
                    render={({ field }) => (
                      <Select
                        placeholder="Select Location"
                        {...field}
                        onChange={(e) => {
                          setFormDataEdit({...formDataEdit, location: e.target.value})
                          field.onChange(e);
                        }}
                        value={formDataEdit.location}
                      >
                        {locations.map((x, i) => (
                          <option key={i} value={x.name}>
                            {x.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                  <FormErrorMessage>{errors['location']?.message}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid spacing={3} columns={2} mt={'3'}>
                <FormControl isInvalid={errors['category']}>
                  <FormLabel>Category</FormLabel>
                  <Controller
                    name={'category'}
                    control={control}
                    defaultValue={formDataEdit.category}
                    render={({ field }) => (
                      <Select
                        placeholder="Select Category"
                        {...field}
                        onChange={(e) => {
                          setFormDataEdit({...formDataEdit, category: e.target.value})
                          field.onChange(e);
                        }}
                        value={formDataEdit.category}
                      >
                        {categories.map((x, i) => (
                          <option key={i} value={x.name}>
                            {x.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                  <FormErrorMessage>{errors['category']?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors['brand']}>
                  <FormLabel>Brand</FormLabel>
                  <Controller
                    name={'brand'}
                    control={control}
                    defaultValue={formDataEdit.brand}
                    render={({ field }) => (
                      <Select
                        placeholder="Select Brand"
                        {...field}
                        onChange={(e) => {
                          setFormDataEdit({...formDataEdit, brand: e.target.value})
                          field.onChange(e);
                        }}
                        value={formDataEdit.brand}
                      >
                        {brands.map((x, i) => (
                          <option key={i} value={x.name}>
                            {x.name}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                  <FormErrorMessage>{errors['brand']?.message}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>

              <SimpleGrid spacing={3} columns={2} mt={'3'}>
                <FormControl isInvalid={errors['quantity']}>
                  <FormLabel>Quantity</FormLabel>
                                    
                  <Controller
                    name={'quantity'}
                    control={control}
                    defaultValue={formDataEdit.quantity}
                    render={({ field }) => (
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) => {
                          setFormDataEdit({...formDataEdit, quantity: e.target.value})
                          field.onChange(e);
                        }}
                        value={formDataEdit.quantity}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors['quantity']?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors['price']}>
                  <FormLabel>Price per Quantity</FormLabel>
                                        
                  <Controller
                    name={'price'}
                    control={control}
                    defaultValue={formDataEdit.price_per_quantity}
                    render={({ field }) => (
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) => {
                          setFormDataEdit({...formDataEdit, price_per_quantity: e.target.value})
                          field.onChange(e);
                        }}
                        value={formDataEdit.price_per_quantity}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors['price']?.message}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>

              <FormControl>
                <FormLabel>Code</FormLabel>
                <Input placeholder='Please input code' name={'code'} isDisabled value={formDataEdit.code}/>
              </FormControl>

              <FormControl isInvalid={errors.spesification} mt={'3'}>
                <FormLabel>Spesification</FormLabel>
                <Controller
                  name="spesification"
                  control={control}
                  defaultValue={formDataEdit.spesification}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      onChange={(e) => {
                        setFormDataEdit({...formDataEdit, spesification: e.target.value})
                        field.onChange(e);
                      }}
                      value={formDataEdit.spesification}
                    />
                  )}
                />
                <FormErrorMessage>{errors.spesification?.message}</FormErrorMessage>
              </FormControl>
                            
              <FormControl isInvalid={errors.condition} mt={'3'}>
                <FormLabel>Condition</FormLabel>
                <Controller
                  name="condition"
                  control={control}
                  defaultValue={formDataEdit.condition}
                  render={({ field }) => (
                    <RadioGroup 
                      onChange={(value) => {
                        setFormDataEdit({...formDataEdit, condition: value})
                        field.onChange(value);
                      }}
                      defaultValue={formDataEdit?.condition}>
                      <Stack direction="row">
                        <Radio value="new">New</Radio>
                        <Radio value="good">Good</Radio>
                        <Radio value="broken">Broken</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                />
                <FormErrorMessage>{errors.condition?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={errors.dateOfPurchase} mt={'3'}>
                <FormLabel>Date of Purchase</FormLabel>
                <Controller
                  name="dateOfPurchase"
                  control={control}
                  defaultValue={formDataEdit.date_of_purchase}
                  render={({ field }) => (
                    <Input
                      type="date"
                      {...field}
                      onChange={(e) => {
                        setFormDataEdit({...formDataEdit, date_of_purchase: e.target.value})
                        field.onChange(e);
                      }}
                      value={formDataEdit.date_of_purchase}
                    />
                  )}
                />
                <FormErrorMessage>{errors.dateOfPurchase?.message}</FormErrorMessage>
              </FormControl>

              <FormControl mt={'3'}>
                <FormLabel>Image</FormLabel>
                
                {formDataEdit?.image ? 
                  <Center>
                    <Box mt={2}>
                      <Image
                        src={formDataEdit?.image}
                        width={300}
                        borderRadius="xl"
                        alt="Image not found"
                        shadow="md"
                      />
                      <Center>
                        <Button colorScheme='red' mt={3} type="button" onClick={() => setFormDataEdit({...formDataEdit, 'image' : ''})}>
                          Change
                        </Button>
                      </Center>
                    </Box>
                  </Center>
                  : 
                  <HStack>
                    <Stack>
                      <input type="file" multiple onChange={(e) => handleFileChange(e.target.files[0], 'edit')} />
                    </Stack>
                  </HStack>}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='blue' mr={3} type="submit">
                Save
              </Button>
              <Button onClick={closeModalEdit}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
             
    </Stack>
  )
}

export default AssetPage