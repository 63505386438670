import { CheckIcon } from '@chakra-ui/icons'
import { Box, Container, Divider, HStack, Heading, Image, List, ListIcon, ListItem, Spacer, Stack, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getSingleDocumentFirebase } from '../../Api/firebaseApi'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { FaMap, FaMoneyBill } from 'react-icons/fa'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicButton from '../../Components/Buttons/DynamicButton'

const RecruitmentPreviewRolePage = () => {
  const [data, setData] = useState()
  const params = useParams()

  const navigate = useNavigate()
  const dateFormat = (date) => {
    return date ? dayjs(date).format('DD MMM YYYY') : '-'
  }
    
  const getSingleRole = async () => {
    getSingleDocumentFirebase ('positions', `${params.id}`)
      .then((result) => {
        setData(result)
      })
  }
  useEffect(() => {
    getSingleRole()
  }, [])
  return (
    <>
      <Box p={2}>
        <HStack>
          <BackButtons/>
          <Text fontSize={'xl'} fontWeight={500}>
            Preview Role
          </Text>
          <Spacer/>
          <DynamicButton action={'update'} title={'Edit Role'} onClick={() => navigate(`/recruitment/jobs-edit/${params.id}`)}/>
        </HStack>
        <Container maxW={'8xl'}>
          <Box>
            <HStack p={8} bgColor={'white'} my={5} align={'left'} justify={'space-between'} w={'100%'} borderBottomRadius={'3xl'} >
              <VStack>
                <HStack gap={5}>
                  <Image boxSize="100px" src={data?.image} />
                  <Stack >
                    <Heading size={'md'}>{data?.position_name ? data.position_name : null}</Heading>
                    <HStack>
                      <Text>{data?.company}</Text>
                    </HStack>
                  </Stack>
                </HStack>
                <Stack align={'left'} justify={'left'} w={'full'} my={3}>
                  <HStack align={'left'}>
                    <FaMap/>
                    <Text>{data?.address}</Text>
                  </HStack>
                  <HStack align={'left'}>
                    <FaMoneyBill/>
                    <Text>
                      {data?.range_salary_high
                        ? `Rp${new Intl.NumberFormat('en-ID', {
                          maximumSignificantDigits: 3,
                        }).format(
                          data.range_salary_low
                        )} - Rp${new Intl.NumberFormat('en-ID', {
                          maximumSignificantDigits: 3,
                        }).format(data.range_salary_high)} `
                        : 'Negotiable'}
                    </Text>
                  </HStack>
                  <Text fontSize={12} color="blackAlpha.600">
                    Berakhir pada : {dateFormat(data?.expiry)}
                  </Text>
                </Stack>
              </VStack>
              {/* <HStack p="2" >
                            {apply === undefined ? 
                            <>
                            <Button bgColor="brand.100" onClick={() => handleApp (data)}>
                            Apply now
                            </Button>
                            </> 
                            :
                            <>
                            <Button bgColor="brand.100" isDisabled={true}>
                            Applied
                            </Button>
                            </>}
                            
                        </HStack> */}
            </HStack>
          </Box>
          <Stack >
            <Divider/>
          </Stack>

          <Stack bgColor={'white'} my={5} borderRadius={'2xl'} p={8}>
            <Box my={8}>
              <Heading fontSize="lg"mb={2}>About Our Program</Heading>
                            
              <Text fontSize={'17px'} fontWeight={'medium'}>{data?.description}</Text>
            </Box>
            <Divider/>
            <HStack w={'100%'} gap={5}>
              <Stack my={5} w={'50%'} >
                <Heading fontSize="lg" my={2}>Job Description</Heading>
                <List mt={2} spacing={1} ml={2}>
                  {data?.jobdesc?.length > 0
                    ? data.jobdesc.map((x, i) => (
                      <ListItem key={i}>
                        <ListIcon as={CheckIcon} color="green.500" />
                        {x}
                      </ListItem>
                    ))
                    : null}
                </List>
              </Stack>

              <Stack my={5} w={'50%'}>
                <Heading fontSize="lg" my={2}>Qualification</Heading>
                <List mt={2} spacing={1} ml={2}>
                  {data?.requirements?.length > 0
                    ? data.requirements.map((x, i) => (
                      <ListItem key={i}>
                        <ListIcon as={CheckIcon} color="green.500" />
                        {x}
                      </ListItem>
                    ))
                    : null}
                </List>
              </Stack>
            </HStack>
            <Divider/>
                        
            <Heading fontSize="lg" my={2}>
              Additional Information
            </Heading>
            <HStack w={'100%'} gap={5}>
              <VStack gap={5} w={'50%'} >
                <VStack align={'left'} w={'100%'}>
                  <Text fontWeight={'medium'}>Position Level :</Text>
                  <Text>{data?.level?data.level:<></>}</Text>
                </VStack>
                <VStack align={'left'} w={'100%'}>
                  <Text fontWeight={'medium'}>Category :</Text>
                  <Text>{data?.time ? data.time : <></>}</Text>
                </VStack>
              </VStack>

              <VStack gap={5}>
                <VStack align={'left'} w={'100%'}>
                  <Text fontWeight={'medium'}>Minimum Experience :</Text>
                  <Text>{data?.experience ? data.experience : <></>}</Text>
                </VStack>
                <VStack align={'left'} w={'100%'}>
                  <Text fontWeight={'medium'}>Placement :</Text>
                  <Text>{data?.workplace?data.workplace:<></>}</Text>
                </VStack>
              </VStack>
                            
            </HStack>
                        
          </Stack>

          <Stack >
            <Divider/>
          </Stack>

          <Stack bgColor={'white'} my={5} borderTopRadius={'2xl'} p={8}>
            <Box my={8}>
              <Heading fontSize="lg"mb={2}>Company Overview</Heading>
                            
              <Text fontSize={'17px'}>{data?.description}</Text>
            </Box>
          </Stack>

        </Container>

      </Box>
    </>
  )
}

export default RecruitmentPreviewRolePage