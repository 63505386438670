import {
  Heading,
  HStack,
  Stack,
  Spacer,
  Center,
  Spinner,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicTable from '../../Components/Table/DynamicTable'
import { useNavigate } from 'react-router-dom'
import useUserStore from '../../Hooks/Zustand/Store'
import { getCollectionFirebaseV } from '../../Api/firebaseApi'

const HrisUserPayslip = () => {
  const globalState = useUserStore()
  const [loading] = useState(false)
  const [periodMonth, setPeriodMonth] = useState()
  const navigate = useNavigate()
  const tableHeader = ['periode', 'start', 'expire']
  const monthNames = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ]
  const options = { day: 'numeric', month: 'long', year: 'numeric' }

  const formatDate = (dateStr) => {
    const dateObj = new Date(dateStr);
    const monthName = monthNames[dateObj.getMonth()];
    const year = dateObj.getFullYear();
    return `${monthName} ${year}`;
  };


  const getPeriode = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany }
    ]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    try {
      const res = await getCollectionFirebaseV('payroll', { conditions }, { sortBy }, { limitValue }, { startAfterData })
      
      setPeriodMonth(res)

    } catch (error) {
      // console.log(error, 'ini error')

    }
  }

  useEffect(() => {
    getPeriode()
  }, [])

  const tableData = periodMonth?.map((x) => {
    const periode = x.periode
    const start = new Date(x.timeFrom).toLocaleDateString('id-ID', options) || '-'
    const end = new Date(x.timeTo).toLocaleDateString('id-ID', options) || '-'
    return {
      x,
      'periode': formatDate(periode),
      'start': start,
      'expire': end
    }
  })

  const handleDetail = (x) => {
    // setDetail(x.x);
    // setMode("step_2");
    navigate(`/hris/payroll/${x.x.id}/payslip-user/`);
  };

  return (
    <Stack p={[1, 1, 5]} spacing={5}>
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons/>
        <Heading size="md">Payslip</Heading>
        <Spacer/>
      </HStack>

      {loading?
        <Stack p={8}>
          <Center>
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='rgba(54, 162, 235, 0.6)'
              size='xl'
            /> 
          </Center>
        </Stack> 
        :
        <DynamicTable header={tableHeader} data={tableData} onRead={handleDetail}/>
      }

  
    </Stack >
  )
}

export default HrisUserPayslip