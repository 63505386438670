/* eslint-disable eqeqeq */
import dayjs from 'dayjs'

const dateTimeFormat = (date,) => {
  return date ? dayjs(date,).format('DD MMM YYYY HH:mm',) : '-'
}

const dateFormat = (date,) => {
  return date ? dayjs(date,).format('DD-MMM-YYYY',) : '-'
}

const fileExtention = (filepath,) => {
  return filepath.split('?',)[0].split('#',)[0].split('.',).pop()
}


const numberFormat = (value,) => {
  if (!value) return '0.00'
  let val = (value / 1)
  val = (value / 1).toFixed(2,).replace(',', '.',)
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',',)
}

const readMore = (val, l = 24,) => {
  if (!val) return ''
  val = val.replace(/\s{2,}/g, ' ',)
  const strLength = val.length
  if (strLength > l) {
    val = `${val.slice(0, l,)  }...`
  }
  return val
    .toLowerCase()
    .replace(/\w/, (firstLetter,) => firstLetter.toUpperCase(),)
}



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height, } = window
  return {
    width,
    height,
  }
}

function flattenChildren(arr,) {
  const result = [];

  for (const item of arr) {
    const newItem = {id:item.id, name: item.name,code:item.code, parent_id:item.parent_id, };

    if (item.children && item.children.length > 0) {
      newItem.children = flattenChildren(item.children,);
    }

    result.push(newItem,);
  }

  return result;
}

function mustNumber (event,) {
  if (!/\d/.test(event.key,) && event.key !== '.' && event.key !== '-') return event.preventDefault()
}

function numberSeparator(x,) {
  x = x === '' ? '0' : x.toString().replace(/,/g, '',)
  if (x.length > 1) {
    if (x === '0.00' || x === 0.00) return 0
    if (x?.charAt(0,) === '0' || x?.charAt(0,) === 0) {
      x = x.substring(1,)
    }
  }
  const min = x[0] == '-' ? true : false

  x = x.replace(/[^\d.]/g, '',)
    .replace(/\./, 'x',)
    .replace(/\./g, '',)
    .replace(/x/, '.',)

  const arr = x.match(/^(\d+)((?:\.\d+)?)$/,)
  let res = 0
  if (arr) {
    
    res =  arr[1].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,',) + arr[2]
  }else{
    res =  x.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,',)
  }

  return min ? '-' + res : res
}

function replaceComma(x,){
  if (x === '') return 0
  x = x.toString()
  x = x.replace(/,/g, '',)
  return parseFloat(x,)
}

export {
  dateTimeFormat,
  dateFormat,
  numberFormat,
  readMore,
  fileExtention,
  getWindowDimensions,
  flattenChildren,
  mustNumber,
  numberSeparator,
  replaceComma,
}