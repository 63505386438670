/* eslint-disable no-undef */
import { useParams } from 'react-router-dom'
import {
  Box, Heading, HStack, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Stack,
  Spacer,
  Text,
  VStack,
  Center,
  Button,
  Input,
  Divider,
  Spinner,
  Flex,
  useToast,
  TableContainer,
} from '@chakra-ui/react'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import { FcCancel, FcMoneyTransfer } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import useUserStore from '../../Hooks/Zustand/Store'
import axios from 'axios'
import { addDocumentFirebase, getCollectionFirebase, getCollectionFirebaseVI, getSingleDocumentFirebase, setDocumentFirebase } from '../../Api/firebaseApi'
import ChartsPiePayroll from '../../Components/Charts/ChartsPiePayroll'
import { getUserByUid } from '../../Utils/getUser';
import { formatFrice } from '../../Utils/numberUtil';
import { TbFileExport } from 'react-icons/tb';
import { getCompanyName } from '../../Utils/getCompany';
import { IoIosSave } from 'react-icons/io';

const HrisMonthlyPayroll = () => {
  const navigate = useNavigate()
  const globalState = useUserStore();
  const [currentData, setCurrentData] = useState([])
  const [loading, setLoading] = useState(false)
  const [periode, setPeriode] = useState()
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [drawer, setDrawer] = useState(false)
  const [detail, setDetail] = useState(null)
  const { id } = useParams()
  const [val, setVal] = useState([]);
  const [val2, setVal2] = useState([]);
  const [payslip, setPayslip] = useState(null)
  const [mode, setMode] = useState('')
  const [isPushingBulk, setIsPushingBulk] = useState(false);



  const totalEmployee = tableData.length

  const payrollCosts = () => {
    let sum = 0;
    for (let i = 0; i < tableData.length; i++) {
      sum += tableData[i].total;
    }

    return sum;
  }

  const netSalary = () => {
    let sum = 0;
    for (let i = 0; i < tableData.length; i++) {
      sum += tableData[i].salary;
    }

    return sum;
  }


  const totalDeductions = () => {
    const arrDeductions = [];
    let totalDeductions = 0;
    tableData.forEach((x) => {
      x.deduction.forEach((y) => {
        arrDeductions.push(y)
      })
    })

    arrDeductions.forEach((x) => {
      totalDeductions += x.amount
    })
    return totalDeductions
  }


  const totalAllowance = () => {
    const arrAllowance = [];
    let totalAllowance = 0;
    tableData.forEach((x) => {
      x.allowance.forEach((y) => {
        arrAllowance.push(y)
      })
    })

    arrAllowance.forEach((x) => {
      totalAllowance += x.amount
    })
    return totalAllowance
  }

  const toast = useToast()

  const getPeriode = async () => {
    try {
      const res = await getSingleDocumentFirebase('payroll', id)
      setPeriode(res)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    getPeriode()
  }, [])

  const getPayslip = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
      { field: 'periodeId', operator: '==', value: id }
    ]
    const sortBy = null
    const limitValue = null
    const startAfterData = null
    try {
      const res = await getCollectionFirebaseVI('payroll_payslip', { conditions }, { sortBy }, { limitValue }, { startAfterData })
      setPayslip(res)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    getPayslip()
  }, [])
  // console.log(periode,'ini data periode')

  const getDataAllowance = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
    ];
    const dataAllowance = await getCollectionFirebase(
      'payroll_allowance',
      conditions
    );

    const resAllowance = dataAllowance.map((x) => ({
      allowance_title: x.type,
      allowance_id: x.id
    }));


    return resAllowance;
  };

  const getDataDeduction = async () => {
    const conditions = [
      { field: 'companyId', operator: '==', value: globalState.currentCompany },
    ];
    const dataDeduction = await getCollectionFirebase(
      'payroll_deduction',
      conditions
    );

    const resDeduction = dataDeduction.map((x) => ({
      deduction_title: x?.type,
      deduction_id: x?.id
    }));


    return resDeduction;
  };


  const handleRunPayroll = async () => {
    setLoading(true);
    try {
      const resAllowance = await getDataAllowance();
      const resDeduction = await getDataDeduction();

      const dataApi = {
        startDate: periode?.timeFrom,
        endDate: periode?.timeTo,
        companyId: globalState?.currentCompany,
        tokenId: globalState?.authFirebaseToken,
      };
      // console.log(dataApi, 'data Api');
      // console.log(resAllowance, 'resAllowance');
      // console.log(resDeduction, 'resDeduction');

      const baseURL = 'https://asia-southeast2-deoapp-indonesia.cloudfunctions.net';
      // const baseURL = 'http://127.0.0.1:8080/deoapp-indonesia/asia-southeast2';
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: process.env.REACT_APP_PAYMENT_KEY,
        },
      };

      const newUrl = `${baseURL}/payroll-run`;
      const res = await axios.post(newUrl, dataApi, options);


      if (res.status === 200) {
        const flattenedData = await Promise.all(
          res.data.data.map(async (item, index) => {
            const userData = await getUserByUid(globalState?.users, item?._id);

            const allowanceData = resAllowance.map((allowance) => {
              const allowanceItem = item.data.find((d) => d.categoryId === allowance.allowance_id);
              return {
                title: allowance.allowance_title,
                amount: allowanceItem ? parseFloat(allowanceItem.amount) : 0,
              };
            });

            const deductionData = resDeduction.map((deduction) => {
              const deductionItem = item.data.find((d) => d.categoryId === deduction.deduction_id);
              return {
                title: deduction.deduction_title,
                amount: deductionItem ? parseFloat(deductionItem.amount) : 0,
              };
            });

            const salaryItem = item.data.find((d) => d.category === 'salary');
            const salary = salaryItem ? parseFloat(salaryItem.amount) : 0;

            const loansItem = item.data.find((d) => d.category === 'loans');
            const loans = loansItem ? parseFloat(loansItem.amount) : 0;

            const totalAllowance = allowanceData.reduce((acc, allowance) => acc + allowance.amount, 0);
            const totalDeduction = deductionData.reduce((acc, deduction) => acc + deduction.amount, 0);

            const total = totalAllowance + salary - totalDeduction - loans;

            return {
              id: index + 1,
              uid: item._id,
              name: userData?.name,
              level: 'staff',
              organization: getCompanyName(globalState.companies, globalState.currentCompany),
              salary: salary,
              allowance: [...allowanceData],
              deduction: [...deductionData],
              loans: loans,
              total: total,
            };
          })
        );

        const headers = Object.keys(flattenedData[0] || {})

        setTableHeaders(headers);
        setTableData(flattenedData);
        setCurrentData(flattenedData);
      }
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };


  const calculateTotalSalary = (allowance, deduction) => {
    const totalAllowance = allowance.reduce((acc, item) => acc + item.amount, 0);
    const totalDeduction = deduction.reduce((acc, item) => acc + item.amount, 0);

    return totalAllowance - totalDeduction;
  }

  const recalculateTotalSalary = () => {
    const totalAllowance = val.reduce((acc, item) => acc + item.amount, 0);
    const totalDeduction = val2.reduce((acc, item) => acc + item.amount, 0);
    const newTotalSalary =
      calculateTotalSalary(detail.allowance, detail.deduction) +
      totalAllowance -
      totalDeduction +
      (detail.salary - detail.loans);

    setDetail((prevDetail) => ({
      ...prevDetail,
      total: newTotalSalary,
    }));
  }



  const handleAddAllowance = () => {
    setVal((prevVal) => [...prevVal, { title: '', amount: 0 }]);
  };

  const handleSaveAllAllowances = () => {
    const newAllowances = val.filter((item) => item.title && item.amount > 0);
    setDetail((prevDetail) => ({ ...prevDetail, allowance: [...prevDetail.allowance, ...newAllowances] }));
    setVal([]);
    recalculateTotalSalary()
  };

  const handleAddDeduction = () => {
    setVal2((prevVal) => [...prevVal, { title: '', amount: 0 }]);
  };

  const handleSaveAllDeductions = () => {
    const newDeductions = val2.filter((item) => item.title && item.amount > 0);
    setDetail((prevDetail) => ({ ...prevDetail, deduction: [...prevDetail.deduction, ...newDeductions] }));
    setVal2([]);
    recalculateTotalSalary()
  };

  const onOpen = async (x) => {
    const edited = payslip.find((n) => (n.name) === (x.name))
    if (edited === undefined) {
      setMode('push')
      setDetail(x)
    } else {
      setDetail(edited)
      setMode('update')
    }
    setDrawer(true)
  }


  const onClose = () => {
    setDetail(null)
    setDrawer(false)
    setVal([])
    setVal2([])
  }
  
  const handleBulkPush = async () => {
    setIsPushingBulk(true);
    setLoading(true);
    try {
      const pushBulkFirebase = tableData.map(async (item) => {
        try {
          const res = await addDocumentFirebase(
            'payroll_payslip',
            {
              ...item,
              periodeId: id
            },
            globalState?.currentCompany
          )
          return res;
        } catch (error) {
          return error.message;
        }
      });
      await Promise.all(pushBulkFirebase);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false);
      setIsPushingBulk(false);
    }
  }

  const handlePushPayslipToState = () => {
    // console.log('detail', detail);
    const payslipArray = tableData;
    const filteredArray = payslipArray.filter((x) => x.uid !== detail.uid);

    const newPayslipArray = [...filteredArray, detail];
    const sortedNewArray = newPayslipArray.sort((a, b) => (b.id - a.id));
    // console.log('updated:', newPayslipArray);
    // console.log('sorted:', sortedNewArray);
    setDrawer(false);
    setPayslip(sortedNewArray);
    setTableData(sortedNewArray);
  };


  const updatePayslip = async () => {
    setLoading(true)

    const updatePayslip = detail
    updatePayslip.companyId = globalState?.currentCompany
    updatePayslip.projectId = globalState?.currentProject
    updatePayslip.periodId = id
    try {
      await setDocumentFirebase('payroll_payslip', detail?.payslipId, detail)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setLoading(false)
      setDrawer(false)
    }
  }

  return (

    <Stack p={[1, 1, 1]} spacing={5}>
      <HStack>
        <BackButtons />
        <Heading size="md">Payroll {currentData?.periode}</Heading>
        <Spacer />
      </HStack>

      <Stack bgColor={'white'} p={4}>
        <Flex w={'100%'} gap={3} direction={['column', 'row', 'row']}>
          <VStack w={['full', '50%', '50%']}>
            <HStack w={'full'}>
              <Stack w={'33%'} h={'125px'} p={[2, 3, 3]} borderRadius={'lg'} boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px">
                <HStack justify={'space-between'}>
                  <Heading fontSize={'sm'} noOfLines={2}>Total Employee</Heading>
                  {/* <FcManager size={32}/> */}

                </HStack>
                <Stack my={[0, 1, 1]}>
                  <Text fontSize={['xs', 'xl', 'xl']}>
                    {totalEmployee || 0} Employees
                  </Text>
                </Stack>
                {/* <HStack>
                  <BsFillArrowDownCircleFill color='red' size={'15'} />
                  <Text fontSize={'3xs'}> 12% from last periode</Text>
                </HStack> */}
              </Stack>

              {/* <Stack w={'33%'} h={'125px'} p={[2, 3, 3]} borderRadius={'lg'} boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px">
                <HStack justify={'space-between'}>
                  <Heading fontSize={'sm'} noOfLines={2}>Total Working Hours</Heading>
                  <FcClock size={32} />
                </HStack>
                <Stack my={[0, 1, 1]}>
                  <Text fontSize={['xs', 'xl', 'xl']}>
                    43645 Hours
                  </Text>
                </Stack>
                // <HStack>
                //   <BsFillArrowUpCircleFill color='green' size={'15'} />
                //   <Text fontSize={'3xs'}> 12% from last periode</Text>
                // </HStack>
              </Stack> */}
              <Stack w={'33%'} h={'125px'} p={[2, 3, 3]} borderRadius={'lg'} boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px">
                <HStack justify={'space-between'}>
                  <Heading fontSize={'sm'} noOfLines={2}>Payroll Costs</Heading>
                  {/* <FcCurrencyExchange size={32} /> */}
                </HStack>
                <Stack my={[0, 1, 1]}>
                  <Text fontSize={['xs', 'xl', 'xl']}>
                    IDR {formatFrice(payrollCosts())}
                  </Text>
                </Stack>
                {/* <HStack>
                  <BsFillArrowDownCircleFill color='red' size={'15'} />
                  <Text fontSize={'3xs'}> 12% from last periode</Text>
                </HStack> */}
              </Stack>
            </HStack>
            <HStack w={'full'}>
              <Stack w={'33%'} h={'125px'} p={[2, 3, 3]} borderRadius={'lg'} boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px">
                <HStack justify={'space-between'}>
                  <Heading fontSize={'sm'} noOfLines={2}>Net Salary</Heading>
                  {/* <FcApproval size={32}/> */}
                </HStack>
                <Stack my={[0, 1, 1]}>
                  <Text fontSize={['xs', 'xl', 'xl']}>
                    IDR {formatFrice(netSalary())}
                  </Text>
                </Stack>
                <HStack>
                  <BsFillArrowRightCircleFill color='orange' size={'15'} />
                  <Text fontSize={'3xs'}> 0% from last periode</Text>
                </HStack>
              </Stack>
              <Stack w={'33%'} h={'125px'} p={[2, 3, 3]} borderRadius={'lg'} boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px">
                <HStack justify={'space-between'}>
                  <Heading fontSize={'sm'} color={'red'}>Deduction</Heading>
                  {/* <FcExport size={32} /> */}
                </HStack>
                <Stack my={[0, 1, 1]}>
                  <Text fontSize={['xs', 'xl', 'xl']}>
                    IDR {formatFrice(totalDeductions())}
                  </Text>
                </Stack>
      
              </Stack>
              <Stack w={'33%'} h={'125px'} p={[2, 3, 3]} borderRadius={'lg'} boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px">
                <HStack justify={'space-between'}>
                  <Heading fontSize={'sm'} color={'green'}>Allowance</Heading>
                  {/* <FcImport size={32} /> */}
                </HStack>
                <Stack my={[0, 1, 1]}>
                  <Text fontSize={['xs', 'xl', 'xl']}>
                    IDR {formatFrice(totalAllowance())}
                  </Text>
                </Stack>
                {/* <HStack>
                  <BsFillArrowUpCircleFill color='green' size={'15'} />
                  <Text fontSize={'3xs'}> 12% from last periode</Text>
                </HStack> */}
              </Stack>
            </HStack>
          </VStack>
          <Stack w={['full', '20%', '20%']} h={['fit-content', '255px', '255px']} p={[3, 7, 7]} borderRadius={'lg'} boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px" >
            <VStack gap={[1, 5, 5]}>
              <FcMoneyTransfer size={50} />
              <Text fontWeight={500}>
                Payment period
              </Text>
              <Heading fontSize={'sm'} noOfLines={2}>{periode?.period}</Heading>
              <Button onClick={() => handleRunPayroll()}>Run Payroll</Button>
            </VStack>
          </Stack>
          <Stack w={['full', '30%', '30%']} h={['fit-content', '255px', '255px']} p={3} borderRadius={'lg'} boxShadow="rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px" >
            <Heading fontSize={'sm'} noOfLines={2}>Salary distribution</Heading>
            <Center w={'full'}>
              <ChartsPiePayroll
                netSalary={netSalary()}
                totalDeductions={totalDeductions()}
                totalAllowance={totalAllowance()}
              />
            </Center>
          </Stack>
        </Flex>

        <Divider pb={3} />

        <HStack justify={'space-between'} mb={20} mt={5}>
          <Stack pt={3} mx={2} pb={2}>
            <Heading fontSize={'sm'}>Employee Payrolls</Heading>
            <Input my={2} placeholder='Search Employee' w={['full', '60%', 'full']} />
          </Stack>
          <Stack alignItems='center'>
            <Heading size='md'>Raw Payroll</Heading>
            <Text color=''>Do not leave this page before <strong style={{ color: 'red' }}>submitting payslip</strong> to database!</Text>
          </Stack>

          <HStack>
            <Button colorScheme='green' onClick={handleBulkPush}> <TbFileExport /> Bulk Push Payslip</Button>
            <Button colorScheme='blue' onClick={() => navigate(`/hris/payroll/${id}/payslip`, { state: { periodeName: periode.periode } })}> <TbFileExport />List Payslips</Button>
          </HStack>
        </HStack>

        <TableContainer overflow={'scroll'}>
          {loading ? (
            <Stack p={8}>
              <Center>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="rgba(54, 162, 235, 0.6)"
                  size="xl"
                />
                {isPushingBulk && <Heading size='md'>Pushing Bulk Payslip...</Heading>}
              </Center>
            </Stack>
          ) : (
            <Table variant="simple">
              <TableCaption>Click Run Payroll to see payroll for this periode</TableCaption>
              <Thead>
                <Tr fontSize="2xs">
                  {tableHeaders.map((header) => (
                    <Th key={header}>{header}</Th>
                  ))}
                  <Th>Edit</Th> {/* Add a column for the Edit button */}
                </Tr>
              </Thead>
              <Tbody>
                {tableData.map((rowData, rowIndex) => (
                  <Tr key={rowIndex}>
                    {tableHeaders.map((header) => {
                      if (header === 'allowance' || header === 'deduction') {
                        return (
                          <Td key={header} fontSize={'sm'}>
                            {rowData[header].map((item, index) => (
                              <div key={index}>
                                <strong>{item.title}:</strong> {formatFrice(item.amount)}
                              </div>
                            ))}
                          </Td>
                        );
                      } else {
                        return <Td key={header} fontSize={'sm'}>{formatFrice(rowData[header])}</Td>;
                      }
                    })}
                    <Td>
                      <Button onClick={() => onOpen(rowData)}>Edit</Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </Stack>

      <Drawer onClose={onClose} isOpen={drawer} placement='bottom'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{detail?.name}</DrawerHeader>
          <DrawerBody mb={10}>

            <Box>
              <HStack justify={'space-between'}>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                  Payslip Detail
                </Text>
                <Button
                  colorScheme='orange'
                  fontSize={'sm'}
                  onClick={handlePushPayslipToState}
                  isLoading={loading}
                >
                  <IoIosSave /> Save
                </Button>

                <Button colorScheme='blue' fontSize={'sm'} isLoading={loading} onClick={updatePayslip}>
                  <TbFileExport /> Update Payslip
                </Button>
                

              </HStack>
              <Box mb={4}>
                <HStack w={'50%'}>
                  <Text w={'50%'}>
                    <strong>Name</strong>
                  </Text>
                  <Text>
                    :{detail?.name}
                  </Text>
                </HStack>
                <HStack w={'50%'}>
                  <Text w={'50%'}>
                    <strong>Position</strong>
                  </Text>
                  <Text>
                    :{detail?.role}
                  </Text>
                </HStack>
                <HStack w={'50%'}>
                  <Text w={'50%'}>
                    <strong>Company</strong>
                  </Text>
                  <Text>
                    :{detail?.organization}
                  </Text>
                </HStack>
              </Box>

              <Flex>
                {/* Allowance Table */}
                <Box flex="1" mr={4}>
                  <Table variant="striped" colorScheme="teal">
                    <TableCaption>Allowance</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>Category</Th>
                        <Th isNumeric>Amount</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Salary</Td>
                        <Td isNumeric>{formatFrice(detail?.salary)}</Td>
                      </Tr>
                      {detail?.allowance.length > 0 ?
                        <>
                          {detail?.allowance.map((item, index) => (
                            <Tr key={index}>
                              <Td>{item.title}</Td>
                              <Td isNumeric>{formatFrice(item.amount)}</Td>
                            </Tr>
                          ))}
                        </>
                        :
                        'belum ada data'
                      }
                    </Tbody>
                  </Table>
                  {val.length > 0 ?
                    <Box p={2} borderRadius={'lg'} shadow={'md'}>
                      {val.map((x, i) => (
                        <HStack my={2} key={i}>
                          <Box w={'50%'}>
                            <Heading size={'sm'}>Type</Heading>
                            <Input
                              type="text"
                              bg="white"
                              placeholder={'Input Type'}
                              value={x.title}
                              onChange={(e) => {
                                const updatedVal = [...val];
                                updatedVal[i].title = e.target.value;
                                setVal(updatedVal);
                              }}
                            />
                          </Box>
                          <Box w={'50%'}>
                            <Heading size={'sm'}>Amount</Heading>
                            <Input
                              type="number"
                              bg="white"
                              // defaultValue={x.amount}
                              onChange={(e) => {
                                const updatedVal = [...val];
                                updatedVal[i].amount = parseFloat(e.target.value) || 0;
                                setVal(updatedVal);
                              }}
                            />
                          </Box>
                          <FcCancel size={25} cursor={'pointer'} />
                        </HStack>
                      ))}
                      <Button justifyContent={'end'} onClick={() => handleSaveAllAllowances()}>Save</Button>
                    </Box>
                    : ''
                  }
                  <Button mt={2} fontSize={'xs'} colorScheme="teal" onClick={() => handleAddAllowance()}>
                    Add Allowance
                  </Button>
                </Box>

                {/* Deduction Table */}
                <Box flex="1">
                  <Table variant="striped" colorScheme="red">
                    <TableCaption>Deduction</TableCaption>
                    <Thead>
                      <Tr>
                        <Th>Category</Th>
                        <Th isNumeric>Amount</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr >
                        <Td>Loans</Td>
                        <Td isNumeric>{formatFrice(detail?.loans)}</Td>
                      </Tr>
                      {detail?.deduction.length > 0 ?
                        <>
                          {detail?.deduction.map((item, index) => (
                            <Tr key={index}>
                              <Td>{item.title}</Td>
                              <Td isNumeric>{formatFrice(item.amount)}</Td>
                            </Tr>
                          ))}
                        </>
                        :
                        'belum ada data'
                      }
                    </Tbody>
                  </Table>
                  {val2.length > 0 ?
                    <Box p={2} borderRadius={'lg'} shadow={'md'}>
                      {val2.map((x, i) => (
                        <HStack my={2} key={i}>
                          <Box w={'50%'}>
                            <Heading size={'sm'}>Type</Heading>
                            <Input
                              type="text"
                              bg="white"
                              placeholder={'Input Type'}
                              value={x.title}
                              onChange={(e) => {
                                const updatedVal2 = [...val2];
                                updatedVal2[i].title = e.target.value;
                                setVal2(updatedVal2);
                              }}
                            />
                          </Box>
                          <Box w={'50%'}>
                            <Heading size={'sm'}>Amount</Heading>
                            <Input
                              type="number"
                              bg="white"
                              defaultValue={x.amount}
                              onChange={(e) => {
                                const updatedVal2 = [...val2];
                                updatedVal2[i].amount = parseFloat(e.target.value) || 0;
                                setVal2(updatedVal2);
                              }}
                            />
                          </Box>
                          <FcCancel size={25} cursor={'pointer'} />
                        </HStack>
                      ))}
                      <Button justifyContent={'end'} onClick={handleSaveAllDeductions}>Save</Button>
                    </Box>
                    : ''
                  }
                  <Button colorScheme="red" fontSize={'xs'} mt={2} onClick={handleAddDeduction}>
                    Add Deduction
                  </Button>
                </Box>
              </Flex>
              <Divider my={2} />
              {/* Total Salary */}
              <Box textAlign={'right'}>
                <Text fontSize="lg" fontWeight="bold">
                  Total Salary: IDR{formatFrice(detail?.total)}
                </Text>
              </Box>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

    </Stack>
  )
}

export default HrisMonthlyPayroll