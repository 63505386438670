/* eslint-disable no-undef */
import axios from 'axios';
import { addDocumentFirebase, getCollectionFirebase, updateDocumentFirebase } from './firebaseApi';
import moment from 'moment';

const baseUrl = process.env.REACT_APP_HRIS_HOST


const createHris = async (params, session, data,) => {
  try {
    const url = `${baseUrl}${params}`;
    const response = await axios.post(url, data || null, {
      headers: session.headers
        ? {
          Authorization: `Bearer ${session.headers}`,
          company: session.companyId ? `${session.companyId}` : {},
          project: session.projectId ? `${session.projectId}` : {},
        }
        : {},
    },);
    return response.data;
  } catch (error) {
    return null;
  }
};

const readHris = async (params, session,) => {
  try {
    const url = `${baseUrl}${params}`;
    const headers = {};

    if (session.headers) {
      headers.Authorization = `Bearer ${session.headers}`;

      // Add company field to headers if session.company exists
      if (session?.companyId) {
        headers.company = `${session.companyId}`;
      }

      // Add project field to headers if session.projectId exists
      if (session?.projectId) {
        headers.project = `${session.projectId}`;
      }
    }

    const response = await axios.get(url, { headers, },);

    return response.data;
  } catch (error) {
    return null;
  }
};

const updateHris = async (params, session, data,) => {
  try {
    const url = `${baseUrl}${params}`;
    const response = await axios.put(url, data || null, {
      headers: session.headers
        ? {
          Authorization: `Bearer ${session.headers}`,
          company: session.companyId ? `${session.companyId}` : {},
          project: session.projectId ? `${session.projectId}` : {},
        }
        : {},
    },);
    return response.data;
  } catch (error) {
    return null;
  }
};

const deleteHris = async (params, session,) => {
  try {
    const url = `${baseUrl}${params}`;
    const headers = {};

    if (session.headers) {
      headers.Authorization = `Bearer ${session.headers}`;

      // Add company field to headers if session.company exists
      if (session.companyId) {
        headers.company = `${session.companyId}`;
      }

      // Add project field to headers if session.projectId exists
      if (session.projectId) {
        headers.project = `${session.projectId}`;
      }
    }

    const response = await axios.delete(url, { headers, },);

    return response.data;
  } catch (error) {
    return null;
  }
};

// const deleteHris = async (params, session, data,) => {
//   try {
//     const url = `${baseUrl}${params}`;
//     const response = await axios.delete(url, data || null, {
//       headers: session.headers
//         ? {
//           Authorization: `Bearer ${session.headers}`,
//           company: session.companyId ? `${session.companyId}` : {},
//           project: session.projectId ? `${session.projectId}` : {},
//         }
//         : {},
//     },);
//     return response.data;
//   } catch (error) {
//     return null;
//   }
// };

const patchHris = async (params, session, data,) => {
  try {
    const url = `${baseUrl}${params}`;
    const response = await axios.patch(url, data || null, {
      headers: session.headers
        ? {
          Authorization: `Bearer ${session.headers}`,
          company: session.companyId ? `${session.companyId}` : {},
          project: session.projectId ? `${session.projectId}` : {},
        }
        : {},
    },);
    return response.data;
  } catch (error) {
    return null;
  }
};

const createHrisFirebase = async (globalState, collection, data) => {

  try {
    const today = moment().startOf('day'); // Start of today
    const companyId = globalState.currentCompany;
    const projectId = globalState.currentProject;
    const officeId = data?.office_uid;

    const conditions = [
      { field: 'companyId', operator: '==', value: companyId },
      { field: 'projectId', operator: '==', value: projectId },
      { field: 'officeId', operator: '==', value: officeId },
      { field: 'userId', operator: '==', value: globalState.uid },
      { field: 'period', operator: '>=', value: moment(today.toDate()).unix() },
    ];

    const sortBy = { field: 'period', direction: 'desc' };
    const limitValue = 1;

    const res = await getCollectionFirebase(collection, conditions, sortBy, limitValue);

    if (res?.length > 0) {
      const dataUpdate = {
        clock_out: moment(new Date()).unix(),
        image_out: data?.image || null,
        latitude_out: data?.latitude || null,
        longitude_out: data?.longitude || null,
        gmt_out: moment().utcOffset() / 60,
        attendanceStatus: 'fulltime'
      };

      const resStatus = await updateDocumentFirebase(collection, res[0].id, dataUpdate);
      return {status: resStatus, data: dataUpdate};
    } else {
      const dataSend = {
        officeId: data?.office_uid,
        companyId: globalState?.currentCompany,
        projectId: globalState?.currentProject,
        userId: globalState?.uid,
        period: moment(new Date()).unix(),
        clock_in: moment(new Date()).unix(),
        image_in: data?.image || null,
        latitude_in: data?.latitude || null,
        longitude_in: data?.longitude || null,
        clock_out: null,
        image_out: null,
        latitude_out: null,
        longitude_out: null,
        gmt_in: moment().utcOffset() / 60,
        module: 'hris',
        attendanceStatus: 'halftime',
      };

      const resStatus = await addDocumentFirebase(collection, dataSend, globalState.currentCompany);
      return {status: resStatus, data: dataSend};
    }
  } catch (error) {
    throw new Error(error.message, 'Failed to send  error message');
  }
};




export {
  createHris,
  readHris,
  updateHris,
  deleteHris,
  patchHris, // Tambahkan fungsi patchAccounting ke ekspor
  createHrisFirebase
};
