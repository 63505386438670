import {
  Box,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  increment,
} from 'firebase/firestore';
import { getCollectionFirebaseV3, getSingleDocumentFirebase, updateDocumentFirebase } from '../../Api/firebaseApi';
import RecruitmentCandidateCardComponent from './RecruitmentCandidateCardComponent';
import RecruitmentApplicantHeaderComponent from './RecruitmentApplicantHeaderComponent';

const RecruitmentApplicantList = () => {
  const [dataApplied, setDataApplied] = useState()
  // const [applicantId] = useState()
  const [readLimit, setReadLimit] = useState('5');
  const toast = useToast()
  const params = useParams();

  const getNew = async () => {
    const conditions = [{ field: 'position_id', operator: '==', value: params.id }];
    try {
      const fetchApplications = await getCollectionFirebaseV3('applications', { conditions }, { sortBy: { field: 'createdAt', direction: 'desc' } }, { limitValue: readLimit }, { startAfterData: null });


      const fetchUsers = fetchApplications.map(async (item) => {
        const userData = await getSingleDocumentFirebase('users', item.uid);
        return userData
      });



      const resultFetchUsers = await Promise.all(fetchUsers);


      const mergedArray = fetchApplications.map((data) => {
        return {
          ...data,
          user: resultFetchUsers.find((x) => x.id == data.uid)
        }
      });
      setDataApplied(mergedArray);

    } catch (error) {
      // console.log('error getting applicants::', error.message);
    }
  };

  // const getApplicant = async () => {
  //   try {
  //     const l = [];
  //     for (const x of applicantId) {
  //       const applicantRef = doc(db, 'users', x);
  //       const applicantSnapshot = await getDoc(applicantRef);
  //       l.push(applicantSnapshot);
  //     }
  //   } catch (error) {
  //     console.log(error.message, ':::error getApplicant')
  //     toast({
  //       title: 'Deoapp.com',
  //       description: error.message,
  //       status: 'error',
  //       position: 'top-right',
  //       isClosable: true,
  //       duration: 3000
  //     });
  //   }
  // };

  useEffect(() => {
    // getApplicant()
    // getApplicantList()
    getNew()
  }, [readLimit])

  const handleIncrementSeen = async (z) => {
    try {
      const res = await getSingleDocumentFirebase('applications', z?.id)
      res.seen = increment(1)
      await updateDocumentFirebase('applications', z?.id, res)
    } catch (error) {
      // toast({
      //   title: 'Deoapp.com',
      //   description: error.message,
      //   status: 'error',
      //   position: 'top-right',
      //   isClosable: true,
      //   duration: 3000
      // });
    }
    // navigate(`/candidate/${id}`)
  }

  const handleSelect = async (data) => {

    try {
      const res = await getSingleDocumentFirebase('applications', data?.id)
      res.status = 'accepted'
      await updateDocumentFirebase('applications', data?.id, res)
    } catch (error) {
      // toast({
      //   title: 'Deoapp.com',
      //   description: error.message,
      //   status: 'error',
      //   position: 'top-right',
      //   isClosable: true,
      //   duration: 3000
      // });
    }
    // toast({
    //   title: 'Status',
    //   description: 'Kandidat terpilih',
    //   status: 'success',
    //   duration: 2000,
    //   isClosable: true,
    // })
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  const handleReject = async (data) => {
    try {
      const res = await getSingleDocumentFirebase('applications', data?.id)
      res.status = 'not_suitable'
      await updateDocumentFirebase('applications', data?.id, res)
    } catch (error) {
      // toast({
      //   title: 'Deoapp.com',
      //   description: error.message,
      //   status: 'error',
      //   position: 'top-right',
      //   isClosable: true,
      //   duration: 3000
      // });
    }
    toast({
      title: 'Status',
      description: 'Kandidat tidak terpilih',
      status: 'error',
      duration: 2000,
      isClosable: true,
    })
    setTimeout(() => {
      window.location.reload()
    }, 500)
  }

  return (
    <Box>
      <RecruitmentApplicantHeaderComponent readLimit={readLimit} setReadLimit={setReadLimit} />
      <Box
        display='flex'
        justifyContent='center'
      >
        <Stack w='full'>
          {dataApplied?.length > 0 && dataApplied?.map((x, i) => (
            <RecruitmentCandidateCardComponent
              key={i}
              x={x}
              seen={() => handleIncrementSeen(x)}
              handleSelect={() => handleSelect(x)}
              handleReject={() => handleReject(x)}
            />
          ))}
        </Stack>
      </Box>
    </Box>

  )
}

export default RecruitmentApplicantList