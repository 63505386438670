import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Grid,
  HStack,
  VStack,
  Divider,
  Spacer,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
import React, { useEffect, useState } from 'react';
import { FcNext, FcPrevious } from 'react-icons/fc';
import { createHris, readHris } from '../../Api/Hris';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import useUserStore from '../../Hooks/Zustand/Store';

dayjs.extend(isBetween)

const HrisDaysOff = () => {
  const [initialDate, setInitialDate] = useState(dayjs().format('YYYY-MM-DD'));
  const dic = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [calendar, setCalendar] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [addDayOff, setAddDayOff] = useState({})
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  
  const height = window.innerHeight
  const globalState = useUserStore();
  const [dayOffData, setDayOffData] = useState([]);

  const getDaysOff = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris('day-off', session);
      setDayOffData(data);
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
        duration: 3000
      });
    }
  };

  useEffect(() => {
    getDaysOff();
  }, [globalState?.currentProject]);

  const generateCalendarData = (month = '') => {
    const params = [];
    const totalDay = dayjs(month).daysInMonth();
    const now = dayjs(month).format('YYYY-MM');
    for (let index = 0; index < totalDay; index++) {
      const d = dayjs(`${now}-${index + 1}`).format('YYYY-MM-DD');
      const dayname = dayjs(d).format('ddd');
      params.push({
        date: dayjs(d).format('DD'),
        periode: d,
        dayname,
      });
    }

    return params;
  };

  // const generateCalendarView = (initialDate) => {
  //   const previousMonth = dayjs(initialDate).subtract(1, 'month');
  //   const currentParams = generateCalendarData(initialDate);
  //   const previousParams = generateCalendarData(previousMonth).reverse();

  //   const firstDayFromCurrentMonth = currentParams[0];
  //   const findIndexDay = dic.findIndex((x) => x === firstDayFromCurrentMonth.dayname);
  //   let getDateFromPrevious = previousParams.slice(0, findIndexDay).reverse();
  //   getDateFromPrevious = getDateFromPrevious.map((x) => {
  //     x.is_previous = 'is_previous';
  //     return x;
  //   });

  //   const dayOffMap = {};
  //   dayOffData.forEach((dayOff) => {
  //     const dayOffDate = dayjs(dayOff.periode).format('DD');
  //     if (!dayOffMap[dayOffDate]) {
  //       dayOffMap[dayOffDate] = [];
  //     }
  //     dayOffMap[dayOffDate].push(dayOff);
  //   });

  //   const calendar = [...getDateFromPrevious, ...currentParams.map((date) => {
  //     const dayOffInfoArray = dayOffMap[date.date] || [];
  //     return {
  //       ...date,
  //       is_filled: dayOffInfoArray.length > 0,
  //       dayOffInfoArray,
  //     };
  //   })];

  //   setCalendar([...calendar]);
  //   setPlainCalendar([...calendar]);
  // };

  const generateCalendarView = (initialDate) => {
    const previousMonth = dayjs(initialDate).subtract(1, 'month');
    const currentParams = generateCalendarData(initialDate);
    const previousParams = generateCalendarData(previousMonth).reverse();
  
    const firstDayFromCurrentMonth = currentParams[0];
    const findIndexDay = dic.findIndex((x) => x === firstDayFromCurrentMonth.dayname);
    let getDateFromPrevious = previousParams.slice(0, findIndexDay).reverse();
    getDateFromPrevious = getDateFromPrevious.map((x) => {
      x.is_previous = 'is_previous';
      return x;
    });
  
    const dayOffMap = {};
    dayOffData.forEach((dayOff) => {
      const startDate = dayjs(dayOff.start_date).format('YYYY-MM-DD');
      const endDate = dayjs(dayOff.end_date).format('YYYY-MM-DD');
  
      for (let date = startDate; date <= endDate; date = dayjs(date).add(1, 'day').format('YYYY-MM-DD')) {
        if (!dayOffMap[date]) {
          dayOffMap[date] = [];
        }
        dayOffMap[date].push(dayOff);
      }
    });
  
    const calendar = [...getDateFromPrevious, ...currentParams.map((date) => {
      const dayOffInfoArray = dayOffMap[date.periode] || [];
      return {
        ...date,
        is_filled: dayOffInfoArray.length > 0,
        dayOffInfoArray,
      };
    })];
  
    setCalendar([...calendar]);
  };
  
  useEffect(() => {
    generateCalendarView(initialDate);
  }, [dayOffData, initialDate]);

  const onChangeDate = (mode) => {
    let periode = dayjs(initialDate);
    if (mode === '-') {
      periode = periode.subtract(1, 'month');
    } else {
      periode = periode.add(1, 'month');
    }
    periode = dayjs(periode).format('YYYY-MM');

    setInitialDate(dayjs(periode).format('YYYY-MM'));
    generateCalendarView(periode);
  };

  const handleAdd = async () => {
    setLoading(true)
    addDayOff.title = 'Day-Off'
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };
    try {
      await createHris('day-off/store', session, addDayOff);
    } catch (error) {
      // console.log(error)
    }
    getDaysOff()
    setToggleModal(false)
    setAddDayOff({})
    setLoading(false)
  }
  

  return (
    <Stack>
      <HStack my={5} alignContent={'flex-start'}>
        <BackButtons />
        <Spacer />

        {/* Adjust the action prop based on your actual requirements */}
        <DynamicButton title="Days off" action={'create'} onClick={() => setToggleModal(true)} />
      </HStack>

      <HStack w={'full'} alignItems={'flex-start'}>
        <Card w="70%" h={'fit-content'} backgroundColor={'white'} shadow={'md'}>
          <CardBody>
            <Stack w={'full'}>
              <HStack justify={'space-between'} my={2}>
                <FcPrevious
                  size={20}
                  cursor={'pointer'}
                  onClick={() => onChangeDate('-')}
                />
                <Text fontSize={'xl'} fontWeight={'semibold'}>
                  {dayjs(initialDate).format('MMMM YYYY')}
                </Text>
                <FcNext
                  size={20}
                  cursor={'pointer'}
                  onClick={() => onChangeDate('+')}
                />
              </HStack>
              <Grid w={'full'} templateColumns="repeat(7, 1fr)" py={3} px={3}>
                {dic.map((x, i) => (
                  <Box key={i} bgColor={'gray.100'} h={12}>
                    <Text
                      align={'center'}
                      justifyContent={'center'}
                      my={2}
                      fontWeight={'medium'}
                      fontSize={'sm'}
                    >
                      {x}
                    </Text>
                  </Box>
                ))}
              </Grid>
              <Grid w={'full'} templateColumns="repeat(7, 1fr)" px={3}>
                {calendar?.map((date, index) => (
                  <Stack
                    shadow={'md'}
                    h={height * 1/8}
                    key={index}
                    p={2}
                  >
                    {!date?.is_previous ? (
                      <>
                        <Text
                          align={'right'}
                          justifyContent={'center'}
                          mt={2}
                          fontWeight={'medium'}
                          fontSize={'sm'}
                        >
                          {date?.date}
                        </Text>
                        {date?.is_filled ? (
                          <VStack>
                            {date?.dayOffInfoArray.map((dayOffInfo, index) => (
                              <Badge size={'2xs'} key={index} colorScheme="red" fontSize={'2xs'}>
                                {dayOffInfo?.description}
                              </Badge>
                            ))}
                          </VStack>
                        ) : ''}
                      </>
                    ) : (
                      ''
                    )}
                  </Stack>
                ))}
              </Grid>
            </Stack>
          </CardBody>
        </Card>
        <Card w={'30%'} bgColor={'white'}>
          <CardBody>
            <Text py={2} fontWeight={'bold'}> Days Off {dayjs(initialDate).format('MMMM YYYY')} </Text>
            <Stack w={'full'} p={2} overflowY={'scroll'} maxH={height * 9/10}>
              {calendar?.map((x) => (
                <>
                  {x?.dayOffInfoArray?.length > 0 ? (
                    <Stack py={3}>
                      <Text>{x.date} {dayjs(initialDate).format('MMMM YYYY')} : </Text>
                      {x.dayOffInfoArray?.map((z, index) => (
                        <Text key={index}>{z.description}</Text>
                      ))}
                      <Divider/>
                    </Stack>
                  ) : 
                    ( <>
                    </>)}
                </>
              ))}

            </Stack>
          </CardBody>
        </Card>
      </HStack>
      
      <Modal isOpen={toggleModal} onClose={() => setToggleModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new day-off</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Text> Select Date</Text>
              <HStack>
                <Input type='date' onChange={(e) => setAddDayOff({...addDayOff,start_date:e.target.value})}/>
                <Input type='date' onChange={(e) => setAddDayOff({...addDayOff,end_date:e.target.value})}/>
              </HStack>
              
            </Stack>
            <Stack>
              <Text>Description</Text>
              <Input onChange={(e) => setAddDayOff({...addDayOff,description:e.target.value})}/>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={() => setToggleModal(false)}>
              Close
            </Button>
            <Button colorScheme='teal' isLoading={loading} onClick={handleAdd}>Add</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
    </Stack>
  );
};

export default HrisDaysOff;
