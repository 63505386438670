import React, {  useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  Heading,
  HStack,
  Icon,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Square,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {  addDocumentFirebaseV3, getSingleDocumentFirebase, updateDocumentFirebaseV2, uploadFileFirebaseV2 } from '../../Api/firebaseApi';
import { auth } from '../../Config/firebase';
import { Controller,  useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { MdCancel, MdFileDownloadDone,MdUploadFile } from 'react-icons/md';
import BackButtons from '../../Components/Buttons/BackButtons';
import images from '../../assets/Documents-cuate.png'

const RecruitmentApplyForm = () => {
  const params = useParams();
  const [data, setData] = useState({});
  const [loadFile, setLoadFile] = useState(null)
  const toast = useToast();
  const user = auth.currentUser
  const formRef = useRef();
  const [currentUser, setCurrentUser] = useState(null)
  const [loading, setLoading] = useState(false)

  const getJobDetail = () => {
    getSingleDocumentFirebase ('positions', `${params.id}`)
      .then((result) => {
        setData(result)
      })
  }

  useEffect(() => {
    getJobDetail()
  }, [])

  const handleFile = (data) => {
    setLoadFile(data)
  }

  const handleCancel = () => {
    setLoadFile(null)
  }

  const getUser = async () => {
    try {
      const userData = await getSingleDocumentFirebase('users', user?.uid)
      setCurrentUser(userData)
      
    } catch (error) {
      // console.log(error)
    }
  }
  useEffect(() => {
    getUser()
  }, [])
  
  const req = yup.object().shape({
    name: yup.string().required('*This Field Reqired'),
    email: yup.string().required('*This Field Reqired'),
    phone: yup.number().required('*This Field Reqired'),
    major: yup.string().required('*This Field Reqired'),
    school: yup.string().required('*This Field Reqired'),
    domicile: yup.string().required('*This Field Reqired'),
    salary: yup.number().required('*This Field Reqired'),
    age: yup.string().required('*This Field Reqired'),
    ipk: yup.number().required('*This Field Reqired'),
    toefl: yup.string().required('*This Field Reqired'),
  })

  const {
    handleSubmit, 
    control, 
    setValue,
    formState: {errors},
  } = useForm({mode: 'onChange', resolver: yupResolver(req)})

  const submitForm = async (payload) => {
    setLoading(true)

    const dataProfile = {
      displayName: payload.name,
      email : payload.email,
      phone : payload.phone,
      major: payload.major,
      school: payload.school,
      domicile: payload.domicile,
      ipk: payload.ipk,
      salaryEx: payload.salary,
      age: payload.age,
      toefl_score: payload.toefl
    }
    const dataApplicants ={
      position_id: params.id,
      uid: user.uid,
      companyId : data.companyId,
      status: 'unprocessed',
      seen: 0,
      createdAt : Date.now().toString()
    }
    if (loadFile[0]) {
      const res = await uploadFileFirebaseV2(loadFile[0], {type:null})
      if (res) {
        dataProfile.cv = res.image_url
      }
    }
    const newData = dataProfile
    try {
      await updateDocumentFirebaseV2('users', user?.uid, newData)
      await addDocumentFirebaseV3('applications', dataApplicants, '')
    } catch (error) {
      // console.log(error.message)
    }
    setLoading(false)
    toast({
      title: 'Success',
      description: 'Application Accepted  : Wait for the Recruiter\'s Response',
      status: 'success',
      isClosable: true,
    });
    setTimeout(() => {
      window.history.back()
    }, 500)
  }

  useEffect(() => {
    if (currentUser) {
      setValue('name', currentUser?.displayName)
      setValue('email', currentUser?.email)
      setValue('age', currentUser?.age)
      setValue('domicile', currentUser?.domicile)
      setValue('ipk', currentUser?.ipk)
      setValue('major', currentUser?.major)
      setValue('phone', currentUser?.phone)
      setValue('salary', currentUser?.salaryEx)
      setValue('school', currentUser?.school)
      setValue('toefl', currentUser?.toefl_score)
    }
  },[currentUser])

  useEffect(() => {
    if (!currentUser) {
      setValue('name', '')
      setValue('email', '')
      setValue('age', '')
      setValue('domicile', '')
      setValue('ipk', '')
      setValue('major', '')
      setValue('phone', '')
      setValue('salary', '')
      setValue('school', '')
      setValue('toefl', '')
    }
  },[])

  
  const { getRootProps, getInputProps } = useDropzone({ 
    onDrop: handleFile,
    multiple: false
  })
  
  return (
    <>
      <HStack py={3}>
        <BackButtons/>
        <Heading size={'md'}>Application Form</Heading>
      </HStack>
      <>
        <Box _name={'relative'} ref={formRef}>
          <Container
            shadow={'md'}
            bgColor={'white'}
            as={SimpleGrid}
            maxW={'9xl'}
            columns={{ base: 1, md: 2 }}
            spacing={{ base: 10, lg: 10 }}
            p={{ base: 5, sm: 10, lg: 10 }}
            borderRadius={'lg'}
          >
            <Stack spacing={{ base: 2, md: 18 }}>
              <Heading
                lineHeight={1.1}
                fontSize={{ base: '2xl', sm: '3xl', md: '4xl', lg: '5xl' }}
              >
                {data.position_name}
              </Heading>

              <Heading size="xl" color="gray.400">
                {data.company}
              </Heading>
              <Image src={images} width={'70%'} m={'auto'}/>
              {/* {loadFile === null ? <></> : 
                <iframe src={loadFile[0]} width={'100%'} height="500px"></iframe>
                } */}
              {/* <Text>{data.job_location}</Text> */}
            </Stack>
            <Stack
              bg={'gray.50'}
              rounded={'xl'}
              p={{ base: 4, sm: 6, md: 8 }}
              spacing={{ base: 8 }}

              // maxW={{ lg: "lg" }}
            >
              <Stack spacing={4}>
                <Heading
                  color={'gray.800'}
                  lineHeight={1.1}
                  fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
                >
                  Join our team
                  <Text
                    as={'span'}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    bgClip="text"
                  >
                    !
                  </Text>
                </Heading>
              </Stack>
              <Text color={'gray.500'} fontSize={{ base: 'xs', sm: 'md' }}>
                *Wajib diisi
              </Text>
              <form onSubmit={handleSubmit(submitForm)}>
                <Stack spacing={4}>
                  <Stack direction={['column','row','row']} w={'full'} gap={2}>
                    <Box w={['full','50%','50%']} h={70}>
                      <Text>Name</Text>
                      <Controller
                        name="name"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*Name (sesuai KTP)"
                            {...field}
                            bg={'gray.100'}
                            border={0}
                            type="text"
                            invalid={errors.name}
                            color={'gray.800'}
                            name="name"
                          />
                        )}
                      />
                      {errors.name && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.name.message}</Text>)}
                    </Box>

                    <Box w={['full','50%','50%']} h={70}>
                      <Text>Email</Text>
                      <Controller
                        name="email"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*Email (firstname@lastname.io)"
                            bg={'gray.100'}
                            {...field}
                            border={0}
                            type="text"
                            invalid={errors.email}
                            color={'gray.800'}
                            name="email"
                          />
                        )}
                      />
                      {errors.email && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.email.message}</Text>)}
                    </Box>
                  </Stack>

                  <Stack direction={['column','row','row']} w={'full'} gap={2}>
                    <Box w={['full','50%','50%']} h={70}>
                      <Text>Phone Number</Text>
                      <Controller
                        name="phone"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*No. Hp 08**********"
                            bg={'gray.100'}
                            border={0}
                            type="text"
                            invalid={errors.phone}
                            {...field}
                            color={'gray.800'}
                            name="phone"
                          />
                        )}
                      />
                      {errors.phone && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.phone.message}</Text>)}

                    </Box>

                    <Box w={['full','50%','50%']} h={70}>
                      <Text>Major</Text>
                      <Controller
                        name="major"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*Major (e.g S1 Teknik Sipil)"
                            bg={'gray.100'}
                            border={0}
                            type="text"
                            invalid={errors.major}
                            {...field}
                            color={'gray.800'}
                            name="major"
                          />
                        )}
                      />
                      {errors.major && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.major.message}</Text>)}
                    </Box>
                  </Stack>
                  <Stack direction={['column','row','row']} w={'full'} gap={2}>
                    <Box w={['full','50%','50%']} h={70}>
                      <Text>University</Text>
                      <Controller
                        name="school"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*Education (e.g Universitas Indonesia)"
                            bg={'gray.100'}
                            border={0}
                            type="text"
                            invalid={errors.school}
                            {...field}
                            color={'gray.800'}
                            name="school"
                          />
                        )}
                      />
                      {errors.school && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.school.message}</Text>)}

                    </Box>
                      
                    <Box w={['full','50%','50%']} h={70}>
                      <Text>GPA</Text>
                      <Controller
                        name="ipk"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*IPK"
                            bg={'gray.100'}
                            border={0}
                            {...field}
                            type="number"
                            invalid={errors.ipk}
                            color={'gray.800'}
                            name="ipk"
                          />
                        )}
                      />
                      {errors.ipk && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.ipk.message}</Text>)}

                    </Box>
                  </Stack>
                  <Stack direction={['column','row','row']} w={'full'} gap={2}>
                    <Box w={['full','50%','50%']} h={70}>
                      <Text>Domicile</Text>
                      <Controller
                        name="domicile"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*Domicile (e.g Dramaga, Kab.Bogor)"
                            bg={'gray.100'}
                            border={0}
                            {...field}
                            type="text"
                            invalid={errors.domicile}
                            color={'gray.800'}
                            name="domicile"
                          />
                        )}
                      />
                      {errors.domicile && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.domicile.message}</Text>)}

                    </Box>
                      
                    <Box w={['full','50%','50%']} h={70}>
                      <Text>Salary Expectation</Text>
                      <Controller
                        name="salary"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*Salary Expectation (e.g Rp. 7.000.000 = 70000000)"
                            bg={'gray.100'}
                            border={0}
                            type="text"
                            invalid={errors.salary}
                            {...field}
                            color={'gray.800'}
                            name="salary"
                          />
                        )}
                      />
                      {errors.salary && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.salary.message}</Text>)}
                    </Box>
                  </Stack>
                  <Stack direction={['column','row','row']} w={'full'} gap={2}>
                    <Box w={['full','50%','50%']} h={70}>
                      <Text>Age</Text>
                      <Controller
                        name="age"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*Age (e.g 21)"
                            bg={'gray.100'}
                            border={0}
                            type="text"
                            invalid={errors.age}
                            {...field}
                            color={'gray.800'}
                            name="age"
                          />
                        )}
                      />
                      {errors.age && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.age.message}</Text>)}

                    </Box>

                    <Box w={['full','50%','50%']} h={70}>
                      <Text>Toefl Score</Text>
                      <Controller
                        name="toefl"
                        control={control}
                        render={({field}) => (
                          <Input
                            placeholder="*Skor Toefl"
                            bg={'gray.100'}
                            border={0}
                            type="text"
                            invalid={errors.toefl}
                            {...field}
                            color={'gray.800'}
                            name="toefl"
                          />
                        )}
                      />
                      {errors.toefl && (<Text fontSize={'xs'} color={'red'} fontStyle={'italic'}>{errors.toefl.message}</Text>)}

                    </Box>
                  </Stack>

                  <Box >                      
                    <FormControl id="file">
                      <Stack {...getRootProps()}>
                            
                        <Input 
                          name="file_CV"
                          accept=".pdf"
                          {...getInputProps()}
                        />   
                        {loadFile === null ?
                          <>
                            <Center
                              borderWidth="3px"
                              borderRadius="lg"
                              borderStyle={'dashed'}
                              px="6"
                              py="4"
                            >
                              <VStack spacing="3">
                                <Square size="8" bg="bg-subtle" borderRadius="lg">
                                  <Icon as={MdUploadFile} boxSize="8" color="muted" />
                                </Square>
                                <VStack spacing="1">
                                  <Text fontSize="sm" color="muted">
                                    *Upload Your Latest CV
                                  </Text>
                                  <HStack spacing="1" whiteSpace="nowrap">
                                    <Button variant="link" colorScheme="blue" size="sm" cursor={'pointer'}>
                                      Click to upload
                                    </Button>
                                    <Text fontSize="sm" color="muted">
                                      or drag here
                                    </Text>
                                  </HStack>
                                  <Text fontSize="xs" color="muted">
                                    PDF up to 2MB
                                  </Text>
                                </VStack>
                              </VStack>
                            </Center>
                          </>
                          :
                          <>
                            <Center
                              borderWidth="3px"
                              borderRadius="lg"
                              borderStyle={'dashed'}
                              px="6"
                              py="4"
                              gap={3}
                            >
                              <MdFileDownloadDone size={30}/>
                              <Text fontSize={'sm'} noOfLines={1}>{loadFile[0].name}</Text>
                              <MdCancel size={30} cursor={'pointer'} onClick={handleCancel}/>
                            </Center>
                                
                          </>}
                                              
                      </Stack>
                    </FormControl>
                  </Box>
                </Stack>
                {loading === true ?
                  <>
                    <Button
                      fontFamily={'heading'}
                      mt={8}
                      w={'full'}
                      bgGradient="linear(to-r, yellow.400,pink.200)"
                      color={'white'}
                      _hover={{
                        bgGradient: 'linear(to-r, #ffd600,yellow.400)',
                        boxShadow: 'xl',
                      }}
                      disabled={loading}
                    >
                      <Spinner/>
                    </Button>
                  </> 
                  : 
                  <>
                    <Button
                      fontFamily={'heading'}
                      mt={8}
                      w={'full'}
                      bgGradient="linear(to-r, yellow.400,pink.200)"
                      color={'white'}
                      _hover={{
                        bgGradient: 'linear(to-r, #ffd600,yellow.400)',
                        boxShadow: 'xl',
                      }}
                      type="submit"
                    >
                      Submit
                    </Button>
                  </>}
                  
              </form>
                
            </Stack>
          </Container>
        </Box>
      </>
      
    </>
  )
}

export default RecruitmentApplyForm