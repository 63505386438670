/* eslint-disable react/no-children-prop */
import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import logo from '../../assets/1.png';
import {
  MdAccountCircle,
  MdEmail,
  MdLock,
  MdOutlinePhoneIphone,
  MdVisibility,
  MdVisibilityOff,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from 'firebase/auth';
import { auth, db } from '../../Config/firebase';
import { doc, setDoc } from 'firebase/firestore';
import DynamicButton from '../../Components/Buttons/DynamicButton';

const RecruitmentSignUp = () => {
  const [name, setName] = useState('');
  const [nohp, setNohp] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 600);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 600);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });


  const navigate = useNavigate();
  const toast = useToast();



  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSignup = () => {
    const displayName = name;

    if (
      (email === '' && password === '' && nohp === '' && name === '') ||
      password !== confirmPassword
    )
      return toast({
        title: 'Something Wrong',
        description: 'check your email, password, data',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-end',
      });

    if (email !== '' && password !== '' && nohp !== '' && name !== '') {
      try {
        setLoading(true);

        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            await updateProfile(auth.currentUser, {
              displayName,
            });
            // navigate("/", { replace: true });
            sendEmailVerification(auth.currentUser);

            // Signed in
            const user = userCredential.user;
            if (user) {
              await setDoc(doc(db, 'users', user.uid), {
                name: name,
                keyword_name: name.toLowerCase().split(' ').join(''),
                email: user.email,
                createdAt: new Date(),
                phone_number: nohp,
                type: 'recruitment'
              });

              setLoading(false);
              toast({
                title: 'Success Create',
                description: `Success Create account ${user.displayName}`,
                status: 'success',
                duration: 10000,
                isClosable: true,
                position: 'top-right',
              });
            }
          })
          .catch((error) => {
            toast({
              title: 'Something Wrong',
              description: `It looks like you don't have account in your browser, please signup and reload this page / ${error.message}`,
              status: 'error',
              duration: 10000,
              isClosable: true,
              position: 'top-right',
            });
            setLoading(false);
          });
      } catch (error) {
        toast({
          title: 'Something Wrong',
          description: error,
          status: 'error',
          duration: 10000,
          isClosable: true,
          position: 'top-end',
        });
        setLoading(false);
      }
    } else {
      toast({
        title: 'Something Wrong',
        description: 'check your data',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-end',
      });
    }
  };

  const height = window.innerHeight;
  const width = window.innerWidth;

  return (
    <>
      <Stack
        spacing={10}
        pt={20}
        minH={height}
        bg="url(https://buildfire.com/wp-content/themes/buildfire/assets/images/gsf-hero-sm.jpg) no-repeat center center fixed"
        bgSize="cover"
        alignItems={'center'}
        justifyContent="center"
      >
        <Stack position={'absolute'} top={5} left={5}>
          <DynamicButton action="custom" title="Back to Signup user" color="blue" variant={'solid'} onClick={() => navigate('/signup')} />
        </Stack>


        <Stack>
          <SimpleGrid columns={['1', '2', '2']} justifySelf='end' gap='5'>
            {isDesktop ?
              <Stack
                minW={['90%', null, width / 4]}
                spacing={3}
                p={10}
                bgColor="blackAlpha.500"
                shadow={'md'}
                borderRadius={'xl'}
                // _hover={{ transform: "scale(1.1)", shadow: "xl" }}
                transition={'0.2s ease-in-out'}
                alignItems={'center'}
                justifyContent="center"
                textColor='white'
              >

                <Heading textAlign='center'>Are you our next professional<br /> talent?</Heading>
                <Text textAlign='center'>We are always looking for new talent to join our studio in Business Development and Information Technology</Text>

              </Stack>
              : ''}

            <Stack alignItems={'center'} justifyContent="center">
              <Stack
                minW={['90%', null, width / 3]}
                justifyContent="center"
                alignItems="center"
                spacing={5}
                pb={10}
                bgColor="blackAlpha.500"
                p={10}
                borderRadius="xl"
              >
                <Box>
                  <Image
                    w="200px"
                    borderRadius={20}
                    src={logo}
                    alt="Alternate Text"
                  />
                </Box>
                <Spacer />

                <Stack alignItems="center">
                  <InputGroup
                    w={{
                      base: '100%',
                      md: '285',
                    }}
                  >
                    <InputLeftAddon
                      children={<MdAccountCircle size={24} color="black" />}
                    />
                    <Input
                      placeholder="Full name"
                      fontSize={'sm'}
                      type="text"
                      bgColor={'white'}
                      color={'blackAlpha.700'}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                </Stack>

                <Stack alignItems="center">
                  <InputGroup
                    w={{
                      base: '100%',
                      md: '285',
                    }}
                  >
                    <InputLeftAddon
                      children={<MdOutlinePhoneIphone size={24} color="black" />}
                    />
                    <Input
                      w={{
                        base: '100%',
                        md: '100%',
                      }}
                      placeholder="Phone number"
                      fontSize={'sm'}
                      type="number"
                      bgColor={'white'}
                      color={'blackAlpha.700'}
                      onChange={(e) => setNohp(e.target.value)}
                    />
                  </InputGroup>
                </Stack>

                <Stack alignItems="center">
                  <InputGroup
                    w={{
                      base: '100%',
                      md: '285',
                    }}
                  >
                    <InputLeftAddon
                      children={<MdEmail name="email" size={24} color="black" />}
                    />
                    <Input
                      w={{
                        base: '100%',
                        md: '100%',
                      }}
                      placeholder="Email"
                      fontSize={'sm'}
                      bgColor={'white'}
                      color={'blackAlpha.700'}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </Stack>
                <Stack alignItems="center">
                  <InputGroup
                    w={{
                      base: '90%',
                      md: '275',
                    }}
                  >
                    <InputLeftAddon children={<MdLock size={24} color="black" />} />
                    <Input
                      w={{
                        base: '100%',
                        md: '100%',
                      }}
                      placeholder="Password"
                      fontSize={'sm'}
                      type={showPassword ? 'text' : 'password'}
                      bgColor={'white'}
                      color={'blackAlpha.700'}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement>
                      {showPassword ? (
                        <MdVisibilityOff
                          size={20}
                          color="black"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <MdVisibility
                          size={20}
                          color="black"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </Stack>

                <Stack alignItems="center">
                  <InputGroup
                    w={{
                      base: '90%',
                      md: '275',
                    }}
                  >
                    <InputLeftAddon children={<MdLock size={24} color="black" />} />
                    <Input
                      w={{
                        base: '100%',
                        md: '100%',
                      }}
                      placeholder="Confirm password"
                      fontSize={'sm'}
                      id="password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      bgColor={'white'}
                      color={'blackAlpha.700'}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleSignup();
                        }
                      }}
                    />
                    <InputRightElement>
                      {showConfirmPassword ? (
                        <MdVisibilityOff
                          size={20}
                          color="black"
                          onClick={toggleConfirmPasswordVisibility}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : (
                        <MdVisibility
                          size={20}
                          color="black"
                          onClick={toggleConfirmPasswordVisibility}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </Stack>


                <Stack pt={5}>
                  {loading ? (
                    <Spinner size={'sm'} />
                  ) : (
                    <Button
                      alignItems={'center'}
                      justifyContent="center"
                      size={'sm'}
                      bgColor={'white'}
                      onClick={() => handleSignup()}
                    >
                      <Text color={'black'} fontSize="xs" fontWeight="bold">
                        CREATE ACCOUNT
                      </Text>
                    </Button>
                  )}
                </Stack>

                <Spacer />

                <HStack space={1}>
                  <Text color={'gray.400'} fontSize="sm">
                    Back to
                  </Text>
                  <Text
                    color={'gray.400'}
                    fontWeight="bold"
                    fontSize="sm"
                    cursor={'pointer'}
                    onClick={() => navigate('/login')}
                  >
                    Login
                  </Text>
                </HStack>

                <Spacer />

                <HStack space={1}>
                  <Text color={'gray.400'} fontSize={['xs', null, 'sm']}>
                    Dont have any account ?
                  </Text>
                  <Text
                    color={'gray.400'}
                    fontSize={['xs', null, 'sm']}
                    cursor="pointer"
                    fontWeight="bold"
                    onClick={() => navigate('/login')}
                  >
                    Sign In !
                  </Text>
                </HStack>

              </Stack>
            </Stack>
          </SimpleGrid>
          <Spacer />

          <Stack>{/* <AppSponsor /> */}</Stack>
        </Stack>
      </Stack>
    </>
  )
}

export default RecruitmentSignUp