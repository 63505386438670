import { FcBriefcase, FcHome } from 'react-icons/fc';

export const data = [
  {
    name:'Home',
    icon: FcHome,
    link:'/',
  },
  {
    name:'Job Application',
    icon: FcBriefcase,
    link:'/application'
  }
]