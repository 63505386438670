import {
  Avatar,
  Button,
  HStack,
  Input,
  Stack,
  Text,
  Box,
  Heading,
  InputGroup,
  InputLeftElement,
  Icon,
  SimpleGrid,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import BackButtons from '../../Components/Buttons/BackButtons';
import {
  MdNavigateBefore,
  MdNavigateNext,
} from 'react-icons/md';
import useUserStore from '../../Hooks/Zustand/Store';
import { clientTypessense } from '../../Api/Typesense';
import _ from 'lodash';
import DynamicTable from '../../Components/Table/DynamicTable';
import { Search2Icon } from '@chakra-ui/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import { FcCalculator, FcConferenceCall } from 'react-icons/fc';

const HrisKpiPage = () => {
  const globalState = useUserStore();
  const [searchParams, setSearchParams] = useSearchParams();
  //state form

  const [data, setData] = useState();
  const [dataUser, setDataUser] = useState();
  const [page, setPage] = useState(1);
  const [chunkUser, setChunkUser] = useState();

  const navigate = useNavigate()
  const show = searchParams.get('show');

  const tableHeader = ['name', 'email', 'score'];

  const handleTypesenseSearch = (q, kodok, pagination = page) => {
    globalState.setIsLoading(true);
    setPage(pagination);
    const users = globalState?.companies?.find(
      (x) => x.id === globalState.currentCompany
    );

    const newArr = _.chunk(users.users, 10);
    if (!newArr) return new Error('no data user');

    const searchParameters = {
      q: q,
      query_by: 'name,email',
      per_page: 10,
      filter_by: `id: [${newArr[page - 1]?.join(',')}]`,
      sort_by: '_text_match:desc',
    };

    clientTypessense
      .collections('users')
      .documents()
      .search(searchParameters)
      .then((x) => {
        const newData = x.hits.map((y) => {
          return { ...y.document };
        });

        setData(newData);

        globalState.setIsLoading(false);
      })

      .catch((err) => {
        setData([]);
        globalState.setIsLoading(false);
        throw new Error(err.message, 'Failed to send api');
      });

    return null;
  };

  const handleSingleTypesenseSearch = (q) => {
    if (q === '') return handleTypesenseSearch('', null, 1);

    const searchPromises = chunkUser.map((userChunk) => {
      const searchParameters = {
        q: q,
        query_by: 'name,email',
        filter_by: `id: [${userChunk.join(',')}]`,
        sort_by: '_text_match:desc',
      };
      return clientTypessense
        .collections('users')
        .documents()
        .search(searchParameters);
    });

    Promise.all(searchPromises)
      .then((results) => {
        // Process the search results without role lookup
        const processedResults = results.flatMap((result) => {
          return result.hits.map((x) => {
            return { ...x.document };
          });
        });

        setData(processedResults);
      })
      .catch((error) => {
        throw new Error(error.message, 'Failed to send api');
      });
  };

  const getDataFirst = () => {
    const users = globalState?.companies?.find(
      (x) => x.id === globalState.currentCompany
    );
    setDataUser(users?.users);
    setChunkUser(_.chunk(users.users, 10));
    handleTypesenseSearch('', users.users, 1);
  };

  useEffect(() => {
    getDataFirst();

    return () => {
      setPage(1);
      setDataUser();
    };
  }, [globalState.currentCompany]);

  let tableData = '';
  tableData = data?.map((data) => {
    const name = data?.name || '';
    const email = data?.email || '';
    // const status = data?.status || "";
    // const role = data?.role || "Role not assigned";

    return {
      data,
      name: (
        <HStack>
          <Avatar size={'md'} name={name} />
          <Stack spacing={1}>
            <Text fontWeight={500} textTransform={'capitalize'}>
              {name}
            </Text>
            <Text fontSize={9}>{data?.id}</Text>
          </Stack>
        </HStack>
      ),
      email: email,
      // status: status,
      // role: role,
    };
  });

  const openModalEdit = (x) => {
    navigate(`/hris/kpi/${x?.data?.id}`)
  };

  return (
    <Stack display={'flex'} flexDirection={'column'} p={[0, 1, 5]} h={'100vh'}>
      <HStack pb={3}>
        <BackButtons />
        {/* <Spacer /> */}
        <Heading size={'md'}>Key Performance Indicator</Heading>
      </HStack>

      <Stack
        // h={'auto'}
        bg={'white'} p="3" borderRadius="md" shadow="base">
        <HStack flexDirection={['column', 'row', 'row']} mt={3} p={2} justifyContent={'space-between'}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.300" />
            </InputLeftElement>
            <Input
              bg={'white'}
              maxW="2xs"
              type="text"
              placeholder="Search User"
              onChange={(e) => handleSingleTypesenseSearch(e.target.value)}
            // onChange={(e) => handleTypesenseSearch(e.target.value)}
            />
          </InputGroup>
          <DynamicButton
            action={'create'}
            title={'Add New KPI'}
            onClick={() => navigate('create')}
            disabled={false}
          />
        </HStack>

        <Stack alignContent={'center'} justifyContent={'center'}>
          <SimpleGrid columns={{ base: 1, md: 2 }} px={{ base: 5, sm: 10, lg: 100 }} gap={12}>
            <Stack
              p={3}
              bg={''}
              border={'1px'}
              shadow={'base'}
              align={'center'}
              cursor={'pointer'}
              borderRadius={'md'}
              borderColor={'gray.300'}
              onClick={() => navigate('list')}
              _hover={{ transform: 'scale(1.03)', transition: '0.3s' }}
            >
              <Icon as={FcCalculator} boxSize={12} />
              <Text fontWeight={500}>KPI Master Data</Text>
              <Text textAlign={'center'} my={5} color={'gray.500'}>
                Manage company&apos;s key performance indicator to standarize workflows for every role!
              </Text>
            </Stack>
            <Stack
              p={3}
              border={'1px'}
              shadow={'base'}
              align={'center'}
              cursor={'pointer'}
              borderRadius={'md'}
              borderColor={'gray.300'}
              onClick={() => setSearchParams({ show: 'people' })}
              _hover={{ transform: 'scale(1.03)', transition: '0.3s' }}
            >
              <Icon as={FcConferenceCall} boxSize={12} />
              <Text fontWeight={500}>People</Text>
              <Text textAlign={'center'} my={5} color={'gray.500'}>
                Assign and edit KPI to individual users and see how they perform!
              </Text>
            </Stack>
          </SimpleGrid>
        </Stack>
        {show === 'people' &&
          <Box>
            <DynamicTable
              header={tableHeader}
              data={tableData}
              onRead={openModalEdit}
            />
          </Box>}

        <HStack justifyContent={'center'} spacing={5} pb={5}>
          {page > 1 ? (
            <Button
              colorScheme="blue"
              size={'sm'}
              onClick={() => handleTypesenseSearch('', null, page - 1)}
            // isDisabled={page > 1 ? false : true}
            >
              <HStack color="white" spacing={1} pr={3}>
                <MdNavigateBefore size={23} />
                <Text>Prev</Text>
              </HStack>
            </Button>
          ) : null}

          {page < dataUser?.length / 10 ? (
            <Button
              colorScheme="blue"
              size={'sm'}
              onClick={() => handleTypesenseSearch('', null, page + 1)}
            // isDisabled={page < mongoData ? false : true}
            >
              <HStack color="white" spacing={1} pl={3}>
                <Text>Next</Text>
                <Box>
                  <MdNavigateNext size={23} />
                </Box>
              </HStack>
            </Button>
          ) : null}
        </HStack>
      </Stack >

    </Stack >
  );
};

export default HrisKpiPage;
