import { HStack, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import {
  FcAdvance,
  FcAlarmClock,
  FcClock,
  FcCompactCamera,
  FcSettings,
} from 'react-icons/fc';
import HrisAttendanceLive from './HrisAttendanceLive';
import HrisAttendanceCorrection from './HrisAttendanceCorrection';
import HrisAttendanceLeave from './HrisAttendanceLeave';
import HrisAttandanceOvertime from './HrisAttandanceOvertime';
import { useNavigate, useParams } from 'react-router-dom';
import BackButtons from '../../Components/Buttons/BackButtons';
import DynamicButton from '../../Components/Buttons/DynamicButton';

const HrisAttandanceActivity = () => {
  const navigate = useNavigate();
  const param = useParams();

  return (
    <Stack>
      <BackButtons />
      <HStack
        flexDirection={['column', 'row', 'row']}
        gap={2}
        bgColor={'blue.800'}
        w={'full'}
        shadow={'md'}
        borderRadius={'md'}
        p={2}
        px={[1, 1, 5]}
      >
        <FcClock size={30} />
        <Text
          fontSize={'lg'}
          fontWeight="bold"
          color={'gray.200'}
          textTransform="capitalize"
        >
          {param.type} Attendance
        </Text>
        <Spacer />
        <Stack
          bgColor={'white'}
          borderRadius={'md'}
          shadow={'md'}
          w={'fit-content'}
          my={3}
        >
          <HStack
            w={['200px', '400px', '400px']}
            overflowX="scroll"
            p={3}
            spacing={2}
            sx={{
              '&::-webkit-scrollbar': {
                w: '3',
                h: '2',
              },
              '&::-webkit-scrollbar-track': {
                w: '5',
                h: '6',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10',
                bg: 'gray.200',
              },
            }}
          >
            <Stack w={'100px'}>
              <DynamicButton
                action={'custom'}
                title={'live'}
                color={'gray'}
                icon={FcCompactCamera}
                onClick={() => navigate('/attendance/home/live')}
                variant="solid"
              />
            </Stack>

            <Stack w={'100px'}>
              <DynamicButton
                action={'custom'}
                title="Correction"
                color={'gray'}
                icon={FcSettings}
                onClick={() => navigate('/attendance/home/correction')}
                variant="solid"
              />
            </Stack>
            <Stack w={'100px'}>
              <DynamicButton
                action={'custom'}
                title="Leave"
                color={'gray'}
                icon={FcAdvance}
                onClick={() => navigate('/attendance/home/leave')}
                variant="solid"
              />
            </Stack>
            <Stack w={'100px'}>
              <DynamicButton
                action={'custom'}
                title="Overtime"
                color={'gray'}
                icon={FcAlarmClock}
                onClick={() => navigate('/attendance/home/overtime')}
                variant="solid"
              />
            </Stack>
          </HStack>
        </Stack>
      </HStack>
      <Stack>
        {param.type === 'overtime' ? (
          <HrisAttandanceOvertime />
        ) : param.type === 'correction' ? (
          <HrisAttendanceCorrection />
        ) : param.type === 'leave' ? (
          <HrisAttendanceLeave />
        ) : (
          <HrisAttendanceLive />
        )}
      </Stack>
    </Stack>
  );
};

export default HrisAttandanceActivity;
