/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Container, Flex } from '@chakra-ui/react';
import { Fragment } from 'react';
// import FooterComponent from "../Components/Footers/FooterComponent";
import HeaderComponent from '../Components/Header/HeaderComponents';
import themeConfig from '../Config/themeConfig';
import { useLocation } from 'react-router-dom';
import SidebarUserRecruitment from '../Components/Sidebar/SidebarUserRecruitment';

function LayoutRecruitment({ children }) {
  const contentWidth =
    themeConfig.contentWidth === 'full' ? 'full' : 'container.xl';

  const location = useLocation();

  const isFunnelEditPage = location.pathname.includes('/lp-builder');

  return (
    <>
      <Flex height="100vh" w={'full'} bgColor={'gray.50'}>
        {/* <SidebarComponentV2 layout={themeConfig.layout} /> */}

        {!isFunnelEditPage && (
          <SidebarUserRecruitment layout={themeConfig.layout} />
        )}

        <Container maxW={'full'} overflowY={'scroll'} pt={'4'}>
          <HeaderComponent layout={themeConfig.layout} />

          <Container minH={'95vh'} maxW={contentWidth}>
            {children}
          </Container>

          {/* <FooterComponent /> */}
        </Container>
      </Flex>
    </>
  );
}

export default LayoutRecruitment;
