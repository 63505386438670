import { Card, CardBody, CardHeader, HStack, Heading, Input, Select, Spacer, Stack, Text, useToast } from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import BackButtons from '../../Components/Buttons/BackButtons'
import DynamicButton from '../../Components/Buttons/DynamicButton'
import useUserStore from '../../Hooks/Zustand/Store'
import { createHris, deleteHris, readHris } from '../../Api/Hris'

const HrisDivisionPage = () => {
  const toast = useToast()
  const [modal, setModal] = useState(false)
  const [user, setUser] = useState([])
  const [detail, setDetail] = useState([])
  const [loading, setLoading] = useState(false)
  const [division, setDivision] = useState([])
  const globalState = useUserStore();


  const [postData, setPostData] = useState({
    project_uid: '',
    manager_uid: '',
    name: ''
  })
  const [mode, setMode] = useState({
    mode: '',
    title: ''
  })

  const getUser = async () => {

    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    }



    try {
      const { data } = await readHris('user?search=', session)
      setUser(data)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    getUser()
  }, [])


  const getDivision = async () => {

    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    }


    try {
      const { data } = await readHris('division', session)

      const userProm = data?.map((x) => {
        const uName = x?.manager_name
        const addUser = user?.find((y) => y?.name === uName)
        if (addUser) {
          x.manager_data = addUser
        }
        return x
      })
      const managerData = await Promise.all(userProm)
      setDivision(managerData)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    getDivision()

    return () => {
      setDivision([])
    }
  }, [])

  const handleDetail = (x) => {
    setDetail(x)
    setMode({ mode: 'detail', title: 'Division Detail' })
    setModal(true)
  }

  const handleAdd = () => {
    setMode({ mode: 'add', title: 'Add Division Form' })
    setModal(true)
  }

  const handleSubmit = async () => {

    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    }

    setLoading(true)
    try {
      await createHris('division', session, postData)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setModal(false)
      getDivision()
      setLoading(false)
      toast({
        title: 'Deoapp HRIS',
        description: 'Success',
        status: 'success',
        duration: 2000
      })
    }
  }

  const handleDelete = async (x) => {
    setLoading(true)

    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject
    }

    try {
      await deleteHris(`division/${x?.id}/delete`, session)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        duration: 3000,
        description: error.message,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    } finally {
      setModal(false)
      getDivision()
      setLoading(false)
      toast({
        title: 'Deoapp HRIS',
        description: 'Division had been deleted',
        status: 'success',
        duration: 2000
      })
    }
  }

  return (
    <Stack>
      <HStack my={5} flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size={'md'} fontWeight="bold">
          Division
        </Heading>
        <Spacer />
        <DynamicButton onClick={handleAdd} title={'Add Division'} action={'create'} />
      </HStack>
      <Card w={'full'} backgroundColor={'white'} shadow={'md'}>
        <CardHeader>
          <HStack justify={'space-between'} flexDirection={['column', 'row', 'row']}>
            <Text fontSize={'lg'} fontWeight={'medium'}>
              Divisions
            </Text>
            <HStack w={['100%', '30%', '30%']}>
              <Text w={'30%'} color={'gray'}>
                Filter
              </Text>
              <Input />
            </HStack>
          </HStack>
        </CardHeader>
        <CardBody>
          <TableContainer>
            <Table variant="striped" colorScheme="gray">
              <Thead bg={'white'}>
                <Tr align={'center'}>
                  <Th>Name</Th>
                  <Th>Leader</Th>
                  <Th>Total Employe</Th>
                  {/* <Th></Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {division?.map((x, index) => (
                  <Tr key={index}>
                    <Td>{x?.name}</Td>
                    <Td>{x?.manager_name}</Td>
                    <Td >{x?.total_employee}</Td>
                    <Td>
                      <DynamicButton action={'read'} size="md" onClick={() => handleDetail(x)} />

                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </CardBody>
      </Card>

      {mode.mode === 'detail' ?
        <Modal isOpen={modal} onClose={() => setModal(false)} motionPreset='slideInBottom' isCentered size={'xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{mode.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <HStack justify={'space-between'} my={2}>
                <Text>Project</Text>
                <Text>{detail?.name}</Text>
              </HStack>
              <HStack justify={'space-between'} my={2}>
                <Text>Manager</Text>
                <Text>{detail?.manager_name}</Text>
              </HStack>
              <HStack justify={'space-between'} my={2}>
                <Text>Total Employee</Text>
                <Text>{detail?.total_employee}</Text>
              </HStack>
            </ModalBody>
            <ModalFooter>
              <DynamicButton action={'delete'} title="Delete Division" isLoading={loading} onClick={() => handleDelete(detail)} />
            </ModalFooter>
          </ModalContent>
        </Modal>
        :
        <Modal isOpen={modal} onClose={() => setModal(false)} motionPreset='slideInBottom' isCentered size={'xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{mode.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack mt={3}>
                <Text>Division Name</Text>
                <Input onChange={(e) => setPostData({ ...postData, name: e.target.value })} placeholder='Input Division Name' />
              </Stack>
              <Stack mt={3}>
                <Text>Division Leader</Text>
                <Select placeholder='Select Manager' onChange={(e) => setPostData({ ...postData, manager_uid: e.target.value })}>
                  {user?.map((x, index) => (
                    <option value={x?.uid} key={index}>{x?.name}</option>
                  ))}
                </Select>
              </Stack>
            </ModalBody>
            <ModalFooter gap={5}>
              <DynamicButton action={'delete'} title="Cancel" isLoading={loading} onClick={() => setModal(false)} />
              <DynamicButton action={'create'} title="Submit" isLoading={loading} onClick={() => handleSubmit()} />
            </ModalFooter>
          </ModalContent>
        </Modal>
      }

    </Stack>
  )
}

export default HrisDivisionPage