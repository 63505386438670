/* eslint-disable react/react-in-jsx-scope */
import { Box, HStack, Select, Text, } from '@chakra-ui/react';
import BackButtons from '../../Components/Buttons/BackButtons';
import PropTypes from 'prop-types';

const RecruitmentApplicantHeaderComponent = ({readLimit, setReadLimit}) => {

  const SearchComponent = () => {

    // const handleSearch = () => {
    //   const newArr = candidates.filter((candidate) => {
    //     return String(candidate.data[search.key])
    //       .toLowerCase()
    //       .includes(search.value.toLowerCase());
    //   });
    //   setFilteredArray(newArr);
    //   setShouldSearch(true);
    // };

    // const handleClear = () =>{
    //   setSearch({});
    //   setShouldSearch(false);
    // };


    return (
    // <Box Box m="2" p="2" bgColor="white" borderRadius="md">
    //   <Text fontSize={13}>Cari kandidat menggunakan kata kunci</Text>

      //   <HStack>
      //     <Input
      //       size='sm'
      //       type="value"
      //       maxW="sm"
      //       id="key"
      //       onChange={(e) => searchChange(e.target.id, e.target.value)}
      //     />
      //     <Select
      //       id="key"
      //       size='sm'
      //       fontSize={13}
      //       placeholder="Field"
      //       maxW="32"
      //       onChange={(e) => searchChange(e.target.id, e.target.value)}
      //     >
      //       <option value="name">Nama</option>
      //       <option value="email">Email</option>
      //       <option value="major">Jurusan</option>
      //       <option value="school">Universitas</option>
      //     </Select>
      //     <Button bgColor="brand.100" size='sm' >Cari</Button>
      //     <Button bgColor="cyan.100" size='sm' >Reset</Button>
      //     <Spacer />
      //     <HStack bgColor="white" p="2" borderRadius="md">
      //       <Text>Urutkan</Text>
      //       <Select
      //         placeholder="Timestamp"
      //         maxW="40"
      //         size="sm"
      //         // onChange={(e) => console.log(e.target.value)}
      //       >
      //         <option value="timestamp">Paling Baru</option>
      //         <option value="timestamp">Paling Lama</option>
      //         <option value="name">Nama</option>
      //         <option value="status">Status</option>
      //         <option value="major">Jurusan</option>
      //         <option value="school">Universitas</option>
      //       </Select>
      //     </HStack>
      //   </HStack>
      // </Box>
      <>
      </>
    );
  };



  return (
    <>
      <Box
        display='flex'
        justifyContent='space-between'
        p="2"
        bgColor="white"
        borderRadius="md"
        my={3}
      >
        <HStack>
          <BackButtons />
          <Text fontSize={'xl'} fontWeight={500}>
            List Applicants
          </Text>
        </HStack>
        <HStack>
          <Text>Show per page:</Text>
          <Select
            width={20}
            onChange={(e) => setReadLimit(e.target.value)}
            value={readLimit}
          >
            <option value='5'>5</option>
            <option value='10'>10</option>
            <option value='20'>20</option>
          </Select>
        </HStack>
      </Box>

      {/* <HStack gap={15}>
          {list?.length > 0 &&
              list?.map((item, i) => (
                <HStack>
                <Button onClick={() => navigate(`/applicants/view/${params.id}/${item?.status}`)} key={i} bgColor={'transparent'} _hover={{bgColor:'transparent'}}>
                  <Text>{item.display}</Text>
                  <Badge bgColor={'red.400'} color={'white'} mb={4} ml={2}>
                    {item.number}
                  </Badge>
                </Button>
                </HStack>
              ))}
          </HStack> */}

      <SearchComponent />
    </>
  )
}


RecruitmentApplicantHeaderComponent.propTypes = {
  readLimit: PropTypes.string,
  setReadLimit: PropTypes.func,
};


export default RecruitmentApplicantHeaderComponent