export const getProjectUsers = (projects, projectId, users=[]) => {
  const selectedProject = projects.find((x) => x?.id === projectId);
  const projectUsers = selectedProject?.users; //this is array od uids
  const arr = []

  users.forEach((user) => {
    if (projectUsers?.indexOf(user?.id) > -1) {
      //In the array!
      arr.push(user)
    } 
  })

  const filteredUser = arr?.filter((x) => Object.keys(x).length > 0 && x?.email !== '');
  
  // Sorting the array based on the 'name' property
  filteredUser.sort((a, b) => a?.name?.localeCompare(b?.name));
  
  return filteredUser
}