import React from 'react';
import HrisAttandancePage from '../Pages/HRIS/HrisAttandancePage';
import HrisHomePage from '../Pages/HRIS/HrisHomePage';
import HrisPage from '../Pages/HRIS/HrisPage';
import HrisAttandanceActivity from '../Pages/HRIS/HrisAttandanceActivity';
import HrisPenaltyPage from '../Pages/HRIS/HrisPenaltyPage';
import HrisCorrectionPage from '../Pages/HRIS/HrisCorrectionPage';
import HrisLeavePage from '../Pages/HRIS/HrisLeavePage';
import HrisOvertimePage from '../Pages/HRIS/HrisOvertimePage';
import HrisOfficePage from '../Pages/HRIS/HrisOfficePage';
import HrisDivisionPage from '../Pages/HRIS/HrisDivisionPage';
import HrisLeaveCategory from '../Pages/HRIS/HrisLeaveCategory';
import HrisPenaltyCategoryPage from '../Pages/HRIS/HrisPenaltyCategoryPage';
import HrisDaysOff from '../Pages/HRIS/HrisDaysOff';
import HrisUsers from '../Pages/HRIS/HrisUsers';
import HrisRolePage from '../Pages/HRIS/HrisRolePage';
import HrisRoleAddPage from '../Pages/HRIS/HrisRoleAddPage';
import HrisPayrollPage from '../Pages/HRIS/HrisPayrollPage';
import HrisUserMainPage from '../Pages/HRIS/HrisUserMainPage';
import HrisOfficeSinglePage from '../Pages/HRIS/HrisOfficeSinglePage';
import HrisDeductionPage from '../Pages/HRIS/HrisDeductionPage';
import HrisAllowancePage from '../Pages/HRIS/HrisAllowancePage';
import HrisEmployeeLoansPage from '../Pages/HRIS/HrisEmployeeLoansPage';
import HrisMonthlyAllowance from '../Pages/HRIS/HrisMonthlyAllowance';
import HrisMonthlyDeduction from '../Pages/HRIS/HrisMonthlyDeduction';
import HrisMonthlyPayroll from '../Pages/HRIS/HrisMonthlyPayroll';
import HrisSalaryPage from '../Pages/HRIS/HrisSalaryPage';
import HrisMonthlySalary from '../Pages/HRIS/HrisMonthlySalary';
import HrisLoanPage from '../Pages/HRIS/HrisLoanPage';
import HrisAttandanceUserPage from '../Pages/HRIS/HrisAttandanceUserPage';
import HrisPayslipPage from '../Pages/HRIS/HrisPayslipPage';
import HrisPayslipDetail from '../Pages/HRIS/HrisPayslipDetail';
import HrisUserPayslip from '../Pages/HRIS/HrisUserPayslip';
import HrisUserPayslipDetail from '../Pages/HRIS/HrisUserPayslipDetail';
import HrisKpiPage from '../Pages/HRIS/HrisKpiPage';
import HrisKpiTargetPerformance from '../Pages/HRIS/HrisKpiTargetPerformance';
import HrisKpiPerformanceEvaluation from '../Pages/HRIS/HrisKpiPerformanceEvaluation';
import HrisApprovalPage from '../Pages/HRIS/HrisApprovalPage';
import HrisPayrollMenu from '../Pages/HRIS/HrisPayrollMenu';
import HrisConfigurationPage from '../Pages/HRIS/HrisConfigurationPage';
import HrisKpiCreatePage from '../Pages/HRIS/HrisKpiListPage';
import HrisKpiListPage from '../Pages/HRIS/HrisKpiListPage';
import ProjectsPage from '../Pages/Configuration/ProjectPage';
import ProjectNewPage from '../Pages/Configuration/ProjectNewPage';
import AttendanceHome from '../Pages/Attendance/AttendanceHome';
import ApprovalHome from '../Pages/Approval/ApprovalHome';
import PayrollHome from '../Pages/Payroll/PayrollHome';
import SettingsHome from '../Pages/Settings/SettingsHome';

const HRISRouter = [
  {
    path: '/hris',
    element: <HrisPage />,
  },


  //attendance
  {
    path: '/attendance/home',
    element: <HrisHomePage />,
  },
  {
    path: '/attendance/home/:type',
    element: <HrisAttandanceActivity />,
  },
  {
    path: '/attendance',
    element: <AttendanceHome  />,
  },
  {
    path: '/hris/payslip',
    element: <HrisUserPayslip />,
  },


  //approval
  {
    path: '/approval',
    element: <ApprovalHome  />,
  },
  {
    path: '/hris/penalty',
    element: <HrisPenaltyPage />,
  },
  {
    path: '/hris/attendance',
    element: <HrisAttandancePage />,
  },
  {
    path: '/hris/attendance/detail/:id',
    element: <HrisAttandanceUserPage />,
  },
  {
    path: '/hris/correction',
    element: <HrisCorrectionPage />,
  },
  {
    path: '/hris/leave',
    element: <HrisLeavePage />,
  },
  {
    path: '/hris/overtime',
    element: <HrisOvertimePage />,
  },
  {
    path: '/hris/payroll/employee-loans',
    element: <HrisEmployeeLoansPage />,
  },


  //payroll
  {
    path: '/payroll',
    element: <PayrollHome  />,
  },
  {
    path: '/hris/payroll',
    element: <HrisPayrollPage />,
  },
  {
    path: '/hris/payroll/:id',
    element: <HrisMonthlyPayroll />,
  },
  {
    path: '/hris/salary',
    element: <HrisSalaryPage />,
  },
  {
    path: '/hris/salary/:id',
    element: <HrisMonthlySalary />,
  },
  {
    path: '/hris/payroll/allowance',
    element: <HrisAllowancePage />,
  },
  {
    path: '/hris/payroll/allowance/:id',
    element: <HrisMonthlyAllowance />,
  },
  {
    path: '/hris/payroll/deduction',
    element: <HrisDeductionPage />,
  },
  {
    path: '/hris/payroll/deduction/:id',
    element: <HrisMonthlyDeduction />,
  },

  //settings
  {
    path: '/setting',
    element: <SettingsHome  />,
  },
  {
    path: '/configuration/project',
    element: <ProjectsPage />,
  },
  {
    path: '/configuration/project/:id',
    element: <ProjectNewPage />,
  },
  {
    path: '/hris/office',
    element: <HrisOfficePage />,
  },
  {
    path: '/hris/office/:id',
    element: <HrisOfficeSinglePage />,
  },
  {
    path: '/hris/leave-category',
    element: <HrisLeaveCategory />,
  },
  {
    path: '/hris/penalty-category',
    element: <HrisPenaltyCategoryPage />,
  },
  {
    path: '/hris/days-off',
    element: <HrisDaysOff />,
  },
  {
    path: '/hris/users',
    element: <HrisUsers />,
  },
  {
    path: '/hris/kpi',
    element: <HrisKpiPage />,
  },
  {
    path: '/hris/kpi/list',
    element: <HrisKpiListPage list />,
  },
  {
    path: '/hris/kpi/create',
    element: <HrisKpiCreatePage create />,
  },
  {
    path: '/hris/kpi/:id',
    element: <HrisKpiTargetPerformance />,
  },
  {
    path: '/hris/kpi/:id/evaluation',
    element: <HrisKpiPerformanceEvaluation />,
  },



  // {
  //   path: '/approval',
  //   element: <HrisApprovalPage />,
  // },
  // {
  //   path: '/configuration',
  //   element: <HrisConfigurationPage />,
  // },
  // {
  //   path: '/payroll',
  //   element: <HrisPayrollMenu />,
  // },



  {
    path: '/hris/division',
    element: <HrisDivisionPage />,
  },
  {
    path: '/hris/role',
    element: <HrisRolePage />,
  },
  {
    path: '/hris/role/add',
    element: <HrisRoleAddPage />,
  },
  {
    path: '/hris/loans',
    element: <HrisLoanPage />,
  },




  {
    path: '/hris/payroll/:period/payslip',
    element: <HrisPayslipPage />,
  },
  {
    path: '/hris/payroll/:period/payslip-user',
    element: <HrisUserPayslipDetail />,
  },
  {
    path: '/hris/payroll/:period/payslip/:id',
    element: <HrisPayslipDetail />,
  },
  {
    path: '/users',
    element: <HrisUserMainPage />,
  },

];

export default HRISRouter;
