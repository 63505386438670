/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */
import { Badge, Card, CardBody, HStack, Image, Text, VStack } from '@chakra-ui/react'
import moment from 'moment'
import { MdLocationPin } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const RecruitmentJobCardComponent = (props) => {
  const data = props?.data;
  const navigate = useNavigate()
  return (
    <Card bgColor={'white'} p={2}{...props} cursor={'pointer'}
      _hover={{
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px',
        m: 1
      }}
      onClick={() => navigate(`/preview/${data?.position_id}/apply`)}
    >
      <CardBody>
        <HStack gap={5}>
          <Image fallbackSrc={'https://fakeimg.pl/150x150?text=deoapp'} w={20} borderRadius={'100%'} src={data?.detail?.image} />
          <VStack align={'flex-start'}>
            <Text noOfLines={1}>{data?.detail?.position_name}</Text>
            <Text fontSize={'xs'}>{data?.detail?.company}</Text>
          </VStack>
        </HStack>
        <HStack>
          <VStack align={'flex-start'}>
            <VStack align={'flex-start'}>
              <HStack my={2}>
                <MdLocationPin />
                <Text>{data?.detail?.address}</Text>
              </HStack>
              <Text mt={-2} fontSize={'2xs'}>Applied at {`${moment.unix(data?.createdAt?.seconds).format('llll')}`}</Text>
              {/* {data?.status === 'unprocessed' ?
                <>
                  {data?.seen > 0 ?
                    <>
                      <Text fontSize={'2xs'}> Seen by recruiter {data?.seen} times</Text>
                    </> :
                    <>
                      <Text fontSize={'2xs'}> Seen by recruiter {data?.seen} times</Text>

                      <Badge
                        colorScheme={
                          data?.status === 'unprocessed' ?
                            'green' :
                            data?.status === 'review' ?
                              'orange' :
                              data?.status === 'rejected' || data?.status === 'not_suitable' ?
                                'red' :
                                ''
                        }
                      >
                        {
                          data?.status === 'unprocessed' ?
                            'terkirim' :
                            data?.status
                        }
                      </Badge>
                    </>}
                </>
                :
                <>
                  {data?.status === 'accepted' ?
                    <>
                      <Badge size={'sm'} fontSize={'3xs'} colorScheme='green'>Accepted: waiting for the interview schedule</Badge>
                    </> : <></>}
                  {data?.status === 'interview' ?
                    <>
                      <Badge size={'sm'} fontSize={'3xs'} colorScheme='yellow'>Interview</Badge>
                    </> : <></>}
                  {data?.status === 'not_suitable' ?
                    <>
                      <Badge size={'sm'} fontSize={'3xs'} colorScheme='red'>Not Suitable</Badge>
                    </> : <></>}

                </>} */}


              <Text fontSize={'2xs'}> Seen by recruiter {data?.seen} times</Text>

              <Badge
                colorScheme={
                  data?.status === 'unprocessed' ?
                    'green' :
                    data?.status === 'review' ?
                      'orange' :
                      data?.status === 'rejected' || data?.status === 'not_suitable' ?
                        'red' :
                        ''
                }
              >
                {
                  data?.status === 'unprocessed' ?
                    'terkirim' :
                    data?.status
                }
              </Badge>
            </VStack>

          </VStack>

        </HStack>
      </CardBody>
    </Card>
  )
}

export default RecruitmentJobCardComponent