/* eslint-disable no-undef */
import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  Divider,
  Grid,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useToast,
  Spacer,
  SimpleGrid,
  Heading,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  useBreakpointValue,
  Center,
  useDisclosure,
  Checkbox,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import dayjs from 'dayjs';
import { FcNext, FcPrevious } from 'react-icons/fc';
import moment from 'moment';
import BackButtons from '../../Components/Buttons/BackButtons';
import { readHris } from '../../Api/Hris';
import useUserStore from '../../Hooks/Zustand/Store';
import { getUserByUid } from '../../Utils/getUser';
import { getCollectionFirebase } from '../../Api/firebaseApi';
import DynamicButton from '../../Components/Buttons/DynamicButton';
import DatePicker from '../../Components/DatePicker/DatePicker';
import ExportExcel from '../../Utils/ExportExcel';
import { useNavigate } from 'react-router';

function HrisAttandancePage() {
  const navigate = useNavigate();
  const toast = useToast();
  const [late, setLate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [users, setUsers] = useState([]);
  const modalFilterDate = useDisclosure();
  const modalOpenMultipleUser = useDisclosure();
  const [calendar, setCalendar] = useState([]);
  const [dataOffice, setDataOffice] = useState([]);
  const [dataReport, setDataReport] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [userSelect, setUserSelect] = useState(null);
  const [activeOffice, setActiveOffice] = useState({});
  const [attendanceLog, setAttendanceLog] = useState(0);
  const [plainCalendar, setPlainCalendar] = useState([]);
  const [modalReports, setModalReports] = useState(false);
  const [correctionData, setCorrectionData] = useState({});
  const [selectedDataForExport, setSelectedDataForExport] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [isError, setIsError] = useState(false);

  const dic = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [initialDate, setInitialDate] = useState(dayjs().format('YYYY-MM-DD'));
  const calendarMonth = moment(initialDate).format('MM');
  const calendarYear = moment(initialDate).format('YYYY');

  const firstDayOfMonth = moment().set({
    year: calendarYear,
    month: calendarMonth - 1,
    date: 1,
  });

  const lastDayOfMonth = moment(firstDayOfMonth)
    .add(1, 'months')
    .subtract(1, 'days');

  const formattedFirstDate = firstDayOfMonth.format('YYYY-MM-DD');
  const formattedLastDate = lastDayOfMonth.format('YYYY-MM-DD');

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const globalState = useUserStore();

  const options = users
    ?.sort()
    ?.filter((x) => x?.name !== undefined)
    .map((x) => ({
      label: x?.name !== '' ? x?.name : x?.email,
      value: x?.id,
    }));

  const fetchUser = async () => {
    const dataCompany = globalState?.companies?.find(
      (x) => x?.id === globalState?.currentCompany
    );

    try {
      if (dataCompany?.users?.length > 0) {
        if (dataCompany?.users) {
          try {
            const promises = dataCompany?.users.map(async (x) => {
              const y = await getUserByUid(globalState?.users, x);
              return y;
            });

            const results = await Promise.all(promises);

            setUsers(results);
          } catch (error) {
            throw new Error(error)
          }
        }
      }
    } catch (error) {
      throw new Error(error)
    }
  };

  const fetchOffice = async () => {
    const conditions = [
      {
        field: 'companyId',
        operator: '==',
        value: globalState?.currentCompany,
      },
    ];

    try {
      const res = await getCollectionFirebase('offices', conditions);
      setDataOffice(res);

      setActiveOffice(res[0]);
    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  useEffect(() => {
    fetchOffice();
    fetchUser();

    return () => {
      setUsers([]);
      setLate(0);
      setAttendanceLog(0);
      setDataOffice([]);
      setActiveOffice({});
    };
  }, [globalState.currentProject]);

  const generateCalendarData = (month = '') => {
    const params = [];
    const totalDay = dayjs(month).daysInMonth();
    const now = dayjs(month).format('YYYY-MM');
    for (let index = 0; index < totalDay; index++) {
      const d = dayjs(`${now}-${index + 1}`).format('YYYY-MM-DD');
      const dayname = dayjs(d).format('ddd');
      params.push({
        date: dayjs(d).format('DD'),
        periode: d,
        dayname,
      });
    }

    return params;
  };

  const generateCalendarView = (initialDate) => {
    const previousMonth = dayjs(initialDate).subtract(1, 'month');
    const currentParams = generateCalendarData(initialDate);

    const previousParams = generateCalendarData(previousMonth).reverse();

    const firstDayFromCurentMOnth = currentParams[0];
    const findIndexDay = dic.findIndex(
      (x) => x === firstDayFromCurentMOnth.dayname
    );
    let getDateFromPrevious = previousParams.slice(0, findIndexDay).reverse();
    getDateFromPrevious = getDateFromPrevious.map((x) => {
      x.is_previous = 'is_previous';
      return x;
    });

    const calendar = [...getDateFromPrevious, ...currentParams];

    setCalendar([...calendar]);
    setPlainCalendar([...calendar]); // tambahin disini yang polos
  };

  useEffect(() => {
    generateCalendarView(initialDate);
  }, []);

  const onChangeDate = (mode) => {
    let periode = dayjs(initialDate);
    if (mode === '-') {
      periode = periode.subtract(1, 'month');
    } else {
      periode = periode.add(1, 'month');
    }
    // periode = dayjs(periode).format("YYYY-MM");
    periode = dayjs(periode).format('YYYY-MM-DD');

    // setInitialDate(dayjs(periode).format("YYYY-MM"));
    setInitialDate(dayjs(periode).format('YYYY-MM-DD'));
    generateCalendarView(periode);
    // setUserSelect(null);
  };

  const onSelect = (x) => {
    const findUser = globalState?.users?.find((value) => value.id === x);

    const firstDate = formattedFirstDate;
    const lastDate = formattedLastDate;

    setCalendar(plainCalendar);
    setUserSelect(findUser);
    if (x !== null) {
      fetchAttendance(x, firstDate, lastDate);
    }
  };

  const handleSelect = (value) => {
    const companySelect = dataOffice.find((x) => x?.id === value);
    setActiveOffice(companySelect);
  };

  const fetchAttendance = async (arg, firstDate, lastDate) => {
    if (activeOffice) {
      const session = {
        headers: globalState?.authFirebaseToken,
      };

      globalState.setIsLoading(true);

      try {
        const { data } = await readHris(
          `attendance/${arg}/detail?startDate=${firstDate}&endDate=${lastDate}&office=${activeOffice?.id}`,
          session
        );


        // setAttendance(data?.attendances);
        // setToggleModal(false);
        setCorrectionData(data?.correction);
        generateCalendarEvent(data?.attendances);

        setLate(data?.lateCount);
        setAttendanceLog(data?.attendanceCount);

        toast({
          title: 'success',
          description: 'Attendance has loaded',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'error!',
          description: error.message,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      } finally {
        globalState.setIsLoading(false);
      }
    }
  };

  const generateCalendarEvent = async (arg) => {
    const attendances = arg || {};

    let calendarArr = JSON.stringify(plainCalendar); // using plainCalendar as per your code
    calendarArr = JSON.parse(calendarArr);

    const calendarProm = calendarArr?.map(async (x) => {
      const date = x?.periode;
      const addAttendance = attendances[date]; // Accessing attendance using date as key
      if (addAttendance) {
        x.detail_attendance = addAttendance;
        x.is_filled = true;
      }
      return x;
    });


    const dataAttendance = await Promise.all(calendarProm);

    setCalendar(dataAttendance);
  };

  const handleReport = async () => {
    const session = {
      headers: globalState?.authFirebaseToken,
      companyId: globalState?.currentCompany,
      projectId: globalState?.currentProject,
    };

    try {
      const { data } = await readHris(
        `attendance/data-report/${globalState?.currentCompany}/${activeOffice?.id
        }?year=${parseInt(calendarYear)}&month=${parseInt(calendarMonth)}`,
        session
      );

      const mergedData = [];

      for (const x of data) {
        const uidUser = x?.user_uid;

        const userData = await getUserByUid(globalState?.users, uidUser);

        if (userData) {
          const mergedObject = {
            name: userData?.name,
            email: userData?.email,
            project: x?.project_uid,
            attendance: x?.attendance,
            correction: x?.correction,
            late: x?.late_count,
            leave: x?.leaves,
            leave_request: x?.leave_request,
          };
          mergedData.push(mergedObject);
        } else {
          mergedData.push(x);
        }
      }
      setDataReport(mergedData);

    } catch (error) {
      throw new Error(error.message, 'Failed to send Slack login message');
    }
  };

  useEffect(() => {
    if (userSelect !== null) {
      fetchAttendance(userSelect?.id, formattedFirstDate, formattedLastDate);
    }
  }, [initialDate, formattedFirstDate]);

  const handleOpenModal = () => {
    handleReport();
    setModalReports(true);
  };

  const handleOpenUserModal = () => {
    navigate(`detail/${userSelect?.id}`, { state: activeOffice });
  };

  const handleSelectChange = (selectedOptions) => {
    // Update the state with the selected options
    setSelectedOptions(selectedOptions);

    if (isRunning) {
      setIsRunning(false);
    }

  };

  const handleDateRange = (value) => {
    const datePick = {
      startDate: moment(value?.startDate).format('YYYY-MM-DD'),
      endDate: moment(value?.endDate).format('YYYY-MM-DD'),
    };
    setSelectedDate(datePick);
  };

  const isWeekend = (date) => {
    //pokoknya di sini mestinya di setup days off per company / office
    const dayOfWeek = moment(date).day()
    if (globalState.currentCompany === 'p2NkaW4E75MxgCa8nkgW' || globalState.currentCompany === 'rIpKqX6WCR345hQCuNCP') {
      return false
    } else {
      if (dayOfWeek === 6 || dayOfWeek === 0) {
        return true
      } else {
        return false
      }
    }

  }

  const handleExportAllToExcel2 = async () => {
    setIsLoading(true);
    const session = {
      headers: globalState?.authFirebaseToken,
    };

    try {
      const userPromises = selectedOptions?.map(async (x) => {
        const y = await getUserByUid(globalState?.users, x?.value);
        return y || {};
      });

      const results = await Promise.all(userPromises);

      //promise data
      const dataPromises = results?.map(async (x) => {
        const { data } = await readHris(
          `attendance/${x?.id}/detail?startDate=${selectedDate?.startDate}&endDate=${selectedDate?.endDate}&office=${activeOffice?.id}`,
          session
        );


        const attendanceData = data?.attendances || {};

        const attendanceArray = Object.entries(attendanceData || {})?.map(
          ([date, data]) => ({
            date: moment(date).format('YYYY-MM-DD'),
            data,
          })
        );

        // console.log({ attendanceArray, attendanceData, data })


        const modifiedData = attendanceArray?.map((attendanceObject) => {
          if (attendanceObject) {
            if (attendanceObject?.date >= selectedDate?.startDate && attendanceObject?.date <= selectedDate?.endDate) {
              let dataExport = {
                name: x?.name || '',
                email: x?.email || '',
                date: attendanceObject?.date || '',
                office_start_hour: activeOffice?.startHour,
                office_end_hour: activeOffice?.endHour,
                office_id: activeOffice?.id || '',
                office: activeOffice?.title || '',
                clock_in: '',
                clock_out: '',
                status: ''
              }
              if (isWeekend(attendanceObject?.date)) {
                dataExport.status = 'DAYS OFF';
              } else {
                if (attendanceObject?.data !== null && attendanceObject?.data !== undefined) {
                  dataExport = {
                    name: x?.name || '',
                    email: x?.email || '',
                    date: attendanceObject?.date || '',
                    office_start_hour: activeOffice?.startHour || '',
                    office_end_hour: activeOffice?.endHour || '',
                    office: activeOffice?.title || '',
                    clock_in:
                      attendanceObject?.data !== null && attendanceObject?.data !== undefined && attendanceObject?.data?.clock_in !== undefined && attendanceObject?.data?.clock_in !== null && attendanceObject?.data?.gmt_offset_in !== null && attendanceObject?.data?.gmt_offset_in !== undefined
                        ? moment(attendanceObject?.data?.clock_in || 0)
                          .utcOffset(14)
                          .format('HH:mm:ss') ?? '--:--:--'
                        :
                        '-',
                    clock_out:
                      attendanceObject?.data !== null && attendanceObject?.data !== undefined && attendanceObject?.data?.clock_out !== undefined && attendanceObject?.data?.clock_out !== null && attendanceObject?.data?.gmt_offset_out !== null && attendanceObject?.data?.gmt_offset_out !== undefined
                        ? moment(attendanceObject?.data?.clock_out || 0)
                          .utcOffset(14)
                          .format('HH:mm:ss') ?? '--:--:--'
                        :
                        // typeof(attendanceObject.data?.clock_out),
                        '-',
                    status:
                      isWeekend(attendanceObject?.date) !== true ?
                        (attendanceObject?.data !== null && attendanceObject.data !== undefined
                          ? (activeOffice?.startHour <
                            moment(attendanceObject?.data?.clock_in || 0)
                              .utcOffset(14)
                              .format('HH:mm')
                            &&
                            activeOffice?.endHour >
                            moment(attendanceObject?.data?.clock_in || 0)
                              .utcOffset(14)
                              .format('HH:mm')) || !attendanceObject?.data?.clock_in
                            ? 'LATE'
                            : 'ON TIME'
                          : '-'
                        ) : (
                          'DAYS OFF'
                        )
                  };
                }
              }
              return dataExport;
            }
          }
        });

        const mD = [];
        modifiedData?.forEach((x) => {
          if (x !== undefined && x !== null) {
            if (x && x?.status !== '') {
              mD?.push(x)
            }
          }
        })

        return mD;
      });
      // console.log({ attendanceArray, results });
      const dataResult = await Promise.all(dataPromises);
      if (!dataResult || dataResult.length === 0) throw new Error('No data found');

      const seen = new Set();
      const duplicates = [];
      dataResult[0].forEach(function (currentObject) {
        if (seen?.has(currentObject.date)) {
          const result = dataResult[0]?.findIndex((v) => v?.date === currentObject.date)
          duplicates?.push(result);
        } else {
          seen?.add(currentObject.date);
        }
      });

      duplicates?.map((x) => dataResult[0]?.splice(x, 1))

      const emailUsers = []

      setSelectedDataForExport(dataResult);
      // console.log(dataResult);

      const resultWithFilterDate = dataResult?.map((x) => {
        const arr2 = [];


        x?.forEach((y) => {
          arr2?.push(y)
        })

        const sortedArray = arr2.sort((a, b) => new moment(a.date).format('YYYYMMDD') - new moment(b.date).format('YYYYMMDD'))
        if (!emailUsers.includes(sortedArray[0]?.email)) {
          emailUsers.push(sortedArray[0]?.email)
          return sortedArray;
        }
      })

      const finalizeDatas = []
      resultWithFilterDate?.forEach((x) => {
        if (x !== undefined || x !== null) {
          if (x && x?.length > 1) {
            finalizeDatas.push(x)
          }
        }
      })

      // console.log(resultWithFilterDate, 'retsult with filter date')
      setSelectedDataForExport(finalizeDatas)
    } catch (error) {
      setIsError(true);
      // console.log('error', error)
      throw new Error(error.message, 'Failed to send Slack login message');
    } finally {

      setIsLoading(false);
      setIsRunning(true);
    }
  };

  const handleOpenMultipleUser = async () => {
    modalOpenMultipleUser.onOpen();
  };

  const handleCloseModal = () => {
    setModalReports(false);
  };

  const handleSelectAll = async () => {
    if (isRunning) {
      setIsRunning(false);
    }

    if (selectAll) {
      setSelectedOptions();
    } else {
      const dataUser = globalState?.users?.map((x) => ({
        label: x?.name !== '' ? x?.name : x?.email,
        value: x?.id,
      }));

      setSelectedOptions(dataUser);
      setIsRunning(false);
    }

    setSelectAll(!selectAll);
  };

  return (
    <Stack>
      <BackButtons />
      <Flex gap={[2, 3, 3]} direction={['column', 'row', 'row']}>
        <Card
          w={['full', '25%', '25%']}
          h={['fit-content', '95vh', '95vh']}
          backgroundColor={'white'}
          shadow={'md'}
        >
          <CardBody w="full">
            <HStack w={'98%'} m={'auto'}>
              <Stack w={'full'} spacing={2}>
                {activeOffice && (
                  <Stack
                    bgColor={'blue.600'}
                    p={2}
                    spacing={1}
                    borderRadius={'md'}
                    color="white"
                  >
                    <HStack>
                      <Text
                        fontSize={'xl'}
                        fontWeight="bold"
                        textTransform={'capitalize'}
                      >
                        {activeOffice?.title}
                      </Text>
                      <Spacer />
                      <Text fontWeight={500}>{activeOffice?.radius} m</Text>
                    </HStack>
                    <HStack alignItems={'center'} justifyContent="center">
                      <Heading>{activeOffice?.startHour}</Heading>
                      <Heading>-</Heading>
                      <Heading>{activeOffice?.endHour}</Heading>
                    </HStack>
                  </Stack>
                )}

                <Stack>
                  <Text fontWeight={500}>Select Office</Text>

                  <Select
                    size={'sm'}
                    textTransform="capitalize"
                    placeholder="Select Office"
                    onChange={(e) => handleSelect(e.target.value)}
                    defaultValue={activeOffice?.id}
                  >
                    {dataOffice
                      ?.sort()
                      ?.filter((x) => x.title !== undefined)
                      .map((x, i) => {
                        return (
                          <option key={i} value={x?.id}>
                            {x?.title !== '' ? x?.title : x?.title}
                          </option>
                        );
                      })}
                  </Select>
                </Stack>

                <Stack>
                  <Flex flexDir='row' justifyContent='space-between'>
                    <Text fontWeight={500}>Users</Text>
                    <Button
                      size={'sm'}
                      colorScheme='blue'
                      textDecoration={'underline'}
                      variant='link'
                      onClick={handleOpenMultipleUser}>
                      Multiple Users Attendance
                    </Button>
                  </Flex>
                  <Select
                    size={'sm'}
                    textTransform="capitalize"
                    // placeholder="Select User"
                    onChange={(e) => onSelect(e.target.value)}
                  >
                    <option value={null}>Select User</option>
                    {users
                      ?.sort()
                      ?.filter((x) => x?.name !== undefined)
                      .map((x, i) => {
                        return (
                          <option key={i} value={x?.id}>
                            {x?.name !== '' ? x?.name : x?.email}
                          </option>
                        );
                      })}
                  </Select>
                </Stack>

                <Text fontWeight={500}>Reports</Text>
                <Divider mb={5} />

                <SimpleGrid columns={[1, 1, 2]} gap={3}>
                  <Stack alignItems={'center'} justifyContent="center">
                    <Text fontWeight={500} color="gray.500">
                      Total
                    </Text>
                    <Divider />
                    <Spacer />
                    <Heading>{attendanceLog || 0}</Heading>
                  </Stack>

                  <Stack alignItems={'center'} justifyContent="center">
                    <Text fontWeight={500} color="gray.500">
                      Late
                    </Text>
                    <Divider />
                    <Spacer />
                    <Heading color={late > 0 ? 'red.600' : 'black'}>
                      {late || 0}
                    </Heading>
                  </Stack>

                  <Stack alignItems={'center'} justifyContent="center">
                    <Text fontWeight={500} color="gray.500">
                      Leave
                    </Text>
                    <Divider />
                    <Spacer />
                    <Heading color={late > 0 ? 'green.600' : 'black'}>
                      {correctionData?.count || 0}
                    </Heading>
                  </Stack>

                  <Stack alignItems={'center'} justifyContent="center">
                    <Text fontWeight={500} color="gray.500">
                      Correction
                    </Text>
                    <Divider />
                    <Spacer />
                    <Heading color={late > 0 ? 'green.600' : 'black'}>
                      {correctionData?.count || 0}
                    </Heading>
                  </Stack>
                </SimpleGrid>

                <Stack py={2}>
                  <Text fontWeight={500}>Correction History</Text>
                  <Divider mb={5} />

                  <Stack h="300px" overflowY={'scroll'}>
                    {correctionData?.rows?.length > 0 ? (
                      correctionData?.rows?.map((x, index) => {
                        return (
                          <Stack key={index}>
                            <Text
                              textAlign={'center'}
                              color="gray.700"
                              fontWeight={500}
                              fontSize={'sm'}
                            >
                              {x?.periode}
                            </Text>
                            <HStack>
                              <Stack>
                                <Text color="gray.700" fontSize={'sm'}>
                                  Clock in
                                </Text>
                                <Badge
                                  fontWeight={500}
                                  colorScheme="green"
                                  fontSize={'sm'}
                                  p={1}
                                >
                                  {x?.clock_in}
                                </Badge>
                              </Stack>
                              <Spacer />
                              <Stack>
                                <Text color="gray.700" fontSize={'sm'}>
                                  Clock out
                                </Text>

                                <Badge
                                  fontWeight={500}
                                  colorScheme="green"
                                  fontSize={'sm'}
                                  p={1}
                                >
                                  {x?.clock_out}
                                </Badge>
                              </Stack>
                            </HStack>
                          </Stack>
                        );
                      })
                    ) : (
                      <Center>
                        <Text fontSize={12}>No Correction History Data</Text>
                      </Center>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </HStack>
          </CardBody>
        </Card>

        {isDesktop ? (
          <Card
            w={['full', '75%', '75%']}
            h={['fit-content', '95vh', '95vh']}
            backgroundColor={'white'}
            shadow={'md'}
          >
            <CardBody>
              <Stack w={'full'}>
                <Stack flexDirection={'flex-end'} alignItems="flex-end">
                  <HStack w={'full'}>
                    <DynamicButton
                      size={'sm'}
                      // title={'Company Attendance Reports'}
                      title={`Print ${dayjs(initialDate).format('MMMM YYYY')} Attendance Reports`}
                      action="read"
                      variant={'solid'}
                      onClick={handleOpenModal}
                    />
                    <Spacer />

                    {userSelect === null ? null : (
                      <DynamicButton
                        size={'sm'}
                        title={`${userSelect?.name} Report Table`}
                        action="read"
                        variant={'solid'}
                        onClick={handleOpenUserModal}
                      />
                    )}
                    {/* <DynamicButton
                      size={'sm'}
                      title={'Multiple User'}
                      action="read"
                      variant={'solid'}
                      onClick={handleOpenMultipleUser}
                    /> */}
                  </HStack>
                </Stack>
                <HStack justify={'space-between'} my={5}>
                  <FcPrevious
                    size={25}
                    cursor={'pointer'}
                    onClick={() => onChangeDate('-')}
                  />
                  <Text fontSize={'2xl'} fontWeight={'semibold'}>
                    {dayjs(initialDate).format('MMMM YYYY')}
                  </Text>
                  <FcNext
                    size={25}
                    cursor={'pointer'}
                    onClick={() => onChangeDate('+')}
                  />
                </HStack>
                <Grid w={'full'} templateColumns="repeat(7, 1fr)" py={5}>
                  {dic?.map((x, i) => (
                    <Box key={i} bgColor={'gray.100'} shadow={'md'} h={12}>
                      <Text
                        align={'center'}
                        justifyContent={'center'}
                        my={2}
                        fontWeight={'medium'}
                        fontSize={['2xs', 'sm', 'sm']}
                      >
                        {x}
                      </Text>
                    </Box>
                  ))}
                </Grid>
                <Grid w={'full'} templateColumns="repeat(7, 1fr)">
                  {calendar?.map((date, index) => (
                    <Stack shadow={'md'} h={[10, 20, 20]} key={index} p={2}>
                      {!date?.is_previous ? (
                        <Stack>
                          <Text
                            align={'right'}
                            justifyContent={'center'}
                            fontWeight={'medium'}
                            fontSize={['2xs', 'sm', 'sm']}
                          >
                            {date.date}
                          </Text>
                          {date?.is_filled ? (
                            <>
                              {date?.detail_attendance?.clock_in ? (
                                <Badge
                                  borderRadius={'lg'}
                                  alignContent={'center'}
                                  w={'fit-content'}
                                  colorScheme={
                                    activeOffice?.startHour <
                                      moment
                                        .parseZone(
                                          date?.detail_attendance?.clock_in || 0
                                        )
                                        .utcOffset(
                                          date?.detail_attendance?.gmt_offset_in || 0
                                        )
                                        .format('HH:mm') &&
                                      activeOffice?.endHour >
                                      moment
                                        .parseZone(
                                          date?.detail_attendance?.clock_in || 0
                                        )
                                        .utcOffset(
                                          date?.detail_attendance?.gmt_offset_in || 0
                                        )
                                        .format('HH:mm')
                                      ? 'red'
                                      : 'blue'
                                  }
                                  fontSize={'2xs'}
                                >
                                  in :{' '}
                                  {moment
                                    .parseZone(date?.detail_attendance?.clock_in || 0)
                                    .utcOffset(
                                      date?.detail_attendance?.gmt_offset_in || 0
                                    )
                                    .format('HH:mm:ss') ?? '--:--:--'}
                                </Badge>
                              ) : (
                                <Badge
                                  borderRadius={'lg'}
                                  w={'fit-content'}
                                  colorScheme="green"
                                  fontSize={'2xs'}
                                >
                                  In : --:--:--
                                </Badge>
                              )}

                              {date?.detail_attendance?.clock_out ? (
                                <Badge
                                  borderRadius={'lg'}
                                  w={'fit-content'}
                                  colorScheme="green"
                                  fontSize={'2xs'}
                                >
                                  Out :{' '}
                                  {moment
                                    .parseZone(
                                      date?.detail_attendance?.clock_out || 0
                                    )
                                    .utcOffset(
                                      date?.detail_attendance?.gmt_offset_out || 0
                                    )
                                    .format('HH:mm:ss')}
                                </Badge>
                              ) : (
                                <Badge
                                  borderRadius={'lg'}
                                  w={'fit-content'}
                                  colorScheme="green"
                                  fontSize={'2xs'}
                                >
                                  Out : --:--:--
                                </Badge>
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </Stack>
                      ) : (
                        ''
                      )}
                    </Stack>
                  ))}
                </Grid>
              </Stack>
            </CardBody>
          </Card>
        ) : (
          <Stack
            h={'50vh'}
            alignItems="center"
            justifyContent={'center'}
            spacing={5}
          >
            <Heading textAlign={'center'} size="md">
              Calendar only in Desktop View
            </Heading>
          </Stack>
        )}

        <Modal isOpen={modalReports} onClose={handleCloseModal} size="4xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              Attendance Report
              <HStack>
                <Text mt={3} fontSize={'2xl'} fontWeight={'semibold'}>
                  {dayjs(initialDate).format('MMMM YYYY')}
                </Text>
                <Spacer />
                {/* <DynamicButton
                  onClick={() => handleExportReport()}
                  title={"Export"}
                  icon={FaRegFileExcel}
                  color={"orange"}
                  action={"custom"}
                /> */}

                {isError === false &&
                  <ExportExcel
                    sheetNames={'Data'}
                    title={'Download Excel'}
                    excelData={dataReport}
                    fileName={'Monthly Company Attendance Report'}
                    colorScheme={'orange'}
                    size={'md'}
                    variant={'outline'}
                  />
                }
              </HStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {dataReport?.length > 0 ? (
                <TableContainer
                  p={3}
                  borderRadius={'md'}
                  border={'1px'}
                  borderColor={'gray.50'}
                  boxShadow={'base'}
                >
                  <Table variant="striped">
                    <Thead>
                      <Tr>
                        <Th>Name</Th>
                        <Th>Email</Th>
                        <Th>Project</Th>
                        <Th>Attendance</Th>
                        <Th>Correction</Th>
                        <Th>Late</Th>
                        <Th>Leave</Th>
                        <Th>Leave Request</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataReport?.map((data, i) => (
                        <Tr fontSize="xs" key={i}>
                          <Td textTransform={'capitalize'}>{data?.name}</Td>
                          <Td>{data?.email}</Td>
                          <Td>{data?.project}</Td>

                          <Td fontWeight="semibold" textAlign="right">
                            {data?.attendance}
                          </Td>
                          <Td fontWeight="semibold" textAlign="right">
                            {data?.correction}
                          </Td>
                          <Td fontWeight="semibold" textAlign="right">
                            {data?.late}
                          </Td>
                          <Td fontWeight="semibold" textAlign="right">
                            {data?.leave}
                          </Td>
                          <Td fontWeight="semibold" textAlign="right">
                            {data?.leave_request}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              ) : (
                <Box my={5} align={'center'} w={'full'}>
                  <Center fontWeight={500} color={'gray.500'}>
                    No Data Attendance
                  </Center>
                </Box>
              )}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Modal
        isOpen={modalFilterDate.isOpen}
        onClose={modalFilterDate.onClose}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Filter Date</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center>
              <DatePicker onDateChange={handleDateRange} startDate={selectedDate?.startDate} endDate={selectedDate?.endDate} />
            </Center>

            <HStack>
              <HStack spacing={4}>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>Start Date: </Text>
                  <Text>{moment(selectedDate?.startDate).format('LLL')}</Text>
                </Stack>
                <Stack fontSize={12} spacing={1}>
                  <Text fontWeight={'semibold'}>End Date:</Text>
                  <Text>{moment(selectedDate?.endDate).format('LLL')}</Text>
                </Stack>
              </HStack>
              <Spacer />
              <Button
                size={'xs'}
                colorScheme="red"
                onClick={() => setSelectedDate()}
              >
                Clear Filter
              </Button>
              <Button
                isLoading={isLoading}
                size={'xs'}
                colorScheme="green"
                onClick={modalFilterDate.onClose}
              >
                Confirm
              </Button>
            </HStack>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={modalOpenMultipleUser.isOpen}
        onClose={modalOpenMultipleUser.onClose}
        size={'2xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Multiple User Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              my={5}
              bg={'white'}
              border={'1px'}
              borderColor={'gray.300'}
              borderRadius={'md'}
              shadow={'sm'}
              p={4}
            >
              <DynamicButton
                size={'sm'}
                title={'Filter Date'}
                action="read"
                variant={'solid'}
                onClick={modalFilterDate.onOpen}
              />
              <Text fontSize={13} mt={4} mb={2} align={'center'}>
                Please set the filter date before select user and generate the
                excel file
              </Text>
              {selectedDate?.startDate !== undefined ?
                <>
                  <Text align={'center'}>Start: {selectedDate.startDate}</Text>
                  <Text align={'center'}>End: {selectedDate.endDate}</Text>
                </>
                : ''}

              <Text align={'center'} fontWeight={'semibold'}>
                Select User
              </Text>
              <DynamicButton
                size={'sm'}
                title={'Multiple User'}
                action="read"
                variant={'solid'}
                onClick={handleOpenMultipleUser}
              />
              <Flex p={3} gap={3} my={2} flexDir={'column'} flexWrap={'wrap'}>
                <ReactSelect
                  isMulti
                  name="User"
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="Select User"
                  onChange={handleSelectChange}
                  isDisabled={
                    selectedDate === undefined || selectAll ? true : false
                  }
                />
              </Flex>
              <Text align={'center'} my={2} fontWeight={'bold'}>
                OR
              </Text>
              <Box align={'center'}>
                <Checkbox
                  isDisabled={selectedDate === undefined ? true : false}
                  size={'sm'}
                  isChecked={selectAll}
                  onChange={handleSelectAll}
                >
                  Select All
                </Checkbox>
              </Box>

              <Box align={'center'} my={2}>
                {/* {isRunning && !isError ? (
                  <ExportExcel
                    sheetNames={'Data'}
                    title={'Download Excel'}
                    excelData={selectedDataForExport}
                    fileName={'Attendance Report'}
                    colorScheme={'orange'}
                    size={'md'}
                    variant={'outline'}
                  />
                ) : (
                  <DynamicButton
                    isLoading={isLoading}
                    size={'sm'}
                    title={'Export'}
                    action="read"
                    variant={'solid'}
                    onClick={handleExportAllToExcel}
                  />
                )}
 */}

                {!isError && !isRunning ? (
                  <DynamicButton
                    isLoading={isLoading}
                    size={'sm'}
                    title={'Export'}
                    action="read"
                    variant={'solid'}
                    onClick={handleExportAllToExcel2}
                  />
                ) : (
                  isError ? (
                    <>
                      <Text color='red'>Export error!</Text>
                      <DynamicButton
                        isLoading={isLoading}
                        size={'sm'}
                        title={'Reset'}
                        action="read"
                        variant={'solid'}
                        onClick={() => {
                          setDataReport([])
                          setIsError(false)
                          modalOpenMultipleUser.onClose()
                          setIsRunning(true)
                          window.location.reload()
                        }}
                      />
                    </>
                  ) :

                    <ExportExcel
                      sheetNames={'Data'}
                      title={'Download Excel'}
                      excelData={selectedDataForExport}
                      fileName={'Attendance Report (' + selectedDate?.startDate + ' - ' + selectedDate?.endDate + ')'}
                      colorScheme={'orange'}
                      size={'md'}
                      variant={'outline'}
                    />
                )}
                {/* <Text mt={20}>This is for development mode:</Text>
                <DynamicButton
                  isLoading={isLoading}
                  size={'sm'}
                  title={'Export'}
                  action="read"
                  variant={'solid'}
                  onClick={handleExportAllToExcel2}
                /> */}
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
}

export default HrisAttandancePage;
