/* eslint-disable no-unsafe-optional-chaining */
import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  Button,
  Stack,
  VStack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Divider,
  Spacer,
  useToast
} from '@chakra-ui/react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getSingleDocumentFirebase } from '../../Api/firebaseApi';
import BackButtons from '../../Components/Buttons/BackButtons';
import { formatFrice } from '../../Utils/numberUtil';
import img from '../../assets/icons/importir.jpg'
import moment from 'moment';

const HrisPayslipDetail = () => {
  const { id } = useParams()
  const [payrollData, setPayrollData] = useState({});
  const toast = useToast()
  const { state } = useLocation();

  const getPayslip = async () => {
    try {
      const res = await getSingleDocumentFirebase('payroll_payslip', `${id}`)
      setPayrollData(res)
    } catch (error) {
      toast({
        title: 'Deoapp.com',
        description: error.message,
        duration: 3000,
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    getPayslip()
  }, [])


  const downloadPayslip = () => {
    const pdf = new jsPDF();

    let yPos = 2;

    const companyLogo = new Image();
    companyLogo.src = img;
    pdf.addImage(companyLogo, 'PNG', 15, 15, 20, 20);

    pdf.setFontSize(20);
    pdf.text('Payslip Information', 40, 26);

    yPos += 40;

    pdf.setFontSize(16);

    pdf.setFontSize(12);
    pdf.text(`Name: ${payrollData.name}`, 15, yPos, { fontWeight: 'bold' });
    pdf.text(`Company: ${payrollData.organization}`, 15, (yPos += 8), { fontWeight: 'bold' });
    pdf.text(`Position: ${payrollData.role}`, 15, (yPos += 8), { fontWeight: 'bold' });

    yPos += 5;

    const allowanceTable = {
      head: [['Allowance', 'Amount']],
      body: [
        ['Salary', `IDR ${formatFrice(payrollData.salary)}`],
        ...payrollData?.allowance?.map((allowance) => [allowance.title, `IDR ${formatFrice(allowance.amount)}`]),
      ],
    };

    const deductionTable = {
      head: [['Deduction', 'Amount']],
      body: [
        ['Loans', `IDR ${formatFrice(payrollData.loans)}`],
        ...payrollData?.deduction?.map((deduction) => [deduction.title, `IDR ${formatFrice(deduction.amount)}`]),
      ],
    };

    const totalSalaryTable = {
      head: [['Total Salary', 'Amount']],
      body: [['Final Salary', `IDR ${formatFrice(payrollData.total)}`]],
    };

    pdf.autoTable({
      startY: yPos,
      theme: 'plain',
      headStyles: {
        textColor: 0,
        fontStyle: 'bold',
        lineWidth: 0,
      },
      bodyStyles: {
        fillColor: [240, 240, 240],
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
      head: allowanceTable.head,
      body: allowanceTable.body,
    });

    yPos = (pdf.autoTable.previous.finalY)
    pdf.autoTable({
      startY: yPos + 5,
      theme: 'plain',
      headStyles: {
        textColor: 0,
        fontStyle: 'bold',
        lineWidth: 0,
      },
      bodyStyles: {
        fillColor: [240, 240, 240],
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
      head: deductionTable.head,
      body: deductionTable.body,
    });

    yPos = (pdf.autoTable.previous.finalY)

    pdf.autoTable({
      startY: yPos + 5,
      theme: 'plain',
      headStyles: {
        textColor: 0,
        fontStyle: 'bold',
        lineWidth: 0,
      },
      bodyStyles: {
        fillColor: [240, 240, 240],
        lineWidth: 0.1,
        lineColor: [0, 0, 0],
      },
      head: totalSalaryTable.head,
      body: totalSalaryTable.body,
    });

    pdf.save('payroll_slip.pdf');
  };


  return (
    <VStack p={5} spacing={8} align="start">
      <HStack flexDirection={['column', 'row', 'row']}>
        <BackButtons />
        <Heading size="md">Payslip Detail : {payrollData.name}</Heading>
        <Spacer />
      </HStack>

      <Stack w={'full'} p={5} bgColor={'white'} borderRadius={'lg'} shadow={'md'} textTransform={'capitalize'} >
        <Heading size="md" my={3}>Payroll Information</Heading>
        <Box mb={4} w={'full'}>
          <HStack w={'50%'} my={2}>
            <Text w={'50%'}>
              <strong>Name</strong> :
            </Text>
            <Text>
              {'  '}{payrollData?.name}
            </Text>
          </HStack>
          <HStack w={'50%'} my={2}>
            <Text w={'50%'}>
              <strong>Level</strong> :
            </Text>
            <Text>
              {'  '}{payrollData?.level}
            </Text>
          </HStack>
          <HStack w={'50%'} my={2}>
            <Text w={'50%'}>
              <strong>Company</strong>:
            </Text>
            <Text>
              {payrollData?.organization}
            </Text>
          </HStack>
          <HStack w={'50%'} my={2}>
            <Text w={'50%'}>
              <strong>Periode</strong>:
            </Text>
            <Text>
              {moment(state?.periodeName, 'YYYY-MM')?.format('MMMM, YYYY')}
            </Text>
          </HStack>
        </Box>

      </Stack>

      <Divider />

      <Stack w={'full'} p={5} bgColor={'white'} borderRadius={'lg'} shadow={'md'}>
        <VStack spacing={4} align="start" w="100%">
          <HStack justify="space-between" w="100%">
            <Heading size="md">Salary</Heading>
            <Button colorScheme="teal" size="sm" onClick={downloadPayslip}>
              Download Payslip (PDF)
            </Button>
          </HStack>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Base Salary</Th>
                <Th isNumeric>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td></Td>
                <Td isNumeric>IDR {formatFrice(payrollData.salary)}</Td>
              </Tr>
            </Tbody>
          </Table>
          <HStack justify="space-between" w="100%">
            <Heading size="md">Allowances</Heading>

          </HStack>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Allowance</Th>
                <Th isNumeric>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payrollData?.allowance?.map((allowance, index) => (
                <Tr key={index}>
                  <Td>{allowance.title}</Td>
                  <Td isNumeric>IDR {formatFrice(allowance.amount)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </VStack>

        <VStack spacing={4} align="start" w="100%">
          <Heading size="md">Deductions</Heading>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Deduction</Th>
                <Th isNumeric>Amount</Th>
              </Tr>
            </Thead>
            <Tbody>
              {payrollData?.deduction?.map((deduction, index) => (
                <Tr key={index}>
                  <Td>{deduction.title}</Td>
                  <Td isNumeric>IDR {formatFrice(deduction.amount)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </VStack>

        <Text fontSize="lg" fontWeight="bold">
          Final Salary: IDR {formatFrice(payrollData.total)}
        </Text>

      </Stack>

    </VStack>
  );
};

export default HrisPayslipDetail;